import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { isBoolean } from '../../utils/validations';
import moment from 'moment-timezone';
import { format } from '../../utils/locale';

const SignSubmit = ({ data, eligibilityStandards, submitDate }) => {
  const hasInitialReviewAddendum = isBoolean(eligibilityStandards?.hasPricedQuotation) && !eligibilityStandards?.hasPricedQuotation && isBoolean(eligibilityStandards?.hasForm211BidPrice) && !eligibilityStandards?.hasForm211BidPrice && !!eligibilityStandards?.hasInitialReview;

  return (
    <>
      <View style={styles.container} fixed>
        <SectionHeader text='Review and Sign' />
      </View>
      <View>
        <SectionTitle text='Review OTC Markets Group Policies' />
        <Row>
          <Column size='100'>
            <Label text='I have reviewed and understand OTC Markets Group Policies on Stock Promotion, Best Practices for Issuers and Guidance on Dilution Risk.' />
            <Value text={isBoolean(data && data.hasReviewed) ? format(data.hasReviewed, null, 'bool') : 'No'} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='I have read and understand the Privacy Policy and consent to collection, use and disclosure of the information in the Background Check Authorization section to conduct background checks, and any further use established in the Privacy Policy. I further represent that any personal data provided to OTC Markets Group is in accordance with applicable privacy laws.' />
            <Value text={isBoolean(data && data.hasReviewedPrivacyPolicy) ? format(data.hasReviewedPrivacyPolicy, null, 'bool') : 'No'} />
          </Column>
        </Row>
      </View>
      <View break>
        <SectionTitle text='SIGNATURE' />
        <Row>
          <Column size='100'>
            <Label text='I confirm that the Company represents and warrants that it has reviewed, understands, and agrees to the OTC Markets Group Issuer Services Agreement and that I have been authorized by the Company and have the legal authority to execute this Agreement on behalf of the Company.' />
            <Value text={isBoolean(data && data.hasReviewedIssuerAgreement) ? format(data.hasReviewedIssuerAgreement, null, 'bool') : 'No'} />
          </Column>
        </Row>
        {hasInitialReviewAddendum && <Row>
          <Column size='100'>
            <Label text='I confirm that the Company represents and warrants that it has reviewed, understands, and agrees to the OTC Markets Group Initial Review Addendum and that I have been authorized by the Company and have the legal authority to execute this Agreement on behalf of the Company.' />
            <Value text={isBoolean(data && data.hasReviewedAddendum) ? format(data.hasReviewedAddendum, null, 'bool') : 'No'} />
          </Column>
        </Row>}
        <Row>
          <Column size='100'>
            <Label text='ACKNOWLEDGEMENT OF ELECTRONIC SIGNATURE' />
            <Value text={isBoolean(data && data.acceptedSignatured) ? format(data.acceptedSignatured, null, 'bool') : 'No'} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='Signed By ' />
            <Value text={data && data.signature} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='TITLE' />
            <Value text={data && data.signeeTitle} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='DATE' />
            <Value text={data.signatureDate ? moment(data.signatureDate).format('LL') : null} />
          </Column>
        </Row>
        <Row>
          <Text style={styles.text}>Acknowledged and agreed to by OTC Markets Group on {moment(submitDate).format('LL')}</Text>
        </Row>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    fontWeight: 200
  },
  page: {
    flexDirection: 'column'
  },
  text: {
    marginTop: 10,
    fontSize: 12
  }
});

export default SignSubmit;
