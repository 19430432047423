import React from 'react';
import PropTypes from 'prop-types';
import { urls } from '../../constants';

const ApplicationGuideLink = ({ appType }) => {
  switch (appType) {
    case 'OTCQX_US':
      return <a href={urls.OTCQXUS_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQX U.S. Application Guide</a>;
    case 'OTCQX_BANKS':
      return <a href={urls.OTCQX_BANKS_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQX U.S. Banks Application Guide</a>;
    case 'OTCQX_INTL':
      return <a href={urls.OTCQX_INTL_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQX International Application Guide</a>;
    case 'OTCQB_US':
      return <a href={urls.OTCQB_US_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQB U.S. Application Guide</a>;
    case 'OTCQB_INTL':
      return <a href={urls.OTCQB_INTL_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQB International Application Guide</a>;
    default:
      return <a href={urls.OTCQXUS_APPLICATION_GUIDE} target='_blank' rel='noopener noreferrer'>OTCQX U.S. Application Guide</a>;
  };
};

ApplicationGuideLink.propTypes = {
  appType: PropTypes.string
};

export default ApplicationGuideLink;
