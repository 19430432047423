import React from 'react';
import { View } from '@react-pdf/renderer';
import { appNames, countries, filingCyclesDesc } from '../../constants';
import { numToStr, renderAddress } from '../../utils/helper';
import moment from 'moment-timezone';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { format } from '../../utils/locale';

const CompanyInfo = ({ data, type }) => {
  const isOTCQXUS = type === appNames.OTCQX_US;
  const isOTCQXBanks = type === appNames.OTCQX_BANKS;
  const isOTCQXIntl = type === appNames.OTCQX_INTL;
  const isOTCQB = type === appNames.OTCQB;
  const isDNS = type === appNames.DNS;

  const companyAddress = data && data.companyAddress ? data.companyAddress : {};
  const address1 = companyAddress.address1 ? companyAddress.address1 : '';
  const address2 = companyAddress && companyAddress.address2 ? companyAddress.address2 : '';
  const address3 = companyAddress && companyAddress.address3 ? companyAddress.address3 : '';
  const city = companyAddress && companyAddress.city ? companyAddress.city : '';
  const state = companyAddress && companyAddress.state ? companyAddress.state : '';
  const zip = companyAddress && companyAddress.zip ? companyAddress.zip : '';
  const country = companyAddress && companyAddress.countryName ? companyAddress.countryName : companyAddress && companyAddress.country ? companyAddress.country : '';
  const hasAddress = address1 || address2 || address3 || city || state || zip || companyAddress.countryName || companyAddress.country;

  const billingAddress = data && data.billingAddress ? data.billingAddress : {};
  const billingAddress1 = billingAddress && billingAddress.address1 ? billingAddress.address1 : '';
  const billingAddress2 = billingAddress && billingAddress.address2 ? billingAddress.address2 : '';
  const billingAddress3 = billingAddress && billingAddress.address3 ? billingAddress.address3 : '';
  const billingCity = billingAddress && billingAddress.city ? billingAddress.city : '';
  const billingState = billingAddress && billingAddress.state ? billingAddress.state : '';
  const billingZip = billingAddress && billingAddress.zip ? billingAddress.zip : '';
  const billingCountry = billingAddress && billingAddress.countryName ? billingAddress.countryName : billingAddress && billingAddress.country ? billingAddress.country : '';
  const hasBillingAddress = billingAddress1 || billingAddress2 || billingAddress3 || billingCity || billingState || billingZip || billingAddress.countryName || billingAddress.country;

  let changeSecType;

  if (isOTCQXUS) changeSecType = 'OTCQX U.S.';
  if (isOTCQXBanks) changeSecType = 'OTCQX Banks';
  if (isOTCQXIntl) changeSecType = 'OTCQX International';
  if (isOTCQB) changeSecType = 'OTCQB';

  return (
    <>
      <View fixed>
        <SectionHeader text='Company Information' />
      </View>
      <View>
        <SectionTitle text='Contact Information' />
        <Row>
          <Column size='50'>
            <Label text='Phone' />
            <Value text={data && data.phoneNumber} />
          </Column>
          <Column size='50'>
            <Label text='WEBSITE URL' />
            <Value text={data && data.companyWebsiteUrl} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='Company Email' />
            <Value text={data && data.companyEmail} />
          </Column>
        </Row>
        {(isOTCQXBanks || isOTCQXUS || isOTCQB || isOTCQXIntl) && <>
          <SectionTitle text='Profile Data' />
          <Row>
            <Column size='25'>
              <Label text='# of Employees' />
              <Value text={numToStr(data.numberOfEmployees)} type='int' />
            </Column>
            <Column size='75'>
              <Label text='# of Employees (As of)' />
              <Value text={data && data.numberOfEmployeesAsOf && moment(data.numberOfEmployeesAsOf).format('LL')} />
            </Column>
          </Row>
        </>}
      </View>
      <View>
        <SectionTitle text='FISCAL YEAR END' />
        <Row>
          <Column size='100'>
            <Label text='Month/Day' />
            <Value text={data && data.fiscalYearEndMonth && data.fiscalYearEndDay ? `${data.fiscalYearEndMonth}/${data.fiscalYearEndDay}` : null} />
          </Column>
        </Row>
        <SectionTitle text='INCORPORATION INFORMATION' />
        <Row>
          <Column size='25'>
            <Label text='STATE OF INCORP.' />
            <Value text={data && data.stateOfIncorporation} />
          </Column>
          <Column size='25'>
            <Label text='COUNTRY OF INCORP.' />
            <Value text={data && data.countryOfIncorporation} />
          </Column>
          <Column size='25'>
            <Label text='Year OF INCORP.' />
            <Value text={data && data.yearOfIncorporation} />
          </Column>
        </Row>
        <SectionTitle text='Corporate Addresses' />
        <Row>
          <Column size='50'>
            <Label text='PRINCIPAL PLACE OF BUSINESS' />
            <Value text={hasAddress ? renderAddress(address1, address2, address3, city, state, zip, country) : null} />
          </Column>
          <Column size='50'>
            <Label text='Billing Address' />
            <Value text={hasBillingAddress ? renderAddress(billingAddress1, billingAddress2, billingAddress3, billingCity, billingState, billingZip, billingCountry) : null} />
          </Column>
        </Row>
      </View>
      {(isOTCQXUS || isOTCQXBanks || isOTCQB || isOTCQXIntl) && <>
        <View break>
          <SectionTitle text='DESCRIPTIONS' />
          <Row>
            <Column size='100'>
              <Label text='BUSINESS DESCRIPTION' />
              <Value text={data && data.businessDescription} />
            </Column>
          </Row>
        </View>
        <View break>
          <SectionTitle text='SECURITIES LISTED OR TRADED ON OTHER MARKETS' />
          { (isOTCQXUS || isOTCQXBanks) && <><Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Does the company have any class of securities listed on a U.S. or International exchange?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.hasOtherMarketSecurities, null, 'bool')} />
            </Column>
          </Row>
            {data.hasOtherMarketSecurities && <>
            <Row>
                <Column size='100'>
                <Label text='DESCRIPTION' />
                <Value text={data.hasOtherMarketSecuritiesDetail} />
              </Column>
              </Row>
          </>}
          </>}
          { (isOTCQB || isOTCQXIntl) && <><Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Does the company have any class of securities listed on a U.S. exchange or on a Qualified Foreign Exchange?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.hasOtherExchange, null, 'bool')} />
            </Column>
          </Row>
            {data.hasOtherExchange && <>
            <Row>
                <Column size='100'>
                <Label text='Name of the Exchange' />
                <Value text={data.hasOtherExchangeName} />
              </Column>
              </Row>
            <Row>
                <Column size='100'>
                <Label text='Tier (if applicable)' />
                <Value text={data.hasOtherExchangeTier} />
              </Column>
              </Row>
            <Row>
                <Column size='50'>
                <Label text='Trading Symbol' />
                <Value text={data.hasOtherExchangeSymbol} />
              </Column>
                <Column size='50'>
                <Label text='Date Listed' />
                <Value text={data && data.hasOtherExchangeDate && moment(data.hasOtherExchangeDate).format('LL')} />
              </Column>
              </Row>
            <Row>
                <Column size='50'>
                <Label text='Filing Cycle' />
                <Value text={data.hasOtherExchangeCycle && filingCyclesDesc.get(data.hasOtherExchangeCycle)} />
              </Column>
                <Column size='50'>
                <Label text='Accounting Standard' />
                <Value text={data.hasOtherExchangeAccount} />
              </Column>
              </Row>
          </>}
          </>}
          <SectionTitle text='CHANGE IN CONTROL' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text={`Has the company undergone a Change in Control event and is re-applying for ${changeSecType}?`} />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.changeInControl, null, 'bool')} />
            </Column>
          </Row>
          {data.changeInControl && <Row>
            <Column size='100'>
              <Label text='DESCRIPTION' />
              <Value text={data && data.changeInControlDetail} />
            </Column>
          </Row>}
          <SectionTitle text='DELISTING FROM A NATIONAL SECURITIES EXCHANGE' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Is the company delisting from a National Securities Exchange?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.isDelistFromMajorExchange, null, 'bool')} />
            </Column>
          </Row>
          {data.isDelistFromMajorExchange && <>
            <Row>
              <Column size='100'>
                <Label text='DESCRIPTION' />
                <Value text={data && data.isDelistFromMajorExchangeDetail} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='DESCRIPTION' />
                <Value text={data && data.isDelistFromMajorExchangeConcerns} />
              </Column>
            </Row>
          </>}
        </View>
      </>}
      { (isDNS) && <>
        <View break>
          <SectionTitle text='SECURITIES LISTED OR TRADED ON OTHER MARKETS' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Does the company have any class of securities listed on a Qualified Foreign Exchange?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.hasOtherExchange, null, 'bool')} />
            </Column>
          </Row>
          {data.hasOtherExchange && <>
            <Row>
              <Column size='100'>
                <Label text='Name of the Exchange' />
                <Value text={data.hasOtherExchangeName} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Tier (if applicable)' />
                <Value text={data.hasOtherExchangeTier} />
              </Column>
            </Row>
            <Row>
              <Column size='50'>
                <Label text='Trading Symbol' />
                <Value text={data.hasOtherExchangeSymbol} />
              </Column>
              <Column size='50'>
                <Label text='Date Listed' />
                <Value text={data && data.hasOtherExchangeDate && moment(data.hasOtherExchangeDate).format('LL')} />
              </Column>
            </Row>
            <Row>
              <Column size='50'>
                <Label text='Filing Cycle' />
                <Value text={data.hasOtherExchangeCycle && filingCyclesDesc.get(data.hasOtherExchangeCycle)} />
              </Column>
              <Column size='50'>
                <Label text='Accounting Standard' />
                <Value text={data.hasOtherExchangeAccount} />
              </Column>
            </Row>
          </>}
        </View>
        <View break>
          <SectionTitle text='SECURITIES LISTED OR TRADED ON US NATIONAL EXCHANGE' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Does the company have any class of securities listed on a US National Exchange?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={data && format(data.hasUsExchange, null, 'bool')} />
            </Column>
          </Row>
          {data.hasUsExchange && <>
            <Row>
              <Column size='100'>
                <Label text='Name of the Exchange' />
                <Value text={data.hasUsExchangeName} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Tier (if applicable)' />
                <Value text={data.hasUsExchangeTier} />
              </Column>
            </Row>
            <Row>
              <Column size='50'>
                <Label text='Trading Symbol' />
                <Value text={data.hasUsExchangeSymbol} />
              </Column>
              <Column size='50'>
                <Label text='Date Listed' />
                <Value text={data && data.hasUsExchangeDate && moment(data.hasUsExchangeDate).format('LL')} />
              </Column>
            </Row>
            <Row>
              <Column size='75'>
                <Label text='Question' />
                <Value text='Is the security expected to delist from the exchange?' />
              </Column>
              <Column size='25'>
                <Label text='Answer' />
                <Value text={data && format(data.isDelistFromUsExchange, null, 'bool')} />
              </Column>
            </Row>
            {data.isDelistFromUsExchange && <>
              <Row>
                <Column size='50'>
                  <Label text='Expected Delist Date' />
                  <Value text={data && data.isDelistFromUsExchangeDateUnknown === true ? 'Delist Date Unknown' : (data.isDelistFromUsExchangeDate && moment(data.isDelistFromUsExchangeDate).format('LL'))} />
                </Column>
              </Row>
            </>}
          </>}
        </View>
      </>}
    </>
  );
};

export default CompanyInfo;
