import React, { useEffect, useReducer, createContext } from 'react';
import { applicationSectionsComplete } from '../constants/cardComplete';
import { isAppReadOnly } from '../utils/helper';
export const ApplicationContext = createContext();

const initialState = {
  isLoaded: false,
  error: null,
  backupApplication: null,
  application: null,
  documents: null,
  owners: null,
  readOnly: null,
  showIncompletes: false,
  constants: {
    countries: null,
    states: null,
    serviceProviders: null
  },
  sessionPayment: null,
  completeCards: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_APPLICATION':
      return {
        ...state,
        isLoaded: true,
        backupApplication: action.original,
        application: action.payload,
        readOnly: isAppReadOnly(action.payload && action.payload.status)
      };
    case 'SET_COMPLETE_CARDS':
      return {
        ...state,
        completeCards: action.payload
      };
    case 'UPDATE_APPLICATION':
      const appField = action.appField;

      return {
        ...state,
        application: {
          ...state.application,
          ...(appField !== 'signSubmit' && appField != 'payment') && {
            signSubmit: {
              ...state.application.signSubmit,
              acceptedSignatured: false,
              signature: null,
              signatureDate: null,
              signeeTitle: null
            }
          },
          [appField]: action.payload
        }
      };
    case 'REMOVE_APPLICATION':
      return initialState;
    case 'SET_DOCUMENTS':
      return {
        ...state,
        documents: action.payload
      };
    case 'SET_OWNERS':
      return {
        ...state,
        owners: action.payload
      };
    case 'SET_INCOMPLETES':
      return {
        ...state,
        showIncompletes: action.payload
      };
    case 'SET_SERVICE_PROVIDERS':
      return {
        ...state,
        constants: {
          ...state.constants,
          serviceProviders: action.payload
        }
      };
    case 'SET_SESSION_PAYMENT':
      return {
        ...state,
        sessionPayment: action.payload
      };
    default:
      throw new Error();
  }
};

export const ApplicationContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.application) {
      const cardsCompleted = applicationSectionsComplete(state.application, state.documents, state.completeCards);
      dispatch({
        type: 'SET_COMPLETE_CARDS',
        payload: cardsCompleted
      });
    }
  }, [state.application, state.documents]);

  return (
    <ApplicationContext.Provider value={[state, dispatch]}>
      {props.children}
    </ApplicationContext.Provider>
  );
};
