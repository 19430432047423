import React from 'react';
import moment from 'moment-timezone';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Footer = ({ companyName, hidePageNumber }) => {
  let currentDate = moment();
  return (
    <>
      <View style={styles.container} fixed>
        <View style={styles.left}>
          <Text style={styles.company}>
            {companyName}
          </Text>
          <Text style={styles.timestamp}>
            Generated on {currentDate.format('LL')} at {currentDate.format('LTS')}
          </Text>
        </View>
        {!hidePageNumber && <View style={styles.right} fixed>
          <Text style={styles.page} render={({ pageNumber }) => (
            pageNumber
          )} />
        </View>}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    bottom: 30,
    left: 72,
    right: 0
  },
  left: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  right: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  company: {
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 200,
    color: '#000000'
  },
  timestamp: {
    fontSize: 10,
    fontWeight: 200,
    color: '#000000'
  },
  page: {
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 200,
    color: '#000000'
  }
});

export default Footer;
