import api from './api';

export async function getApplicationDocs (appId, email) {
  const response = await api.request({
    method: 'get',
    url: '/app-portal/document/list/application',
    params: {
      email,
      appId
    }
  });

  return response.data;
};

export async function getUserDocs (email) {
  const response = await api.request({
    method: 'get',
    url: '/app-portal/document/list/user',
    params: {
      email
    }
  });

  return response.data;
};

export async function deleteApplicationDoc (docId, appId, email) {
  const response = await api.request({
    method: 'delete',
    url: '/app-portal/document/delete/application',
    params: {
      docId,
      appId,
      email
    }
  });

  return response.data;
};

export async function uploadApplicationDoc (typeId, appId, email, file, attributes) {
  let formData = new FormData();

  formData.append('file', file);

  const response = await api.request({
    method: 'post',
    url: '/app-portal/document/upload/application',
    params: {
      typeId,
      appId,
      email,
      attributes: JSON.stringify(attributes)
    },
    data: formData
  });
  return response.data;
};

export async function uploadUserDoc (typeId, email, file, attributes) {
  let formData = new FormData();

  formData.append('file', file);

  const response = await api.request({
    method: 'post',
    url: '/app-portal/document/upload/user',
    params: {
      typeId,
      email,
      attributes: JSON.stringify(attributes)
    },
    data: formData
  });
  return response.data;
};
