import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { appNames } from '../../constants';
import LOGO_OTCQXUS from './LOGO_OTCQXUS';
import LOGO_OTCQXINTL from './LOGO_OTCQXINTL';
import LOGO_OTCQXBanks from './LOGO_OTCQXBanks';

const HeaderOTCQX = ({ type }) => {
  const isUS = type === appNames.OTCQX_US;
  const isBanks = type === appNames.OTCQX_BANKS;
  const isIntl = type === appNames.OTCQX_INTL;

  return (
    <View style={styles.header} fixed>
      <View style={styles.headerImg}>
        {isUS && <LOGO_OTCQXUS />}
        {isBanks && <LOGO_OTCQXBanks />}
        {isIntl && <LOGO_OTCQXINTL />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    display: 'block',
    backgroundColor: '#00a88f',
    left: 0,
    top: 0,
    right: 0,
    width: '100vw',
    height: '72px'
  },
  headerImg: {
    position: 'absolute',
    top: 42,
    left: 72
  }
});

export default HeaderOTCQX;
