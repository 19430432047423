import api from './api';

export async function findCompany (search) {
  let query;

  if (search.includes(' ')) {
    query = `comp_name:${search.replace(/ /g, String.raw`\ `)}*`;
  } else {
    query = `comp_name:${search}* OR symbol:${search}*`;
  }

  const response = await api.request({
    method: 'get',
    url: '/company/solr/app_portal/select',
    params: {
      q: query,
      rows: 5
    }
  });
  return response.data;
}
