import React, { useState, useEffect, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import cn from 'classnames';
import { entities } from '../../../constants';
import { isDirectorComplete } from '../../../constants/cardComplete';
import Title from '../../Title';
import Entity from '../../Entity';
import SlidePanel from '../../SlidePanel';
import FieldError from '../../FieldError';
import AddDirector from './AddDirector';
import styles from '../Application.module.scss';

const DirectorsTab = ({ peopleList, onPersonAdd, readOnly, onPersonUpdate, onPersonRemove }) => {
  const [state] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [directorView, setDirectorView] = useState(null);
  const [isChairPersonView, setChairpersonView] = useState(false);
  const [chairpersonListFocus, setChairpersonListFocus] = useState(false);
  const [directorListFocus, setDirectorListFocus] = useState(false);
  const application = state.application;
  const isOTCQB = application.typeId === 4;
  const isDNS = application.typeId === 5;
  const officerList = peopleList && peopleList.filter(person => person.isOfficer && !person.isDirector);
  const addTitle = isChairPersonView ? 'Add Chairperson' : 'Add Director';
  const chairpersonList = peopleList && peopleList.filter(person => person.isDirector && person.isChairPerson);
  const directorList = peopleList && peopleList.filter(person => person.isDirector && !person.isChairPerson);
  const hasChairperson = chairpersonList && chairpersonList.length > 0;
  const hasDirectors = directorList && directorList.length > 0;
  const minDirectors = isOTCQB ? 1 : isDNS ? 0 : 2;
  const hasMinDirectors = chairpersonList && directorList && chairpersonList.length + directorList.length >= minDirectors;
  const minDirectorsText = `At least ${isOTCQB ? 'one' : 'two'} members are required on the Board of Directors.`;
  let directorTitle = `${directorView && directorView.firstName} ${directorView && directorView.lastName}`;
  const showIncompletes = state.showIncompletes;

  if (!directorTitle || directorTitle.trim().length === 0) directorTitle = entities.NO_NAME;

  useEffect(() => {
    if (!slidePane) {
      setDirectorView(null);
      setChairpersonView(false);
    }
  }, [slidePane]);

  const handleUpdate = director => {
    if (isDirectorComplete(director)) director.isDirectorVerified = true;
    onPersonUpdate(director);
    setSlidePane(false);
  };

  const handleAdd = director => {
    if (isDirectorComplete(director)) director.isDirectorVerified = true;
    onPersonAdd(director);
    setSlidePane(false);
  };

  const handleEdit = person => {
    setDirectorView(person);
    setSlidePane(true);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation();
    onPersonRemove(person, 'isChairPerson');
  };

  const renderList = type => {
    const list = type === 'chairpersons' ? chairpersonList : directorList;
    return <div className={styles.entityList}>
      {list.map((person, i) => {
        const isComplete = isDirectorComplete(person);
        const isVerified = person.isDirectorVerified;
        let subTitle = 'Enter Information';
        let title = `${person.firstName} ${person.lastName}`;
        if (!title || title.trim().length === 0) title = entities.NO_NAME;
        if (isComplete) {
          if (isVerified) {
            subTitle = 'Complete';
          } else {
            subTitle = 'Verify Information';
          }
        }

        const onRemoveClick = e => handleRemove(e, person);
        let entityIcon = 'user';

        if (isComplete) entityIcon = 'userComplete';
        if (showIncompletes && !isComplete) entityIcon = 'incomplete';

        return <Entity
          key={i}
          title={title}
          showIncompletes={showIncompletes && !isComplete}
          icon={entityIcon}
          isComplete={(isComplete && isVerified)}
          subTitle={subTitle}
          subTitleHighlight={!(isComplete && isVerified)}
          confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
          onClick={() => handleEdit(person)}
          handleRemove={!readOnly && onRemoveClick} />;
      })}
    </div>;
  };

  const slideChairpersonPanel = () => {
    setChairpersonView(true);
    setSlidePane(true);
  };

  return (
    <div>
      <div
        onMouseEnter={() => setChairpersonListFocus(true)}
        onMouseLeave={() => setChairpersonListFocus(false)}>
        <div className={cn({
          [styles.incomplete]: (showIncompletes && !hasMinDirectors)
        })}>
          <Title className={styles.inline} title='Chairpersons' type='h3' />
          {(showIncompletes && !hasMinDirectors) && <FieldError error={minDirectorsText} isFocus={chairpersonListFocus} />}
        </div>
        {hasChairperson && renderList('chairpersons')}
        {!readOnly && <Entity
          title='Add Chairperson'
          isAdd
          onClick={slideChairpersonPanel} />}
        {readOnly && !hasChairperson && <Entity
          title='No Chairpersons'
          isEmpty />}
      </div>
      <div
        className='mtXL'
        onMouseEnter={() => setDirectorListFocus(true)}
        onMouseLeave={() => setDirectorListFocus(false)}>
        <div className={cn({
          [styles.incomplete]: (showIncompletes && !hasMinDirectors)
        })}>
          <Title className={styles.inline} title='Directors' type='h3' />
          {(showIncompletes && !hasMinDirectors) && <FieldError error={minDirectorsText} isFocus={directorListFocus} />}
        </div>
        {hasDirectors && renderList()}
        {!readOnly && <Entity
          title='Add Director'
          isAdd
          onClick={() => setSlidePane(true)} />}
        {readOnly && !hasDirectors && <Entity
          title='No Directors'
          isEmpty />}
      </div>
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={directorView ? directorTitle : addTitle}>
        <AddDirector
          directorView={directorView}
          setDirectorView={setDirectorView}
          officerList={officerList}
          isChairPerson={isChairPersonView}
          readOnly={readOnly}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          setSlidePane={setSlidePane} />
      </SlidePanel>
    </div>
  );
};

export default DirectorsTab;
