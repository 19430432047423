import React from 'react';
import cn from 'classnames';
import TextBox from '../TextBox';
import styles from './PhoneInput.module.scss';

export default function Field (data) {
  return (
    <div className={cn(styles.select, 'f16')}>
      <div className={cn('flag', styles.countryField, `${data && data.id && data.id.toLowerCase()}`)} />
      <div className={styles.hide}>
        <TextBox
          className={styles.countryField}
          defaultValue=''
          readOnly />
      </div>
    </div>
  );
}
