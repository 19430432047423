import React from 'react';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const BillingContacts = ({ data }) => {
  const primaryContact = data && data.find(item => item.isPrimaryBillingContact);
  const additionalContacts = data && data.filter(item => item.isOtherBillingContact);
  const hasAdditionalContacts = additionalContacts && additionalContacts.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='DESIGNATE CONTACTS' />
      </View>
      <View>
        <SectionTitle text='Billing Contacts | Primary Contact' />
        {primaryContact &&
        <>
          <Row>
            <Column size='50'>
              <Label text='Name' />
              <Value text={primaryContact && renderFullName(primaryContact.firstName, primaryContact.middleName, primaryContact.lastName)} />
            </Column>
            <Column size='50'>
              <Label text='COMPANY AFFILIATION' />
              <Value text={primaryContact && primaryContact.title1} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Phone Number' />
              <Value text={primaryContact && primaryContact.phone} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Email' />
              <Value text={primaryContact && primaryContact.email} />
            </Column>
          </Row>
          <Divider />
        </>
        }
        {!primaryContact && <Value text={null} />}

      </View>
      {hasAdditionalContacts && <>
        <View fixed>
          <SectionTitle text='Billing Contacts | Additional' />
        </View>
        {additionalContacts.map((person, i) => {
          const pageBreak = i !== 0 && isInt(i / 4);

          return (
            <View break={pageBreak} key={`billing-${i}`}>
              <Row>
                <Column size='50'>
                  <Label text='Name' />
                  <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
                </Column>
                <Column size='50'>
                  <Label text='COMPANY AFFILIATION' />
                  <Value text={person.title1} />
                </Column>
              </Row>
              <Row>
                <Column size='100'>
                  <Label text='Phone Number' />
                  <Value text={person.phone} />
                </Column>
              </Row>
              <Row>
                <Column size='100'>
                  <Label text='Email' />
                  <Value text={person.email} />
                </Column>
              </Row>
              <Divider />
            </View>
          );
        })}
      </>
      }
    </>
  );
};

export default BillingContacts;
