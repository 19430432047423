import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import cn from 'classnames';
import FieldError from '../FieldError';
import { TextBox as TextBoxDevEx } from 'devextreme-react';
import { errors } from '../../constants';
import styles from './TextBox.module.scss';

const TextBox = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || props.value.trim().length === 0);

  const handleFocusIn = e => {
    props.onFocusIn && props.onFocusIn();
    setFocus(true);
  };

  const handleFocusOut = e => {
    props.onFocusOut && props.onFocusOut();
    setFocus(false);
  };

  const handleValueChange = e => {
    const event = e;
    e.value = e.value && e.value.trim();
    props.onValueChanged && props.onValueChanged(event);
  };

  const handleOptionChanged = e => {
    const event = e;
    if (e.name === 'text' && e.value) {
      e.value = e.value && e.value.trim();
      props.onOptionChanged && props.onOptionChanged(event);
    }
  };

  return <div>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} size={props.labelSize} />}
      {!props.hideErrorIcon && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <TextBoxDevEx
      {...props}
      inputAttr={{
        autocomplete: 'no'
      }}
      className={cn({
        [styles.incomplete]: props.isIncomplete
      })}
      labelMode='hidden'
      isValid={showRequired ? !!(props.value && props.value.trim().length > 0) : props.isValid}
      onFocusIn={handleFocusIn}
      onFocusOut={handleFocusOut}
      onValueChanged={handleValueChange}
      onOptionChanged={handleOptionChanged}
      showClearButton={props.showClearButton && isFocus}>
      {props.children && props.children}
    </TextBoxDevEx>
  </div>;
};

TextBox.defaultProps = {
  isValid: true
};

TextBox.propTypes = {
  onValueChanged: PropTypes.func,
  onOptionChanged: PropTypes.func,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  labelString: PropTypes.string,
  children: PropTypes.node,
  showClearButton: PropTypes.bool,
  hideErrorIcon: PropTypes.bool,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isIncomplete: PropTypes.bool
};

export default TextBox;
