import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes as faTimesLight,
  faCheck as faCheckLight,
  faUser as faUserLight,
  faUserCheck as faUserCheckLight,
  faFileCertificate as faFileCertificateLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faFile as faFileLight,
  faFileCheck as faFileCheckLight,
  faQuestionCircle as faQuestionCircleLight,
  faSave as faSaveLight,
  faUserFriends as faUserFriendsLight
} from '@fortawesome/pro-light-svg-icons';

import {
  faPlus as faPlusReg,
  faLongArrowLeft as faLongArrowLeftReg,
  faLongArrowRight as faLongArrowRightReg,
  faPlusCircle as faPlusCircleReg,
  faCheckCircle as faCheckCircleReg,
  faChevronDown as faChevronDownReg,
  faCheck as faCheckReg,
  faEnvelope as faEnvelopeReg,
  faPhone as faPhoneReg
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCircle as faCircleSolid,
  faCaretDown as faCaretDownSolid
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faPlusReg,
  faTimesLight,
  faCheckLight,
  faCheckReg,
  faEnvelopeReg,
  faPhoneReg,
  faLongArrowLeftReg,
  faLongArrowRightReg,
  faPlusCircleReg,
  faChevronDownReg,
  faCheckCircleReg,
  faFileLight,
  faFileCheckLight,
  faFileCertificateLight,
  faQuestionCircleLight,
  faUserFriendsLight,
  faSaveLight,
  faExclamationTriangleLight,
  faUserLight,
  faUserCheckLight,
  faCircleSolid,
  faCaretDownSolid
);
