import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from '../../WithRouter';
import equal from 'fast-deep-equal/es6';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../../../context/Application.context';
import { NotificationsContext } from '../../../context/Notifications.context';
import PrimaryContactsTab from './PrimaryContactsTab';
import AuthorizedUsersTab from './AuthorizedUsersTab';
import BillingContactsTab from './BillingContactsTab';
import ApplicationDescription from '../../ApplicationDescription';
import Label from '../../Label';
import TabMenu from '../../TabMenu';
import { createID } from '../../../utils/helper';

const tabItems = [
  {
    title: 'Primary Contacts',
    path: 'primary-contacts',
    component: PrimaryContactsTab
  },
  {
    title: 'Authorized Users',
    path: 'authorized-users',
    component: AuthorizedUsersTab
  },
  {
    title: 'Billing Contacts',
    path: 'billing-contacts',
    component: BillingContactsTab
  }
];

const DesignateContacts = ({ navigate, match: { params: { appId, appSection, appTab } }, name, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const hasIncompleteNotification = notificationState.notifications.find(n => n.type === 'incompletes');
  const currentTab = tabItems.find(item => item.path === appTab);
  const [activeTab, setActiveTab] = useState(currentTab || tabItems[0]);
  const application = state.application;
  const typeId = application.typeId;
  const appTypeName = application.typeDesc;
  const isOTCQX = typeId === 1 || typeId === 2 || typeId === 3;
  const isOTCQB = typeId === 4;
  const isDNS = typeId === 5;
  const backupApplication = state.backupApplication;
  const peopleList = application.peopleList;
  const completeCards = state.completeCards;
  let maxSection = 6;
  let isReady = !!(completeCards && completeCards['company-information'] &&
    completeCards['reporting-standards'] &&
    completeCards['officers-directors-control-persons'] &&
    completeCards['service-providers'] &&
    activeTab);

  if (isOTCQX) isReady = isReady && completeCards['financial-standards'];
  if (isOTCQX || isOTCQB) isReady = isReady && completeCards['eligibility-standards'];
  if (isOTCQB) maxSection = 5;
  if (isDNS) {
    maxSection = 5;
    isReady = isReady && completeCards['change-in-control'];
  }

  useEffect(() => {
    if (!equal({ ...backupApplication.peopleList }, { ...application.peopleList })) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    const newTab = tabItems.find(item => item.path === appTab);
    setActiveTab(newTab);
  }, [appTab]);

  useEffect(() => {
    activeTab && navigate(`/application/${appId}/${appSection}/${activeTab.path}`);
  }, [activeTab]);

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'peopleList',
      card: 'designate-contacts'
    });
  };

  const onPersonAdd = person => {
    const newPerson = person;
    const id = createID(peopleList);

    newPerson.id = id;
    onUpdate([...peopleList, newPerson]);
  };

  const onPersonUpdate = person => {
    let updatePeopleList = peopleList;

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id === person.id) return person;

      return item;
    });

    onUpdate(updatePeopleList);
  };

  const onPersonRemove = (person, type) => {
    const updatePeopleList = peopleList;
    const personUpdate = updatePeopleList.find(item => item.id === person.id);
    // Potentially fully remove person if they are no longer officer, director or control person...
    personUpdate[type] = false;
    onUpdate(updatePeopleList);
  };

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const handleIncompleteClick = () => {
    !hasIncompleteNotification && dispatchNotification({
      type: 'ADD_NOTIFICATION',
      payload: {
        id: new Date().getTime(),
        description: 'You are now able to view all Incomplete Data Fields',
        type: 'incompletes'
      }
    });

    dispatch({
      type: 'SET_INCOMPLETES',
      payload: true
    });
  };

  const renderTab = () => {
    const Component = activeTab.component;
    return <Component
      appTypeName={appTypeName}
      peopleList={peopleList}
      onPersonAdd={onPersonAdd}
      onPersonUpdate={onPersonUpdate}
      onPersonRemove={onPersonRemove} />;
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc='Designate Primary Contact, Authorized User, and Billing Contacts below.' />
      {!isReady && <Label isError>
        <b>
          Please complete Sections 1 through {maxSection} in order to access the Designate Contacts section. If you want to view all the incomplete fields on the application, <a onClick={handleIncompleteClick}>Click Here</a>
        </b>
      </Label>}
      {isReady && <>
        <TabMenu items={tabItems} activeTab={activeTab.title} handleTabClick={handleTabClick} />
        {renderTab()}
      </>}
    </div>
  );
};

DesignateContacts.propTypes = {
  name: PropTypes.string,
  match: PropTypes.object,
  saveApplication: PropTypes.func

};

export default withRouter(DesignateContacts);
