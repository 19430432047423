import React, { useContext, useState } from 'react';
import moment from 'moment-timezone';
import parsePhoneNumber from 'libphonenumber-js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateAwsUser } from '../../api/auth';
import { AuthContext } from '../../context/Auth.context';
import { NotificationsContext } from '../../context/Notifications.context';
import { errors, routes, titles, urls, phoneNumberRegex } from '../../constants';
import DialogBox from '../../components/DialogBox';
import Label from '../../components/Label';
import PhoneInput from '../../components/PhoneInput';
import Title from '../../components/Title';
import Entity from '../../components/Entity';
import styles from './ManageMFA.module.scss';

export const checkPhoneNum = (phoneNumber) => {
  return phoneNumberRegex.test(phoneNumber);
};

const ManageMFA = () => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const userPhoneNumber = authState.phoneNumber;
  const [isChangeSuccess, setChangeSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const parsedNumber = userPhoneNumber && parsePhoneNumber(userPhoneNumber);
  const country = parsedNumber ? parsedNumber.country : 'us';
  const [editedPhone, setEditedPhone] = useState(userPhoneNumber);
  const [editDialog, toggleEditDialog] = useState(false);
  const isIQUser = authState.iq;
  const isAWSUser = authState.aws;

  const handleInputChage = value => {
    setEditedPhone(`${value}`);
  };

  const handleEditClick = () => {
    if (error) setError(false);
    if (phoneError) setPhoneError(false);
    if (isChangeSuccess) setChangeSuccess(false);
    toggleEditDialog(true);
  };

  const handleUpdatePhone = () => {
    const validPhone = checkPhoneNum(editedPhone);
    if (!validPhone) {
      setPhoneError(errors.INVALID_PHONE_NUMBER);
      return;
    }
    const attributes = {
      phone_number: editedPhone
    };
    const id = new Date().getTime();

    updateAwsUser(authState.session, attributes)
      .then(() => {
        setChangeSuccess(true);
        authDispatch({
          type: 'UPDATE_USER',
          payload: editedPhone
        });

        dispatchNotification({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: id,
            description: `Your settings have been saved. ${moment().format('MMMM D, YYYY')}`,
            type: 'save'
          }
        });

        setTimeout(() => {
          dispatchNotification({
            type: 'REMOVE_NOTIFICATION',
            id: id
          });
        }, 3000);
      }).catch(e => setError(e.message || errors.TRY_AGAIN));
    toggleEditDialog(false);
  };

  const renderIQ = () => <>
    <p>
      Your account is associated with OTCIQ credentials. To manage your MFA click the link below to be taken to the OTCIQ Profile page.
    </p>
    <Label className='mtXL'>
      <b>
        You need to go to OTCIQ to manage your MFA. <a href={urls.IQ_PROFILE} target='_blank' rel='noopener noreferrer'>Click Here to Go to OTCIQ Profile</a>
      </b>
    </Label>
  </>;

  const renderAWS = () => <>
    <Title title='Phone Number for Multi-Factor Authentication' type='h2' />
    <p>
      The system will send a text containing the MFA code to the number provided. Please make sure this is a number that can receive texts.
    </p>
    <div className={styles.addBtn}>
      <Entity title={userPhoneNumber} isComplete={isChangeSuccess} icon={isChangeSuccess ? 'check' : undefined} subTitle='Edit' size='small' onClick={handleEditClick} />
      {editDialog &&
      <DialogBox
        title={titles.EDIT_PHONE_NUMBER}
        onCancelClick={() => toggleEditDialog(false)}
        onSubmitClick={handleUpdatePhone}>
        <PhoneInput
          countryCodeValue={country}
          phoneValue={editedPhone}
          onValueChanged={handleInputChage} />
        {phoneError && <Label title={phoneError} isError />}
      </DialogBox>
      }
    </div>
    {error && <Label title={error} isError />}
  </>;

  return <div className={styles.container}>
    {isIQUser && renderIQ()}
    {isAWSUser && renderAWS()}

    <div className='mtXXL'>
      <Link to={routes.MY_APPLICATIONS}>
        <FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back to My Applications
      </Link>
    </div>
  </div>;
};

ManageMFA.propTypes = {

};

export default ManageMFA;
