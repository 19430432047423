import React from 'react';
import PrivateRoute from './components/PrivateRoute';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { routes } from './constants';
import PdfViewer from './pdfTemplates/PdfViewer';
import ApplicationPage from './containers/ApplicationPage';
import DashboardPage from './containers/DashboardPage';
import SignInPage from './containers/SignInPage';
import CreateAccountPage from './containers/CreateAccountPage';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import AccountSettings from './containers/AccountSettings';
import ConfirmAccountPage from './containers/ConfirmAccountPage';
import MicroUIWrapper from './containers/MicroUIWrapper';
import { TypesContextProvider } from './context/Types.context';
import { NotificationsContextProvider } from './context/Notifications.context';
import { ApplicationContextProvider } from './context/Application.context';
import { AuthProvider } from './context/Auth.context';
import Layout from './components/Layout';
import styles from './App.module.scss';
import './styles/otc-theme.scss';

const App = () => {
  const isDevEnv = process.env.NODE_ENV === 'development';

  return (
    <div className='otc-theme'>
      <div className={styles.app}>
        <NotificationsContextProvider>
          <AuthProvider>
            <TypesContextProvider>
              <ApplicationContextProvider>
                <BrowserRouter>
                  <Layout>
                    <Routes>
                      {isDevEnv && <Route path='/pdf' element={<PdfViewer />} />}
                      <Route path={routes.HOME} element={<SignInPage />} />
                      <Route path={routes.SIGNIN} element={<SignInPage />} />
                      <Route path={routes.SIGNUP} element={<CreateAccountPage />} />
                      <Route path={routes.CONFIRM_ACCOUNT} element={<ConfirmAccountPage />} />
                      <Route path={routes.FORGOT_PWD} element={<ForgotPasswordPage />} />
                      <Route path='/' element={<PrivateRoute />}>
                        <Route path={routes.DASHBOARD} element={<DashboardPage />} />
                        <Route path={routes.APPLICATION} element={<ApplicationPage />} />
                        <Route path={routes.ACCOUNT_SETTINGS} element={<AccountSettings />} />
                        <Route path={routes.MICRO_APP} element={<MicroUIWrapper />} />
                      </Route>
                      <Route path='*' element={<Navigate to={'/'} replace />} />
                    </Routes>
                  </Layout>
                </BrowserRouter>
              </ApplicationContextProvider>
            </TypesContextProvider>
          </AuthProvider>
        </NotificationsContextProvider>
      </div>
    </div>
  );
};

export default App;
