import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { entities, reportingStandards, urls } from '../../../constants';
import { isUSAorCAN, validateCompleteServiceProvider } from '../../../constants/cardComplete';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import EntityList from '../../EntityList';
import SlidePanel from '../../SlidePanel';
import AddServiceProvider from './AddServiceProvider';
import { createID } from '../../../utils/helper';

const providers = [
  {
    typeId: '6',
    title: 'Transfer Agent',
    addTitle: 'Add Transfer Agent',
    key: 'transferAgents',
    isRequired: app => app && app.companyInfo && app.companyInfo.countryOfIncorporation && isUSAorCAN(app.companyInfo.countryOfIncorporation),
    appTypes: [1, 2, 3, 4]
  },
  {
    typeId: '6',
    title: 'Transfer Agent, Registrar or Depositary Bank',
    addTitle: 'Add Transfer Agent, Registrar or Depositary Bank',
    key: 'transferAgents',
    isRequired: app => app && app.companyInfo && app.companyInfo.countryOfIncorporation && isUSAorCAN(app.companyInfo.countryOfIncorporation),
    appTypes: [5]
  },
  {
    typeId: '5',
    title: 'Auditor',
    addTitle: 'Add Auditor',
    key: 'auditors',
    isRequired: true,
    appTypes: [1, 2, 3, 4]
  },
  {
    typeId: '3',
    title: 'Securities Counsel',
    addTitle: 'Add Securities Counsel',
    key: 'securitiesCounsels',
    isRequired: true,
    appTypes: [1, 2, 4]
  },
  {
    typeId: '3',
    title: 'Securities Counsel',
    addTitle: 'Add Securities Counsel',
    key: 'securitiesCounsels',
    appTypes: [3]
  },
  {
    typeId: '7',
    title: 'Corporate Broker',
    addTitle: 'Add Corporate Broker',
    key: 'corporateBroker',
    max: 1,
    appTypes: [2, 4]
  },
  {
    typeId: '2',
    title: 'Investor Relations Services',
    addTitle: 'Add Investor Relations Services',
    key: 'investorRelations',
    appTypes: [1, 2, 3, 4]
  },
  {
    typeId: '1',
    title: 'Investment Bank',
    addTitle: 'Add Investment Bank',
    key: 'investmentBanks',
    appTypes: [1, 2, 3, 4]
  }
];

const ServiceProviders = ({ name, company, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerView, setProviderView] = useState(null);
  const application = state.application;
  const isOTCQXBanks = application.typeId === 2;
  const isOTCQB = application.typeId === 4;
  const isDNS = application.typeId === 5;
  const backupApplication = state.backupApplication;
  const providerList = providers.filter(option => option.appTypes.includes(application.typeId));
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  useEffect(() => {
    const backup = {
      transferAgents: backupApplication.transferAgents,
      auditors: backupApplication.auditors,
      securitiesCounsels: backupApplication.securitiesCounsels,
      qxSponsor: backupApplication.qxSponsor,
      qbSponsor: backupApplication.qbSponsor,
      investorRelations: backupApplication.investorRelations,
      investmentBanks: backupApplication.investmentBanks,
      corporateBroker: backupApplication.corporateBroker
    };

    const current = {
      transferAgents: application.transferAgents,
      auditors: application.auditors,
      securitiesCounsels: application.securitiesCounsels,
      qxSponsor: application.qxSponsor,
      qbSponsor: application.qbSponsor,
      investorRelations: application.investorRelations,
      investmentBanks: application.investmentBanks,
      corporateBroker: application.corporateBroker
    };

    if (!equal(backup, current)) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    if (selectedProvider) setSlidePane(true);
  }, [selectedProvider]);

  useEffect(() => {
    if (!slidePane) {
      setProviderView(null);
      setSelectedProvider(null);
    }
  }, [slidePane]);

  const onUpdate = (data, field) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: field,
      card: 'service-providers'
    });
  };

  const onProviderAdd = data => {
    const provider = selectedProvider.key;
    const newProvider = data;
    const id = createID(application[provider]);
    newProvider.id = id;
    onUpdate([...application[provider] || [], newProvider], provider);
    setSlidePane(false);
  };

  const onProviderUpdate = data => {
    const provider = selectedProvider.key;
    let updateProvderList = application[provider];

    updateProvderList = updateProvderList.map(provider => {
      if (provider.id === data.id) return data;

      return provider;
    });

    onUpdate(updateProvderList, provider);

    setSlidePane(false);
  };

  const handleEdit = (data, provider) => {
    setSelectedProvider(provider);
    setProviderView(data);
    setSlidePane(true);
  };

  const handleRemove = (e, data, type) => {
    e.stopPropagation();
    const provider = type.key;
    onUpdate(application[provider].filter(item => item.id !== data.id), provider);
  };

  const entityTitle = provider => {
    if (provider.noOutsideCounsel) return 'Internal Securities Counsel';
    const title = provider.name;

    if (!title || title.trim().length === 0) return entities.NO_NAME;
    return title;
  };

  const isIncomplete = key => {
    return !application[key] || !application[key].find(data => validateCompleteServiceProvider(data, key));
  };

  const isUSAorCANCountry = isUSAorCAN(application?.companyInfo?.countryOfIncorporation);

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={`Provide information for each of the following Service Providers of ${company}.`} />
      {providerList.map((provider, i) => {
        const list = application[provider.key];
        const isRequired = typeof provider.isRequired === 'function' ? provider.isRequired(application) : provider.isRequired;

        if (provider.key === 'corporateBroker' && isOTCQXBanks) !application.companyInfo.isDelistFromMajorExchange ? provider.isRequired = true : provider.isRequired = false;
        if (provider.key === 'qbSponsor' && isOTCQB) application.reportingStandard === reportingStandards.INTERNATIONAL ? provider.isRequired = true : provider.isRequired = false;

        const onRemoveClick = (e, data) => handleRemove(e, data, provider);
        return <EntityList
          key={i}
          className={i > 0 ? 'mtXL' : ''}
          title={provider.title}
          footNote={provider.subTitle}
          list={list}
          confirmRemoveText={entities.CONFIRM_REMOVE_SP}
          showIncompleteList={isRequired && showIncompletes && isIncomplete(provider.key)}
          showIncompletes={showIncompletes}
          incompleteMessage={`Incomplete Information. Need at least 1 complete ${provider.title}.`}
          icon={'user'}
          iconComplete={'userComplete'}
          entityTitle={entityTitle}
          validateComplete={data => validateCompleteServiceProvider(data, provider.key, application.companyInfo)}
          addTitle={(provider.max && list ? list.length < provider.max : true) && !isReadOnly && provider.addTitle ? provider.addTitle : undefined}
          readOnly={isReadOnly}
          handleEntityClick={d => handleEdit(d, provider)}
          handleEntityRemove={!isReadOnly && onRemoveClick}
          handleAddClick={() => setSelectedProvider(provider)}
        />;
      })}
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title='Add New Service Provider'>
        <AddServiceProvider
          title={selectedProvider && selectedProvider.title}
          providerView={providerView}
          type={selectedProvider && selectedProvider.key}
          typeId={selectedProvider && selectedProvider.typeId}
          isDNS={isDNS}
          handleUpdate={onProviderUpdate}
          readOnly={isReadOnly}
          handleAdd={onProviderAdd}
          setSlidePane={setSlidePane}
          isUSAorCAN={isUSAorCANCountry} />
      </SlidePanel>
    </div>
  );
};

ServiceProviders.propTypes = {
  name: PropTypes.string,
  saveApplication: PropTypes.func
};

export default ServiceProviders;
