import React, { useState } from 'react';
import { errors } from '../../../constants';
import { validNumberOfShares, validPercentOfClass } from '../../../constants/cardComplete';
import { isNumber } from '../../../utils/validations';
import DialogBox from '../../DialogBox';
import Controls from '../../Controls';
import Title from '../../Title';
import TextArea from '../../TextArea';
import NumberBox from '../../NumberBox';
import styles from '../../Application/Application.module.scss';

const NA = 'N/A';

const shareHoldingObject = {
  numberOfShares: null,
  percentOfClass: null,
  note: null
};

const ShareHoldingForm = ({ inline, disabled, securityOwner, title, readOnly, isControlPerson, showIncompletes, onCancelClick, onSaveClick }) => {
  const [holdings, setHoldings] = useState(securityOwner || shareHoldingObject);
  const onValueChanged = (value, field) => {
    setHoldings(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleSaveClick = holdings => {
    onSaveClick(holdings);
  };

  const renderForm = () => <div className={styles.innerGrid}>
    <div className={styles.col3}>
      <NumberBox
        label='Number of Shares Owned'
        showClearButton
        placeholder={disabled ? NA : 'XXX,XXX'}
        format='#,##0'
        tabIndex={1}
        name='numberofshares'
        isValid={!(isControlPerson && isNumber(holdings.numberOfShares) && !validNumberOfShares(holdings.numberOfShares))}
        errorText={errors.MIN_ONE_SHARE_OWNED}
        isRequired={showIncompletes}
        readOnly={readOnly}
        value={disabled ? NA : holdings.numberOfShares}
        disabled={disabled}
        onValueChanged={e => onValueChanged(e.value, 'numberOfShares')} />
    </div>
    <div className={styles.col3}>
      <NumberBox
        label='Percent of Class Owned'
        placeholder={disabled ? NA : 'XX%'}
        format='#0.##%'
        tabIndex={2}
        name='percentOfClass'
        max={1}
        step={0.01}
        isRequired={showIncompletes}
        readOnly={readOnly}
        isValid={!(isControlPerson && isNumber(holdings.percentOfClass) && !validPercentOfClass(holdings.percentOfClass))}
        errorText={errors.MIN_ONE_PERCENT_OWNED}
        value={disabled ? NA : holdings.percentOfClass}
        showClearButton
        disabled={disabled}
        onValueChanged={e => onValueChanged(e.value, 'percentOfClass')} />
    </div>
    <div className={styles.col6}>
      <TextArea
        label='Note (optional)'
        showClearButton
        placeholder={disabled ? NA : 'Use this section to describe the basis of ownership for any shares not currently outstanding.'}
        name='shareholdingNote'
        height={80}
        maxLength={1500}
        tabIndex={3}
        readOnly={readOnly}
        value={disabled ? NA : holdings.note}
        disabled={disabled}
        onValueChanged={e => onValueChanged(e.value, 'note')} />
    </div>
  </div>;

  return <>
    {inline ? <>
      <Title className={styles.securityTitle} title={title} type='h3' />
      {renderForm()}
      <Controls
        className='mtXXL'
        cancelText='Cancel'
        submitText='Save'
        onCancelClick={onCancelClick}
        onSubmitClick={() => handleSaveClick(holdings)} />
    </>
      : <DialogBox
        readOnly={readOnly}
        title={title}
        onCancelClick={onCancelClick}
        onSubmitClick={() => handleSaveClick(holdings)}>
        {renderForm()}
      </DialogBox>
    }
  </>;
};

export default ShareHoldingForm;
