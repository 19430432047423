import React from 'react';
import PropTypes from 'prop-types';
import { getCreditCardExpiryMonthYear } from '../../../utils/helper';
import { format } from '../../../utils/locale';
import EntityTitle from '../../Entity/EntityTitle';
import Title from '../../Title';
import styles from './PaymentSubmit.module.scss';

const ReviewCCPayment = ({ payment, paymentInfo, companyAddress, applicationBasicFee, isReducedFeeTypeUpgrade }) => {
  const address = paymentInfo.address;
  const companyAddressState = (companyAddress && companyAddress.state) || '';
  const expiresInfo = getCreditCardExpiryMonthYear(paymentInfo.month, paymentInfo.year);
  const applicationReducedFee = isReducedFeeTypeUpgrade && format(payment.reducedFee, payment.reducedFee, 'currency');
  const taxAmount = payment.taxAmount && format(payment.taxAmount, payment.taxAmount, 'currency');
  const applicationTotalFee = format(payment.appFeeTotal, payment.appFeeTotal, 'currency');

  return (
    <div id='reviewPaymentContainer' className={styles.reviewPayment}>
      <Title className='mtXL' title='Ready For Payment' type='h2' />
      <p>Please look over the payment information and verify it is correct before submitting.</p>
      <Title className='mtLg' title='' type='h2' />
      <p><span className={styles.fieldValue}>{paymentInfo.fullName}</span></p>
      <p><span className={styles.fieldValue}>{paymentInfo.email}</span></p>
      <p>
        <span className={styles.label}>{paymentInfo.ccType} ending in: </span>
        <span className={styles.fieldValue}>{paymentInfo.ccNumber.toString().slice(-4)}</span>
      </p>
      <p>
        <span className={styles.label}>Expires: </span>
        <span className={styles.fieldValue}>{expiresInfo}</span>
      </p>
      <Title className='mtLg' title='' type='h2' />
      <p><span className={styles.fieldValue}>{address.address1} </span></p>
      {address.address2 && <p><span className={styles.fieldValue}>{address.address2} </span></p>}
      {address.state && <p><span className={styles.fieldValue}>{`${address.city}, ${address.state} ${address.zip}`} </span></p>}
      {!address.state && <p><span className={styles.fieldValue}>{`${address.city} ${address.zip}`}</span></p>}
      <p><span className={styles.fieldValue}>{`${address.countryName}`} </span></p>
      <Title className='mtXL' title='Payment Due' type='h2' />
      <p>
        <span className={styles.label}>Application Fee: </span>
        <span className={styles.fieldValue}>{applicationBasicFee}</span>
      </p>
      {applicationReducedFee && <p>
        <span className={styles.label}>Discount: </span>
        <span className={styles.fieldValue}>-{applicationReducedFee}</span>
      </p> }
      {payment.taxAmount && <p>
        <span className={styles.label}>{`${companyAddressState} Sales Tax (${payment.taxRate}%): `}</span>
        <span className={styles.fieldValue}>{taxAmount}</span>
      </p> }
      <Title className='mtLg' title='' type='h2' />
      <EntityTitle title={applicationTotalFee} size='medium' />
    </div>
  );
};

ReviewCCPayment.propTypes = {
  payment: PropTypes.object,
  paymentInfo: PropTypes.object,
  companyAddress: PropTypes.object,
  applicationBasicFee: PropTypes.string,
  isReducedFeeTypeUpgrade: PropTypes.bool
};

export default ReviewCCPayment;
