import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from '../../components/WithRouter';
import { AuthContext } from '../../context/Auth.context';
import { ApplicationContext } from '../../context/Application.context';
import { TypesContext } from '../../context/Types.context';
import Button from '../../components/Button';
import SelectBox from '../../components/SelectBox';
import TextArea from '../../components/TextArea';
import Title from '../../components/Title';
import Label from '../../components/Label';
import CompanySearch from '../CompanySearch';
import { createApplication } from '../../api/application';
import styles from './StartNewApplication.module.scss';

const APP_TYPE = 'Application Type';
const COMPANY = 'Company';
const AFFILIATION = 'Affiliation';
const DEFAULT_ITEM = 'default';

const activeApplicationTypes = ['OTCQX_US', 'OTCQX_BANKS', 'OTCQX_INTL', 'OTCQB', 'DNS'];

const StartNewApplication = ({ navigate }) => {
  const [authState] = useContext(AuthContext);
  const [state, dispatch] = useContext(ApplicationContext);
  const [typesState] = useContext(TypesContext);
  const applicationTypes = typesState && typesState.appTypes && typesState.appTypes.filter(type => activeApplicationTypes.includes(type.typeId));
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [applicationType, setApplicationType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyAffilation, setCompanyAffilation] = useState(null);
  const userEmail = authState.email;
  const isApplicationReady = applicationType && selectedCompany && companyAffilation;

  const onOptionChanged = e => {
    if (e.name === 'text') {
      const value = e.value;
      setCompanyAffilation(value);
    }
  };

  const handleCompanySelect = (option) => {
    if (option === DEFAULT_ITEM) {
      setSelectedCompany(false);
    } else {
      setSelectedCompany(option);
    }
  };
  const handleCreate = () => {
    setError(false);
    const params = {
      affiliation: companyAffilation,
      email: userEmail,
      fullName: authState.fullName,
      initials: authState.initials
    };

    setSubmitting(true);
    createApplication(applicationType, selectedCompany.Name, selectedCompany.symbol, params)
      .then(data => {
        dispatch({
          type: 'SET_APPLICATION',
          payload: data,
          original: data
        });
        dispatch({
          type: 'SET_OWNERS',
          payload: [{ ...params, id: { email: params.email } }]
        });
        navigate(`/application/${data.id}?start=true`);
      })
      .catch(e => {
        setSubmitting(false);
        setError(e && e.response && e.response.data && e.response.data.message || 'Error creating application.');
      });
  };

  return (
    <section className={cn('innerGrid', styles.container)}>
      <div className='newRow4'>
        <Title title='Start a New Application' />
        <p>
          To start your application, select the type of application, company, and affiliation.
        </p>
      </div>
      <div className='mtLg newRow3'>
        <Title title={APP_TYPE} type='h2' />
        <SelectBox
          name='applicationType'
          placeholder='Select Application Type'
          valueExpr='typeId'
          displayExpr='description'
          items={applicationTypes}
          value={applicationType}
          onValueChanged={e => setApplicationType(e.value)} />
      </div>
      <div className='mtXL newRow4'>
        <Title title={COMPANY} type='h2' />
        <p>
          Please enter company name below. If the company name matches with any of the companies in our system the matches will be shown in an auto complete dropdown. If you are unable to find the company you are looking for, please select Add New Company.
        </p>
      </div>
      <div className='newRow3'>
        <div id='companyList'>
          <CompanySearch
            onSubmit={handleCompanySelect} />
        </div>
      </div>
      <div className='mtXL newRow4'>
        <Title title={AFFILIATION} type='h2' />
        <p>
          Describe your relationship to the Company.
        </p>
        <TextArea
          showClearButton
          placeholder='List your title if you are an officer or director.'
          name='description'
          height={80}
          maxLength={30}
          errorText='Incomplete Data. Please enter affiliation.'
          value={companyAffilation}
          onOptionChanged={onOptionChanged}
        />
      </div>
      {error && <Label className='mtMed newRow4' title={error} isError />}
      <Button className='mtXXL newRow4' inactive={isSubmitting || !isApplicationReady} showLoader={isSubmitting} title={isSubmitting ? 'Creating Application...' : 'Start Application'} color='gray' fullWidth onClick={handleCreate} />
    </section>
  );
};

export default withRouter(StartNewApplication);
