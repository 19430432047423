import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Title from '../../components/Title';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './RequestExempt.module.scss';

const RequestExempt = ({ item, onSaveComplete }) => {
  const [value, setValue] = useState('');
  const handleSave = () => {
    if (value && /\S/.test(value)) onSaveComplete(value, 'reasonForExemption', item);
  };

  return <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.header}>
        <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />
        <Title className={styles.title} title='Request for Exemption' type='h1' />
      </div>
      <p>
        Enter reason for exemption
      </p>
      <TextArea
        placeholder='Enter reason'
        showClearButton
        name='reasonExempt'
        height={80}
        maxLength={1500}
        value={value}
        onValueChanged={e => setValue(e.value)} />
      <div className={cn(styles.button, 'mgMed mtMed')}>
        <Button title='Save' onClick={handleSave} buttonType='secondary' fullWidth color='gray' />
      </div>
    </div>
  </div>;
};

RequestExempt.propTypes = {
  next: PropTypes.string,
  onSaveComplete: PropTypes.func
};

export default RequestExempt;
