import React from 'react';
import PropTypes from 'prop-types';
import styles from './NumberList.module.scss';

const NumberList = ({ list }) => (
  <ol className={styles.container}>
    {list.map((item, i) => <li key={i} dangerouslySetInnerHTML={{ __html: item }} />)}
  </ol>
);

NumberList.propTypes = {
  list: PropTypes.array.isRequired
};

export default NumberList;
