import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const Label = ({ text }) => (
  <Text style={styles.title}>
    {text}
  </Text>
);

const styles = StyleSheet.create({
  title: {
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase'
  }
});

export default Label;
