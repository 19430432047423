import React, { useState, useEffect, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import { validateCompleteDesignatedContact } from '../../../constants/cardComplete';
import { entities } from '../../../constants';
import SlidePanel from '../../SlidePanel';
import styles from '../Application.module.scss';
import EntityList from '../../EntityList';
import AddContact from './AddContact';

const PRIMARY_FLAG = 'isPrimaryBillingContact';
const ADDITIONAL_FLAG = 'isOtherBillingContact';

const PRIMARY_BILLING = 'Primary Billing Contact';
const ADDITIONAL_BILLING = 'Additional Billing Contact';

const contactObj = {
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  isAccountPayable: false
};

const BillingContactsTab = ({ peopleList, onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [state] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [contactView, setContactView] = useState(null);
  const [billingType, setBillingType] = useState(null);
  const [flagType, setFlagType] = useState(PRIMARY_FLAG);
  const contactTotal = peopleList.filter(person => person[PRIMARY_FLAG]).length;
  const primaryBillingContact = peopleList.filter(person => person[PRIMARY_FLAG]);
  const additionalBillingList = peopleList.filter(person => person[ADDITIONAL_FLAG]);
  const prevAddedList = peopleList.filter(person => (!person[PRIMARY_FLAG] && !person[ADDITIONAL_FLAG]) && (person.isOfficer || person.isDirector || person.isPrimaryContact || person.isAuthorizedUser));
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;
  let billingContactTitle = `${contactView && contactView.firstName} ${contactView && contactView.lastName}`;
  let existingContactAffiliation = null;

  if (!billingContactTitle || billingContactTitle.trim().length === 0) billingContactTitle = entities.NO_NAME;

  if (contactView && contactView.isOfficer) existingContactAffiliation = 'Officer';
  if (contactView && contactView.isDirector) existingContactAffiliation = 'Director';
  if (contactView && contactView.isOfficer && contactView.isDirector) existingContactAffiliation = 'Officer, Director';

  useEffect(() => {
    if (!slidePane) setContactView(null);
  }, [slidePane]);

  const handleAddClick = (type, flag) => {
    setFlagType(flag);
    setSlidePane(true);
    setBillingType(type);
  };

  const handleUpdate = contact => {
    onPersonUpdate(contact);
    setSlidePane(false);
  };

  const handleAdd = contact => {
    onPersonAdd(contact);
    setSlidePane(false);
  };

  const handleEdit = (contact, type, flag) => {
    setBillingType(type);
    setFlagType(flag);
    setContactView(contact);
    setSlidePane(true);
  };

  const handlePrimaryRemove = (e, contact) => {
    e.stopPropagation();
    onPersonRemove(contact, PRIMARY_FLAG);
  };

  const handleAdditionalRemove = (e, contact) => {
    e.stopPropagation();
    onPersonRemove(contact, ADDITIONAL_FLAG);
  };

  const entityTitle = contact => {
    const title = `${contact.firstName} ${contact.lastName}`;
    if (!title || title.trim().length === 0) return entities.NO_NAME;

    return title;
  };

  return (
    <div>
      <p className={styles.description}>
        All billing notices will be sent by email addressed to the Primary Billing Contact with Additional Billing Contact(s) copied.
      </p>
      <EntityList
        title={PRIMARY_BILLING}
        addTitle={contactTotal < 1 ? `Add ${PRIMARY_BILLING}` : undefined}
        showIncompleteList={showIncompletes && contactTotal < 1}
        showIncompletes={showIncompletes}
        incompleteMessage='One Primary Billing Contact is required.'
        icon={'user'}
        iconComplete={'userComplete'}
        readOnly={isReadOnly}
        list={primaryBillingContact}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        entityTitle={entityTitle}
        validateComplete={contact => validateCompleteDesignatedContact(contact, PRIMARY_FLAG)}
        handleAddClick={() => handleAddClick(PRIMARY_BILLING, PRIMARY_FLAG)}
        handleEntityClick={contact => handleEdit(contact, PRIMARY_BILLING, PRIMARY_FLAG)}
        handleEntityRemove={!isReadOnly && handlePrimaryRemove} />
      <EntityList
        className='mtXL'
        title={`${ADDITIONAL_BILLING} {optional}`}
        addTitle={`Add ${ADDITIONAL_BILLING}`}
        icon={'user'}
        iconComplete={'userComplete'}
        showIncompletes={showIncompletes}
        list={additionalBillingList}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        entityTitle={entityTitle}
        validateComplete={contact => validateCompleteDesignatedContact(contact, ADDITIONAL_FLAG)}
        readOnly={isReadOnly}
        handleAddClick={() => handleAddClick(ADDITIONAL_BILLING, ADDITIONAL_FLAG)}
        handleEntityClick={contact => handleEdit(contact, ADDITIONAL_BILLING, ADDITIONAL_FLAG)}
        handleEntityRemove={!isReadOnly && handleAdditionalRemove} />
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={contactView ? billingContactTitle : `Add ${billingType}`}
        subTitle={existingContactAffiliation && existingContactAffiliation}>
        <AddContact
          contactType={billingType}
          prevAddDesc={`Select an individual previously entered, or Add a New ${billingType}.`}
          addNewDesc={`Enter title and name of ${billingType}.`}
          flag={flagType}
          contactObj={contactObj}
          contactView={contactView}
          setContactView={setContactView}
          prevAddedList={prevAddedList}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          setSlidePane={setSlidePane} />
      </SlidePanel>
    </div>
  );
};

export default BillingContactsTab;
