import React, { useState } from 'react';
import { Tooltip } from 'devextreme-react/tooltip';
import moment from 'moment-timezone';
import { timezones } from '../../constants';
import styles from './Table.module.scss';

const Row = ({ item, columns, tooltip, hideTooltip }) => {
  const id = `target-${item.id}`;
  const [showTooltip, setShowTooltip] = useState(false);
  const isHideTooltip = hideTooltip(item);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const TooltipComponent = tooltip;

  return (
    <>
      <tr id={id} onMouseEnter={!isHideTooltip && handleMouseEnter} onMouseLeave={!isHideTooltip && handleMouseLeave}>
        {columns.map((column, i) => <td key={i} className={styles.cell}>
          {column.type === 'date' && moment(item[column.field]).tz(timezones.NEW_YORK).format('MM/DD/YYYY')}
          {(column.type === 'string' || !column.type) && item[column.field]}
          {item[column.field] !== 'Logo' && <Tooltip
            target={`#${id}`}
            visible={showTooltip}
            position='bottom'
            hideOnOutsideClick={false}
          >
            <div>
              <TooltipComponent item={item} />
            </div>
          </Tooltip>}
        </td>)}
      </tr>
    </>
  );
};

export default Row;
