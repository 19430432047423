import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { countries, urls, filingCycles } from '../../../constants';
import { isRequiredCountry } from '../../../constants/cardComplete';
import { TypesContext } from '../../../context/Types.context';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import Title from '../../Title';
import CheckBoxField from '../../CheckBoxField';
import QuestionCard from '../../QuestionCard';
import DynamicPhoneInput from '../../DynamicPhoneInput';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import TextArea from '../../TextArea';
import NumberBox from '../../NumberBox';
import DateBox from '../../DateBox';
import WebsiteField from '../../WebsiteField';
import EmailField from '../../EmailField';
import { monthsList } from '../../../constants/months';
import { yearsList } from '../../../constants/years';
import { isBoolean, isDate } from '../../../utils/validations';
import styles from '../Application.module.scss';

const US = countries.US;
const CA = countries.CA;
const AU = countries.AU;

const maxDate = new Date();

const CompanyInformation = ({ name }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [typesState] = useContext(TypesContext);
  const [copyAddress, setCopyAddress] = useState(false);
  const application = state.application;
  const appTypeName = application.typeDesc;
  const isReadOnly = state.readOnly;
  const companyInfo = application.companyInfo;
  const payment = application.payment;
  const companyAddress = companyInfo.companyAddress;
  const billingAddress = companyInfo.billingAddress;
  const isFiscalYearMonth = companyInfo && companyInfo.fiscalYearEndMonth;
  const daysInFiscalMonthSelected = monthsList.find(month => month.id === companyInfo.fiscalYearEndMonth);
  const showIncompletes = state.showIncompletes;
  const countryList = (typesState && typesState.codes && typesState.codes.countries) || [];
  const statesList = (typesState && typesState.codes && typesState.codes.states) || [];
  const isCompanyNameBlank = (!companyInfo.name || (companyInfo.name && companyInfo.name.trim().length === 0));
  const isDNS = application.typeId === 5;
  const showProfileData = !isDNS;
  let countryCompanyAddress = companyAddress && companyAddress.country ? companyAddress.country : US;
  let countryNameCompanyAddress = companyAddress && companyAddress.countryName ? companyAddress.countryName : countries.UNITED_STATES;
  let countryBillingAddress = billingAddress && billingAddress.country ? billingAddress.country : US;
  let countryNameBillingAddress = billingAddress && billingAddress.countryName ? billingAddress.countryName : countries.UNITED_STATES;
  let countryOfIncorporation = companyInfo && companyInfo.countryOfIncorporation ? companyInfo.countryOfIncorporation : US;

  const questions = [
    {
      title: 'Securities Listed or Traded on Other Markets',
      subTitle: 'Does the company have any class of securities listed on a U.S. or International exchange?',
      fieldName: 'hasOtherMarketSecurities',
      trueDetails: [
        {
          desc: 'Please list all applicable markets.',
          fieldName: 'hasOtherMarketSecuritiesDetail'
        }
      ],
      appTypes: [1, 2]
    },
    {
      title: !isDNS ? 'Securities Listed or Traded on Other Markets' : 'Securities Listed or Traded on a Qualified Foreign Exchange',
      subTitle: () => <p>Does the company have any class of securities listed on a {!isDNS ? 'U.S. exchange or on a ' : ''} <a href={urls.QFE} target='_blank' rel='noopener noreferrer'>Qualified Foreign Exchange</a>?</p>,
      fieldName: 'hasOtherExchange',
      trueDetails: [
        {
          hideDetails: true,
          desc: () => <div className={cn('mtMed', styles.innerGrid)}>
            <div className={styles.col3}>
              <TextBox
                label='Name of the Exchange'
                placeholder='Name'
                name='hasOtherExchangeName'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                isRequired={showIncompletes}
                value={companyInfo.hasOtherExchangeName}
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeName')} />
            </div>
            <div className={styles.col3}>
              <TextBox
                label='Tier (if applicable)'
                placeholder='Tier'
                name='hasOtherExchangeTier'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                value={companyInfo.hasOtherExchangeTier}
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeTier')} />
            </div>
            <div className={styles.col3}>
              <TextBox
                label='Trading Symbol'
                placeholder='Symbol'
                name='hasOtherExchangeSymbol'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                isRequired={showIncompletes}
                value={companyInfo.hasOtherExchangeSymbol}
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeSymbol')} />
            </div>
            <div className={styles.col3}>
              <DateBox
                label='Date Listed'
                readOnly={isReadOnly}
                placeholder='MM/DD/YYYY'
                isRequired={showIncompletes}
                value={companyInfo.hasOtherExchangeDate}
                type='date'
                max={maxDate}
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeDate')} />
            </div>
            <div className={styles.col3}>
              <SelectBox
                label='Filing Cycle'
                name='hasOtherExchangeCycle'
                placeholder='Select'
                readOnly={isReadOnly}
                items={filingCycles}
                isRequired={showIncompletes}
                value={companyInfo.hasOtherExchangeCycle}
                valueExpr='id'
                displayExpr='text'
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeCycle')} />
            </div>
            <div className={styles.col3}>
              <TextBox
                label='Accounting Standard'
                placeholder='Accounting Standard'
                name='hasOtherExchangeAccount'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                isRequired={showIncompletes}
                value={companyInfo.hasOtherExchangeAccount}
                onValueChanged={e => onValueChanged(e.value, 'hasOtherExchangeAccount')} />
            </div>
          </div>
        }
      ],
      appTypes: [3, 4, 5]
    },
    {
      title: 'Change in Control',
      subTitle: () => <p>Has the company undergone a <a href={urls.GLOSSARY_CHANGE_CONTROL} target='_blank' rel='noopener noreferrer'>Change in Control</a> event and is re-applying for {appTypeName}?</p>,
      fieldName: 'changeInControl',
      trueDetails: [
        {
          desc: 'Describe the Change in Control.',
          fieldName: 'changeInControlDetail'
        }
      ],
      appTypes: [1, 2, 3, 4]
    },
    {
      title: 'Delisting from a National Securities Exchange',
      subTitle: 'Is the Company delisting from a National Securities Exchange?',
      fieldName: 'isDelistFromMajorExchange',
      trueDetails: [
        {
          desc: 'Provide the anticipated date of delisting and the reason for delisting.',
          fieldName: 'isDelistFromMajorExchangeDetail'
        },
        {
          desc: 'Please provide any compliance or regulatory concerns expressed by the exchange. State “None” if there are none.',
          fieldName: 'isDelistFromMajorExchangeConcerns'
        }
      ],
      appTypes: [1, 2, 3, 4]
    },
    {
      title: 'Securities Listed or Traded on US National Exchange',
      subTitle: 'Does the company have any class of securities listed on a US National Exchange?',
      fieldName: 'hasUsExchange',
      trueDetails: [
        {
          hideDetails: true,
          desc: () => <div className={cn('mtMed', styles.innerGrid)}>
            <div className={styles.col3}>
              <TextBox
                label='Name of the Exchange'
                placeholder='Name'
                name='hasUsExchangeName'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                isRequired={showIncompletes}
                value={companyInfo.hasUsExchangeName}
                onValueChanged={e => onValueChanged(e.value, 'hasUsExchangeName')} />
            </div>
            <div className={styles.col3}>
              <TextBox
                label='Tier (if applicable)'
                placeholder='Tier'
                name='hasUsExchangeTier'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                value={companyInfo.hasUsExchangeTier}
                onValueChanged={e => onValueChanged(e.value, 'hasUsExchangeTier')} />
            </div>
            <div className={styles.col3}>
              <TextBox
                label='Trading Symbol'
                placeholder='Symbol'
                name='hasUsExchangeSymbol'
                showClearButton
                readOnly={isReadOnly}
                maxLength={75}
                isRequired={showIncompletes}
                value={companyInfo.hasUsExchangeSymbol}
                onValueChanged={e => onValueChanged(e.value, 'hasUsExchangeSymbol')} />
            </div>
            <div className={styles.col3}>
              <DateBox
                label='Date Listed'
                readOnly={isReadOnly}
                placeholder='MM/DD/YYYY'
                isRequired={showIncompletes}
                value={companyInfo.hasUsExchangeDate}
                type='date'
                max={maxDate}
                onValueChanged={e => onValueChanged(e.value, 'hasUsExchangeDate')} />
            </div>
            <div className={styles.col6}>
              <QuestionCard
                type={'nested'}
                item={usExchangeDelistQuestion}
                isRequired={showIncompletes}
                section={companyInfo}
                readOnly={isReadOnly}
                handleQuestionClick={handleQuestionClick}
                onValueChanged={onValueChanged} />
            </div>
          </div>
        }
      ],
      appTypes: [5]
    }
  ];

  const usExchangeDelistQuestion = {
    title: 'US National Exchange Delist',
    subTitle: 'Is the security expected to delist from the exchange?',
    fieldName: 'isDelistFromUsExchange',
    trueDetails: [
      {
        desc: () => <div className={cn('mtMed', styles.innerGrid)}>
          <div className={styles.col3}>
            <DateBox
              label='Expected Delist Date'
              readOnly={isReadOnly}
              placeholder={companyInfo.isDelistFromUsExchangeDateUnknown ? 'N/A' : 'MM/DD/YYYY'}
              isRequired={showIncompletes && !companyInfo.isDelistFromUsExchangeDateUnknown}
              value={!companyInfo.isDelistFromUsExchangeDateUnknown ? companyInfo.isDelistFromUsExchangeDate : undefined}
              type='date'
              max={maxDate}
              onValueChanged={e => onValueChanged(e.value, 'isDelistFromUsExchangeDate')} />
          </div>
          <div className={styles.col3}>
            <CheckBoxField
              className='middle'
              size='small'
              value={companyInfo.isDelistFromUsExchangeDateUnknown}
              text='Delist Date Unknown'
              onValueChanged={onDelistFromUsExchangeDateUnknown} />
          </div>
        </div>,
        hideDetails: true
      }
    ]
  };

  // Add prepopulated country to country list if it does not exist
  if (countryCompanyAddress && !countryList.find(country => country.id === countryCompanyAddress || country.name === countryCompanyAddress)) {
    countryList.push({ id: countryCompanyAddress, name: countryCompanyAddress });
  }

  if (countryBillingAddress && !countryList.find(country => country.id === countryBillingAddress || country.name === countryBillingAddress)) {
    countryList.push({ id: countryBillingAddress, name: countryBillingAddress });
  }

  if (countryOfIncorporation && !countryList.find(country => country.id === countryOfIncorporation || country.name === countryOfIncorporation)) {
    countryList.push({ id: countryOfIncorporation, name: countryOfIncorporation });
  }

  // Set country to ID if prepopulated name matches on provided by common country API
  if (countryCompanyAddress && countryList.find(country => country.name === countryCompanyAddress || country.name === country.countryName)) countryCompanyAddress = countryList.find(country => country.name === countryCompanyAddress || country.name === country.countryName).id;
  if (countryBillingAddress && countryList.find(country => country.name === countryBillingAddress || (billingAddress && country.name === billingAddress.countryName))) countryBillingAddress = countryList.find(country => country.name === countryBillingAddress || country.name === billingAddress.countryName).id;
  if (countryOfIncorporation && countryList.find(country => country.name === countryOfIncorporation)) countryOfIncorporation = countryList.find(country => country.name === countryOfIncorporation).id;

  // Set country to ID if propulated name matches one of the hard coded values below
  switch (countryCompanyAddress) {
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      countryCompanyAddress = countryList.find(country => country.id === US).id;
      countryNameCompanyAddress = countryList.find(country => country.id === US).name;
      break;

    case countries.CAN:
    case countries.CANADA:
      countryCompanyAddress = countryList.find(country => country.id === CA).id;
      countryNameCompanyAddress = countryList.find(country => country.id === CA).name;
      break;

    case countries.AUS:
    case countries.AUSTRALIA:
      countryCompanyAddress = countryList.find(country => country.id === AU).id;
      countryNameCompanyAddress = countryList.find(country => country.id === AU).name;
      break;
  };

  switch (countryBillingAddress) {
    case countries.USA:
      countryBillingAddress = countryList.find(country => country.id === US).id;
      countryNameBillingAddress = countryList.find(country => country.id === US).name;
      break;

    case countries.CAN:
      countryBillingAddress = countryList.find(country => country.id === CA).id;
      countryNameBillingAddress = countryList.find(country => country.id === CA).name;
      break;

    case countries.AUS:
      countryBillingAddress = countryList.find(country => country.id === AU).id;
      countryNameBillingAddress = countryList.find(country => country.id === AU).name;
      break;
  };

  switch (countryOfIncorporation) {
    case countries.USA:
      countryOfIncorporation = countryList.find(country => country.id === US).id;
      break;

    case countries.CAN:
      countryOfIncorporation = countryList.find(country => country.id === CA).id;
      break;

    case countries.AUS:
      countryOfIncorporation = countryList.find(country => country.id === AU).id;
      break;
  };

  const companyStatesList = statesList.filter(state => {
    let country = (countryCompanyAddress && countryList.find(country => country.id === countryCompanyAddress)) || { id: US };
    return state.countryId === country.id;
  });
  const billingStatesList = statesList.filter(state => {
    let country = (countryBillingAddress && countryList.find(country => country.id === countryBillingAddress)) || { id: US };
    return state.countryId === country.id;
  });
  const incorporationStatesList = statesList.filter(state => {
    let country = (companyInfo && countryOfIncorporation && countryList.find(country => country.id === countryOfIncorporation)) || { id: US };
    return state.countryId === country.id;
  });

  const getDaysList = () => {
    let daysList = [];
    if (daysInFiscalMonthSelected && daysInFiscalMonthSelected.days) {
      for (var i = 1; i < daysInFiscalMonthSelected.days + 1; i++) {
        daysList.push(parseInt(('0' + i).slice(-2)));
      }
    }

    return daysList;
  };

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'companyInfo',
      card: 'company-information'
    });
  };

  const onPaymentUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'payment'
    });
  };

  const onValueChanged = (value, field, group) => {
    const updateCompanyInfo = { ...companyInfo };

    if (group) {
      const isCompanyAddress = group === 'companyAddress';
      const isBillingAddress = group === 'billingAddress';

      if (isBillingAddress || isCompanyAddress) setCopyAddress(false);

      updateCompanyInfo[group] = { ...updateCompanyInfo[group] };
      updateCompanyInfo[group][field] = value;

      const isCompAddressCountry = isCompanyAddress && field === 'country';
      const isCompAddressState = isCompanyAddress && field === 'state';
      const isCompAddressZip = isCompanyAddress && field === 'zip';
      const isBillingAddressCountry = isBillingAddress && field === 'country';

      if (isCompAddressZip || isCompAddressState) {
        if (payment) {
          onPaymentUpdate(null);
        }
        if (state.sessionPayment) {
          dispatch({
            type: 'SET_SESSION_PAYMENT',
            payload: null
          });
        }
      }

      if (isCompAddressCountry) {
        const countryName = countryList.find(country => country.id === value).name;
        updateCompanyInfo[group].countryName = countryName;
        if (companyInfo.companyAddress && companyInfo.companyAddress.state && !companyStatesList.find(state => state.countryId === value)) {
          updateCompanyInfo.companyAddress.state = null;
        }
      }

      if (isBillingAddressCountry) {
        const countryName = countryList.find(country => country.id === value).name;
        updateCompanyInfo[group].countryName = countryName;
        if (companyInfo.billingAddress && companyInfo.billingAddress.state && !billingStatesList.find(state => state.countryId === value)) {
          updateCompanyInfo.billingAddress.state = null;
        }
      }

      if (!updateCompanyInfo.companyAddress) updateCompanyInfo.companyAddress = {};
      if (!updateCompanyInfo.billingAddress) updateCompanyInfo.billingAddress = {};

      if (!updateCompanyInfo.companyAddress.country) updateCompanyInfo.companyAddress.country = countryCompanyAddress;
      if (!updateCompanyInfo.companyAddress.countryName) updateCompanyInfo.companyAddress.countryName = countryNameCompanyAddress;

      if (!updateCompanyInfo.billingAddress.country) updateCompanyInfo.billingAddress.country = countryBillingAddress;
      if (!updateCompanyInfo.billingAddress.countryName) updateCompanyInfo.billingAddress.countryName = countryNameBillingAddress;

      if (!updateCompanyInfo.countryOfIncorporation) updateCompanyInfo.countryOfIncorporation = countryOfIncorporation;

      onUpdate(updateCompanyInfo);
    } else {
      updateCompanyInfo[field] = value;

      // handle incorporation info
      const isIncorporationCountry = field === 'countryOfIncorporation' && companyInfo.stateOfIncorporation;
      if (isIncorporationCountry) {
        if (!incorporationStatesList.find(state => state.countryId === value)) {
          updateCompanyInfo.stateOfIncorporation = null;
        }
      }

      if (field === 'isDelistFromUsExchangeDate' && isDate(value) && companyInfo.isDelistFromUsExchangeDateUnknown) {
        updateCompanyInfo.isDelistFromUsExchangeDateUnknown = null;
        updateCompanyInfo.isDelistFromUsExchangeDate = value;
      }

      onUpdate(updateCompanyInfo);
    }
  };

  if (companyInfo.companyAddress && !countryCompanyAddress) onValueChanged(US, 'country', 'companyAddress');
  if (companyInfo.billingAddress && !countryBillingAddress) onValueChanged(US, 'country', 'billingAddress');
  if (companyInfo && !companyInfo.countryOfIncorporation) onValueChanged(US, 'countryOfIncorporation');

  const handleQuestionClick = (value, field) => {
    const updateCompanyInfo = companyInfo;
    updateCompanyInfo[field] = value;
    onUpdate(updateCompanyInfo);
  };

  const onCopyAddressChange = e => {
    const updateBillingInfo = { ...companyInfo };
    const value = e.value;
    setCopyAddress(value);

    if (value) {
      updateBillingInfo.billingAddress = { ...companyInfo.companyAddress };
      onUpdate(updateBillingInfo);
    }
  };

  const onDelistFromUsExchangeDateUnknown = e => {
    const value = e.value;
    companyInfo.isDelistFromUsExchangeDateUnknown = value;
    onUpdate(companyInfo);
  };

  const renderQuestons = () => {
    const applicationQuestions = questions.filter(q => q.appTypes.includes(application.typeId));
    return applicationQuestions.map((q, i) => {
      return <QuestionCard
        key={i}
        item={q}
        section={companyInfo}
        readOnly={isReadOnly}
        isValid={showIncompletes ? isBoolean(companyInfo && companyInfo[q.fieldName]) : true}
        showIncompletes={showIncompletes}
        handleQuestionClick={handleQuestionClick}
        onValueChanged={onValueChanged} />;
    });
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={`Enter company information for ${companyInfo.name}`} />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        <div className={cn(styles.col6, styles.fieldGroup)}>
          <TextBox
            label='Company Name'
            labelSize='big'
            placeholder='Company Name'
            name='name'
            showClearButton
            readOnly={isReadOnly}
            maxLength={75}
            errorText={isCompanyNameBlank && 'Company Name cannot be empty.'}
            isRequired={(!companyInfo.name || (companyInfo.name && companyInfo.name.trim().length === 0)) || showIncompletes}
            value={companyInfo && companyInfo.name}
            onValueChanged={e => onValueChanged(e.value, 'name')} />
        </div>
      </div>
      <Title title='Corporate Address - Principal Place of Business' type='h2' />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        <div className={cn(styles.col6, styles.fieldGroup)}>
          <TextBox
            label='Address'
            placeholder='Address 1'
            name='address1'
            showClearButton
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            value={companyAddress && companyAddress.address1}
            onValueChanged={e => onValueChanged(e.value, 'address1', 'companyAddress')} />
          <TextBox
            placeholder='Address 2'
            name='address2'
            showClearButton
            readOnly={isReadOnly}
            value={companyAddress && companyAddress.address2}
            onValueChanged={e => onValueChanged(e.value, 'address2', 'companyAddress')} />
          <TextBox
            placeholder='Address 3'
            name='address3'
            showClearButton
            readOnly={isReadOnly}
            value={companyAddress && companyAddress.address3}
            onValueChanged={e => onValueChanged(e.value, 'address3', 'companyAddress')} />
        </div>
        <div className={styles.col3}>
          <SelectBox
            label='Country'
            name='country'
            placeholder='Select Country'
            valueExpr='id'
            displayExpr='name'
            readOnly={isReadOnly}
            items={countryList}
            isRequired={showIncompletes}
            value={countryCompanyAddress}
            onValueChanged={e => onValueChanged(e.value, 'country', 'companyAddress')} />
        </div>
        <div className={styles.col3} />
        <div className={styles.col2}>
          <TextBox
            label='City'
            placeholder='City'
            name='city'
            showClearButton
            errorMessage='Test Error msg'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={companyAddress && companyAddress.city}
            onValueChanged={e => onValueChanged(e.value, 'city', 'companyAddress')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='State/Province'
            name='state'
            placeholder='Select State'
            valueExpr='id'
            displayExpr='name'
            searchExpr={['id', 'name']}
            disabled={!companyStatesList || (companyStatesList && companyStatesList.length === 0)}
            items={companyStatesList}
            isRequired={showIncompletes && (countryCompanyAddress && isRequiredCountry(countryCompanyAddress))}
            readOnly={isReadOnly}
            value={companyAddress && companyAddress.state}
            onValueChanged={e => onValueChanged(e.value, 'state', 'companyAddress')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Postal Code'
            placeholder='Postal Code'
            name='zip'
            showClearButton
            isRequired={showIncompletes && (countryCompanyAddress && isRequiredCountry(countryCompanyAddress))}
            readOnly={isReadOnly}
            value={companyAddress && companyAddress.zip}
            onValueChanged={e => onValueChanged(e.value, 'zip', 'companyAddress')} />
        </div>
      </div>
      <Title title='Billing Address' type='h2' />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        {!isReadOnly && <div className={styles.col6}>
          <CheckBoxField
            value={copyAddress}
            text='Copy Corporate Address - Principal Place of Business'
            onValueChanged={onCopyAddressChange} />
        </div>}
        <div className={cn(styles.col6, styles.fieldGroup)}>
          <TextBox
            label='Address'
            placeholder='Address 1'
            name='address1'
            readOnly={isReadOnly}
            showClearButton
            isRequired={showIncompletes}
            value={billingAddress && billingAddress.address1}
            onValueChanged={e => e.event && onValueChanged(e.value, 'address1', 'billingAddress')} />
          <TextBox
            placeholder='Address 2'
            name='address2'
            readOnly={isReadOnly}
            showClearButton
            value={billingAddress && billingAddress.address2}
            onValueChanged={e => e.event && onValueChanged(e.value, 'address2', 'billingAddress')} />
          <TextBox
            placeholder='Address 3'
            name='address3'
            readOnly={isReadOnly}
            showClearButton
            value={billingAddress && billingAddress.address3}
            onValueChanged={e => e.event && onValueChanged(e.value, 'address3', 'billingAddress')} />
        </div>
        <div className={styles.col3}>
          <SelectBox
            label='Country'
            name='country'
            placeholder='Select Country'
            readOnly={isReadOnly}
            valueExpr='id'
            displayExpr='name'
            items={countryList}
            isRequired={showIncompletes}
            value={countryBillingAddress}
            onValueChanged={e => e.event && onValueChanged(e.value, 'country', 'billingAddress')} />
        </div>
        <div className={styles.col3} />
        <div className={styles.col2}>
          <TextBox
            label='City'
            placeholder='City'
            name='city'
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            showClearButton
            value={billingAddress && billingAddress.city}
            onValueChanged={e => e.event && onValueChanged(e.value, 'city', 'billingAddress')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='State/Province'
            name='State/Province'
            placeholder='Select State'
            valueExpr='id'
            readOnly={isReadOnly}
            displayExpr='name'
            searchExpr={['id', 'name']}
            disabled={!billingStatesList || (billingStatesList && billingStatesList.length === 0)}
            items={billingStatesList}
            isRequired={showIncompletes && (countryBillingAddress && isRequiredCountry(countryBillingAddress))}
            value={billingAddress && billingAddress.state}
            onValueChanged={e => e.event && onValueChanged(e.value, 'state', 'billingAddress')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Postal Code'
            placeholder='Postal Code'
            name='zip'
            readOnly={isReadOnly}
            isRequired={showIncompletes && (countryBillingAddress && isRequiredCountry(countryBillingAddress))}
            showClearButton
            value={billingAddress && billingAddress.zip}
            onValueChanged={e => e.event && onValueChanged(e.value, 'zip', 'billingAddress')} />
        </div>
      </div>
      <Title title='Contact Information' type='h2' />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        <div className={styles.col3}>
          <DynamicPhoneInput
            label='Phone'
            countryCodeField='phoneCountryCode'
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            phoneNumberField='phoneNumber'
            countryCodeValue={companyInfo.phoneCountryCode}
            phoneValue={companyInfo.phoneNumber}
            onValueChanged={onValueChanged} />
        </div>
        <div className={styles.col3}>
          <EmailField
            label='Company Email'
            placeholder='email@mail.com'
            name='companyEmail'
            readOnly={isReadOnly}
            showClearButton
            isRequired={showIncompletes}
            value={companyInfo.companyEmail}
            onValueChanged={e => onValueChanged(e.value, 'companyEmail')} />
        </div>
        <div className={styles.col3}>
          <WebsiteField
            label='Company Website'
            placeholder='www.company.com'
            name='companyWebsiteUrl'
            readOnly={isReadOnly}
            showClearButton
            isRequired={!isDNS && showIncompletes}
            value={companyInfo.companyWebsiteUrl}
            onValueChanged={e => onValueChanged(e.value, 'companyWebsiteUrl')} />
        </div>
      </div>
      {showProfileData && <>
        <Title title='Profile Data' type='h2' />
        <div className={cn(styles.formSection, styles.innerGrid)}>
          <div className={styles.col6}>
            <TextArea
              label='Business Description'
              showClearButton
              placeholder='Enter Business Description of company'
              readOnly={isReadOnly}
              name='businessDescription'
              height={80}
              maxLength={1500}
              isRequired={showIncompletes}
              value={companyInfo.businessDescription}
              onValueChanged={e => onValueChanged(e.value, 'businessDescription')} />
          </div>
          <div className={styles.col2}>
            <NumberBox
              label='# of Employees'
              placeholder='XX'
              readOnly={isReadOnly}
              name='numberOfEmployees'
              showClearButton
              showSpinButtons
              isRequired={showIncompletes}
              value={companyInfo.numberOfEmployees}
              onValueChanged={e => onValueChanged(e.value, 'numberOfEmployees')} />
          </div>
          <div className={styles.col2}>
            <DateBox
              label='# of Employees (As of)'
              readOnly={isReadOnly}
              placeholder='MM/DD/YYYY'
              isRequired={showIncompletes}
              value={companyInfo.numberOfEmployeesAsOf}
              type='date'
              max={maxDate}
              onValueChanged={e => onValueChanged(e.value, 'numberOfEmployeesAsOf')} />
          </div>
        </div>
      </>}
      <Title title='Fiscal Year End' type='h2' />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        <div className={styles.col2}>
          <SelectBox
            label='Month'
            name='fiscalYearEndMonth'
            placeholder='Month'
            readOnly={isReadOnly}
            valueExpr='id'
            displayExpr='month'
            items={monthsList}
            isRequired={showIncompletes}
            value={companyInfo.fiscalYearEndMonth}
            onValueChanged={e => onValueChanged(e.value, 'fiscalYearEndMonth')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='Day'
            name='fiscalYearEndDay'
            placeholder='Day'
            items={getDaysList()}
            isRequired={showIncompletes}
            value={companyInfo.fiscalYearEndDay}
            readOnly={!isFiscalYearMonth || isReadOnly}
            onValueChanged={e => onValueChanged(e.value, 'fiscalYearEndDay')} />
        </div>
      </div>
      <Title title='Incorporation Information' type='h2' />
      <div className={cn(styles.formSection, styles.innerGrid)}>
        <div className={styles.col3}>
          <SelectBox
            label='Country of Incorporation'
            name='countryOfIncorporation'
            placeholder='Select Country'
            valueExpr='id'
            displayExpr='name'
            readOnly={isReadOnly}
            items={countryList}
            isRequired={showIncompletes}
            value={countryOfIncorporation}
            onValueChanged={e => onValueChanged(e.value, 'countryOfIncorporation')} />
        </div>
        <div className={styles.col3}>
          <SelectBox
            label='State/Province of Incorporation'
            name='stateOfIncorporation'
            placeholder='Select State'
            valueExpr='id'
            displayExpr='name'
            searchExpr={['id', 'name']}
            readOnly={isReadOnly}
            disabled={!incorporationStatesList || (incorporationStatesList && incorporationStatesList.length === 0)}
            items={incorporationStatesList}
            isRequired={showIncompletes && isRequiredCountry(countryOfIncorporation)}
            value={companyInfo.stateOfIncorporation}
            onValueChanged={e => onValueChanged(e.value, 'stateOfIncorporation')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='Year of Incorporation'
            name='yearOfIncorporation'
            placeholder='YYYY'
            readOnly={isReadOnly}
            items={yearsList}
            isRequired={showIncompletes}
            value={companyInfo.yearOfIncorporation}
            onValueChanged={e => onValueChanged(e.value, 'yearOfIncorporation')} />
        </div>
      </div>
      <div className={styles.formSection}>
        {renderQuestons()}
      </div>
    </div>
  );
};

CompanyInformation.propTypes = {
  name: PropTypes.string
};

export default CompanyInformation;
