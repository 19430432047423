import React from 'react';
import { View } from '@react-pdf/renderer';
import { appNames, reportingStandards, applicationMinBidPriceMap } from '../../constants';
import { isBoolean } from '../../utils/validations';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { format } from '../../utils/locale';

const EligibilityStandards = ({ data, type, reportingStandard }) => {
  const eligibilityStandards = data || {};
  const isOTCQXUS = type === appNames.OTCQX_US;
  const isOTCQXBanks = type === appNames.OTCQX_BANKS;
  const isOTCQXIntl = type === appNames.OTCQX_INTL;
  const isOTCQB = type === appNames.OTCQB;
  const isDNS = type === appNames.DNS;
  const isAlternativeReportStandard = reportingStandard === reportingStandards.ALTERNATIVE;
  const finraMinBidPrice = applicationMinBidPriceMap.get(type) || '0';

  return (
    <>
      <View fixed>
        <SectionHeader text='ELIGIBILITY STANDARDS' />
      </View>
      <View>
        <SectionTitle text='AUDITED FINANCIAL STANDARDS' />
        {(isOTCQXUS || isOTCQXBanks || isOTCQB) && <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text='Does the Company have annual financial statements audited by a PCAOB registered auditor and unaudited interim statements prepared in accordance with U.S. GAAP?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.auditedFinancials, null, 'bool')} />
          </Column>
        </Row>}
        {isOTCQXIntl && <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text='Does the company have audited financial reports dated within the previous 15 months?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.auditedFinancials, null, 'bool')} />
          </Column>
        </Row>}
        {isBoolean(eligibilityStandards.auditedFinancials) && !eligibilityStandards.auditedFinancials && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.auditedFinancialsDetail} />
          </Column>
        </Row>}
        <SectionTitle text='SHELL OR A BLANK CHECK COMPANY' />
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text=' Is the company a shell or a blank check company?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.isShellBlankCheckCompany, null, 'bool')} />
          </Column>
        </Row>
        {(isOTCQXUS || isOTCQXBanks || isOTCQXIntl) && eligibilityStandards.isShellBlankCheckCompany && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.isShellBlankCheckCompanyDetail} />
          </Column>
        </Row>}
        <SectionTitle text='IN GOOD STANDING' />
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text=' Is the company in good standing under the laws of each jurisdiction in which the company is organized or does business?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.inGoodStanding, null, 'bool')} />
          </Column>
        </Row>
        {isBoolean(eligibilityStandards.inGoodStanding) && !eligibilityStandards.inGoodStanding && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.inGoodStandingDetail} />
          </Column>
        </Row>}
      </View>
      <View break>
        <SectionTitle text='BANKRUPTCY' />
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text='Is the company subject to any Bankruptcy or reorganization proceedings?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.isBankrupt, null, 'bool')} />
          </Column>
        </Row>
        {eligibilityStandards.isBankrupt && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.isBankruptDetail} />
          </Column>
        </Row>}
        { !isOTCQXIntl && <>
          <SectionTitle text='Independent Directors' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text=' Does the company have a Board of Directors that includes at least two (2) Independent Directors?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={format(eligibilityStandards.hasTwoIndependentDirectors, null, 'bool')} />
            </Column>
          </Row>
        </>}
        {(isOTCQXUS || isOTCQXBanks || (isOTCQB && isAlternativeReportStandard)) && isBoolean(eligibilityStandards.hasTwoIndependentDirectors) && !eligibilityStandards.hasTwoIndependentDirectors && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.hasTwoIndependentDirectorsDetail} />
          </Column>
        </Row>}
        { !isOTCQXIntl && <>
          <SectionTitle text='Audit Committee' />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text=' Does the company have an audit committee, a majority of whose members are Independent Directors?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={format(eligibilityStandards.hasAuditComittee, null, 'bool')} />
            </Column>
          </Row>
        </>}
        {(isOTCQXUS || isOTCQXBanks || (isOTCQB && isAlternativeReportStandard)) && isBoolean(eligibilityStandards.hasAuditComittee) && !eligibilityStandards.hasAuditComittee && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION' />
            <Value text={eligibilityStandards.hasAuditComitteeDetail} />
          </Column>
        </Row>}
      </View>
      <View break>
        {(isOTCQXUS || isOTCQXBanks) && <>
          <SectionTitle text={`ANNUAL SHAREHOLDERS' MEETINGS`} />
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text='Does the company conduct Annual Shareholders’ Meetings and make Annual Financial Reports available to its shareholders at least 15 calendar days prior to such meetings?' />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={format(eligibilityStandards.hasShareholderMeetings, null, 'bool')} />
            </Column>
          </Row>
          {isBoolean(eligibilityStandards.hasShareholderMeetings) && !eligibilityStandards.hasShareholderMeetings && <Row>
            <Column size='100'>
              <Label text='DESCRIPTION' />
              <Value text={eligibilityStandards.hasShareholderMeetingsDetail} />
            </Column>
          </Row>}
        </>}
        <SectionTitle text='Priced Quotation' />
        <Row>
          <Column size='75'>
            <Label text='Question' />
            { !isOTCQB &&
            <Value text={'Do the company\'s securities have proprietary priced quotations published by a broker-dealer in OTC LINK ATS?'} /> }
            { isOTCQB &&
            <Value text={'Do the company\'s securities have proprietary priced quotations published by a broker-dealer in OTC LINK ATS and a current bid price of at least $0.01?'} /> }
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(eligibilityStandards.hasPricedQuotation, null, 'bool')} />
          </Column>
        </Row>
        {isBoolean(eligibilityStandards.hasPricedQuotation) && !eligibilityStandards.hasPricedQuotation && <>
          <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text={`Will a broker-dealer file a Form 211 with FINRA with a bid price of at least $${finraMinBidPrice} USD?`} />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={format(eligibilityStandards.hasForm211BidPrice, null, 'bool')} />
            </Column>
          </Row>
          {isBoolean(eligibilityStandards.hasPricedQuotation) && !eligibilityStandards.hasPricedQuotation && isBoolean(eligibilityStandards.hasForm211BidPrice) && !eligibilityStandards.hasForm211BidPrice && <Row>
            <Column size='75'>
              <Label text='Question' />
              <Value text={`Does the company wish to request OTC Markets Group to file a Form 211 with FINRA to obtain proprietary quotes?`} />
            </Column>
            <Column size='25'>
              <Label text='Answer' />
              <Value text={format(eligibilityStandards.hasInitialReview, null, 'bool')} />
            </Column>
          </Row>}
        </>}
      </View>
    </>
  );
};

export default EligibilityStandards;
