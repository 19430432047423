import React from 'react';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const Officers = ({ data }) => {
  const officers = data && data.filter(item => item.isOfficer);
  const hasOfficers = officers && officers.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='OFFICERS, DIRECTORS, & CONTROL PERSONS' />
        <SectionTitle text='OFFICERS' />
      </View>
      {officers && officers.map((person, i) => {
        const personTitles = [];
        if (person.title1) personTitles.push(person.title1);
        if (person.title2) personTitles.push(person.title2);
        if (person.title3) personTitles.push(person.title3);
        if (person.title4) personTitles.push(person.title4);

        const titles = personTitles.join(', ');
        const pageBreak = i !== 0 && isInt(i / 5);

        return (
          <View break={pageBreak} key={`officers-${i}`}>
            <Row>
              <Column size='50'>
                <Label text='Name' />
                <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
              </Column>
              <Column size='50'>
                <Label text='Titles' />
                <Value text={titles} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Phone Number' />
                <Value text={person.phone} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Email' />
                <Value text={person.email} />
              </Column>
            </Row>
            <Divider />
          </View>
        );
      })}
      {!hasOfficers && <Value text={null} />}
    </>
  );
};

export default Officers;
