import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FilterLabel.module.scss';

const FilterLabel = ({ name, filter, onRemove }) => (
  <span className={styles.label}>
    <span className={styles.name}>{name}: {filter}</span>
    <span className={styles.icon} onClick={() => onRemove(filter)}>
      <FontAwesomeIcon icon={['fal', 'times']} />
    </span>
  </span>
);

export default FilterLabel;
