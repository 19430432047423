import React from 'react';
import cn from 'classnames';
import { useDrop } from 'react-dnd';
import styles from './DropContainer.module.scss';

const DropContainer = ({ children, boxType, onActive }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: boxType,
    drop: () => ({ name: 'DropContainer' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={styles.container}>
      {isActive && <div className={styles.dropArea}>Drop Here</div>}
      <div className={cn({
        [styles.active]: isActive
      })}>
        {children}
      </div>
    </div>
  );
};

export default DropContainer;
