import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../WithRouter';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment-timezone';
import getConfig from '../../config';
import { routes } from '../../constants';
import { getQueryObj } from '../../utils/helper';
import { logUser, validateSession } from '../../api/auth';
import { AuthContext } from '../../context/Auth.context';
import { NotificationsContext } from '../../context/Notifications.context';
import { TypesContext } from '../../context/Types.context';
import DialogBox from '../DialogBox';
import Header from '../Header';
import Label from '../Label';
import SystemNotifications from '../SystemNotifications';
import styles from './Layout.module.scss';

const { IDLE_TIMEOUT, IDLE_COUNTDOWN } = getConfig();

const Layout = ({ children, navigate, location }) => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [typesState] = useContext(TypesContext);
  const [idleTime, setIdleTime] = useState(null);
  const [hasToken, setToken] = useState(getQueryObj(location.search).token);
  const hasNotifications = notificationState.notifications;
  const hasIncompleteNotification = hasNotifications && hasNotifications.find(n => n.type === 'incompletes');
  const isAuth = authState.isAuthenticated;
  const showHeader = isAuth || location.pathname === routes.CONFIRM_ACCOUNT;

  const calculateTimeLeft = () => {
    const difference = moment(idleTime).add(IDLE_COUNTDOWN, 'm').toDate() - new Date();
    let timeLeft = {};

    if (difference <= 0) {
      setIdleTime(null);
      setTimeLeft(false);
      authDispatch({
        type: 'SIGN_OUT_USER',
        payload: true
      });
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(false);

  useEffect(() => {
    if (hasToken) {
      validateSession(hasToken)
        .then(data => {
          logUser(data.email);

          authDispatch({
            type: 'SET_USER',
            payload: data
          });
        })
        .catch(_ => {
          navigate('/', { replace: true });
          setToken(null);
        });
    }
  }, [hasToken]);

  useEffect(() => {
    if (!location.pathname.includes('/application')) {
      hasIncompleteNotification && dispatchNotification({
        type: 'REMOVE_NOTIFICATION',
        id: hasIncompleteNotification.id
      });
    }
  }, [location]);

  useEffect(() => {
    if (!isAuth) {
      setIdleTime(null);
      setTimeLeft(false);
    };

    if (isAuth) setToken(null);
  }, [isAuth]);

  useEffect(() => {
    // On first render, counter will be 0
    // The condition will be false and setTimeout() won't start
    if (timeLeft && idleTime) {
      setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  const handleOnIdle = () => {
    if (isAuth) {
      setIdleTime(moment());
      setTimeLeft(calculateTimeLeft());
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const handleLogOutClick = () => authDispatch({
    type: 'SIGN_OUT_USER'
  });

  const handleContinueClick = () => {
    setIdleTime(null);
    setTimeLeft(false);
  };

  return (
    <>
      {authState.isReady && <>
        {(isAuth && idleTime) && <div className={styles.overlay}>
          <DialogBox
            className={styles.dialog}
            theme='dark'
            title='Your session is about to time out'
            cancelText='Log Out'
            onCancelClick={handleLogOutClick}
            submitText='Yes, Continue Session'
            submitColor='green'
            onSubmitClick={handleContinueClick}
            subTitle={<>You will be logged out in {timerComponents}<br />Do you want to stay logged in?</>} />
        </div>}
        {!isAuth && !hasToken && children}
        <>
          {showHeader && <Header />}
          {isAuth && <>
            {typesState.error && <div className={styles.error}>
              <Label isError>
                {typesState.error}
              </Label>
            </div>}
            {typesState.isLoaded && <div className={styles.loggedIn}>
              {children}
            </div>}
          </>}
        </>
        {hasNotifications && <SystemNotifications notifications={hasNotifications} />}
      </>}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  navigate: PropTypes.func,
  location: PropTypes.object
};

export default withRouter(Layout);
