import React from 'react';
import { noControlPersonText } from '../../constants';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const ControlPersonIndividual = ({ data, percentOwnership }) => {
  const individuals = data && data.filter(item => item.isControl);
  const hasIndividuals = individuals && individuals.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='OFFICERS, DIRECTORS, & CONTROL PERSONS' />
        <SectionTitle text='CONTROL PERSONS | INDIVIDUALS' />
      </View>
      {individuals && individuals.map((person, i) => {
        const pageBreak = i !== 0 && isInt(i / 5);
        return (
          <View break={pageBreak} key={`individual-${i}`}>
            <Row>
              <Column size='100'>
                <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
              </Column>
            </Row>
            <Divider />
          </View>
        );
      })}
      {!hasIndividuals && <Value text={noControlPersonText(percentOwnership)} />}
    </>
  );
};

export default ControlPersonIndividual;
