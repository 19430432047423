import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import LOGO_OTCQB from './LOGO_OTCQB';

const HeaderOTCQB = () => {
  return (
    <View style={styles.header} fixed>
      <View style={styles.headerImg}>
        <LOGO_OTCQB />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    display: 'block',
    backgroundColor: '#F7931D',
    left: 0,
    top: 0,
    right: 0,
    width: '100vw',
    height: '72px'
  },
  headerImg: {
    position: 'absolute',
    top: 37,
    left: 72
  }
});

export default HeaderOTCQB;
