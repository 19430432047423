import React, { useEffect, useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { appStates, changeInCtrl, entities, urls, reportingStandards } from '../../../constants';
import { entityTitle, createID } from '../../../utils/helper';
import { isFormerExexComplete, validDnsSkipCompany } from '../../../constants/cardComplete';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import { isBoolean } from '../../../utils/validations';
import QuestionCard from '../../QuestionCard';
import AddOfficer from '../OfficersDirectorsControlPersons/AddOfficer';
import EntityList from '../../EntityList';
import Divider from '../../Divider';
import Label from '../../Label';
import NumberList from '../../NumberList';
import FieldError from '../../FieldError';
import CheckBoxField from '../../CheckBoxField';
import SlidePanel from '../../SlidePanel';
import styles from '../../Application/Application.module.scss';

const pageDesc = [
  {
    text: 'Please confirm whether the company has experienced any of the following change of control events in the previous 12 months or select None of the Above.'
  }
];
const skipText = 'This section is currently not applicable to your company. Select the Next button below to continue with your application.';

const ChangeInControl = ({ company, name, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [formerExecView, setFormerExecView] = useState(null);
  const [slidePane, setSlidePane] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const application = state.application;
  const backupApplication = state.backupApplication;
  const changeInControlDetail = application && application.companyInfo && application.companyInfo.changeInControlDetail;
  const hasChangeControl = application && application.companyInfo && isBoolean(application.companyInfo.changeInControl);
  const hasChangeDetail = changeInControlDetail && !changeInControlDetail.includes('isNone');
  const isChangeDetailNone = changeInControlDetail === 'isNone';
  const peopleList = application.peopleList || [];
  const formerExecList = peopleList && peopleList.filter(person => person.isFormerExecutive);
  const contactTotal = peopleList.filter(person => person.isFormerExecutive).length;
  const hasFormerExec = formerExecList && formerExecList.length > 0;
  const companyInfo = application.companyInfo = { ...application.companyInfo };
  const appTypeName = application.typeDesc;
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;
  const isInProgress = state.application.status === appStates.IN_PROGRESS;
  const isSubmitted = state.application.status === appStates.SUBMITTED;
  const showCustodianQuestion = !!(application && application.companyInfo && application.companyInfo.changeInControl);
  const canSkip = application && application.companyInfo && validDnsSkipCompany(application.companyInfo, application.reportingStandard);

  const custodianQuestion = {
    title: 'Court Appointed Custodian',
    subTitle: 'Is there a Court Appointed Custodian?',
    fieldName: 'isCourtAppointedCustodian',
    falseDetails: [
      {
        desc: () => <div className='mtMed'>
          <p>
            You will be prompted to upload the following document(s) in the Supporting Documents section (Section 11) of this application:
          </p>
          <NumberList list={[
            `<a href=${urls.ADDENDUM_CHANGE} target="_blank" rel="noopener noreferrer">Disclosure for Change in Control</a>`,
            'Board Resolution',
            'Letter of Resignation'
          ]} />
        </div>,
        hideDetails: true
      }
    ],
    trueDetails: [
      {
        desc: () => <div className='mtMed'>
          <p>
            You will be prompted to upload the following document(s) in the Supporting Documents section (Section 11) of this application:
          </p>
          <NumberList list={[
            `<a href=${urls.ADDENDUM_CHANGE} target="_blank" rel="noopener noreferrer">Disclosure for Change in Control</a>`,
            'Application for Appointment of Custodian',
            'Court Order Granting Custodianship',
            'Letter of Resignation',
            'Board Resolution'
          ]} />
        </div>,
        hideDetails: true
      }
    ]
  };

  useEffect(() => {
    const isPeopleChange = !equal({ ...backupApplication.peopleList }, { ...application.peopleList });
    if (isPeopleChange) {
      saveApplication();
    }
  }, [state.application]);

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'companyInfo'
    });
  };

  const onPersonUpdate = (data, field) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: field
    });
  };

  const onValueChanged = (value, field) => {
    const updateCompanyInfo = { ...companyInfo };
    updateCompanyInfo[field] = value;
    onUpdate(updateCompanyInfo);
  };

  const handleQuestionClick = (value, field) => {
    const updateCompanyInfo = { ...companyInfo };
    updateCompanyInfo[field] = value;
    onUpdate(updateCompanyInfo);
  };

  const onCheckBoxChange = (value, field) => {
    const updateCompanyInfo = { ...companyInfo };

    if (field === 'isNone') {
      if (value) {
        updateCompanyInfo.changeInControl = false;
        updateCompanyInfo.changeInControlDetail = 'isNone';
      } else {
        updateCompanyInfo.changeInControl = null;
        updateCompanyInfo.changeInControlDetail = null;
      }
    } else {
      let changeControlList = updateCompanyInfo.changeInControlDetail ? updateCompanyInfo.changeInControlDetail.split(',') : [];
      if (value === false) changeControlList = changeControlList.filter(item => item !== field);
      if (value) changeControlList.push(field);

      if (changeControlList && changeControlList.length > 0) updateCompanyInfo.changeInControl = true;
      if (!changeControlList || changeControlList.length === 0) updateCompanyInfo.changeInControl = null;
      updateCompanyInfo.changeInControlDetail = changeControlList.join();
    }

    onUpdate(updateCompanyInfo);
  };

  const onPanelClose = () => {
    setFormerExecView(null);
    setSlidePane(false);
  };

  const validateComplete = person => {
    return isFormerExexComplete(person);
  };

  const handleAdd = person => {
    const newPerson = person;
    if (!person.id) {
      const id = createID(peopleList);
      newPerson.id = id;
    }

    onPersonUpdate([...peopleList, newPerson], 'peopleList');
    onPanelClose();
  };

  const handleUpdate = person => {
    let updatePeopleList = [...peopleList];

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id === person.id) return person;

      return item;
    });

    onPersonUpdate(updatePeopleList, 'peopleList');

    onPanelClose();
  };

  const handleRemove = (e, person) => {
    e.stopPropagation();

    const updatePeopleList = peopleList;
    const personUpdate = updatePeopleList.find(item => item.id === person.id);

    personUpdate.isFormerExecutive = false;
    onPersonUpdate(updatePeopleList, 'peopleList');
  };

  const handleEdit = person => {
    setFormerExecView(person);
    setSlidePane(true);
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={canSkip ? null : pageDesc} />
      {canSkip &&
      <Label className='mtLg' title={skipText} />
      }
      {!canSkip && <>
        <div
          onMouseEnter={showIncompletes ? () => setFocus(true) : undefined}
          onMouseLeave={showIncompletes ? () => setFocus(false) : undefined}>
          <div className={cn({
            [styles.incomplete]: (showIncompletes && !hasChangeControl)
          })} style={{ paddingBottom: '8px' }}>
            {(showIncompletes && !hasChangeControl) && <FieldError error='One or more of the options have been selected.' isFocus={isFocus} />}
          </div>
          {hasChangeDetail && <Label className='mtLg' title='One or more options have been selected. Additional information is needed below.' />}
          <CheckBoxField
            className='mtMed'
            readOnly={isReadOnly}
            value={changeInControlDetail && changeInControlDetail.includes('isChangeInMajority')}
            text={changeInCtrl.isChangeInMajority}
            disabled={isChangeDetailNone}
            onValueChanged={e => onCheckBoxChange(e.value, 'isChangeInMajority')}>
            <p>
              Any “person” (as such term is used in Sections 13(d) and 14(d) of the Exchange Act) becoming the “beneficial owner” (as defined in Rule 13d-3 of the Exchange Act), directly or indirectly, of securities of the Company representing fifty percent (50%) or more of the total voting power represented by the Company’s then outstanding voting securities;
            </p>
          </CheckBoxField>
          <CheckBoxField
            className='mtMed'
            readOnly={isReadOnly}
            value={changeInControlDetail && changeInControlDetail.includes('isSaleDisAssets')}
            text={changeInCtrl.isSaleDisAssets}
            disabled={isChangeDetailNone}
            onValueChanged={e => onCheckBoxChange(e.value, 'isSaleDisAssets')}>
            <p>
              The consummation of the sale or disposition by the Company of all or substantially all of the Company’s assets;
            </p>
          </CheckBoxField>
          <CheckBoxField
            className='mtMed'
            readOnly={isReadOnly}
            value={changeInControlDetail && changeInControlDetail.includes('isChangeMajorityDir')}
            text={changeInCtrl.isChangeMajorityDir}
            disabled={isChangeDetailNone}
            onValueChanged={e => onCheckBoxChange(e.value, 'isChangeMajorityDir')}>
            <p>
              A change in the composition of the Company’s board occurring within a two (2)-year period, as a result of which fewer than a majority of the directors are directors immediately prior to such change; or
            </p>
          </CheckBoxField>
          <CheckBoxField
            className='mtMed'
            readOnly={isReadOnly}
            value={changeInControlDetail && changeInControlDetail.includes('isMerger')}
            text={changeInCtrl.isMerger}
            disabled={isChangeDetailNone}
            onValueChanged={e => onCheckBoxChange(e.value, 'isMerger')}>
            <p>
              The consummation of a merger or consolidation of the Company with any other corporation, other than a merger or consolidation which would result in the voting securities of the Company outstanding immediately prior thereto continuing to represent (either by remaining outstanding or by being converted into voting securities of the surviving entity or its parent) at least fifty percent (50%) of the total voting power represented by the voting securities of the Company or such surviving entity or its parent outstanding immediately after such merger or consolidation.
            </p>
          </CheckBoxField>
          <CheckBoxField
            className='mtMed'
            readOnly={isReadOnly}
            value={changeInControlDetail && changeInControlDetail.includes('isNone')}
            text='None of the Above'
            disabled={hasChangeDetail}
            onValueChanged={e => onCheckBoxChange(e.value, 'isNone')} />
        </div>
        {showCustodianQuestion &&
        <div>
          <Divider />
          <p>
            Provide the name, title and contact information for the company's former executives.
          </p>
          <EntityList
            title={`Former Executives of ${company}`}
            addTitle={!isReadOnly && contactTotal < 10 && 'Add Former Executive'}
            icon={'user'}
            showIncompletes={showIncompletes}
            incompleteMessage='At least one Former Executive is required'
            iconComplete={'userComplete'}
            list={hasFormerExec ? formerExecList : []}
            entityTitle={entityTitle}
            readOnly={isReadOnly}
            confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
            validateComplete={validateComplete}
            handleAddClick={() => setSlidePane(true)}
            handleEntityClick={handleEdit}
            handleEntityRemove={!isReadOnly && handleRemove} />
          <Divider />
          <QuestionCard
            item={custodianQuestion}
            readOnly={isReadOnly}
            section={companyInfo}
            isValid={showIncompletes ? isBoolean(companyInfo && companyInfo[custodianQuestion.fieldName]) : true}
            showIncompletes={showIncompletes}
            handleQuestionClick={handleQuestionClick}
            onValueChanged={onValueChanged} />
        </div>
        }
        <SlidePanel
          isOpen={slidePane}
          onClose={onPanelClose}
          title='Add Former Executive'>
          <AddOfficer
            officerView={formerExecView}
            setOfficerView={setFormerExecView}
            handleUpdate={handleUpdate}
            handleAdd={handleAdd}
            readOnly={isReadOnly}
            isFormerExec
            setSlidePane={setSlidePane} />
        </SlidePanel>
      </>}
    </div>
  );
};

ChangeInControl.propTypes = {
  name: PropTypes.string
};

export default ChangeInControl;
