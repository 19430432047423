import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const Row = ({ children }) => (
  <View style={styles.row}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  }
});

export default Row;
