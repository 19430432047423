import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/Auth.context';
import NavButton from '../NavButton';
import { routes, dashboardPaths } from '../../constants';
import styles from './DashboardNav.module.scss';

const DashboardNav = ({ section }) => {
  const [authState] = useContext(AuthContext);
  const isIQUser = authState.iq;

  const isStartNewApp = section === dashboardPaths.START_APPLICATION;
  const isMyApps = section === dashboardPaths.MY_APPLICATIONS;
  const isMyDocs = section === dashboardPaths.MY_DOCUMENTS;
  const isManageProfile = section === dashboardPaths.APP;

  return (
    <div className={styles.container}>
      {!isIQUser && <>
        <Link to={routes.START_APPLICATION}>
          <NavButton text='Start New Application' type='secondary' icon='plus' active={isStartNewApp} />
        </Link>
        <hr />
      </>}
      <div className={styles.options}>
        {isIQUser && <Link to={routes.MANAGE_COMPANY_PROFILE}>
          <NavButton text='Manage Company Profile' active={isManageProfile} />
        </Link>}
        <Link to={routes.MY_APPLICATIONS}>
          <NavButton text='My Applications' active={isMyApps} />
        </Link>
        <Link to={routes.MY_DOCUMENTS}>
          <NavButton text='My Documents' active={isMyDocs} />
        </Link>
      </div>
    </div>
  );
};

DashboardNav.propTypes = {
  section: PropTypes.string
};

export default DashboardNav;
