import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from 'devextreme-react';
import Controls from '../../Controls';
import Title from '../../Title';

const ReducedPayment = ({ closeSlidePane, submitReducedPayment, payment, reducedFeesOptions, title }) => {
  const [reduceFeeType, setReduceFeeType] = useState(payment.reducedFeeType);

  const handleCancel = () => {
    closeSlidePane();
  };

  const handleSubmit = () => {
    if (!reduceFeeType) { return; }
    submitReducedPayment(reduceFeeType, reducedFeesOptions);
  };
  const handleOfflinePaymentChange = (value) => {
    setReduceFeeType(value);
  };

  const renderCustomItem = data => {
    return <div>
      {data.title && <Title title={data.title} type='h3' /> }
      {data.desc && <p>
        {data.desc}
      </p>}
    </div>;
  };

  return (
    <div id='reducedPaymentContainer'>
      <div>
        <p>
          {title} Select one of the following, if any apply.
        </p>
        <div className={'mtMed'}>

          <RadioGroup
            id='radio-group-reduce-payments'
            valueExpr='id'
            items={reducedFeesOptions}
            value={reduceFeeType}
            itemRender={renderCustomItem}
            onValueChanged={e => handleOfflinePaymentChange(e.value)}
          />
        </div>

      </div>
      <div className={'mtXXL'}>
        <Controls
          cancelText={'Cancel'}
          submitText={'Confirm'}
          onCancelClick={handleCancel}
          onSubmitClick={handleSubmit} />

      </div>
    </div>
  );
};

ReducedPayment.propTypes = {
  title: PropTypes.string,
  reducedFeesOptions: PropTypes.array,
  submitReducedPayment: PropTypes.func,
  payment: PropTypes.object,
  closeSlidePane: PropTypes.func
};

export default ReducedPayment;
