import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.context';
import PropTypes from 'prop-types';
import { routes } from '../../constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authState] = useContext(AuthContext);

  return authState.isAuthenticated ? <Outlet /> : <Navigate to={routes.SIGNIN} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default PrivateRoute;
