import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import equal from 'fast-deep-equal/es6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appStates, errors, urls, timezones, achPayments,
  onlinePayments, offlinePaymentTypes, taxUsaStates, applicationFeeMap, paymentTypesDesc, paymentTypes,
  reducedFeesDescOtcqx, reducedFeesOptionsOtcqx, reducedFeesDescOtcqb, reducedFeesOptionsOtcqb } from '../../../constants';
import { isSubmitReady } from '../../../constants/cardComplete';
import { ApplicationContext } from '../../../context/Application.context';
import { AuthContext } from '../../../context/Auth.context';
import { getTaxRate } from '../../../api/payment';
import { getTaxAmount } from '../../../utils/helper';
import { isUSA } from '../../../utils/validations';
import { format } from '../../../utils/locale';
import Entity from '../../Entity';
import Title from '../../Title';
import Label from '../../Label';
import SlidePanel from '../../SlidePanel';
import ApplicationDescription from '../../ApplicationDescription';
import DownloadReceipt from '../../DownloadReceipt';
import ReviewCCPayment from './ReviewCCPayment';
import ReviewAchPayment from './ReviewAchPayment';
import AchPayment from './AchPayment';
import CreditCardPayment from './CreditCardPayment';
import OfflinePayment from './OfflinePayment';
import ReducedPayment from './ReducedPayment';
import styles from './PaymentSubmit.module.scss';

const paymentAchTitle = 'ACH Payment (U.S. Bank)';
const paymentCreditCardTitle = 'Credit Card Payment';
const paymentOfflinePaymentTitle = 'Offline Payment (Wire Transfer or Check)';

const reducedFeeOtcqxSectionTitle = 'Companies that are upgrading their service or reapplying for OTCQX may be eligible for a reduced Application Fee.';
const reducedFeeOtcqbSectionTitle = 'Companies that are upgrading their service or reapplying for OTCQB may be eligible for a reduced Application Fee.';
const reducedFeeTitle = 'Eligibility for Reduced Application Fee';

const FEES_DESC = <>Invoice for annual fee will be sent to Billing Contact(s) upon approval of application. For information on fees, see <a href={urls.FEE_SCHEDULE} target='_blank' rel='noopener noreferrer'>Fee Schedule</a>.</>;

const PaymentSubmit = ({ name, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [authState] = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [taxError, setTaxError] = useState(null);
  const [slidePaneReducedFee, setSlidePaneReducedFee] = useState(false);
  const [slideAddPayment, setSlideAddPayment] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState({});
  const application = state && state.application;
  const appId = application && application.id;
  const backupApplication = state.backupApplication;
  const appTypeId = application.typeId;
  const userEmail = authState.email;
  const isOTCQX = appTypeId === 1 || appTypeId === 2 || appTypeId === 3;
  const isOTCQB = appTypeId === 4;
  const companyInfo = application.companyInfo;
  const appTypeName = application.typeDesc;
  const completeCards = state.completeCards;
  const payment = application.payment || defaultPayment;
  const isReadOnly = state.readOnly;
  const isReady = isSubmitReady(completeCards) || application.status === appStates.SUBMITTED || application.status === appStates.APPROVED || application.status === appStates.CLOSED;
  const sessionPayment = state.sessionPayment;
  const appBasicFee = applicationFeeMap.get(appTypeId);
  const applicationBasicFee = format(appBasicFee, appBasicFee, 'currency');
  const isOnlinePaymentComplete = payment.paymentType && onlinePayments.includes(payment.paymentType) && payment.transactionId;
  const isOfflinePaymentComplete = payment.paymentType && offlinePaymentTypes.includes(payment.paymentType);
  const isPaymentComplete = isOnlinePaymentComplete || isOfflinePaymentComplete;
  const paymentTypeDesc = sessionPayment && sessionPayment.paymentType && paymentTypesDesc.get(sessionPayment.paymentType);
  const reduceFeeOptionSelected = payment && !!payment.reducedFeeType;
  const applicationSubTotalFee = reduceFeeOptionSelected ? payment && format(payment.reducedFee, payment.reducedFee, 'currency') : applicationBasicFee;
  const isReducedFeeTypeUpgrade = reduceFeeOptionSelected && payment.reducedFee > 0;
  const isReducedFeeTypeWaive = reduceFeeOptionSelected && payment.reducedFee === 0;
  const hasPaymentType = sessionPayment && sessionPayment.paymentType;
  const INITIAL_PAYMENT = { appFeeSubtotal: appBasicFee, appFeeTotal: appBasicFee };
  const showAddCC = slideAddPayment === paymentTypes.CREDIT;
  const showAddACH = slideAddPayment === paymentTypes.ACH_CHECKINGS;
  const showAddOffline = slideAddPayment === paymentTypes.OFFLINE;
  const showSubmitAppError = !!(application && application.payment && (application.payment.transactionId && !application.submitDate));

  const addPaymentTitle = () => {
    switch (slideAddPayment) {
      case paymentTypes.CREDIT:
        return paymentCreditCardTitle;
      case paymentTypes.ACH_CHECKINGS:
      case paymentTypes.ACH_SAVINGS:
        return paymentAchTitle;
      case paymentTypes.OFFLINE:
      case paymentTypes.OFFLINE_CHECK:
        return paymentOfflinePaymentTitle;
      default:
        return '';
    }
  };

  const pageDesc = `The ${appTypeName} Application Fee is non-refundable and must be paid prior to submission of this application. The Company acknowledges that OTC Markets Group has the right to refuse the Company’s ${appTypeName} Application in its sole and absolute discretion.`;

  const pageDescObj = [
    {
      text: pageDesc
    },
    {
      text: FEES_DESC
    }
  ];

  useEffect(() => {
    if (!equal({ ...backupApplication.payment }, { ...application.payment })) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    // set initial fees if user is coming here for first time

    if (isReady) {
      let initialPayment = { ...INITIAL_PAYMENT };

      if (Object.keys(payment).length === 0 || !payment.taxRate) {
        const companyAddress = application.companyInfo && application.companyInfo.companyAddress;
        if (companyAddress && companyAddress.zip && isUSA(companyAddress.country) && taxUsaStates.includes(companyAddress.state)) {
          getTaxDetails(companyAddress.zip);
        } else {
          setDefaultPayment(initialPayment);
        }
      } else {
        const zipTaxRate = payment.taxRate;
        if (zipTaxRate) {
          const taxAmount = getTaxAmount(appBasicFee, zipTaxRate);
          initialPayment.taxRate = zipTaxRate;
          initialPayment.taxAmount = taxAmount;
          initialPayment.appFeeTotal = appBasicFee + taxAmount;
        }
        setDefaultPayment(initialPayment);
      }

      if (!sessionPayment || !sessionPayment.paymentType) {
        dispatch({
          type: 'SET_SESSION_PAYMENT',
          payload: application.payment || initialPayment
        });
      }
    }
  }, [isReady]);

  const getTaxDetails = (zip) => {
    getTaxRate(zip)
      .then(data => {
        const zipTaxRate = data.taxRate;
        const taxAmount = getTaxAmount(appBasicFee, zipTaxRate);
        const appFeeTotal = appBasicFee + taxAmount;
        const initialPayment = { appFeeSubtotal: appBasicFee, taxAmount: taxAmount, taxRate: zipTaxRate, appFeeTotal: appFeeTotal };

        setDefaultPayment(initialPayment);
        dispatch({
          type: 'SET_SESSION_PAYMENT',
          payload: initialPayment
        });
        setTaxError(null);
      })
      .catch(e => {
        if ((e && e.response && e.response.data && e.response.data.code) && e.response.data.code === 404) return;

        const taxErrorMsg = <>We were unable to generate tax information. <a onClick={() => getTaxDetails(zip)}>Click here</a>  to {errors.TRY_AGAIN}</>;
        setTaxError(taxErrorMsg);
      });
  };

  const onPaymentUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'payment'
    });
  };

  const addPayment = paymentInfo => {
    if (offlinePaymentTypes.includes(paymentInfo.paymentType)) {
      if (error) setError(null);
      let updatePayment = { ...payment, ...paymentInfo };
      updatePayment.paymentDate = moment().tz(timezones.NEW_YORK).valueOf();

      dispatch({
        type: 'SET_SESSION_PAYMENT',
        payload: paymentInfo
      });
    }

    handleAddPaymentClose();

    dispatch({
      type: 'SET_SESSION_PAYMENT',
      payload: { ...sessionPayment, ...paymentInfo }
    });
  };

  const submitReducedPayment = (type, reducedFeeOptions) => {
    const reducedFeeOption = reducedFeeOptions.find(reducedFee => reducedFee.id === type);
    // update fees and all if payment has not been made by offline/payment methods
    if (!payment.paymentType && reducedFeeOption) {
      const reducedFee = reducedFeeOption.fee;
      closeSlidePaneReducedFee();
      let updatePayment = { ...payment };
      delete updatePayment.paymentDate;
      updatePayment.reducedFeeType = type;
      updatePayment.reducedFee = reducedFee;
      if (reducedFee === 0) {
        // wavier option selected
        delete updatePayment.taxAmount;
        updatePayment.paymentDate = moment().tz(timezones.NEW_YORK).valueOf();
      }
      let appFeeTotal = reducedFee;
      // re-calculate tax if needed
      if (payment.taxRate) {
        const taxAmount = getTaxAmount(reducedFee, payment.taxRate);
        updatePayment.taxAmount = taxAmount;
        appFeeTotal = reducedFee + taxAmount;
      }

      updatePayment.appFeeTotal = appFeeTotal;
      onPaymentUpdate(updatePayment);

      dispatch({
        type: 'SET_SESSION_PAYMENT',
        payload: { ...sessionPayment, ...updatePayment }
      });
    }
  };

  const handleAddPaymentClose = () => setSlideAddPayment(false);

  const closeSlidePaneReducedFee = () => {
    setSlidePaneReducedFee(false);
  };

  const onRemovePayment = e => {
    e.stopPropagation();

    const reset = {
      ...INITIAL_PAYMENT,
      appFeeTotal: sessionPayment.appFeeTotal,
      appFeeSubtotal: sessionPayment.appFeeSubtotal,
      reducedFee: sessionPayment.reducedFee,
      reducedFeeType: sessionPayment.reducedFeeType,
      taxAmount: sessionPayment.taxAmount,
      taxRate: sessionPayment.taxRate
    };

    if (offlinePaymentTypes.includes(sessionPayment.paymentType)) {
      if (error) setError(null);
      onPaymentUpdate(reset);
    }

    dispatch({
      type: 'SET_SESSION_PAYMENT',
      payload: reset
    });
  };

  const removeReducedFee = (e) => {
    e.stopPropagation();
    const resetPayment = { ...defaultPayment };
    delete resetPayment.reducedFee;
    delete resetPayment.reducedFeeType;

    onPaymentUpdate(resetPayment);

    dispatch({
      type: 'SET_SESSION_PAYMENT',
      payload: resetPayment
    });
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={pageDescObj} />
      {!isReady && <Label className='mtXL' isError title='Please complete all prior sections in order to access this Payment section.' />}
      {isReady && <>
        <Title className='mtXL' title='Application Fee' type='h2' />
        <hr />
        <Title className={styles.appFees} title={applicationSubTotalFee} type='h2' />
        <hr />
        {taxError && <Label className='mtLg' isError title={taxError} />}

        {/* Reduced Fees */}
        {!hasPaymentType && <>
          {isOTCQX && <>
            <Title className='mtLg' title='' type='h2' />
            <p>{reducedFeeOtcqxSectionTitle}</p>
            <Entity
              isComplete={reduceFeeOptionSelected}
              icon={reduceFeeOptionSelected ? 'check' : null}
              title={reduceFeeOptionSelected ? reducedFeesDescOtcqx.get(payment.reducedFeeType) : reducedFeeTitle}
              subTitle={'Find Out More'}
              size='small'
              handleRemove={removeReducedFee}
              onClick={() => setSlidePaneReducedFee(true)}
            />
          </>}
          {isOTCQB && <>
            <Title className='mtLg' title='' type='h2' />
            <p>{reducedFeeOtcqbSectionTitle}</p>
            <Entity
              isComplete={reduceFeeOptionSelected}
              icon={reduceFeeOptionSelected ? 'check' : null}
              title={reduceFeeOptionSelected ? reducedFeesDescOtcqb.get(payment.reducedFeeType) : reducedFeeTitle}
              subTitle={'Find Out More'}
              size='small'
              handleRemove={removeReducedFee}
              onClick={() => setSlidePaneReducedFee(true)}
            />
          </>}
        </>
        }

        {/* LANDING */}
        {(!hasPaymentType && !isReducedFeeTypeWaive) && <>
          <Title className='mtXL' title='Select Payment Type' type='h2' />
          <p>
            Select the payment type you wish to use to pay the application fee for {companyInfo.name}.
          </p>
          <div className={styles.list}>
            <Entity
              title={paymentAchTitle}
              subTitle='Select'
              size='small'
              section={'none'}
              onClick={() => setSlideAddPayment(paymentTypes.ACH_CHECKINGS)}
            />
            <Entity
              title={paymentCreditCardTitle}
              subTitle='Select'
              size='small'
              section={'none'}
              onClick={() => setSlideAddPayment(paymentTypes.CREDIT)}
            />
            <Entity
              title={paymentOfflinePaymentTitle}
              subTitle='Select'
              size='small'
              section={'none'}
              onClick={() => setSlideAddPayment(paymentTypes.OFFLINE)}
            />
          </div>
        </>}

        {/* PAYMENT ADDED */}
        {(!isPaymentComplete && hasPaymentType) && <div className='mtLg'>
          {sessionPayment.paymentType === paymentTypes.CREDIT && <>
            <Entity title={paymentTypeDesc} subTitle='Edit'
              onClick={() => setSlideAddPayment(paymentTypes.CREDIT)}
              handleRemove={onRemovePayment}
              confirmRemoveText={`Are you sure you want to delete your ${paymentTypeDesc} information?`} />
            <ReviewCCPayment
              companyAddress={application.companyInfo.companyAddress}
              payment={payment}
              paymentInfo={sessionPayment}
              applicationBasicFee={applicationBasicFee}
              isReducedFeeTypeUpgrade={isReducedFeeTypeUpgrade} />
          </>}
          {achPayments.includes(sessionPayment.paymentType) && <>
            <Entity title={paymentTypeDesc} subTitle='Edit'
              onClick={() => setSlideAddPayment(paymentTypes.ACH_CHECKINGS)}
              handleRemove={onRemovePayment}
              confirmRemoveText={`Are you sure you want to delete your ${paymentTypeDesc} information?`} />
            <ReviewAchPayment
              companyAddress={application.companyInfo.companyAddress}
              payment={payment}
              paymentInfo={sessionPayment}
              applicationBasicFee={applicationBasicFee}
              isReducedFeeTypeUpgrade={isReducedFeeTypeUpgrade} />
          </>}
          {offlinePaymentTypes.includes(sessionPayment.paymentType) && <>
            <Title className='mtXL' title='Payment To Be Completed Offline' type='h2' />
            <p>
              You’ve selected to pay offline for the {appTypeName} Application.
            </p>
            <Entity title={paymentTypeDesc} subTitle='Edit'
              onClick={() => setSlideAddPayment(paymentTypes.OFFLINE)}
              handleRemove={onRemovePayment}
              confirmRemoveText={`Are you sure you want to delete your ${paymentTypeDesc} information?`} />
          </>}
        </div>}
        {isOnlinePaymentComplete && <>
          <Title className='mtXL' title='Payment Complete' type='h2' />
          <p>
            Thank you for your {appTypeName} Application Fee payment.
          </p>
          <DownloadReceipt appId={appId} userEmail={userEmail} />
        </>}
        {isOfflinePaymentComplete && <>
          <Title className='mtXL' title='Payment To Be Completed Offline' type='h2' />
          <p>
            You’ve selected to pay offline for the {appTypeName} Application.
          </p>
          <Entity title={paymentTypeDesc} subTitle={'View'}
            onClick={() => setSlideAddPayment(paymentTypes.OFFLINE)}
            handleRemove={!isReadOnly && onRemovePayment}
            confirmRemoveText={`Are you sure you want to delete your ${paymentTypeDesc} information ?`} />
        </>}
      </>}

      {showSubmitAppError && <Label className='mtLg' isError>
        <div className={styles.error}>
          <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} size='md' /><span className={styles.errorTitle}>Application Error</span>
        </div>
        {errors.SUBMIT_APP}
      </Label>}

      <SlidePanel
        isOpen={slidePaneReducedFee}
        onClose={closeSlidePaneReducedFee}
        title={reducedFeeTitle}>
        { isOTCQX && <ReducedPayment closeSlidePane={closeSlidePaneReducedFee} submitReducedPayment={submitReducedPayment}
          payment={payment} reducedFeesOptions={reducedFeesOptionsOtcqx} title={reducedFeeOtcqxSectionTitle} /> }
        { isOTCQB && <ReducedPayment closeSlidePane={closeSlidePaneReducedFee} submitReducedPayment={submitReducedPayment}
          payment={payment} reducedFeesOptions={reducedFeesOptionsOtcqb} title={reducedFeeOtcqbSectionTitle} /> }
      </SlidePanel>

      <SlidePanel
        isOpen={!!slideAddPayment}
        onClose={handleAddPaymentClose}
        title={addPaymentTitle()}>
        {showAddACH && <AchPayment payment={sessionPayment} closeSlidePane={handleAddPaymentClose} handleAddPayment={addPayment} paymentError={error} isReducedFeeTypeUpgrade={isReducedFeeTypeUpgrade} applicationBasicFee={applicationBasicFee} />}
        {showAddCC && <CreditCardPayment payment={sessionPayment} closeSlidePane={handleAddPaymentClose} handleAddPayment={addPayment} paymentError={error} isReducedFeeTypeUpgrade={isReducedFeeTypeUpgrade} />}
        {showAddOffline && <OfflinePayment payment={sessionPayment} closeSlidePane={handleAddPaymentClose} handleAddPayment={addPayment} paymentError={error} isReducedFeeTypeUpgrade={isReducedFeeTypeUpgrade} applicationBasicFee={applicationBasicFee} />}
      </SlidePanel>
    </div>
  );
};

PaymentSubmit.propTypes = {
  name: PropTypes.string,
  saveApplication: PropTypes.func
};

export default PaymentSubmit;
