import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { downloadPaymentReceipt } from '../../api/application';
import Entity from '../Entity';

const DownloadReceipt = ({ appId, userEmail }) => {
  const [isDownloading, setDownloading] = useState(false);
  const downloadTitle = isDownloading ? 'Downloading...' : `Receipt for Application Payment`;
  const subTitle = isDownloading ? '' : 'Download';

  const handleReceiptDownload = async () => {
    setDownloading(true);
    downloadPaymentReceipt(appId, userEmail)
      .then(() => {
        setDownloading(false);
      })
      .catch(e => {
        setDownloading(false);
        console.log('DOWNLOAD RECEIPT DATA ERROR', e);
      });
  };

  return (
    <Entity title={downloadTitle} onClick={handleReceiptDownload} icon='file' subTitle={subTitle} />
  );
};

DownloadReceipt.propTypes = {
  appId: PropTypes.number,
  userEmail: PropTypes.string
};

export default DownloadReceipt;
