import React, { useState, useEffect, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import SlidePanel from '../../SlidePanel';
import AddOfficer from './AddOfficer';
import styles from '../Application.module.scss';
import EntityList from '../../EntityList';
import { entities } from '../../../constants';
import { entityTitle } from '../../../utils/helper';
import { isOfficerComplete } from '../../../constants/cardComplete';

const OfficersTab = ({ peopleList, readOnly, onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [state] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [officerView, setOfficerView] = useState(null);
  let officerTitle = `${officerView && officerView.firstName} ${officerView && officerView.lastName}`;
  const showIncompletes = state.showIncompletes;

  const officerList = peopleList && peopleList.filter(person => person.isOfficer);
  const hasOfficers = officerList && officerList.length > 0;
  const directorList = peopleList && peopleList.filter(person => person.isDirector && !person.isOfficer);

  if (!officerTitle || officerTitle.trim().length === 0) officerTitle = entities.NO_NAME;

  useEffect(() => {
    if (!slidePane) setOfficerView(null);
  }, [slidePane]);

  const handleUpdate = officer => {
    onPersonUpdate(officer);
    setSlidePane(false);
  };

  const handleAdd = officer => {
    onPersonAdd(officer);
    setSlidePane(false);
  };

  const handleEdit = person => {
    setOfficerView(person);
    setSlidePane(true);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation();
    onPersonRemove(person, 'isOfficer');
  };

  const validateComplete = person => {
    return isOfficerComplete(person);
  };

  return (
    <div>
      <p className={styles.description}>
        Enter all Executive Officers and provide contact information.
      </p>
      <EntityList
        title='Executive Officers'
        addTitle={!readOnly && 'Add Officer'}
        icon={'user'}
        showIncompleteList={showIncompletes && officerList.length < 1}
        showIncompletes={showIncompletes}
        incompleteMessage='At least one Officer is required'
        iconComplete={'userComplete'}
        list={hasOfficers ? officerList : []}
        entityTitle={entityTitle}
        readOnly={readOnly}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        validateComplete={validateComplete}
        handleAddClick={() => setSlidePane(true)}
        handleEntityClick={handleEdit}
        handleEntityRemove={!readOnly && handleRemove} />
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={officerView ? officerTitle : 'Add Officer'}>
        <AddOfficer
          officerView={officerView}
          setOfficerView={setOfficerView}
          directorList={directorList}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          readOnly={readOnly}
          setSlidePane={setSlidePane} />
      </SlidePanel>
    </div>
  );
};

export default OfficersTab;
