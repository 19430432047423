import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './NavButton.module.scss';

const NavButton = props => {
  const isPlusIcon = props.icon === 'plus';
  const isBackIcon = props.icon === 'back';

  return (
    <div id={props.id} className={cn(styles.container, props.className, {
      [styles.active]: props.active,
      [styles.secondary]: props.type === 'secondary',
      [styles.spaceBetween]: isBackIcon,
      [styles.blackBorder]: props.type === 'secondary'
    })} onClick={props.onClick}>
      {props.icon && <span className={styles.icon}>
        {isPlusIcon && <FontAwesomeIcon icon={['far', 'plus']} />}
        {isBackIcon && <FontAwesomeIcon icon={['far', 'long-arrow-left']} />}
      </span>}
      <span className={cn({
        [styles.back]: isBackIcon
      })}>
        {props.text}
      </span>
    </div>
  );
};

NavButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.oneOf(['plus', 'back']),
  secondary: PropTypes.oneOf(['primary', 'secondary'])
};

export default NavButton;
