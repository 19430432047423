import React from 'react';
import { isBoolean } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const SPTransferAgent = ({ data }) => (
  <>
    <View fixed>
      <SectionHeader text='SERVICE PROVIDERS' />
      <SectionTitle text='TRANSFER AGENT' />
    </View>
    {data && data.map((item, i) => {
      return (
        <View break={i > 0} key={`ta-${i}`}>
          <Row>
            <Column size='100'>
              <Label text='NAME OF FIRM' />
              <Value text={item.name} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Has the company authorized transfer agent?' />
              <Value text={item.verifiedSharesAuth ? 'Yes' : 'No'} />
            </Column>
          </Row>
          <SectionTitle text='PRIMARY CONTACT' />
          <Row>
            <Column size='100'>
              <Label text='Name' />
              <Value text={renderFullName(item.primaryFirstName, item.primaryMiddleName, item.primaryLastName)} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Title' />
              <Value text={item.primaryContactTitle} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Phone Number' />
              <Value text={item.primaryContactPhone} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='Email' />
              <Value text={item.primaryContactEmail} />
            </Column>
          </Row>
        </View>
      );
    })}
    {(!data || data.length === 0) && <Value text={null} />}
  </>
);

export default SPTransferAgent;
