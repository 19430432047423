import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { urls, reportingStandards, applicationMinBidPriceMap } from '../../../constants';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import { isBoolean } from '../../../utils/validations';
import QuestionCard from '../../QuestionCard';
import Footnote from '../../Footnote';
import Label from '../../Label';
import styles from './EligibilityStandards.module.scss';

const EligibilityStandards = ({ name }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const application = state.application;
  const isOTCQB = application.typeId === 4;
  const finraMinBidPrice = applicationMinBidPriceMap.get(application.typeId) || '0';

  const initialReviewAdditionalInfo = () => {
    const displayInitialReviewLabel = isBoolean(eligibilityStandards.hasInitialReview) && !eligibilityStandards.hasInitialReview;
    return <>
      <Footnote text={'Companies that do not have proprietary priced quotations may become quoted by undergoing an “Initial Review” by OTC Markets Group or by having a broker-dealer file and clear a Form 211 with FINRA.'} />
      <Footnote className={styles.bold} text={() => <>Note that an Initial Review may extend the processing time of the company's {appTypeName} application. Additionally, the company must arrange for a broker-dealer to begin quoting the security on <a href={urls.LINK_ATS_OVERVIEW} target='_blank' rel='noopener noreferrer'>OTC LINK</a> within 4 days of approval for trading on {appTypeName}.</>} />
      {!isOTCQB && <Footnote text={`Companies that may require an initial review include private companies looking to go public, non-U.S. companies listed on a foreign stock exchange and companies with securities on the Grey Market. OTC Markets Group may conduct the initial reviews as part of the OTCQX application and approval process for qualifying companies.`} />}
      {isOTCQB && <Footnote text={`Companies that may require an initial review include private companies looking to go public, non-U.S. companies listed on a foreign stock exchange, companies that were previously delinquent in their reporting obligations and companies with securities on the Grey Market. OTC Markets Group may conduct the initial reviews as part of the OTCQB application and approval process for qualifying companies. Shell Companies (or companies that display characteristics common to Shell Companies) and companies with less than 10% public float applying to the OTCQB Market should work directly with a broker-dealer to submit a Form 211 with FINRA.`} />}
      {displayInitialReviewLabel && <>
        <Label className={styles.labelMargin} title={`Proprietary quotes and a bid price of at least $${finraMinBidPrice} USD are required. Please ensure the company has a plan to meet this requirement before proceeding.`} />
      </>}
    </>;
  };

  const initialReviewQuestion = {
    title: 'Initial Review ',
    subTitle: 'Does the company wish to request OTC Markets Group to file a Form 211 with FINRA to obtain proprietary quotes?',
    fieldName: 'hasInitialReview',
    extraInfo: initialReviewAdditionalInfo
  };

  const form211Question = {
    title: 'Form 211',
    subTitle: `Will a broker-dealer file a Form 211 with FINRA with a bid price of at least $${finraMinBidPrice} USD?`,
    fieldName: 'hasForm211BidPrice',
    falseDetails: [
      {
        desc: () => <QuestionCard
          type={'nested'}
          item={initialReviewQuestion}
          section={eligibilityStandards}
          readOnly={isReadOnly}
          handleQuestionClick={handleQuestionClick}
          onValueChanged={onValueChanged} />,
        hideDetails: true
      }
    ]
  };
  const questions = [
    {
      title: 'Audited Financial Statements',
      subTitle: () => <>
        <p>Does the company have annual financial statements audited by a <a href={urls.PCAOB} target='_blank' rel='noopener noreferrer'>PCAOB registered auditor</a> and unaudited interim statements prepared in accordance with U.S. GAAP?</p>
        {isOTCQB && <Footnote text='Note: International Reporting companies are exempt from using a PCAOB registered auditor and following US GAAP.' />}
      </>,
      fieldName: 'auditedFinancials',
      falseDetails: [
        {
          desc: 'Audited financial statements are required. Please explain when the company expects to have audited financials.',
          fieldName: 'auditedFinancialsDetail',
          appTypes: [1, 2, 3]
        },
        {
          desc: 'Audited financial statements are required. Please explain why the requirement described above is not currently met.',
          fieldName: 'auditedFinancialsDetail',
          appTypes: [4]
        }
      ],
      appTypes: [1, 2, 4]
    },
    {
      title: 'Audited Financial Statements',
      subTitle: 'Does the company have audited financial reports dated within the previous 15 months?',
      fieldName: 'auditedFinancials',
      falseDetails: [
        {
          desc: 'Audited financial statements are required. Please explain when the company expects to have audited financials.',
          fieldName: 'auditedFinancialsDetail',
          appTypes: [1, 2, 3]
        },
        {
          desc: 'Audited financial statements are required. Please explain why the requirement described above is not currently met.',
          fieldName: 'auditedFinancialsDetail',
          appTypes: [4]
        }
      ],
      appTypes: [3]
    },
    {
      title: 'Shell or a Blank Check Company',
      subTitle: () => <p>Is the company a <a href={urls.GLOSSARY_SHELL} target='_blank' rel='noopener noreferrer'>shell</a> or a blank check company?</p>,
      fieldName: 'isShellBlankCheckCompany',
      trueDetails: [
        {
          desc: 'Shell or Blank check companies do not qualify for OTCQX.  Please explain the company’s plan to meet this requirement.',
          fieldName: 'isShellBlankCheckCompanyDetail',
          appTypes: [1, 2, 3]
        }
      ],
      appTypes: [1, 2, 3, 4]
    },
    {
      title: 'In Good Standing',
      subTitle: 'Is the company in good standing under the laws of each jurisdiction in which the company is organized or does business?',
      fieldName: 'inGoodStanding',
      falseDetails: [
        {
          desc: 'Companies are required to be in good standing. Please explain the company’s plan to meet this requirement.',
          fieldName: 'inGoodStandingDetail',
          appTypes: [1, 2, 3, 4]
        }
      ],
      appTypes: [1, 2, 3, 4]
    },
    {
      title: 'Bankruptcy',
      subTitle: 'Is the company subject to any Bankruptcy or reorganization proceedings?',
      fieldName: 'isBankrupt',
      trueDetails: [
        {
          desc: 'Companies under bankruptcy or reorganization proceedings are not eligible for OTCQX. Please provide further information below on when your company plans to emerge out of bankruptcy or complete reorganization proceedings.',
          fieldName: 'isBankruptDetail',
          appTypes: [1, 2, 3]
        },
        {
          desc: 'Companies under bankruptcy or reorganization proceedings are not eligible for OTCQB. Please provide further information below on when your company plans to emerge out of bankruptcy or complete reorganization proceedings.',
          fieldName: 'isBankruptDetail',
          appTypes: [4]
        }
      ],
      appTypes: [1, 2, 3, 4]
    },
    {
      title: 'Independent Directors',
      subTitle: () => <p>Does the company have a board of directors that includes at least two (2) <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Directors</a>?</p>,
      fieldName: 'hasTwoIndependentDirectors',
      falseDetails: [
        {
          desc: () => <p>Companies are required to have at least two <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Directors</a> serve on the Board of Directors to qualify for OTCQX. Please explain the company’s plan to meet this requirement.</p>,
          fieldName: 'hasTwoIndependentDirectorsDetail',
          appTypes: [1, 2]
        },
        {
          desc: () => <p>Alternative Reporting Standard Companies are required to have at least one Independent Director upon application and may phase in the 2<sup>nd</sup> <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Director</a> within 90 days or the time of the Company’s next shareholder meeting. In any event, the independence requirement must be satisfied within one year of the Company joining OTCQB. Please explain the company’s plan to meet this requirement.</p>,
          fieldName: 'hasTwoIndependentDirectorsDetail',
          appTypes: [4]
        }
      ],
      appTypes: [1, 2, 4]
    },
    {
      title: 'Audit Committee',
      subTitle: () => <p>Does the company have an audit committee, a majority of whose members are <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Directors</a>?</p>,
      fieldName: 'hasAuditComittee',
      falseDetails: [
        {
          desc: () => <p>Companies are required to have an audit committee with a majority of them being <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Directors</a>. Please explain the company’s plan to meet this requirement.</p>,
          fieldName: 'hasAuditComitteeDetail',
          appTypes: [1, 2]
        },
        {
          desc: () => <p>Alternative Reporting Standard Companies are required to have an audit committee with at least 1 Independent director upon application and may phase in the 2<sup>nd</sup> <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Director</a> within 90 days or the time of the Company’s next shareholder meeting. Please explain the company’s plan to meet this requirement..</p>,
          fieldName: 'hasAuditComitteeDetail',
          appTypes: [4]
        }
      ],
      appTypes: [1, 2, 4]
    },
    {
      title: `Annual Shareholders' Meetings`,
      subTitle: `Does the company conduct annual shareholders' meetings and make annual financial reports available to its shareholders at least 15 calendar days prior to such meetings?`,
      fieldName: 'hasShareholderMeetings',
      falseDetails: [
        {
          desc: 'Please explain why the company currently does not hold Annual Shareholder’s Meetings and provide information on whether the company plans to hold such meetings once it joins OTCQX.',
          fieldName: 'hasShareholderMeetingsDetail',
          appTypes: [1, 2]
        }
      ],
      appTypes: [1, 2]
    },
    {
      title: `Priced Quotation`,
      subTitle: () => <>
        { !isOTCQB && <p>Do the company's securities have proprietary priced quotations published by a broker-dealer in <a href={urls.LINK_ATS_OVERVIEW} target='_blank' rel='noopener noreferrer'>OTC LINK</a> ATS?</p>}
        { isOTCQB && <p>Do the company's securities have proprietary priced quotations published by a broker-dealer in <a href={urls.LINK_ATS_OVERVIEW} target='_blank' rel='noopener noreferrer'>OTC LINK</a> ATS and a current bid price of at least $0.01?</p>}
      </>,
      fieldName: 'hasPricedQuotation',
      falseDetails: [
        {
          desc: () => <QuestionCard
            type={'nested'}
            item={form211Question}
            section={eligibilityStandards}
            readOnly={isReadOnly}
            handleQuestionClick={handleQuestionClick}
            onValueChanged={onValueChanged} />,
          hideDetails: true,
          appTypes: [1, 2, 3, 4]
        }
      ],
      appTypes: [1, 2, 3, 4]
    }
  ];

  const eligibilityStandards = application.eligibilityStandards = { ...application.eligibilityStandards };
  const applicationQuestions = questions.filter(q => q.appTypes.includes(application.typeId));
  const appTypeName = application.typeDesc;
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'eligibilityStandards',
      card: 'eligibility-standards'
    });
  };

  const onValueChanged = (value, field) => {
    const updateEligibilityStandards = { ...eligibilityStandards };
    updateEligibilityStandards[field] = value;
    onUpdate(updateEligibilityStandards);
  };

  const handleShowDetail = detailObject => {
    const showDetail = detailObject.appTypes.includes(application.typeId);
    if (isOTCQB && (detailObject.fieldName === 'hasTwoIndependentDirectorsDetail' || detailObject.fieldName === 'hasAuditComitteeDetail')) {
      return showDetail && application.reportingStandard === reportingStandards.ALTERNATIVE;
    }

    return showDetail;
  };

  const handleQuestionClick = (value, field) => {
    const updateEligibilityStandards = { ...eligibilityStandards };
    updateEligibilityStandards[field] = value;
    onUpdate(updateEligibilityStandards);
  };

  const renderQuestons = () => {
    return applicationQuestions.map((q, i) => {
      return <QuestionCard
        key={i}
        item={q}
        onShowDetail={handleShowDetail}
        readOnly={isReadOnly}
        section={eligibilityStandards}
        isValid={showIncompletes ? isBoolean(eligibilityStandards && eligibilityStandards[q.fieldName]) : true}
        showIncompletes={showIncompletes}
        handleQuestionClick={handleQuestionClick}
        onValueChanged={onValueChanged} />;
    });
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={`To be eligible for ${appTypeName}, a Company must meet the following standards.`} />
      {renderQuestons()}
    </div>
  );
};

EligibilityStandards.propTypes = {
  name: PropTypes.string
};

export default EligibilityStandards;
