import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../../components/WithRouter';
import { isEmail } from '../../utils/validations';
import { emails, phone_numbers, routes, storage, errors } from '../../constants';
import { getExistingIQUser, awsForgotPassword, awsForgotPasswordSubmit } from '../../api/auth';
import { AuthContext } from '../../context/Auth.context';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import AuthWrapper from '../../components/AuthWrapper';
import Button from '../../components/Button';
import EmailField from '../../components/EmailField';
import TextBox from '../../components/TextBox';
import PasswordRequirements from '../../components/PasswordRequirements';
import Label from '../../components/Label';
import styles from './ForgotPasswordPage.module.scss';

const PASSWORD = 'password';
const RESET_CODE = 'reset code';
const IQ_REDIRECT = 'iq redirect';
const RESET_SUCCESS = 'reset success';
const userRememberedEmail = window.localStorage.getItem(storage.AP_USER_REMEMBER);

const userObj = {
  email: '',
  password: null,
  resetCode: null
};

const GoBackSignIn = () => (
  <div className={styles.footerSignup}>
    Go back to <Link to={routes.SIGNIN}>Sign In</Link>
  </div>
);

const ForgotPasswordPage = ({ navigate }) => {
  const [user, setUser] = useState(userObj);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [view, setView] = useState(PASSWORD);
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState(null);
  const [error, setError] = useState(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [passwordError, setPasswordError] = useState(false);
  const [redirectsUser, setRedirectsUser] = useState(false);
  const [authState] = useContext(AuthContext);
  const isForgotPwdView = view === PASSWORD;
  const isResetView = view === RESET_CODE;
  const isRedirectMFAView = view === IQ_REDIRECT;
  const isResetSuccessView = view === RESET_SUCCESS;

  let authWrapperTitle;
  if (isForgotPwdView) authWrapperTitle = 'Forgot Password';
  if (isResetView) authWrapperTitle = 'Change Password';
  if (isRedirectMFAView) authWrapperTitle = 'Redirecting to OTCIQ';
  if (isResetSuccessView) authWrapperTitle = 'Success!';

  const passwordButton = {
    icon: passwordMode === 'text' ? '/images/icons/eye-regular.svg' : '/images/icons/eye-slash-regular.svg',
    type: 'default',
    onClick: () => {
      passwordMode === 'text' ? setPasswordMode('password') : setPasswordMode('text');
    }
  };

  useEffect(() => {
    let timer;
    if (redirectsUser) timer = setTimeout(() => window.location.href = redirectsUser, 5000);
    if (!redirectsUser) clearTimeout(timer);
    return () => clearTimeout(timer);
  }, [redirectsUser]);

  useEffect(() => {
    if (authState.isAuthenticated) navigate(routes.MY_APPLICATIONS);
  }, [authState]);

  const onValueChanged = (value, field) => {
    setUser(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleSubmitEmail = e => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    if (error) setError(null);
    const email = user.email;
    if (isEmail(email)) {
      getExistingIQUser(email, true)
        .then(url => {
          setRedirectsUser(url);
          setView(IQ_REDIRECT);
          setDisableSubmitBtn(false);
        })
        .catch(() => handleAwsForgotPwd());
    } else {
      setError(errors.FORGOT_PWD_INVALID_EMAIL);
      setDisableSubmitBtn(false);
    }
  };

  const handleAwsForgotPwd = () => {
    awsForgotPassword(user.email)
      .then(res => {
        setCodeDeliveryDetails({ ...res.CodeDeliveryDetails });
        setView(RESET_CODE);
        setDisableSubmitBtn(false);
      })
      .catch(e => {
        setError(e.message);
        setDisableSubmitBtn(false);
      });
  };

  const handleSubmitReset = e => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    awsForgotPasswordSubmit(user.email, user.resetCode, user.password)
      .then(() => {
        setView(RESET_SUCCESS);
        setDisableSubmitBtn(false);
      })
      .catch(e => {
        setError(e.message);
        setDisableSubmitBtn(false);
      });
  };

  const handleCancelRedirect = () => {
    setRedirectsUser(false);
    setUser(userObj);
    setView(PASSWORD);
  };

  return (
    <AuthWrapper title={authWrapperTitle}>
      {isForgotPwdView && <div>
        <p>
          Enter email address to reset password. You may need to check your spam folder or unblock mail from @otcmarkets. If you are still not able to access your account, contact <a href={emails.ISSUER_SERVICES}>Issuer Services</a> at {phone_numbers.ISSUER_SERVICES}.
        </p>
        <form className={styles.form} onSubmit={e => handleSubmitEmail(e)}>
          <div className='mtMed'>
            <EmailField
              label='Email'
              placeholder=''
              name='email'
              // isRequired={showIncompletes}
              value={user.email || userRememberedEmail}
              onValueChanged={e => onValueChanged(e.value, 'email')} />
          </div>
          {error && <Label className='mtMed' title={error} isError />}
          <div className='mtLg'>
            <Button title='Submit' fullWidth inactive={disableSubmitBtn} type='submit' />
            <GoBackSignIn />
          </div>
        </form>
      </div>}
      {isResetView && <div>
        <p>
          Enter reset code sent to {codeDeliveryDetails.Destination} and enter new password.
        </p>
        <form onSubmit={e => handleSubmitReset(e)}>
          <div className='mtMed'>
            <TextBox
              label='Reset Code'
              name='resetCode'
              value={user.resetCode}
              onValueChanged={e => onValueChanged(e.value, 'resetCode')} />
          </div>
          <div className='mtMed'>
            <TextBox
              label='Password'
              placeholder='At least 14 characters'
              name='password'
              value={user.password}
              mode={passwordMode}
              valueChangeEvent='keyup'
              onValueChanged={e => onValueChanged(e.value, 'password')}>
              <TextBoxButton
                name='password'
                location='after'
                options={passwordButton}
              />
            </TextBox>
            <PasswordRequirements className='mtMed' value={user.password} isError={passwordError} />
          </div>
          {error && <Label className='mtMed' title={error} isError />}
          <div className='mtLg'>
            <Button title='Submit' inactive={disableSubmitBtn} fullWidth type='submit' />
            <GoBackSignIn />
          </div>
        </form>
      </div>}
      {isResetSuccessView && <div>
        Password changed successfully. Please <Link to={routes.SIGNIN}>Sign In</Link> with your new credentials.
      </div>}
      {isRedirectMFAView && <div>
        <p>
          The Email / Username entered is associated with an OTCIQ account. You are being redirected to Change Password through OTCIQ and will be redirected back to App Portal after signing in.
        </p>
        <p>
          <a href={redirectsUser}>
            Go to OTCIQ.com Change Password
          </a>
        </p>
        <Button className='mtLg' title='Cancel Redirect' onClick={handleCancelRedirect} fullWidth buttonType='secondary' />
        <GoBackSignIn />
      </div>}
    </AuthWrapper>
  );
};

export default withRouter(ForgotPasswordPage);
