import React from 'react';
import { isBoolean } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';

const LegalDisciplinaryHistory = ({ data }) => {
  return (
    <>
      <View fixed>
        <SectionHeader text='LEGAL/DISCIPLINARY HISTORY' />
      </View>
      <View>
        <SectionTitle text='THE COMPANY, ITS PREDECESSORS, AND SUBSIDIARIES PROCEEDINGS IN THE PAST 10 YEARS:' />
        <Row>
          <Column size='100'>
            <Value text='Provide disclosure for any proceedings within the past 10 years, or indicate there were no proceedings.' />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='THERE WERE NO PROCEEDINGS IN THE PAST 10 YEARS' />
            <Value text={data.companyLegalHistoryPastTenYears ? 'True' : 'False'} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='DESCRIPTION OF PROCEEDINGS' />
            <Value text={!data.companyLegalHistoryPastTenYears && data.companyLegalHistoryPastTenYearsDesc || '-'} />
          </Column>
        </Row>
        <SectionTitle text='Past Officers and Directors of the Company, its Predecessors and Subsidiaries Proceedings in the Past 5 years:' />
        <Row>
          <Column size='100'>
            <Value text='Provide disclosure for proceedings within the past 5 years related to individuals who served as Officers and/or Directors of the Company, its predecessors and subsidiaries, during the time the individual was employed by the Company. Indicate none if there were no proceedings.' />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='THERE WERE NO PROCEEDINGS IN THE PAST 5 YEARS' />
            <Value text={data.companyOfficerLegalHistoryPastFiveYears ? 'True' : 'False'} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='DESCRIPTION OF PROCEEDINGS' />
            <Value text={!data.companyOfficerLegalHistoryPastFiveYears && data.companyOfficerLegalHistoryPastFiveYearsDesc || '-'} />
          </Column>
        </Row>
      </View>
    </>
  );
};

LegalDisciplinaryHistory.defaultProps = {
  data: {}
};

export default LegalDisciplinaryHistory;
