import api from './api';

const paymentProductId = 'GW';

export function getCreditCardPayload (params, address = {}, companyInfo = {}) {
  const queryParams = {
    productId: paymentProductId,
    appId: params.appId,
    subtotal: params.appFeeSubtotal,
    amount: params.appFeeTotal,
    taxAmount: params.taxAmount,
    taxRate: params.taxRate,
    reducedFeeType: params.reducedFeeType,
    reducedFee: params.reducedFee,
    // CC specific fields
    ccNumber: params.ccNumber,
    ccType: params.ccType,
    expiry: params.expiresInfo,
    cvv: params.cvv,
    name: params.fullName,
    address: address.address1,
    address2: address.address2,
    city: address.city,
    stateName: address.stateName, // full name of state
    zip: address.zip,
    countryName: address.countryName, // full name of country
    // user specific fields
    userFields: {
      companyName: companyInfo.name,
      companyId: companyInfo.id,
      companyCustomerId: companyInfo.customerId
    }

  };
  return queryParams;
}

export function getAchPayload (params, companyInfo = {}) {
  const queryParams = {
    productId: paymentProductId,
    appId: params.appId,
    name: params.fullName,
    subtotal: params.appFeeSubtotal,
    amount: params.appFeeTotal,
    taxAmount: params.taxAmount,
    taxRate: params.taxRate,
    reducedFeeType: params.reducedFeeType,
    reducedFee: params.reducedFee,
    // ACH specific fields
    paymentType: params.accountType, // checking / saving
    bankAccountNumber: params.bankAccountNumber,
    bankRoutingNumber: params.bankRoutingNumber,
    // user specific fields
    userFields: {
      companyName: companyInfo.name,
      companyId: companyInfo.id,
      companyCustomerId: companyInfo.customerId
    }
  };
  return queryParams;
}
export function getWirePayload (params) {
  const queryParams = {
    productId: paymentProductId,
    appId: params.appId,
    subtotal: params.appFeeSubtotal,
    amount: params.appFeeTotal,
    taxAmount: params.taxAmount,
    taxRate: params.taxRate,
    reducedFeeType: params.reducedFeeType,
    reducedFee: params.reducedFee,
    // WIRE specific fields
    name: params.nameOnBankAcct,
    wireConfirmationNumber: params.wireTransferConfNum
  };
  return queryParams;
}

export function getCheckPayload (params) {
  const queryParams = {
    productId: paymentProductId,
    appId: params.appId,
    subtotal: params.appFeeSubtotal,
    amount: params.appFeeTotal,
    taxAmount: params.taxAmount,
    taxRate: params.taxRate,
    reducedFeeType: params.reducedFeeType,
    reducedFee: params.reducedFee,
    // CHECK specific fields
    name: params.nameOnBankAcct,
    checkNumber: params.checkNumber,
    checkDate: params.checkDate
  };
  return queryParams;
}

export const getTaxRate = async (zipCode) => {
  const response = await api.request({
    method: 'get',
    url: `/payment/tax/${zipCode}`
  });
  return response.data;
};

export const sendCreditCardPayment = async (data, app) => {
  const paymentData = getCreditCardPayload(data, data.address, app.companyInfo);
  const response = await api.request({
    method: 'post',
    url: `/payment/charge/cc`,
    data: paymentData
  });
  return response.data;
};

export const sendAchPayment = async (data, app) => {
  const paymentData = getAchPayload(data, app.companyInfo);
  const response = await api.request({
    method: 'post',
    url: `/payment/charge/ach`,
    data: paymentData
  });
  return response.data;
};

export const sendCheckPayment = async (data) => {
  const paymentData = getCheckPayload(data);
  const response = await api.request({
    method: 'post',
    url: `/payment/charge/check`,
    data: paymentData
  });
  return response.data;
};

export const sendWirePayment = async (data) => {
  const paymentData = getWirePayload(data);
  const response = await api.request({
    method: 'post',
    url: `/payment/charge/wire`,
    data: paymentData
  });
  return response.data;
};
