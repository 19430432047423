import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ResultItem.module.scss';

const ResultItem = item => {
  if (item.id === 'ADD') {
    return <div className={styles.add}>
      <FontAwesomeIcon icon={['far', 'plus-circle']} />
      <span className={styles.addText}>Add {item.Name}</span>
    </div>;
  }

  return <div className={styles.container}>
    {item.Name}
  </div>;
};

export default ResultItem;
