import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';
import { isWebsite } from '../../utils/validations';

const WebsiteField = ({ label, name, placeholder, disabled, readOnly, isRequired, value, onValueChanged, showClearButton }) => {
  const isWebsiteValid = isWebsite(value);

  return (
    <TextBox
      label={label}
      placeholder={placeholder}
      name={name}
      readOnly={readOnly}
      showClearButton={showClearButton}
      disabled={disabled}
      isRequired={isRequired}
      isValid={isWebsiteValid}
      errorText={!isWebsiteValid && 'Invalid Format. Please use https://www.website.com'}
      value={value}
      onValueChanged={onValueChanged} />
  );
}
;

WebsiteField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  showClearButton: PropTypes.bool,
  value: PropTypes.string,
  onValueChanged: PropTypes.func
};

WebsiteField.defaultProps = {
  label: 'Email',
  placeholder: 'email@mail.com',
  name: 'website'
};

export default WebsiteField;
