import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { awsCodes, routes, storage, urls, errors } from '../../constants';
import { AuthContext } from '../../context/Auth.context';
import { logUser, signInIQ, signInAWS, getCurrentAwsUser, resendIQMFA, validateAwsChallenge, validateIQChallenge } from '../../api/auth';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import AuthWrapper from '../../components/AuthWrapper';
import Button from '../../components/Button';
import EmailField from '../../components/EmailField';
import TextBox from '../../components/TextBox';
import { withRouter } from '../../components/WithRouter';
import CheckBoxField from '../../components/CheckBoxField';
import Label from '../../components/Label';
import CodeInput from '../../components/CodeInput';
import styles from './SignInPage.module.scss';

const userRememberedEmail = window.localStorage.getItem(storage.AP_USER_REMEMBER);
const SIGN_IN_ERROR = <>{errors.SIGN_IN_USER_NOT_EXIST} Please re-enter or <Link to={routes.SIGNUP}>Create New Account</Link>.</>;
const SIGNIN = 'sign in';
const MFA = 'mfa';

const userObj = {
  email: userRememberedEmail || '',
  password: null
};

const SignInPage = props => {
  const [user, setUser] = useState(userObj);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [awsUser, setAwsUser] = useState(null);
  const [view, setView] = useState(SIGNIN);
  const [rememberMe, setRememberMe] = useState(!!userRememberedEmail);
  const [error, setError] = useState(null);
  const [mfaCode, setMFACode] = useState('');
  const [successResend, setSuccessResend] = useState(null);
  const [mfaCodeError, setMFACodeError] = useState(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [authState, authDispatch] = useContext(AuthContext);
  const isSignInView = view === SIGNIN;
  const isMFAView = view === MFA;
  const isAwsMFA = !!(awsUser && awsUser.challengeParam);
  const codeFields = awsUser && awsUser.challengeParam ? 6 : 4;
  const isTimedOut = authState.timedOut;

  const resetSignIn = () => {
    setError(null);
    setMFACodeError(null);
    setView(SIGNIN);
  };

  const MFA_ERROR = <>{errors.SIGN_IN_MFA}  Please re-enter code or <a onClick={resetSignIn}>Sign In</a> again.</>;

  let authWrapperTitle;
  if (isSignInView) authWrapperTitle = 'Sign In';
  if (isMFAView) authWrapperTitle = 'Enter PIN Code Sent to the Email / Mobile Number Linked to this Account.';

  const passwordButton = {
    icon: passwordMode === 'text' ? '/images/icons/eye-regular.svg' : '/images/icons/eye-slash-regular.svg',
    type: 'default',
    onClick: () => {
      passwordMode === 'text' ? setPasswordMode('password') : setPasswordMode('text');
    }
  };

  useEffect(() => {
    const redirectPath = authState.iq ? routes.MANAGE_COMPANY_PROFILE : routes.MY_APPLICATIONS;
    if (authState.isAuthenticated) props.navigate(redirectPath);
  }, [authState]);

  const onValueChanged = (value, field) => {
    setUser(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleRememberMeClick = e => {
    const value = e.value;
    if (value === false) window.localStorage.removeItem(storage.AP_USER_REMEMBER);
    setRememberMe(value);
  };

  const handleLogin = e => {
    e.preventDefault();
    if (error) setError(null);
    setDisableSubmitBtn(true);
    signInIQ(user)
      .then(data => {
        if (rememberMe) storeRememberMe();
        if (data.challengeId) {
          setView(MFA);
        } else {
          setIQUser(data);
        }
        setUser(data);
        setDisableSubmitBtn(false);
      })
      .catch(() => handleAWSUserLogin());
  };

  const handleAWSUserLogin = () => {
    signInAWS(user.email, user.password)
      .then(awsUser => {
        if (rememberMe) storeRememberMe();

        if (awsUser && awsUser.challengeParam) {
          setAwsUser(awsUser);
          setView(MFA);
          setDisableSubmitBtn(false);
        } else {
          logInAwsUser();
        }
      })
      .catch(e => {
        if (e.code === awsCodes.SIGN_IN_USER_NOT_FOUND) {
          setError(SIGN_IN_ERROR);
        } else {
          setError(e.message);
        }
        setDisableSubmitBtn(false);
      });
  };

  const storeRememberMe = () => window.localStorage.setItem(storage.AP_USER_REMEMBER, user.email);

  const handleResendCode = () => {
    setError(null);
    setMFACode('');
    setMFACodeError(null);
    setSuccessResend(null);
    if (isAwsMFA) {
      handleResendAwsMFA();
    } else {
      handleResendIQMFA();
    }
  };

  const handleResendAwsMFA = () => {
    signInAWS(user.email, user.password)
      .then(awsUser => {
        if (awsUser && awsUser.challengeParam) {
          setAwsUser(awsUser);
          setView(MFA);
        }
        setSuccessResend('Your multi-factor authentication code has been sent.');
      })
      .catch(() => setMFACodeError(errors.SIGN_IN_MFA_RESEND));
  };

  const handleResendIQMFA = () => {
    resendIQMFA(user.id)
      .then(id => {
        setSuccessResend('Your multi-factor authentication code has been sent.');
        setUser(prevState => {
          return {
            ...prevState,
            challengeId: id
          };
        });
      })
      .catch(() => setMFACodeError(errors.SIGN_IN_MFA_RESEND));
  };

  const handleCodeChange = value => {
    setMFACode(value);
  };

  const handleConfirmMFA = e => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    setSuccessResend(null);
    if (isAwsMFA) {
      handleConfirmAwsMFA();
    } else {
      handleConfirmIQMFA();
    }
  };

  const handleConfirmAwsMFA = () => {
    validateAwsChallenge(awsUser, mfaCode, awsUser.challengeName)
      .then(() => logInAwsUser())
      .catch(() => {
        setMFACodeError(MFA_ERROR);
        setDisableSubmitBtn(false);
      });
  };

  const logInAwsUser = () => {
    const awsUserObj = {};
    getCurrentAwsUser().then(data => {
      const attributes = data.attributes;
      awsUserObj.aws = true;
      awsUserObj.email = attributes.email;
      awsUserObj.phoneNumber = attributes.phone_number;
      awsUserObj.firstName = attributes.given_name;
      awsUserObj.lastName = attributes.family_name;
      awsUserObj.middleName = attributes.middle_name;
      awsUserObj.fullName = `${attributes.given_name} ${attributes.family_name}`;
      awsUserObj.initials = awsUserObj.firstName.charAt(0) + awsUserObj.lastName.charAt(0);
      awsUserObj.initials = awsUserObj.initials.toUpperCase();

      setDisableSubmitBtn(false);

      authDispatch({
        type: 'SET_USER',
        payload: awsUserObj,
        session: data
      });
    });
  };

  const handleConfirmIQMFA = () => {
    validateIQChallenge(user.id, mfaCode, user.challengeId)
      .then(response => {
        if (response === true) {
          setIQUser(user);
        } else {
          setMFACodeError(MFA_ERROR);
        }
        setDisableSubmitBtn(false);
      })
      .catch(() => {
        setMFACodeError(<>Error submitting MFA Code. {errors.TRY_AGAIN}</>);
        setDisableSubmitBtn(false);
      });
  };

  const setIQUser = user => {
    logUser(user.email);
    authDispatch({
      type: 'SET_USER',
      payload: user
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={cn(styles.top, styles.padding)}>
          <div className={styles.lockup}>
            <div className={styles.welcome}>
              Welcome to
            </div>
            <img className={styles.logo} src='/logos/otc-gateway-logo-color.png' alt='OTC Gateway' />
          </div>
        </div>
        <div className={cn(styles.bottom, styles.padding)}>
          <p className={styles.description}>
            An online platform for companies to apply for OTCQX, OTCQB and the OTC Disclosure and News Service or securely upload documents that contain personal information.
          </p>
          <p className={styles.cta}>
            Sign in or <Link to={routes.SIGNUP}>create an account</Link> to get started.
          </p>
          <p className={styles.cta}>
            If you are a current OTCIQ user, sign in using your <a href={urls.IQ_PROFILE} target='_blank' rel='noopener noreferrer'>OTCIQ.com</a> credentials.
          </p>
          <footer>
            <ul className={styles.footerGroup}>
              <li className={styles.item}>
                <a className={styles.link} href={urls.OTC_WEBSITE} target='_blank'>
                  OTCMarkets.com
                </a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={urls.TERMS} target='_blank'>
                  Terms of Use
                </a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={urls.PRIVACY_POLICY} target='_blank'>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
      <div className={styles.signIn}>
        <div className={styles.wrapper}>
          <AuthWrapper title={authWrapperTitle} formOnly>
            {isSignInView && <div>
              {isTimedOut && <Label isError title={errors.TIMED_OUT} />}
              <form className={styles.form} onSubmit={e => handleLogin(e)}>
                <div className='mtMed'>
                  <EmailField
                    label='Email'
                    placeholder=''
                    name='email'
                    value={user.email || userRememberedEmail}
                    onValueChanged={e => onValueChanged(e.value, 'email')} />
                </div>
                <div className='mtMed'>
                  <TextBox
                    label='Password'
                    name='password'
                    value={user.password}
                    mode={passwordMode}
                    onValueChanged={e => onValueChanged(e.value, 'password')}>
                    <TextBoxButton
                      name='password'
                      location='after'
                      options={passwordButton}
                    />
                  </TextBox>
                </div>
                <div className='mtMed'>
                  <CheckBoxField text='Remember My Email' size='small' value={rememberMe} onValueChanged={handleRememberMeClick} />
                  <div className='mtSm'>
                    <Link to={routes.FORGOT_PWD}>
                      <span className={styles.forgot}>Forgot Password?</span>
                    </Link>
                  </div>
                </div>
                {error && <Label className='mtMed' isError>
                  {error}
                </Label>}
                <div className='mtLg'>
                  <Button title='Sign In' fullWidth type='submit' inactive={disableSubmitBtn} showLoader={disableSubmitBtn} />
                  <div className={styles.separator}>
                    <span className={styles.text}>or</span>
                  </div>
                  <Link to={routes.SIGNUP}>
                    <Button className='mtMed' buttonType='secondary' title='Create New Account' fullWidth />
                  </Link>
                </div>
              </form>
            </div>}
            {isMFAView && <form onSubmit={e => handleConfirmMFA(e)}>
              <div className='mtMed' >
                <CodeInput type='number' fields={codeFields} value={mfaCode} onChange={handleCodeChange} />
              </div>
              {successResend && <Label className='mtMed' title={successResend} />}
              {mfaCodeError && <Label className='mtMed' isError>
                {mfaCodeError}
              </Label>}
              <div className='mtMed'>
                <Button title='Submit' fullWidth inactive={disableSubmitBtn} showLoader={disableSubmitBtn} type='submit' />
                <div className={styles.footerSignup}>
                  <span className={cn('mbSm', styles.link)} onClick={handleResendCode}>
                    Resend Code
                  </span> or go back to <span className={styles.link} onClick={() => setView(SIGNIN)}>Sign In</span>
                </div>
              </div>
            </form>}
          </AuthWrapper>
        </div>
      </div>
    </div>
  );
};

SignInPage.propTypes = {
  navigate: PropTypes.func
};

export default withRouter(SignInPage);
