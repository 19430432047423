import React, { useContext } from 'react';
import cn from 'classnames';
import { NotificationsContext } from '../../context/Notifications.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './NotificationLabel.module.scss';

const NotificationLabel = props => {
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);

  const handleRemove = () => {
    dispatchNotification({
      type: 'REMOVE_NOTIFICATION',
      id: props.id
    });
  };

  return (
    <div id={props.id} className={cn(styles.container, props.className, {
      [styles.error]: props.type === 'error'
    })} onClick={props.onClick}>
      {props.text}
      <FontAwesomeIcon className={styles.close} icon={['fal', 'times']} onClick={handleRemove} />
    </div>
  );
};

NotificationLabel.propTypes = {
  text: PropTypes.string
};

export default NotificationLabel;
