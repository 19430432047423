import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DialogBox from '../DialogBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Entity.module.scss';

const Entity = ({ className, title, subTitle, subTitleHighlight, confirmRemoveText, icon, onClick, size, showIncompletes, handleRemove, hideClose, isAdd, isEmpty, isInactive, isComplete }) => {
  const [confirmRemove, setConfirmRemove] = useState(false);

  const onRemoveClick = e => {
    e.stopPropagation();
    setConfirmRemove(true);
  };

  const hideDialog = e => {
    e.stopPropagation();
    setConfirmRemove(false);
  };

  const onSubmit = e => {
    setConfirmRemove(false);
    handleRemove(e);
  };

  return <div className={cn(className, styles.container, styles[size], {
    [styles.add]: isAdd,
    [styles.complete]: isComplete,
    [styles.empty]: isEmpty,
    [styles.inactive]: isInactive,
    [styles.incomplete]: showIncompletes
  })} onClick={(onClick && !isInactive) ? onClick : undefined}>
    <div className={styles.verticalAlign}>
      {icon === 'incomplete' && <span className={cn(styles.icon, styles.incompleteIcon)}>
        <FontAwesomeIcon
          icon={['fal', 'exclamation-triangle']} />
      </span>}
      {icon === 'user' && <span className={styles.icon}>
        <FontAwesomeIcon icon={['fal', 'user']} />
      </span>}
      {icon === 'userComplete' && <span className={styles.iconCheck}>
        <FontAwesomeIcon icon={['fal', 'user-check']} />
      </span>}
      {icon === 'fileCert' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'file-certificate']} />
      </span>}
      {icon === 'file' && <span className={styles.icon}>
        <FontAwesomeIcon icon={['fal', 'file']} />
      </span>}
      {icon === 'fileComplete' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'file-check']} />
      </span>}
      {isAdd && <span className={styles.addIcon}>
        <FontAwesomeIcon icon={['far', 'plus-circle']} />
      </span>}
      {icon === 'check' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['far', 'check']} />
      </span>}
      <span className={styles.title}>
        {title}
      </span>
    </div>
    <div className={styles.centerAlign}>
      {subTitle && <span className={cn(styles.subTitle, {
        [styles.complete]: isComplete,
        [styles.highlight]: subTitleHighlight
      })}>{subTitle}</span>}
      {(handleRemove && !hideClose) && <span className={styles.remove} onClick={!isInactive ? onRemoveClick : undefined} onMouseDown={e => e.stopPropagation()}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </span>}
    </div>
    {confirmRemove && <DialogBox
      className={styles.dialog}
      subTitle={confirmRemoveText || 'Are you sure you want to delete?'}
      cancelText='Cancel'
      submitText='Yes'
      isHorizontal
      size={size}
      onCancelClick={e => hideDialog(e)}
      onSubmitClick={e => onSubmit(e)} />}
  </div>;
};

Entity.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  showIncompletes: PropTypes.bool,
  confirmRemoveText: PropTypes.string,
  icon: PropTypes.oneOf(['user', 'userComplete', 'fileCert', 'file', 'fileComplete', 'incomplete', 'check']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  handleRemove: PropTypes.func,
  isAdd: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isInactive: PropTypes.bool,
  onClick: PropTypes.func
};

Entity.defaultProps = {
  size: 'large'
};

export default Entity;
