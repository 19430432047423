import api from './api';
const FileDownload = require('js-file-download');

export const getOwnerApplications = async (params) => {
  const response = await api.request({
    method: 'get',
    url: '/application',
    params: params
  });
  return response.data;
};

export const createApplication = async (typeId, companyName, symbol, data) => {
  const response = await api.request({
    method: 'post',
    url: '/app-portal/application/create',
    params: {
      typeId: typeId,
      companyName,
      symbol
    },
    data: data
  });
  return response.data;
};

export const getUserApplications = async (email) => {
  const response = await api.request({
    method: 'get',
    url: '/app-portal/application',
    params: {
      email: email
    }
  });
  return response.data;
};

export const getApplication = async (id, email) => {
  const response = await api.request({
    method: 'get',
    url: `/app-portal/application/${id}`,
    params: {
      email: email
    }
  });
  return response.data;
};

export const updateApplication = async (email, data) => {
  const response = await api.request({
    method: 'put',
    url: '/app-portal/application/update',
    params: {
      email: email
    },
    data: data
  });
  return response.data;
};

export const submitApplication = async (appId, email, data) => {
  const response = await api.request({
    method: 'post',
    url: `/app-portal/application/submit`,
    params: {
      email
    },
    data: data
  });
  return response.data;
};

export const downloadApplication = async (appId, userEmail) => {
  const response = await api.request({
    method: 'get',
    url: `/app-portal/application/${appId}/download`,
    responseType: 'blob',
    params: {
      email: userEmail
    }
  });

  const header = response && response.headers['content-disposition'];
  const filename = header.split('filename=')[1] || 'OTC Gateway.pdf';
  FileDownload(response.data, filename);
  return true;
};

export const cancelApplication = async (appId, email) => {
  const response = await api.request({
    method: 'post',
    url: `/app-portal/application/${appId}/cancel`,
    params: {
      email: email
    }
  });
  return response.data;
};

export const getApplicationOwners = async (appId, email) => {
  const response = await api.request({
    method: 'get',
    url: `/app-portal/application/${appId}/owners?email=${email}`
  });
  return response.data;
};

export const inviteNewOwner = async (appId, email, data) => {
  const response = await api.request({
    method: 'post',
    url: `/app-portal/application/${appId}/owners/invite?email=${email}`,
    data
  });
  return response.data;
};

export const deleteOwner = async (appId, email, data) => {
  const response = await api.request({
    method: 'delete',
    url: `/app-portal/application/${appId}/owners/remove?email=${email}`,
    data
  });
  return response.data;
};

export const uploadApplicationPDF = async (file, filename) => {
  var fd = new FormData();
  fd.append('file', file, filename);

  const response = await api.request({
    method: 'put',
    url: `/app-portal/document/upload/application?filename=${filename}`,
    data: fd
  });
  return response.data;
};

export const downloadPaymentReceipt = async (appId, email) => {
  const response = await api.request({
    method: 'get',
    url: `/app-portal/application/${appId}/receipt/download`,
    params: {
      email: email
    },
    responseType: 'blob'
  });

  const header = response && response.headers['content-disposition'];
  const filename = header.split('filename=')[1] || 'Receipt.pdf';
  FileDownload(response.data, filename);
  return true;
};
