import React from 'react';
import { noControlPersonText } from '../../constants';
import { isBoolean } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderAddress, renderFullName } from '../../utils/helper';
import { format } from '../../utils/locale';

const ControlPersonCorpEntity = ({ entities, people, percentOwnership }) => (
  <>
    <View fixed>
      <SectionHeader text='OFFICERS, DIRECTORS, & CONTROL PERSONS' />
      <SectionTitle text='CONTROL PERSONS | CORPORATE ENTITY' />
    </View>
    {entities && entities.map((entity, i) => {
      const address1 = entity.address1 ? entity.address1 : '';
      const address2 = entity.address2 ? entity.address2 : '';
      const address3 = entity.address3 ? entity.address3 : '';
      const city = entity.city ? entity.city : '';
      const state = entity.state ? entity.state : '';
      const zip = entity.zip ? entity.zip : '';
      const country = entity.countryName ? entity.countryName : entity.country ? entity.country : '';
      const hasAddress = address1 || address2 || address3 || city || state || zip || entity.countryName || entity.country;
      const hasOwners = isBoolean(entity.haveBeneficialOwners) && entity.haveBeneficialOwners;
      const hasNoOwners = isBoolean(entity.haveBeneficialOwners) && !entity.haveBeneficialOwners;

      return (
        <View break={i > 0} key={`corpentity-${i}`}>
          <Row>
            <Column size='100'>
              <Label text='CORPORATE ENTITY NAME' />
              <Value text={entity.entityName} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='CORPORATE ENTITY ADDRESS' />
              <Value text={hasAddress ? renderAddress(address1, address2, address3, city, state, zip, country) : null} />
            </Column>
          </Row>
          <Row>
            <Column size='100'>
              <Label text='BENEFICIAL OWNER?' />
              <Value text={format(entity.haveBeneficialOwners, null, 'bool')} />
            </Column>
          </Row>
          {hasOwners && <Row>
            <Column size='100'>
              <Label text='NAME OF BENEFICIAL OWNERS' />
              {entity.owners.map((owner, j) => {
                const person = people.find(person => person.id === owner);
                return <Value key={`owner-${j}`} text={renderFullName(person.firstName, person.middleName, person.lastName)} />;
              })}
            </Column>
          </Row>}
          {hasNoOwners && <Row>
            <Column size='100'>
              <Label text='EXPLANATION FOR NO BENEFICIAL OWNER' />
              <Value text={entity.reasonForNone} />
            </Column>
          </Row>}
        </View>
      );
    })}
    {(!entities || entities.length === 0) && <Value text={noControlPersonText(percentOwnership)} />}
  </>
);

export default ControlPersonCorpEntity;
