import React from 'react';
import PropTypes from 'prop-types';
import List from 'devextreme-react/list';
import ResultItem from './ResultItem';

const SearchResults = ({ data, setSelectedCompany }) => {
  const onItemClick = e => {
    setSelectedCompany(e.itemData);
  };

  return (
    <List
      dataSource={data}
      height='100%'
      itemRender={ResultItem}
      onItemClick={onItemClick} />
  );
};

SearchResults.propTypes = {
  data: PropTypes.array,
  setSelectedCompany: PropTypes.func
};

export default SearchResults;
