import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { appStates, storage, timezones, urls } from '../../../constants';
import { isBoolean } from '../../../utils/validations';
import { ApplicationContext } from '../../../context/Application.context';
import { isSignReady } from '../../../constants/cardComplete';
import Title from '../../Title';
import Label from '../../Label';
import CheckBoxField from '../../CheckBoxField';
import Footnote from '../../Footnote';
import TextBox from '../../TextBox';
import ApplicationDescription from '../../ApplicationDescription';
import styles from './SignSubmit.module.scss';

const hasReviewedText = 'I have reviewed and understand OTC Markets Group Policies on Stock Promotion, Best Practices for Issuers and Guidance on Dilution Risk.';
const hasReadPrivacyText = 'I have read and understand the Privacy Policy and consent to collection, use and disclosure of the information in the Background Check Authorization section to conduct background checks, and any further use established in the Privacy Policy. I further represent that any personal data provided to OTC Markets Group is in accordance with applicable privacy laws.';

const SignSubmit = ({ name }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const application = state && state.application;
  const appId = application?.id;
  const VIEWED_AGREEMENT_STORAGE_KEY = `${storage.VIEWED_AGREEMENT}_${appId}`;
  const VIEWED_ADDUNDEM_STORAGE_KEY = `${storage.VIEWED_ADDUNDEM}_${appId}`;
  const [isAgreementChecboxActive, setAgreementActive] = useState(window.localStorage.getItem(VIEWED_AGREEMENT_STORAGE_KEY));
  const [isAddendumChecboxActive, setAddendumActive] = useState(window.localStorage.getItem(VIEWED_ADDUNDEM_STORAGE_KEY));
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;
  const appTypeId = application.typeId;
  const isOTCQB = appTypeId === 4;
  const eligibilityStandards = application?.eligibilityStandards;
  const signSubmit = application.signSubmit || { ...application.signSubmit };
  const completeCards = state.completeCards;
  const showInitialReviewAddendum = eligibilityStandards && isBoolean(eligibilityStandards.hasPricedQuotation) && !eligibilityStandards.hasPricedQuotation && isBoolean(eligibilityStandards.hasForm211BidPrice) && !eligibilityStandards.hasForm211BidPrice && !!eligibilityStandards.hasInitialReview;
  const isSignatureActive = isAgreementChecboxActive && signSubmit.hasReviewedIssuerAgreement && (showInitialReviewAddendum ? signSubmit.hasReviewedAddendum && isAddendumChecboxActive : true);
  const isReady = isSignReady(completeCards) || application.status === appStates.SUBMITTED || application.status === appStates.APPROVED || application.status === appStates.CLOSED;

  const pageDescription = <div>
    <p>
      Once you sign below, you will not be able to make any further changes to this Application.
    </p>
    <p>
      Select the "Application Participants" icon to the right to share this application with an appropriate signatory. The new participant will receive an email inviting them to join the application where they will be able to review, edit, and sign.
    </p>
    {isOTCQB && <p>
      Submission of this OTCQB application does not guarantee that a company will be approved for trading on the OTCQB market. See Section 1.4 of the <a href={urls.OTCQB_STANDARDS} target='_blank' rel='noopener noreferrer'>OTCQB Standards</a> for a description of OTC Markets Group’s application review process.
    </p>}
  </div>;

  const issuerAgreementCheckBox = <div className={styles.customCheckbox}>
    <a onClick={_ => handleViewClick(storage.VIEWED_AGREEMENT)}>View Issuer Services Agreement</a>
    <p>
      I confirm that the Company represents and warrants that it has reviewed, understands, and agrees to the OTC Markets Group Issuer Services Agreement and that I have been authorized by the Company and have the legal authority to execute this Agreement on behalf of the Company.
    </p>
  </div>;

  const reviewAddendumCheckBox = <div className={styles.customCheckbox}>
    <a onClick={_ => handleViewClick(storage.VIEWED_ADDUNDEM)}>View Initial Review Addendum</a>
    <p>
      I confirm that the Company represents and warrants that it has reviewed, understands, and agrees to the OTC Markets Group Initial Review Addendum and that I have been authorized by the Company and have the legal authority to execute this Agreement on behalf of the Company.
    </p>
  </div>;

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'signSubmit',
      card: 'sign-submit'
    });
  };

  const handleViewClick = viewType => {
    if (viewType === storage.VIEWED_AGREEMENT) {
      window.localStorage.setItem(VIEWED_AGREEMENT_STORAGE_KEY, new Date());
      window.open(urls.ISSUER_SERVICE_AGREEMENT);
      setAgreementActive(true);
    }

    if (viewType === storage.VIEWED_ADDUNDEM) {
      window.localStorage.setItem(VIEWED_ADDUNDEM_STORAGE_KEY, new Date());
      window.open(urls.INITIAL_REVIEW_ADDENDUM);
      setAddendumActive(true);
    }
  };

  const onValueChanged = (value, field) => {
    let updateSignSubmit = signSubmit;

    if ((field === 'hasReviewedIssuerAgreement' || field === 'hasReviewedAddendum') && value === false) {
      updateSignSubmit.acceptedSignatured = false;
      updateSignSubmit.signature = null;
      updateSignSubmit.signeeTitle = null;
      updateSignSubmit.signatureDate = null;
    }

    if (field === 'signature' && value?.length > 0) {
      updateSignSubmit.signatureDate = new Date().getTime();
    } else if (field === 'signature' && (value?.length === 0 || !value)) {
      updateSignSubmit.signatureDate = null;
    }

    updateSignSubmit[field] = value;
    onUpdate(updateSignSubmit);
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={pageDescription} />
      {!isReady && <Label className='mtXL' isError title='Please complete all prior sections in order to access this Review and Sign section.' />}
      {isReady && <>
        <Title className='mtXL' title='Review OTC Markets Group Policies' type='h2' />
        <CheckBoxField
          readOnly={isReadOnly}
          value={signSubmit.hasReviewed}
          isRequired={showIncompletes}
          text={hasReviewedText}
          onValueChanged={e => onValueChanged(e.value, 'hasReviewed')} />
        <div className={styles.links}>
          <p>
            <a href={urls.STOCK_PROMO_POLICY} target='_blank' rel='noopener noreferrer'>See Stock Promotion Policy</a>
          </p>
          <p>
            <a href={urls.BEST_PRACTICE_ISSUERS} target='_blank' rel='noopener noreferrer'>See Best Practices for Issuers</a>
          </p>
          <p>
            <a href={urls.DILUTION_RISK} target='_blank' rel='noopener noreferrer'>See Guidance on Dilution Risk</a>
          </p>
        </div>
        <CheckBoxField
          className='mtLg'
          readOnly={isReadOnly}
          value={signSubmit.hasReviewedPrivacyPolicy}
          isRequired={showIncompletes}
          text={hasReadPrivacyText}
          onValueChanged={e => onValueChanged(e.value, 'hasReviewedPrivacyPolicy')} />
        <div className={styles.links}>
          <p>
            <a href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>See Privacy Policy</a>
          </p>
        </div>
        <Title className='mtXL' title='Signature' type='h2' />
        <p>
          Open the Issuer Services Agreement{showInitialReviewAddendum && ' and Initial Review Addendum'} to review before completing your signature.
        </p>
        <p>
          In typing my name into the "Signature" box below, I certify that:
        </p>
        <CheckBoxField
          disabled={!isAgreementChecboxActive}
          readOnly={isReadOnly}
          value={signSubmit.hasReviewedIssuerAgreement}
          isRequired={showIncompletes}
          customChild={issuerAgreementCheckBox}
          errorText='You have not reviewed the Issuer Services Agreement.'
          onValueChanged={e => onValueChanged(e.value, 'hasReviewedIssuerAgreement')} />
        {showInitialReviewAddendum && <CheckBoxField
          className='mtLg'
          disabled={!isAddendumChecboxActive}
          readOnly={isReadOnly}
          value={signSubmit.hasReviewedAddendum}
          isRequired={showIncompletes}
          customChild={reviewAddendumCheckBox}
          errorText='You have not reviewed the Initial Review Addendum.'
          onValueChanged={e => onValueChanged(e.value, 'hasReviewedAddendum')} />}
        <CheckBoxField
          className='mtLg'
          readOnly={isReadOnly}
          disabled={!isSignatureActive}
          value={signSubmit.acceptedSignatured}
          isRequired={showIncompletes}
          text='I acknowledge this as my Electronic Signature.'
          onValueChanged={e => onValueChanged(e.value, 'acceptedSignatured')} />
        <div className='mtLg'>
          <TextBox
            label='Signature (Enter Name) Must be an Officer or Director'
            placeholder='Enter Name'
            name='signature'
            showClearButton
            disabled={!isSignatureActive}
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            value={signSubmit.signature}
            onValueChanged={e => onValueChanged(e.value, 'signature')} />
        </div>
        {signSubmit.signatureDate && <Footnote text={moment(signSubmit.signatureDate).tz(timezones.NEW_YORK).format('MM/DD/YYYY')} />}
        <div className='mtLg'>
          <TextBox
            label='Title'
            placeholder={`Enter Signee's Title`}
            name='signeeTitle'
            showClearButton
            disabled={!isSignatureActive}
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            value={signSubmit.signeeTitle}
            onValueChanged={e => onValueChanged(e.value, 'signeeTitle')} />
        </div>
      </>}
    </div>
  );
};

SignSubmit.propTypes = {
  name: PropTypes.string
};

export default SignSubmit;
