import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { ApplicationContext } from '../../../context/Application.context';
import { entities, noControlPersonText } from '../../../constants';
import { isIndividualComplete, isCorporateEntityComplete } from '../../../constants/cardComplete';
import { renderPercentOwnership } from '../../../utils/helper';
import Title from '../../Title';
import Entity from '../../Entity';
import SlidePanel from '../../SlidePanel';
import CheckBoxField from '../../CheckBoxField';
import FieldError from '../../FieldError';
import AddCorpEntityControlPerson from './AddCorpEntityControlPerson';
import AddIndividualControlPerson from './AddIndividualControlPerson';
import styles from '../Application.module.scss';

const INDIVIDUAL = 'individual';
const CORP_ENTITY = 'corporateEntity';

const NotAvaiilableEntity = () => <Entity
  title='Not Available'
  icon='userComplete'
  isComplete
  subTitle='Complete' />;

const ControlPersonTab = ({ noControlPerson, peopleList, corporateEntityList, readOnly, onValueChange, onPersonAdd, onPersonUpdate, onPersonRemove, onCorpEntityAdd, onCorpEntityUpdate, onCorpEntityRemove }) => {
  const [state] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [controlView, setControlView] = useState(null);
  const [slideView, setSlideView] = useState(null);
  const [isFocus, setFocus] = useState(false);
  const individualsList = peopleList && peopleList.filter(person => person.isControl && !person.isCorporateEntity);
  const hasIndividuals = individualsList && individualsList.length > 0;
  const hasCorpEntities = corporateEntityList && corporateEntityList.length > 0;
  const isIndividual = slideView === INDIVIDUAL;
  const isBeneficialOwner = slideView === CORP_ENTITY;
  const showAddEntity = !readOnly && !noControlPerson;
  const isTabComplete = noControlPerson || (hasIndividuals || hasCorpEntities);
  const application = state.application;
  const isDNS = application.typeId === 5;
  const showIncompletes = state.showIncompletes;
  let controlViewTitle = isIndividual ? controlView && `${controlView.firstName} ${controlView.lastName}` : controlView && controlView.entityName;
  let addTitle;

  if (!controlViewTitle || controlViewTitle.trim().length === 0) controlViewTitle = entities.NO_NAME;

  if (isIndividual) addTitle = 'Add Individual (Control Person)';
  if (isBeneficialOwner) addTitle = 'Add Corporate Entity (Control Person)';

  useEffect(() => {
    if (!slidePane) setControlView(null);
  }, [slidePane]);

  const handleSlideView = type => {
    setSlideView(type);
    setSlidePane(true);
  };

  const handleUpdate = item => {
    if (isIndividual) {
      onPersonUpdate(item);
    }

    if (isBeneficialOwner) {
      onCorpEntityUpdate(item);
    }

    setSlidePane(false);
    setSlideView(null);
  };

  const handleAdd = item => {
    if (isIndividual) {
      onPersonAdd(item);
    }

    if (isBeneficialOwner) {
      onCorpEntityAdd(item);
    }

    setSlidePane(false);
    setSlideView(null);
  };

  const handleEdit = (item, type) => {
    setSlideView(type);
    setControlView(item);
    setSlidePane(true);
  };

  const handleRemove = (e, item, type) => {
    e.stopPropagation();

    if (type === INDIVIDUAL) {
      onPersonRemove(item, 'isControl');
    }

    if (type === CORP_ENTITY) {
      onCorpEntityRemove(item);
    }
  };

  const renderIndividualList = () => (
    <div className={styles.entityList}>
      {individualsList.map((person, i) => {
        const isComplete = isIndividualComplete(person);
        let title = `${person.firstName} ${person.lastName}`;
        if (!title || title.trim().length === 0) title = entities.NO_NAME;

        const onRemoveClick = e => handleRemove(e, person, INDIVIDUAL);
        let entityIcon = 'user';

        if (isComplete) entityIcon = 'userComplete';
        if (showIncompletes && !isComplete) entityIcon = 'incomplete';

        return <Entity
          key={i}
          title={title}
          icon={entityIcon}
          showIncompletes={showIncompletes && !isComplete}
          isComplete={isComplete}
          confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
          subTitle={isComplete ? 'Complete' : 'Enter Information'}
          subTitleHighlight={!isComplete}
          onClick={() => handleEdit(person, INDIVIDUAL)}
          handleRemove={!readOnly && onRemoveClick} />;
      })}
    </div>
  );

  const renderCorpEntityList = () => (
    <div className={styles.entityList}>
      {corporateEntityList.map((item, i) => {
        const isComplete = isCorporateEntityComplete(item, peopleList);
        let title = item.entityName;
        if (!title || title.trim().length === 0) title = entities.NO_NAME;

        const onRemoveClick = e => handleRemove(e, item, CORP_ENTITY);
        let entityIcon = 'user';

        if (isComplete) entityIcon = 'userComplete';
        if (showIncompletes && !isComplete) entityIcon = 'incomplete';

        return <Entity
          key={i}
          title={title}
          icon={entityIcon}
          showIncompletes={showIncompletes && !isComplete}
          isComplete={isComplete}
          confirmRemoveText={entities.CONFIRM_REMOVE_CORP_ENTITY}
          subTitle={isComplete ? 'Complete' : 'Enter Information'}
          subTitleHighlight={!isComplete}
          onClick={() => handleEdit(item, CORP_ENTITY)}
          handleRemove={!readOnly && onRemoveClick} />;
      })}
    </div>
  );

  return (
    <div
      onMouseEnter={showIncompletes ? () => setFocus(true) : undefined}
      onMouseLeave={showIncompletes ? () => setFocus(false) : undefined}>
      <div className={cn({
        [styles.incomplete]: (showIncompletes && !isTabComplete)
      })} style={{ paddingBottom: '8px' }}>
        {(showIncompletes && !isTabComplete) && <FieldError error='At least one person or entity is required. Or select No Control Persons.' isFocus={isFocus} />}
      </div>
      <div className={styles.description}>
        <p>
          Enter all persons or entities beneficially owning {renderPercentOwnership(application)}% or more of any class of the issuer’s shares; do not include Officers and Directors previously listed.
        </p>
        <p>
          A Beneficial Shareholder is any person who, directly or indirectly, has or shares the power to vote or dispose of a stock. Beneficial ownership includes the right to acquire the security within 60 days including but not limited to the exercise of options or warrants, or through conversion. See Securities Exchange Act Rule 13d-3.
        </p>
      </div>
      <CheckBoxField
        className='mbXL'
        value={noControlPerson}
        readOnly={readOnly}
        disabled={hasIndividuals || hasCorpEntities}
        text={noControlPersonText(renderPercentOwnership(application))}
        onValueChanged={onValueChange} />
      <div>
        <Title title='Individuals' type='h3' />
        {hasIndividuals && !noControlPerson && renderIndividualList()}
        {showAddEntity && <Entity
          title='Add Individual (Control Person)'
          isAdd
          onClick={() => handleSlideView(INDIVIDUAL)} />}
        {noControlPerson && <NotAvaiilableEntity />}
        {readOnly && !hasIndividuals && !noControlPerson && <Entity
          title='No Individuals'
          isEmpty />}
      </div>
      <div className='mtXL'>
        <Title title='Corporate Entities' type='h3' />
        {hasCorpEntities && !noControlPerson && renderCorpEntityList()}
        {showAddEntity && <Entity
          title='Add Corporate Entities (Control Person)'
          isAdd
          onClick={() => handleSlideView(CORP_ENTITY)} />}
        {noControlPerson && <NotAvaiilableEntity />}
        {readOnly && !hasCorpEntities && !noControlPerson && <Entity
          title='No Corporate Entities'
          isEmpty />}
      </div>
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={controlView ? controlViewTitle : addTitle}>
        {isBeneficialOwner && <AddCorpEntityControlPerson
          peopleList={peopleList}
          controlView={controlView}
          readOnly={readOnly}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          handleUpdateOwner={(item) => onPersonUpdate(item)}
          handleAddOwner={(item) => onPersonAdd(item)}
          setSlidePane={setSlidePane}
          percentOwnership={renderPercentOwnership(application)} />}
        {isIndividual && <AddIndividualControlPerson
          individualView={controlView}
          readOnly={readOnly}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          setSlidePane={setSlidePane} />}
      </SlidePanel>
    </div>
  );
};

export default ControlPersonTab;
