import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';
import SelectBox from '../SelectBox';
import { affilations } from '../../constants/companyAffilations';
import styles from './CompanyAffiliation.module.scss';

const CompanyAffiliation = ({ setCompanyAffilation, label, value, disabled, readOnly, isRequired }) => {
  let defaultValue = affilations.find(item => item === value);
  if (value && value !== defaultValue) defaultValue = 'Other';
  const [selectValue, setSelect] = useState(defaultValue || null);
  const [isOther, setisOther] = useState(defaultValue === 'Other' ? defaultValue : false);
  const [textValue, setTextValue] = useState((isOther && value) || null);

  const onValueChanged = e => {
    const value = e.value;
    setSelect(value);
    setTextValue(null);

    if (value === 'Other') {
      setisOther(true);
    } else {
      setCompanyAffilation(value);
      setisOther(false);
    }
  };

  const onOptionChanged = e => {
    if (e.name === 'text') {
      const value = e.value;
      setTextValue(value);
      setCompanyAffilation(value);
    }
  };

  return (
    <div className={styles.container}>
      <SelectBox
        id='company-affilation'
        placeholder='Select Affiliation'
        label={label}
        isRequired={isRequired}
        readOnly={readOnly}
        items={disabled ? ['N/A'] : affilations}
        value={disabled ? 'N/A' : selectValue}
        disabled={disabled}
        onValueChanged={onValueChanged} />
      {(isOther && !disabled) && <div className={styles.other}>
        <TextBox
          label='Enter other affilation'
          placeholder='Affiliation'
          name='affiliation'
          showClearButton
          readOnly={readOnly}
          isRequired={isRequired}
          value={textValue}
          onOptionChanged={onOptionChanged} />
      </div>}
    </div>
  );
};

CompanyAffiliation.propTypes = {
  setCompanyAffilation: PropTypes.func
};

export default CompanyAffiliation;
