import React, { useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../../context/Application.context';
import NavButton from '../NavButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from '../../constants';
import styles from './ApplicationNav.module.scss';

const ApplicationNav = ({ applicationSections, appId, currentSection }) => {
  const [state] = useContext(ApplicationContext);

  return (
    <div className={styles.container}>
      <Link to={routes.MY_APPLICATIONS}>
        <NavButton className='mbSm' text='Back to My Applications' icon='back' />
      </Link>
      {applicationSections.map((section, i) => {
        let path = section.path;
        const isActive = currentSection === section.path;
        const isComplete = (state && state.completeCards && state.completeCards[section.path]) || false;
        const navIndex = i + 1;

        if (section.tabs) path = `${path}/${section.tabs[0]}`;

        return <Link key={section.name} to={`/application/${appId}/${path}`} className={cn(styles.navItem, {
          [styles.active]: isActive,
          [styles.complete]: isComplete
        })}>
          {isComplete && <FontAwesomeIcon className={styles.completeIcon} icon={['far', 'check']} />}
          <div className={styles.navTitle}>
            {section.name}
          </div>
          <div className={styles.navIndex}>
            {navIndex}
          </div>
        </Link>;
      })}
    </div>
  );
};

ApplicationNav.propTypes = {
  appId: PropTypes.string,
  applicationSections: PropTypes.array,
  currentSection: PropTypes.string
};

export default ApplicationNav;
