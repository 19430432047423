import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { financialStandardsQualification } from '../../constants';
import styles from './QuestionFlow.module.scss';
import Button from '../Button';
import QuestionFlowCard from './QuestionFlowCard';
import FlowListItem from '../FlowListItem';

const DOES_NOT_QUALIFY = financialStandardsQualification.DOES_NOT_QUALIFY;
const QUALIFIES_US = financialStandardsQualification.QUALIFIES_US;
const QUALIFIES_INTL = financialStandardsQualification.QUALIFIES_INTL;

const QuestionFlow = ({ questions, appType, companyName, handleResult, handleUpdate, isIncomplete, section }) => {
  const firstQuestion = questions.find(q => q.firstQuestion);
  const [result, setResult] = useState(null);
  const isBlank = !result;
  const isFail = result === DOES_NOT_QUALIFY;
  const isOTCQXUS = result === QUALIFIES_US;
  const isOTCQXIntl = result === QUALIFIES_INTL;
  const isSuccess = isOTCQXUS || isOTCQXIntl;
  const hasCompleted = isFail || isSuccess;
  const getContinueFlow = () => {
    let contQuestions = [firstQuestion];

    const checkForAnswer = q => {
      if (hasCompleted) return;

      if (q && section && (typeof section[q.fieldName] === 'boolean' || (typeof section[q.fieldName] === 'string' && section[q.fieldName].length > 0))) {
        const addQuestion = questions.find(item => item.fieldName === q.next[appType][section[q.fieldName]]);
        contQuestions.push(addQuestion);
        checkForAnswer(addQuestion);
      }
    };

    checkForAnswer(firstQuestion);

    return contQuestions;
  };

  const startFlow = !isIncomplete && [firstQuestion];
  const continueFlow = isIncomplete && getContinueFlow();
  const [questionFlow, setQuestionFlow] = useState(startFlow || continueFlow);
  const [hasComponentDetail, setComponentDetail] = useState(false);

  const onQuestionClick = (value, fieldName) => {
    const answeredQuestion = questions.find(q => q.fieldName === fieldName);

    if (answeredQuestion.next && answeredQuestion.next[appType] && answeredQuestion.next[appType].component && value === true) {
      setComponentDetail(true);
      return;
    }

    handleUpdate(value, fieldName);

    let next = answeredQuestion.next[appType][value.toString()];

    const conditionalNext = answeredQuestion.next[appType].conditionalNext;

    if (conditionalNext && value === conditionalNext.answer) {
      const newValue = section[conditionalNext.condition] === conditionalNext.answer ? next : conditionalNext.result;
      next = newValue;
    }

    if (next === QUALIFIES_US) {
      setResult(QUALIFIES_US);
      handleUpdate(true, QUALIFIES_US);
    } else if (next === QUALIFIES_INTL) {
      setResult(QUALIFIES_INTL);
      handleUpdate(true, QUALIFIES_INTL);
    } else if (next === DOES_NOT_QUALIFY) {
      setResult(DOES_NOT_QUALIFY);
      handleUpdate(true, DOES_NOT_QUALIFY);
    } else {
      setQuestionFlow([...questionFlow, questions.find(q => q.fieldName === next)]);
    }
  };

  const onSubmitSuccess = (value, fieldName, item) => {
    setComponentDetail(false);
    handleUpdate(value, fieldName);
    handleUpdate(true, item.fieldName);
    setQuestionFlow([...questionFlow, questions.find(q => q.fieldName === item.next[appType].true)]);
  };

  const onFinish = () => {
    if (isFail) handleResult(true, DOES_NOT_QUALIFY);
    if (isOTCQXUS) handleResult(true, QUALIFIES_US);
    if (isOTCQXIntl) handleResult(true, QUALIFIES_INTL);
  };

  const handlePrevious = () => {
    handleUpdate(null, questionFlow[questionFlow.length - 2].fieldName);
    setComponentDetail(false);
    const updateQuestionFlow = questionFlow;
    updateQuestionFlow.pop();
    setQuestionFlow(updateQuestionFlow);
  };

  const handlePreviousResult = () => {
    setResult(null);
    handleUpdate(null, questionFlow[questionFlow.length - 1].fieldName);
    if (isFail) handleUpdate(false, DOES_NOT_QUALIFY);
    if (isOTCQXUS) handleUpdate(false, QUALIFIES_US);
    if (isOTCQXIntl) handleUpdate(false, QUALIFIES_INTL);
  };

  return <div className={styles.container}>
    <div>
      {questionFlow && questionFlow.map((q, i) => {
        return q ? <QuestionFlowCard
          key={i}
          index={i + 1}
          item={q}
          fieldValue={q && section[q.fieldName]}
          hasComponentDetail={hasComponentDetail && q.component}
          handleSubmitSuccess={onSubmitSuccess}
          handlePreviousClick={questionFlow.length > 1 && handlePrevious}
          handleQuestionClick={onQuestionClick} /> : null;
      })}
    </div>
    <div className={styles.result}>
      <div className={styles.resultSquare}>
        <FlowListItem
          isBlank={isBlank}
          isFail={isFail}
          isSuccess={isSuccess} />
      </div>
      {isFail && <div className={styles.message}>
        <div className={styles.title}>{companyName} does not qualify for OTCQX</div>
      </div>}
      {isOTCQXUS && <div className={cn(styles.message, styles.success, styles.title)}>{companyName} Meets the Financial Standards for OTCQX U.S.</div>}
      {isOTCQXIntl && <div className={cn(styles.message, styles.success, styles.title)}>{companyName} Meets the Financial Standards for OTCQX International</div>}
    </div>
    {hasCompleted && <>
      <Button className='mtLg' title='Back to Previous Question' fullWidth buttonType='secondary' onClick={handlePreviousResult} />
      <Button className='mtXL' title='Finish' onClick={onFinish} color='gray' fullWidth />
    </>}
  </div>;
};

QuestionFlow.propTypes = {
  questions: PropTypes.array,
  companyName: PropTypes.string,
  handleResult: PropTypes.func,
  handleUpdate: PropTypes.func
};

export default QuestionFlow;
