import React from 'react';
import PropTypes from 'prop-types';
import { urls } from '../../constants';
import DialogBox from '../DialogBox';
import styles from './PrivacyPolicyDisclaimer.module.scss';

const PrivacyPolicyDisclaimer = ({ onClose }) => (
  <DialogBox
    title='Disclaimer for Data Collection and Privacy Policy'
    submitText='Accept'
    hideCancel
    onSubmitClick={onClose}>
    <p>
      As a matter of practice, OTC Markets Group collects personal information from individuals associated with companies on our markets in order to conduct comprehensive background checks and ongoing compliance or surveillance reviews for the purpose of maintaining the integrity of our markets.
    </p>
    <p>
      OTC Markets Group takes the security of your information very seriously. Please read the <b className={styles.bold}><a href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>Privacy Policy Statement</a></b> to learn about your data privacy rights and how the information that you provide on this form is used by OTC Markets Group.
    </p>
    <p>
      By providing information on this application and clicking “Accept”, below, the individuals and entities named in this application consent to ongoing background and compliance checks in connection with the Company and other companies, individuals and activities on our markets. 
    </p>
  </DialogBox>
);

PrivacyPolicyDisclaimer.propTypes = {
  onClose: PropTypes.func
};

export default PrivacyPolicyDisclaimer;
