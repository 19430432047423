import React, { useContext, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { US_BANK, reportingStandardOptionsMap } from '../../../constants/reportingStandardOptions';
import { appTypeIds } from '../../../constants';
import { isUSA } from '../../../utils/validations';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import Title from '../../Title';
import TextArea from '../../TextArea';
import FieldError from '../../FieldError';
import { RadioGroup } from 'devextreme-react';
import styles from '../../Application/Application.module.scss';

const getReportingStandardOptions = (appTypeId, countryOfIncorporation) => {
  let options = reportingStandardOptionsMap.get(appTypeId) || [];
  if ((appTypeId === appTypeIds.OTCQX_BANKS || appTypeId === appTypeIds.OTCQB) && isUSA(countryOfIncorporation)) {
    options = isUSA(countryOfIncorporation) ? [...options, US_BANK] : options;
  }
  return options;
};

const ReportingStandards = ({ name, company }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [isFocus, setFocus] = useState(false);
  const application = state.application;
  const companyInfo = application.companyInfo;
  const reportingStandard = application.reportingStandard;
  const reportingStandardDetail = application.reportingStandardDetail;
  const countryOfIncorporation = companyInfo && companyInfo.countryOfIncorporation;
  const radioOptions = getReportingStandardOptions(application.typeId, countryOfIncorporation);
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;

  const onUpdate = (data, fieldName) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: fieldName,
      card: 'reporting-standards'
    });
  };

  const onValueChanged = (e) => {
    let updateReportingStandards = reportingStandard;
    updateReportingStandards = e.value;
    onUpdate(updateReportingStandards, 'reportingStandard');
  };

  const onTextValueChanged = (e) => {
    let updateDetail = reportingStandardDetail;
    updateDetail = e.value;
    onUpdate(updateDetail, 'reportingStandardDetail');
  };

  const renderCustomItem = data => {
    const Component = data.component;
    const detailBox = data.details;
    const showDetailBox = detailBox && reportingStandard === data.id;

    return <div className={styles.radioItem}>
      <Title title={data.id} type='h3' />
      {data.desc && <p>
        {data.desc}
      </p>}
      {Component && <p>
        <Component />
      </p>}
      {showDetailBox && <>
        {detailBox.desc && <p><i>{detailBox.desc}</i></p>}
        <TextArea
          label={detailBox.label}
          showClearButton
          placeholder={detailBox.placeholder || 'Enter text here.'}
          name='reportingStandardDetail'
          height={80}
          maxLength={1500}
          errorText='Incomplete Data. Please enter detail.'
          isRequired={showIncompletes}
          value={reportingStandardDetail}
          onValueChanged={onTextValueChanged}
        />
      </>}
    </div>;
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={`Please identify the reporting standard ${company} follows.`} />
      <div
        onMouseEnter={(showIncompletes && !reportingStandard) ? () => setFocus(true) : undefined}
        onMouseLeave={(showIncompletes && !reportingStandard) ? () => setFocus(false) : undefined}>
        <div className={cn({
          [styles.incomplete]: (showIncompletes && !reportingStandard)
        })} style={{ paddingBottom: '8px' }}>
          {(showIncompletes && !reportingStandard) && <FieldError error='One Reporting Standard must be selected.' isFocus={isFocus} />}
        </div>
        <RadioGroup
          id='radio-group-with-selection'
          valueExpr='id'
          items={radioOptions}
          itemRender={renderCustomItem}
          value={reportingStandard}
          readOnly={isReadOnly}
          onValueChanged={onValueChanged} />
      </div>
    </div>
  );
};

ReportingStandards.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string
};

export default ReportingStandards;
