export const affilations = [
  'Accountant',
  'Administrative Assistant',
  'CEO',
  'CFO',
  'CMO',
  'COO',
  'CTO',
  'Chairman',
  'Chief Accounting Officer',
  'Chief Business Officer',
  'Company Secretary',
  'Consultant',
  'Controller',
  'Counsel',
  'Director, Board of Directors',
  'Executive Assistant',
  'Executive Director',
  'General Counsel',
  'Head of Investor Relations',
  'Investor Relations',
  'Investor Relations, Manager',
  'Legal Counsel',
  'Managing Director',
  'President',
  'Treasurer',
  'Vice President',
  'Other'
];
