import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionFlowCard.module.scss';
import Button from '../../Button';
import QuestionCard from '../../QuestionCard';
import FlowListItem from '../../FlowListItem';

const QuestionFlowCard = ({ item, index, hasComponentDetail, handleSubmitSuccess, handleQuestionClick, fieldValue, handlePreviousClick }) => {
  const hasValue = (typeof fieldValue === 'boolean' || (fieldValue && fieldValue.length > 0));
  const Component = item.component;
  const ResultComponent = hasValue ? item.result[fieldValue.toString()] : null;
  const isResultComponent = typeof ResultComponent === 'function';
  const handleSaveCompelte = (value, fieldName, item) => {
    handleSubmitSuccess(value, fieldName, item);
  };

  const componentProps = {
    item: item,
    onSaveComplete: handleSaveCompelte
  };
  return (
    <div className={styles.container}>
      <div className={styles.flow}>
        <FlowListItem index={index} />
        <div className={styles.connector} />
      </div>
      {(!hasValue && !hasComponentDetail) && <div>
        <QuestionCard
          item={item}
          type='secondary'
          section={{ companyInfo: '' }}
          handleQuestionClick={handleQuestionClick} />
        {handlePreviousClick && <Button className='mbMed' title='Back to Previous Question' fullWidth buttonType='secondary' onClick={handlePreviousClick} /> }
      </div>}
      {hasComponentDetail && <div className={styles.component}>
        <Component {...componentProps} />
        {handlePreviousClick && <Button className='mbMed' title='Back to Previous Question' fullWidth buttonType='secondary' onClick={handlePreviousClick} /> }
      </div>}
      {(hasValue && !hasComponentDetail) && <div className={styles.result}>{isResultComponent ? <ResultComponent /> : item.result[fieldValue.toString()]}</div>}
    </div>
  );
};

QuestionFlowCard.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object
};

export default QuestionFlowCard;
