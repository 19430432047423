import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Amplify } from 'aws-amplify';
import { getCurrentAwsUser, awsSignout } from '../api/auth';
import { getConfig } from '../api/common';
import { storage } from '../constants';

export const AuthContext = createContext();

export const AuthConsumer = AuthContext.Consumer;

const COOKIE_EXP = 0.416667;

const initialState = {
  isReady: false,
  isAuthenticated: false,
  firstName: null,
  lastName: null,
  fullName: null,
  email: null,
  initials: null,
  timedOut: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_READY':
      return {
        ...state,
        isReady: true
      };
    case 'SET_USER':
      window.sessionStorage.setItem(storage.AP_USER, JSON.stringify(action.payload));
      if (action.payload) {
        action.payload.email = action.payload.email && action.payload.email.toLowerCase();
      }
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload,
        session: action.session,
        timedOut: false
      };
    case 'UPDATE_USER':
      const loggedInUser = window.sessionStorage.getItem(storage.AP_USER);
      const updatedUser = JSON.parse(loggedInUser);
      updatedUser.phoneNumber = action.payload;
      window.sessionStorage.setItem(storage.AP_USER, JSON.stringify(updatedUser));
      return {
        ...state,
        phoneNumber: action.payload
      };
    case 'SIGN_OUT_USER':
      if (state.aws) {
        awsSignout();
      }
      window.sessionStorage.removeItem(storage.AP_USER);
      return {
        ...initialState,
        isReady: true,
        timedOut: action.payload
      };
    default:
      throw new Error();
  }
};

export const AuthProvider = props => {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const loggedInUser = window.sessionStorage.getItem(storage.AP_USER);

  const setAuthReady = () => authDispatch({
    type: 'SET_READY'
  });

  useEffect(() => {
    getConfig()
      .then(data => {
        Amplify.configure({
          Auth: {
            region: data.awsRegion,
            // Amazon Cognito Identity Pool ID
            identityPoolId: data.awsIdentityPoolId,
            // Amazon Cognito User Pool ID
            userPoolId: data.awsUserPool,
            // Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: data.awsClientId,
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
              domain: window.location.hostname,
              // OPTIONAL - Cookie expiration in days
              expires: COOKIE_EXP,
              // OPTIONAL - Cookie secure flag
              // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
              secure: false
            }
          },
          Storage: {
            AWSS3: {
              region: data.awsRegion,
              bucket: data.awsS3PdfBucket // REQUIRED -  Amazon S3 bucket name
            }
          }
        });

        if (loggedInUser) {
          const userObj = JSON.parse(loggedInUser);
          if (userObj.aws) {
            getCurrentAwsUser().then(user => {
              authDispatch({
                type: 'SET_USER',
                payload: userObj,
                session: user
              });

              setAuthReady();
            }).catch(e => {
              authDispatch({
                type: 'SIGN_OUT_USER'
              });

              setAuthReady();
            });
          } else {
            authDispatch({
              type: 'SET_USER',
              payload: userObj
            });

            setAuthReady();
          }
        } else {
          setAuthReady();
        }
      })
      .catch(e => {
        console.error('Error retrieving auth configurations.', e);
      });
  }, []);

  return (
    <AuthContext.Provider value={[authState, authDispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
