import React, { useState, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import { TypesContext } from '../../../context/Types.context';
import cn from 'classnames';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import QuestionCard from '../../QuestionCard';
import Controls from '../../Controls';
import Entity from '../../Entity';
import Title from '../../Title';
import Button from '../../Button';
import FieldError from '../../FieldError';
import PreviousAdded from '../../PreviousAdded';
import { entities, countries, errors } from '../../../constants';
import { isRequiredCountry, isBeneficialOwnerComplete } from '../../../constants/cardComplete';
import { isBoolean } from '../../../utils/validations';
import { createID } from '../../../utils/helper';
import styles from '../Application.module.scss';

const ENTITY = 'entity';
const OWNER = 'owner';
const ADD_CORP_ENTITY = 'addCorpEntity';
const PREVIOUS_ADDED = 'previouslyAdded';
const ADD_NEW_PARTICIPANTS = 'addNewOwner';
const EDIT_NEW_PARTICIPANTS = 'editNewOwner';
const US = countries.US;
const CA = countries.CA;
const AU = countries.AU;

const corpEntityObj = {
  entityName: null,
  haveBeneficialOwners: null,
  reasonForNone: null,
  owners: []
};

const personObj = {
  firstName: '',
  middleName: '',
  lastName: '',
  isCorporateEntity: true,
  isExempt: false
};

const AddCorpEntityControlPerson = ({ controlView, individualView, setSlidePane, peopleList, handleAdd, handleAddOwner, handleUpdateOwner, handleUpdate, percentOwnership }) => {
  const [entity, setEntity] = useState(controlView || { ...corpEntityObj });
  const [person, setPerson] = useState(individualView || personObj);
  const [owners, setOwners] = useState((entity && entity.owners) || []);
  const [individualOwnerFocus, setIndividualOwnerFocus] = useState(false);
  const [slideView, setSlideView] = useState(ADD_CORP_ENTITY);
  const prevAddedList = peopleList && peopleList.filter(item => (item.isOfficer || item.isDirector) && !owners.includes(item.id));
  const isPrevAddedPeople = prevAddedList && prevAddedList.length > 0;
  const hasOwners = owners && owners.length > 0;
  const [state] = useContext(ApplicationContext);
  const [typesState] = useContext(TypesContext);
  const countryList = (typesState && typesState.codes && typesState.codes.countries) || [];
  let countryEntity = entity && entity.country ? entity.country : US;

  if (countryEntity && countryList.find(country => country.name === countryEntity)) countryEntity = countryList.find(country => country.name === countryEntity).id;

  switch (countryEntity) {
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      countryEntity = countryList.find(country => country.id === US).id;
      break;

    case countries.CAN:
    case countries.CANADA:
      countryEntity = countryList.find(country => country.id === CA).id;
      break;

    case countries.AUS:
    case countries.AUSTRALIA:
      countryEntity = countryList.find(country => country.id === AU).id;
      break;
  };

  let statesList = (typesState && typesState.codes && typesState.codes.states) || [];
  statesList = statesList.filter(state => {
    let country = (entity && countryEntity) || 'US';
    return state.countryId === country;
  });
  const usaCode = countryList && countryList.find(country => country.id === 'US');
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  const IndividualOwner = ({ onClick }) => {
    const [list, setList] = useState([...owners]);
    const isMaxOwners = list && list.length >= 3;
    const onRemove = (e, id) => {
      e.stopPropagation();
      const updateList = list;
      const person = peopleList.find(person => person.id === id);
      const index = updateList.indexOf(id);
      if (index > -1) {
        updateList.splice(index, 1);
        person.isCorporateEntity = false;
      }

      setList(updateList);
      handleRemoveOwner(updateList);
    };

    const onEdit = (id) => {
      const person = peopleList.find(person => person.id === id);
      setPerson(person);
      setSlideView(EDIT_NEW_PARTICIPANTS);
    };

    return (
      <div className='mtMed'
        onMouseEnter={() => setIndividualOwnerFocus(true)}
        onMouseLeave={() => setIndividualOwnerFocus(false)}>
        <div>
          <Title className={styles.inline} title='Individual Owner' type='h3' />
          {(showIncompletes && (!list || list.length === 0)) && <FieldError error={errors.INCOMPLETE_DATA_FIELD} isFocus={individualOwnerFocus} />}
        </div>
        {hasOwners && <div className={styles.entityList}>
          {list.map((id) => {
            const person = peopleList.find(person => person.id === id);
            const isComplete = isBeneficialOwnerComplete(person);
            const name = person && person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : entities.NO_NAME;
            if (person) {
              return <Entity
                key={id}
                title={name}
                size='small'
                showIncompletes={showIncompletes && !isComplete}
                isComplete={isComplete}
                subTitle={isComplete ? 'Complete' : 'Enter Information'}
                subTitleHighlight={!isComplete}
                onClick={() => onEdit(id)}
                confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
                handleRemove={(e) => onRemove(e, id)} />;
            };
          })}
        </div>}
        {(!isMaxOwners && !isReadOnly) && <Entity
          title='Add Beneficial Owner'
          isAdd
          size='small'
          onClick={onClick} />}
        {((!list || list.length === 0) && isReadOnly) && <Entity
          title='No Beneficial Owner'
          isEmpty
          size='small' />}
      </div>
    );
  };

  const question = {
    title: 'Control/Ownership',
    subTitle: 'Is there an individual who beneficially owns or controls this entity?',
    fieldName: 'haveBeneficialOwners',
    falseDetails: [
      {
        desc: 'Please explain why an individual cannot be identified under this corporate entity.',
        fieldName: 'reasonForNone'
      }
    ],
    trueDetails: {
      component: () => <IndividualOwner onClick={() => setSlideView(isPrevAddedPeople ? PREVIOUS_ADDED : ADD_NEW_PARTICIPANTS)} />
    }
  };

  const onValueChanged = (type, value, field) => {
    if (type === ENTITY) {
      const updatedEntity = entity;
      updatedEntity[field] = value;

      if (field === 'country') {
        const country = countryList.find(c => c.id === value);
        updatedEntity.countryName = country && country.name || value;

        if (updatedEntity.state) {
          if (!statesList.find(state => state.countryId === value)) {
            updatedEntity.state = null;
          }
        }
      }

      if (!updatedEntity.country) updatedEntity.country = countryEntity;
      if (!updatedEntity.countryName) updatedEntity.countryName = countryList.find(c => c.id === countryEntity).name || countryEntity;

      setEntity(prevState => {
        return {
          ...prevState,
          updatedEntity
        };
      });
    }

    if (type === OWNER) {
      setPerson(prevState => {
        return {
          ...prevState,
          [field]: value
        };
      });
    }
  };

  const handleUpdateEntity = () => {
    const updateEntity = entity;

    if (updateEntity.haveBeneficialOwners) {
      updateEntity.owners = owners;
      updateEntity.reasonForNone = null;
    }

    if (!updateEntity.haveBeneficialOwners) updateEntity.owners = [];

    handleUpdate(updateEntity);
  };

  const handleSelect = person => {
    setOwners([...owners, person.id]);
    setSlideView(ADD_CORP_ENTITY);
  };

  const handleSubmit = () => {
    const newEntity = entity;
    newEntity.owners = owners;

    if (newEntity.haveBeneficialOwners) newEntity.reasonForNone = null;

    handleAdd(entity);
  };

  const handleOwnerAdd = () => {
    const id = createID(peopleList);
    setOwners([...owners, id]);
    handleAddOwner({ ...person, id: id });
    setPerson(personObj);
    setSlideView(ADD_CORP_ENTITY);
  };

  const handleOwnerUpdate = () => {
    handleUpdateOwner({ ...person });
    setSlideView(ADD_CORP_ENTITY);
  };

  const handleRemoveOwner = list => {
    setOwners(list);
  };

  const renderAddNewEntity = () => (
    <>
      <p className={styles.description}>
        Enter the name of the corporate entity that owns {percentOwnership}% or more of the issuer’s shares.
      </p>
      <div className={styles.innerGrid}>
        <div className={styles.col6}>
          <TextBox
            label='Name'
            placeholder='Name'
            name='entityName'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={entity.entityName}
            showClearButton
            onValueChanged={e => onValueChanged(ENTITY, e.value, 'entityName')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='Country'
            name='country'
            placeholder='Select Country'
            valueExpr='id'
            displayExpr='name'
            isRequired={showIncompletes}
            items={countryList}
            readOnly={isReadOnly}
            defaultValue={(usaCode && usaCode.id) || null}
            value={countryEntity}
            onValueChanged={e => onValueChanged(ENTITY, e.value, 'country')} />
        </div>
        {/* <div className={styles.col3} /> */}
        <div className={styles.col2}>
          <TextBox
            label='City'
            placeholder='City'
            name='city'
            showClearButton
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={entity.city}
            onValueChanged={e => onValueChanged(ENTITY, e.value, 'city')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='State/Province'
            name='state'
            placeholder='Select State'
            valueExpr='id'
            displayExpr='name'
            searchExpr={['id', 'name']}
            disabled={!statesList || (statesList && statesList.length === 0)}
            items={statesList}
            isRequired={showIncompletes && isRequiredCountry(countryEntity)}
            readOnly={isReadOnly}
            value={entity.state}
            onValueChanged={e => onValueChanged(ENTITY, e.value, 'state')} />
        </div>
      </div>
      <div className='mtXL mbXL'>
        <QuestionCard
          item={question}
          section={entity}
          showIncompletes={showIncompletes}
          readOnly={isReadOnly}
          isValid={showIncompletes ? isBoolean(entity[question.fieldName]) : true}
          handleQuestionClick={(val, field) => onValueChanged(ENTITY, val, field)}
          onValueChanged={(val, field) => onValueChanged(ENTITY, val, field)} />
      </div>
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={controlView ? 'Update' : 'Add'}
          onCancelClick={() => setSlidePane(false)}
          onSubmitClick={controlView ? handleUpdateEntity : handleSubmit} />
      </div>}
    </>
  );

  const renderPrevAdded = () => (
    <>
      <Title title='Add Beneficial Owner' type='h2' />
      <p>
        Select individual who has been previously added as an Officer or Director, or add a new individual as the beneficial owner.
      </p>
      <PreviousAdded
        listTitle='All Officers and Directors Previously Added'
        items={prevAddedList}
        handleSelect={handleSelect} />
      <div className='mtMed'>
        <Button
          title='Add New Beneficial Owner'
          buttonType='secondary'
          fullWidth
          onClick={() => setSlideView(ADD_NEW_PARTICIPANTS)} />
      </div>
      <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlideView(ADD_CORP_ENTITY)} />
      </div>
    </>
  );

  const renderOwnerForm = () => (
    <>
      <p className='mtLg mbLg'>
        Enter Full Legal Name of Beneficial Owner.
      </p>
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <TextBox
            label='First Name'
            placeholder='First Name'
            name='firstName'
            value={person.firstName}
            readOnly={isReadOnly || person.isPreExisting}
            showClearButton
            isRequired={showIncompletes}
            onValueChanged={e => onValueChanged(OWNER, e.value, 'firstName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Middle Name'
            placeholder='Middle Name'
            name='middleName'
            value={person.middleName}
            readOnly={isReadOnly || person.isPreExisting}
            showClearButton
            onValueChanged={e => onValueChanged(OWNER, e.value, 'middleName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Last Name'
            placeholder='Last Name'
            name='lastName'
            value={person.lastName}
            readOnly={isReadOnly || person.isPreExisting}
            showClearButton
            isRequired={showIncompletes}
            onValueChanged={e => onValueChanged(OWNER, e.value, 'lastName')} />
        </div>
      </div>
    </>
  );

  const renderNewOwner = () => (
    <>
      <Title title='Add Beneficial Owner' type='h2' />
      {renderOwnerForm()}
      <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText='Add'
          onCancelClick={() => setSlideView(isPrevAddedPeople ? PREVIOUS_ADDED : ADD_CORP_ENTITY)}
          onSubmitClick={handleOwnerAdd} />
      </div>
    </>
  );

  const renderUpdateOwner = () => (
    <>
      <Title title='Edit Beneficial Owner' type='h2' />
      {renderOwnerForm()}
      <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText='Update'
          onCancelClick={() => setSlideView(ADD_CORP_ENTITY)}
          onSubmitClick={handleOwnerUpdate} />
      </div>
    </>
  );

  return (
    <div>
      {slideView === ADD_CORP_ENTITY && renderAddNewEntity()}
      {slideView === PREVIOUS_ADDED && renderPrevAdded()}
      {slideView === ADD_NEW_PARTICIPANTS && renderNewOwner()}
      {slideView === EDIT_NEW_PARTICIPANTS && renderUpdateOwner()}
    </div>
  );
};

export default AddCorpEntityControlPerson;
