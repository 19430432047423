import React from 'react';
import PropTypes from 'prop-types';
import styles from './ApplicationTitle.module.scss';

const ApplicationTitle = ({ type, company }) => {
  return <div className={styles.container}>
    {type} Application | {company}
  </div>;
};

ApplicationTitle.propTypes = {
  type: PropTypes.string,
  company: PropTypes.string
};

export default ApplicationTitle;
