import React from 'react';
import moment from 'moment-timezone';
import { timezones } from '../../constants';
import styles from './DocumentTooltip.module.scss';

const DocumentTooltip = ({ title, attributes }) => {
  const isDate = attributes && attributes.asOfDate;
  const isFullName = attributes && attributes.fullName;
  const isCustodianName = attributes && attributes.custodianName;
  const isIndividualNames = attributes && attributes.individualNames;
  const isPeriodEndDate = attributes && attributes.periodEndDate;
  const isDescription = attributes && attributes.description;

  return (
    <div className={styles.container}>
      {title && <div className={styles.header}>
        {title}
      </div>}
      <div>
        {isDate && <div className={styles.row}>
          <div className={styles.title}>
            Document Date
          </div>
          <div className={styles.value}>
            {moment(attributes.asOfDate).tz(timezones.NEW_YORK).format('MM/DD/YYYY')}
          </div>
        </div>}
        {isPeriodEndDate && <div className={styles.row}>
          <div className={styles.title}>
            Period End Date
          </div>
          <div className={styles.value}>
            {moment(attributes.periodEndDate).tz(timezones.NEW_YORK).format('MM/DD/YYYY')}
          </div>
        </div>}
        {isFullName && <div className={styles.row}>
          <div className={styles.title}>
            Full Name
          </div>
          <div className={styles.value}>
            {attributes.fullName}
          </div>
        </div>}
        {isCustodianName && <div className={styles.row}>
          <div className={styles.title}>
            Custodian Name
          </div>
          <div className={styles.value}>
            {attributes.custodianName}
          </div>
        </div>}
        {isIndividualNames && <div className={styles.row}>
          <div className={styles.title}>
            Individual Name(s)
          </div>
          <div className={styles.value}>
            {attributes.individualNames.join(', ')}
          </div>
        </div>}
        {isDescription && <div className={styles.row}>
          <div className={styles.title}>
            Description
          </div>
          <div className={styles.value}>
            {attributes.description}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default DocumentTooltip;
