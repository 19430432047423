import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { routes, dashboardPaths, validDashboardSections } from '../../constants/index';
import { AuthContext } from '../../context/Auth.context';
import DashboardNav from '../../components/DashboardNav';
import StartNewApplication from '../StartNewApplication';
import MyApplications from '../MyApplications';
import { withRouter } from '../../components/WithRouter';
import MyDocuments from '../MyDocuments';
import MicroUIWrapper from '../MicroUIWrapper';
import styles from './DashboardPage.module.scss';

const DashboardPage = ({ navigate, match: { params: { section, app } } }) => {
  const [authState] = useContext(AuthContext);
  const isIQUser = authState.iq;
  const isStartNewApp = section === dashboardPaths.START_APPLICATION;
  const isMyApps = section === dashboardPaths.MY_APPLICATIONS;
  const isMyDocs = section === dashboardPaths.MY_DOCUMENTS;
  const isMicroUI = section === dashboardPaths.APP;

  useEffect(() => {
    if (isMicroUI && !isIQUser) navigate(routes.MY_APPLICATIONS);
    !validDashboardSections.includes(section) && navigate(routes.MY_APPLICATIONS);
  }, [section]);

  return (
    <div className={styles.container}>
      <nav>
        <DashboardNav section={section} />
      </nav>
      <main>
        {isStartNewApp && <StartNewApplication />}
        {isMyApps && <MyApplications />}
        {isMyDocs && <MyDocuments />}
        {isMicroUI && <MicroUIWrapper appName={app} />}
      </main>
    </div>
  );
};

DashboardPage.propTypes = {
  navigate: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      section: PropTypes.string
    })
  })
};

export default withRouter(DashboardPage);
