import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { ApplicationContext } from '../../../context/Application.context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TextBox from '../../TextBox';
import DynamicPhoneInput from '../../DynamicPhoneInput';
import PreviousAdded from '../../PreviousAdded';
import Title from '../../Title';
import Button from '../../Button';
import Entity from '../../Entity';
import DialogBox from '../../DialogBox';
import Controls from '../../Controls';
import EmailField from '../../EmailField';
import TitleSelect from '../../TitleSelect';
import SortableDrag from '../../SortableDrag';
import FieldError from '../../FieldError';
import { errors } from '../../../constants';
import styles from '../Application.module.scss';

const personObj = {
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  titles: [],
  title1: null,
  title2: null,
  title3: null,
  title4: null,
  isExempt: false
};

const officerObj = {
  ...personObj,
  isOfficer: true
};

const formerExecObj = {
  ...personObj,
  isFormerExecutive: true
};

const PREV_ADDED_VIEW = 'previousAdded';
const ADD_NEW_VIEW = 'addNew';

const AddOfficer = ({ officerView, setOfficerView, directorList, setSlidePane, handleAdd, handleUpdate, isFormerExec }) => {
  const personView = isFormerExec ? formerExecObj : officerObj;
  const [state] = useContext(ApplicationContext);
  const previousAddView = !officerView && directorList && directorList.length > 0;
  const [view, setView] = useState(previousAddView ? PREV_ADDED_VIEW : ADD_NEW_VIEW);
  const [officer, setOfficer] = useState(officerView || personView);
  const [titlesFocus, setTitlesFocus] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [addTitle, toggleAddTitle] = useState(false);
  const [history, setHistory] = useState(null);
  const hasTitles = officer && officer.title1;
  const isMaxTitles = officer && officer.title4;
  const isAdd = !officerView || (officerView && !officerView.isOfficer);
  let titleArray = [];
  if (officer.title1) titleArray.push(officer.title1);
  if (officer.title2) titleArray.push(officer.title2);
  if (officer.title3) titleArray.push(officer.title3);
  if (officer.title4) titleArray.push(officer.title4);
  const [titles, setTitles] = useState(titleArray);
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  useEffect(() => {
    setOfficer(prevState => {
      return {
        ...prevState,
        titles: titles && titles,
        title1: titles && titles[0],
        title2: titles && titles[1],
        title3: titles && titles[2],
        title4: titles && titles[3]
      };
    });
  }, [titles]);

  const onValueChanged = (value, field) => {
    setOfficer(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleSelection = value => {
    setSelectedTitle(value);
  };

  const handleTitleSave = () => {
    setOfficer(prevState => {
      const titleList = [...prevState.titles || [], selectedTitle];
      setTitles(titleList);
      return {
        ...prevState,
        titles: titleList,
        title1: titleList[0],
        title2: titleList[1],
        title3: titleList[2],
        title4: titleList[3]
      };
    });

    setSelectedTitle(null);
    toggleAddTitle(false);
  };

  const handleUpdateOfficer = () => {
    const newOfficer = officer;
    if (!newOfficer.isOfficer && !isFormerExec) newOfficer.isOfficer = true;
    delete newOfficer.titles;
    handleUpdate(newOfficer);
  };

  const handleSelect = person => {
    const newOfficer = person;
    if (!newOfficer.titles) newOfficer.titles = [];
    setHistory(PREV_ADDED_VIEW);
    setOfficer(newOfficer);
    setOfficerView(newOfficer);
    setView(ADD_NEW_VIEW);
  };

  const handleAddNew = () => {
    setHistory(PREV_ADDED_VIEW);
    setView(ADD_NEW_VIEW);
  };

  const handleSubmit = () => {
    const person = officer;
    delete person.titles;
    handleAdd(person);
  };

  const removeCard = title => {
    const updateTitles = officer.titles;
    const index = updateTitles.indexOf(title);
    updateTitles.splice(index, 1);

    setOfficer(prevState => {
      return {
        ...prevState,
        titles: updateTitles,
        title1: updateTitles[0],
        title2: updateTitles[1],
        title3: updateTitles[2],
        title4: updateTitles[3]
      };
    });
  };

  const renderTitles = () => (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.entityList}>{titles && titles.map((card, i) => {
        const onRemove = () => removeCard(card);

        return (
          (
            <SortableDrag key={card} index={i} id={card} items={titles} setItems={setTitles} disable={isReadOnly}>
              <Entity
                title={card}
                size='small'
                handleRemove={!isReadOnly && onRemove} />
            </SortableDrag>
          )
        );
      })}</div>
    </DndProvider>
  );

  const handleCancel = () => {
    history === PREV_ADDED_VIEW && directorList && directorList.length > 0 ? setView(PREV_ADDED_VIEW) : setSlidePane(false);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        Select individual previously entered as an Officer, Director, or Control Person or add a new individual below
      </p>
      <PreviousAdded
        listTitle='All Directors Previously Added'
        items={directorList}
        handleSelect={handleSelect} />
      <div className='mtMed'>
        <Button
          title='Add New Officer'
          buttonType='secondary'
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={cn('mtXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePane(false)} />
      </div>
    </div>
  );

  const renderAddNew = () => (
    <div>
      <p className={styles.description}>
        Provide legal name, title and contact information below. Contact information (phone number and email) is for internal use only; this information will not be made publicly available.
      </p>
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <TextBox
            label='First Name'
            placeholder='First Name'
            name='firstName'
            readOnly={isReadOnly || officer.isPreExisting}
            isRequired={showIncompletes}
            value={officer.firstName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'firstName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Middle Name'
            placeholder='Middle Name'
            name='middleName'
            readOnly={isReadOnly || officer.isPreExisting}
            value={officer.middleName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'middleName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Last Name'
            placeholder='Last Name'
            name='lastName'
            isRequired={showIncompletes}
            readOnly={isReadOnly || officer.isPreExisting}
            value={officer.lastName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'lastName')} />
        </div>
      </div>
      <div className={cn('mtMed', styles.innerGrid)}>
        <div className={styles.col3}>
          <DynamicPhoneInput
            label='Phone Number'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            phoneValue={officer.phone}
            countryCodeValue={officer.phoneCountryCode}
            countryCodeField='phoneCountryCode'
            phoneNumberField='phone'
            onValueChanged={onValueChanged} />
        </div>
        <div className={styles.col3}>
          <EmailField
            label='Email'
            placeholder='email@mail.com'
            name='email'
            readOnly={isReadOnly}
            isRequired={showIncompletes}
            value={officer.email}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'email')} />
        </div>
      </div>
      <div
        className='mtXL'
        onMouseEnter={() => showIncompletes ? setTitlesFocus(true) : undefined}
        onMouseLeave={() => showIncompletes ? setTitlesFocus(false) : undefined}>
        {!addTitle && <div>
          <div>
            <Title className={styles.inline} title='Titles' type='h3' />
            {(showIncompletes && !officer.title1) && <FieldError error={errors.INCOMPLETE_DATA_FIELD} isFocus={titlesFocus} />}
          </div>
          <p>
            Please enter all titles associated with this person.
          </p>
          {hasTitles && renderTitles()}
          {(!isMaxTitles && !isReadOnly) && <Entity
            title='Add Title'
            isAdd
            size='small'
            onClick={() => toggleAddTitle(true)} />}
          {(!officer.title1 && isReadOnly) && <Entity
            title='No Titles'
            isEmpty
            size='small' />}
        </div>}
        {addTitle && <div>
          <p>
            Please enter all titles associated with this person.
          </p>
          <DialogBox
            title='Add New Title'
            subTitle='Select title from preset selections.'
            onCancelClick={() => toggleAddTitle(false)}
            onSubmitClick={handleTitleSave}
            isSubmitDisabled={!selectedTitle || selectedTitle.trim().length === 0}>
            <TitleSelect onSelection={handleSelection} selectedTitles={titles} />
          </DialogBox>
        </div>}
      </div>
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={isAdd ? 'Add' : 'Update'}
          onCancelClick={handleCancel}
          onSubmitClick={officerView ? handleUpdateOfficer : handleSubmit} />
      </div>}
    </div>
  );

  return (
    <>
      {view === PREV_ADDED_VIEW && renderPreviouslyAdded()}
      {view === ADD_NEW_VIEW && renderAddNew()}
    </>
  );
};

export default AddOfficer;
