import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import getConfig from '../../config';
import Avatar from '../Avatar';
import { AuthContext } from '../../context/Auth.context';
import styles from './Header.module.scss';

const { REACT_ENV } = getConfig();

const Header = props => {
  const [authState] = useContext(AuthContext);
  const userInitials = authState.initials;
  const isNotProd = REACT_ENV !== 'prod';
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to={routes.HOME}>
          <h1 className={styles.title}>
            <img className={styles.logo} src='/logos/otc-gateway-logo-white.png' alt='OTC Gateway' />
            {isNotProd && <span className={styles.env}>({REACT_ENV})</span>}
          </h1>
        </Link>
        {authState.isAuthenticated && <Avatar initials={userInitials} />}
      </div>
    </header>
  );
};

export default Header;
