import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import { withRouter } from '../../components/WithRouter';
import styles from './MicroUIWrapper.module.scss';

const MicroUIWrapper = (props) => {
  const microUIName = props.appName || props.params.app;

  useEffect(() => {
    const scriptId = `micro-frontend-script-${microUIName}`;

    const microAssetPath = `/app/${microUIName}/assets`;

    // eslint-disable-next-line no-undef
    fetch(`${microAssetPath}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${microAssetPath}${manifest.files['main.js']}`;
        console.log('script.src', script.src);
        script.onload = () => {
          window.renderMicro(`${microUIName}-container`);
        };

        // CSS
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${microAssetPath}${manifest.files['main.css']}`;
        link.media = 'all';
        head.appendChild(link);

        document.body.appendChild(script);
      });

    return () => {
      window.unmountApp && window.unmountApp(`${microUIName}-container`);
    };
  }, []);

  return (
    <div className={styles.container}>
      <main id={`${microUIName}-container`}>
        <div style={{ marginTop: '50px' }}>
          <Loading />
        </div>
      </main>
    </div>
  );
};

MicroUIWrapper.propTypes = {
  appName: PropTypes.string,
  params: PropTypes.shape({
    app: PropTypes.string
  })
};

export default withRouter(MicroUIWrapper);
