import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Label from '../Label';
import Title from '../Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PasswordRequirements.module.scss';

const PasswordRequirements = ({ className, value, isError }) => {
  const isMinValid = !!(value && value.length >= 14);
  const isNumValid = !!(value && /\d/g.test(value));
  const isCharValid = !!(value && /[~`!@_#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(value));
  const isLowerValid = !!(value && /[a-z]/g.test(value));
  const isUpperValid = !!(value && /[A-Z]/g.test(value));

  const getIcon = bool => bool ? <FontAwesomeIcon className={styles.icon} icon={['far', 'check']} size='xs' />
    : <FontAwesomeIcon className={styles.icon} icon={['fas', 'circle']} size='xs' />;

  return (
    <div className={cn(className, styles.container)}>
      <Label isError={isError}>
        <Title title='Password requires:' type='h3' />
        <ul className={styles.list}>
          <li className={cn(styles.item, {
            [styles.valid]: isMinValid,
            [styles.error]: isError && !isMinValid
          })}>
            {getIcon(isMinValid)}
            Minimum 14 characters
          </li>
          <li className={cn(styles.item, {
            [styles.valid]: isUpperValid,
            [styles.error]: isError && !isUpperValid
          })}>
            {getIcon(isUpperValid)}
            Require uppercase letters
          </li>
          <li className={cn(styles.item, {
            [styles.valid]: isNumValid,
            [styles.error]: isError && !isNumValid
          })}>
            {getIcon(isNumValid)}
            Require numeric character
          </li>
          <li className={cn(styles.item, {
            [styles.valid]: isLowerValid,
            [styles.error]: isError && !isLowerValid
          })}>
            {getIcon(isLowerValid)}
            Require lowercase letters
          </li>
          <li className={cn(styles.item, {
            [styles.valid]: isCharValid,
            [styles.error]: isError && !isCharValid
          })}>
            {getIcon(isCharValid)}
            Require special character
          </li>
        </ul>
      </Label>
    </div>
  );
};

PasswordRequirements.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  isError: PropTypes.bool
};

export default PasswordRequirements;
