import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Title from '../../Title';
import Entity from '../../Entity';
import Controls from '../../Controls';
import CheckBoxField from '../../CheckBoxField';
import ShareHoldingForm from './ShareHoldingForm';
import { isNumber } from '../../../utils/validations';
import { validateControlPersonOwnership, validateCorporateEntityOwnership } from '../../../constants/cardComplete';
import styles from '../../Application/Application.module.scss';

const Shareholdings = ({ owner, securities, setSlidePane, readOnly, showIncompletes, onSave, onShareHoldingSave, onShareHoldingUpdate }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(owner);
  const isExempt = ownerDetails && ownerDetails.isExempt;
  const isControlPerson = owner.isControl || owner.entityName;

  useEffect(() => {
    setOwnerDetails({ ...owner });
  }, [owner]);
  const entityTitle = security => {
    const symbol = security.symbol && `${security.symbol} - `;
    let title = `${symbol || ''}${security.type} - Class ${security.securityClass}`;

    return title;
  };

  const validateComplete = (owner, securities) => {
    if (owner.isControl && !validateControlPersonOwnership(securities)) return false;
    if (owner.entityName && !validateCorporateEntityOwnership(securities)) return false;
    return securities && isNumber(securities.numberOfShares) && isNumber(securities.percentOfClass);
  };

  const onValueChanged = (value, field) => {
    setOwnerDetails(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleSave = (sec, data, inline) => {
    const updateOwner = { ...ownerDetails };
    const hasSecOwnership = updateOwner.securityOwnerships && Array.isArray(updateOwner.securityOwnerships);
    hasSecOwnership ? updateOwner.securityOwnerships.push({ ...data, secId: sec.secId }) : updateOwner.securityOwnerships = [{ ...data, secId: sec.secId }];
    onShareHoldingSave(updateOwner);
    if (inline) {
      setSlidePane(false);
    } else {
      setShowDialog(false);
    }
  };

  const handleUpdate = (sec, data, inline) => {
    const updateOwner = { ...ownerDetails };
    updateOwner.securityOwnerships = updateOwner.securityOwnerships ? updateOwner.securityOwnerships.map(sec => {
      if (sec.secId === data.secId) {
        return data;
      } else {
        return sec;
      }
    }) : [{ ...data, secId: sec.secId }];

    onShareHoldingUpdate(updateOwner);

    if (inline) {
      setSlidePane(false);
    } else {
      setShowDialog(false);
    }
  };

  const renderSingleShareHoldingForm = () => {
    const sec = securities[0];
    const index = sec.secId;
    const secOwner = owner && owner.securityOwnerships && owner.securityOwnerships.find(item => item.secId === index);
    return <ShareHoldingForm
      inline
      disabled={ownerDetails && ownerDetails.isExempt}
      isControlPerson={isControlPerson}
      securityOwner={secOwner}
      title={entityTitle(sec)}
      readOnly={readOnly}
      onCancelClick={() => setSlidePane(false)}
      onSaveClick={data => secOwner ? handleUpdate(sec, data, true) : handleSave(sec, data, true)} />;
  };

  return (
    <>
      <Title title='Shareholdings' type='h2' />
      <p>
        Enter beneficial shareholdings below.
      </p>
      <p>
        Beneficial ownership includes the right to acquire the security within 60 days including but not limited to the exercise of options or warrants, or through conversion
      </p>
      {!isControlPerson && <CheckBoxField
        className='mtLg'
        tabIndex={0}
        readOnly={readOnly}
        value={ownerDetails && ownerDetails.isExempt}
        text='Select if this person has no shareholdings of the company'
        onValueChanged={e => onValueChanged(e.value, 'isExempt')} />}
      <div className='mtLg'>
        {(securities && securities.length > 1) && securities.map((sec, i) => {
          const index = sec.secId;
          const secOwner = owner && owner.securityOwnerships && owner.securityOwnerships.find(item => item.secId === index);
          const isComplete = validateComplete(owner, secOwner);
          let subTitle = 'Enter Information';
          if (isExempt) subTitle = 'No Holdings';
          if (!isExempt && isComplete) subTitle = 'Complete';

          return (
            <div key={i} className={styles.entityList}>
              <Entity
                title={entityTitle(sec)}
                icon='fileCert'
                isInactive={isExempt}
                showIncompletes={showIncompletes && !isComplete && !ownerDetails.isExempt}
                subTitleHighlight={isExempt || !isComplete}
                subTitle={subTitle}
                isComplete={isComplete}
                onClick={() => !isExempt && setShowDialog(index)} />
              {(showDialog && showDialog === index) &&
              <ShareHoldingForm
                securityOwner={secOwner}
                title={entityTitle(sec)}
                showIncompletes={showIncompletes}
                readOnly={readOnly}
                isControlPerson={isControlPerson}
                onCancelClick={() => setShowDialog(false)}
                onSaveClick={data => secOwner ? handleUpdate(sec, data) : handleSave(sec, data)} />}
            </div>
          );
        })}
      </div>
      {(securities && securities.length === 1) && renderSingleShareHoldingForm()}
      {(!readOnly && (securities && securities.length > 1)) && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText='Save'
          onCancelClick={() => setSlidePane(false)}
          onSubmitClick={() => onSave(ownerDetails)} />
      </div>}
    </>
  );
};

Shareholdings.prototype = {
  owner: PropTypes.object,
  securities: PropTypes.array,
  setSlidePane: PropTypes.func,
  handleSave: PropTypes.func
};

export default Shareholdings;
