import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { urls } from '../../../constants';
import { isBoolean } from '../../../utils/validations';
import { isDirectorComplete } from '../../../constants/cardComplete';
import { ApplicationContext } from '../../../context/Application.context';
import TextBox from '../../TextBox';
import CheckBoxField from '../../CheckBoxField';
import EmailField from '../../EmailField';
import DynamicPhoneInput from '../../DynamicPhoneInput';
import Button from '../../Button';
import QuestionCard from '../../QuestionCard';
import PreviousAdded from '../../PreviousAdded';
import Controls from '../../Controls';
import styles from '../Application.module.scss';

const checkboxes = [
  {
    label: 'Audit Committee',
    field: 'isAuditCommittee'
  },
  {
    label: 'Nominating Committee',
    field: 'isNominatingCommittee'
  },
  {
    label: 'Compensation Committee',
    field: 'isCompensationCommittee'
  }
];

const question = {
  title: 'Independent Director',
  subTitle: () => <p>Is this individual an  <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Director</a>?</p>,
  fieldName: 'isIndependent'
};

const directorObj = {
  isDirector: true,
  isChairPerson: false,
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  isIndependent: null,
  isAuditCommittee: false,
  isNominatingCommittee: false,
  isCompensationCommittee: false,
  isExempt: false
};

const PREV_ADDED_VIEW = 'previousAdded';
const ADD_NEW_VIEW = 'addNew';

const AddDirector = ({ directorView, setDirectorView, setSlidePane, officerList, handleAdd, handleUpdate, isChairPerson }) => {
  const [state] = useContext(ApplicationContext);
  const previousAddView = !directorView && officerList && officerList.length > 0;
  const newDirectorObj = isChairPerson ? { ...directorObj, isChairPerson: true } : directorObj;
  const [view, setView] = useState(previousAddView ? PREV_ADDED_VIEW : ADD_NEW_VIEW);
  const [director, setDirector] = useState(directorView || newDirectorObj);
  const renderOfficers = officerList && officerList.slice(0, 4);
  const [history, setHistory] = useState(null);
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;
  const showMore = officerList.length > 4 && renderOfficers.length <= 4;

  const onValueChanged = (value, field) => {
    setDirector(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleUpdateDirector = () => {
    const newDiretor = director;
    if (!newDiretor.isDirector) newDiretor.isDirector = true;
    if (isChairPerson && !newDiretor.isChairPerson) newDiretor.isChairPerson = true;
    handleUpdate(newDiretor);
  };

  const handleCancel = () => {
    history === PREV_ADDED_VIEW && officerList && officerList.length > 0 ? setView(PREV_ADDED_VIEW) : setSlidePane(false);
  };

  const handleSubmit = () => {
    handleAdd(director);
  };

  const handleSelect = person => {
    const newDiretor = person;
    newDiretor.isIndependent = null;
    newDiretor.isAuditCommittee = false;
    newDiretor.isNominatingCommittee = false;
    newDiretor.isCompensationCommittee = false;
    setHistory(PREV_ADDED_VIEW);
    setDirector(newDiretor);
    setDirectorView(newDiretor);
    setView(ADD_NEW_VIEW);
  };

  const handleAddNew = () => {
    setHistory(PREV_ADDED_VIEW);
    setView(ADD_NEW_VIEW);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        Select individual previously entered as an Officer, Director, or Control Person or add a new individual below
      </p>
      <PreviousAdded
        listTitle='All Officers Previously Added'
        items={officerList}
        handleSelect={handleSelect} />
      <div className='mtMed'>
        <Button
          title='Add New Individual'
          buttonType='secondary'
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={cn('mtXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePane(false)} />
      </div>
    </div>
  );

  const renderAddNew = () => (
    <div>
      <p className={styles.description}>
        Provide legal name, board position and contact information below.
      </p>
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <TextBox
            label='First Name'
            placeholder='First Name'
            name='firstName'
            isRequired={showIncompletes}
            readOnly={isReadOnly || director.isPreExisting}
            value={director.firstName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'firstName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Middle Name'
            placeholder='Middle Name'
            name='middleName'
            readOnly={isReadOnly || director.isPreExisting}
            value={director.middleName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'middleName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Last Name'
            placeholder='Last Name'
            name='lastName'
            isRequired={showIncompletes}
            readOnly={isReadOnly || director.isPreExisting}
            value={director.lastName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'lastName')} />
        </div>
      </div>
      <div className={cn('mtMed', styles.innerGrid)}>
        <div className={styles.col3}>
          <DynamicPhoneInput
            label='Phone Number'
            readOnly={isReadOnly}
            countryCodeValue={director.phoneCountryCode}
            phoneValue={director.phone}
            countryCodeField='phoneCountryCode'
            phoneNumberField='phone'
            onValueChanged={onValueChanged} />
        </div>
        <div className={styles.col3}>
          <EmailField
            label='Email'
            placeholder='email@mail.com'
            name='email'
            readOnly={isReadOnly}
            value={director.email}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'email')} />
        </div>
      </div>
      <div className='mtXL mbXL'>
        <QuestionCard
          item={question}
          section={director}
          readOnly={isReadOnly}
          isValid={showIncompletes ? isBoolean(director.isIndependent) : true}
          showIncompletes={showIncompletes}
          handleQuestionClick={onValueChanged} />
      </div>
      <div>
        <p>
          Select all Committee Memberships that apply to this Director.
        </p>
        <div className={styles.checkboxList}>
          {checkboxes.map((checkbox, i) => <CheckBoxField
            key={i}
            value={director[checkbox.field]}
            text={checkbox.label}
            readOnly={isReadOnly}
            onValueChanged={e => onValueChanged(e.value, checkbox.field)} />)}
        </div>
      </div>
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={directorView ? (isDirectorComplete(directorView) && !directorView.isDirectorVerified) ? 'Verify' : 'Update' : 'Add'}
          onCancelClick={handleCancel}
          onSubmitClick={directorView ? handleUpdateDirector : handleSubmit} />
      </div>}
    </div>
  );

  return (
    <>
      {view === PREV_ADDED_VIEW && renderPreviouslyAdded()}
      {view === ADD_NEW_VIEW && renderAddNew()}
    </>
  );
};

export default AddDirector;
