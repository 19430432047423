import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NotificationLabel from '../../components/NotificationLabel';
import styles from './SystemNotifications.module.scss';
import './transition.scss';

const SystemNotifications = ({ notifications }) => {
  const items = notifications.map(n => (
    <CSSTransition
      key={n.id}
      timeout={500}
      classNames='item'>
      <NotificationLabel key={n.id} className={styles.notification} id={n.id} text={n.description} type={n.type} />
    </CSSTransition>
  ));
  return (
    <div className={styles.container}>
      <TransitionGroup className='notifications'>
        {items}
      </TransitionGroup>
    </div>
  );
};

SystemNotifications.propTypes = {
  notifications: PropTypes.array
};

export default SystemNotifications;
