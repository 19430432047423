import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { TypesContext } from '../../../context/Types.context';
import { ApplicationContext } from '../../../context/Application.context';
import { countries, urls } from '../../../constants';
import { isRequiredCountry } from '../../../constants/cardComplete';
import ServiceProviderSearch from '../../../containers/ServiceProviderSearch';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import EmailField from '../../EmailField';
import CheckBoxField from '../../CheckBoxField';
import WebsiteField from '../../WebsiteField';
import DynamicPhoneInput from '../../DynamicPhoneInput';
import Controls from '../../Controls';
import Title from '../../Title';
import TitleSelect from '../../TitleSelect';
import styles from '../Application.module.scss';

const PLACEHOLDER_NA = 'N/A';
const US = countries.US;
const CA = countries.CA;
const AU = countries.AU;

const transferAgentObj = {
  id: null,
  name: null,
  phone: null,
  website: null,
  email: null,
  country: null,
  primaryContactName: null,
  primaryContactTitle: null,
  primaryContactPhone: null,
  primaryContactEmail: null,
  verifiedSharesAuth: false
};

const initialContact = {
  name: null,
  phone: null,
  website: null,
  email: null,
  address1: null,
  address2: null,
  address3: null,
  city: null,
  state: null,
  zip: null,
  country: null
};

const providerObj = {
  ...initialContact,
  noOutsideCounsel: null,
  primaryFirstName: null,
  primaryMiddleName: null,
  primaryLastName: null,
  primaryContactTitle: null,
  primaryContactPhone: null,
  primaryContactEmail: null
};

const AddServiceProvider = ({ isDNS, typeId, title, providerView, setSlidePane, handleAdd, handleUpdate, isUSAorCAN }) => {
  const [typesState] = useContext(TypesContext);
  const [state] = useContext(ApplicationContext);
  const isTransferAgent = typeId === '6';
  const isQXSponsor = typeId === '4';
  const isSecurityCounsel = typeId === '3';
  const [provider, setProvider] = useState(providerView || (isTransferAgent ? transferAgentObj : providerObj));
  const isAdd = !providerView;
  const isReadOnly = state.readOnly;
  const isNoOutsideCounsel = provider && provider.noOutsideCounsel;
  const countryList = (typesState && typesState.codes && typesState.codes.countries) || [];
  const showFirmContactFields = !isTransferAgent && !isQXSponsor;
  let countryProvider = provider && provider.country ? provider.country : US;

  if (countryProvider && countryList.find(country => country.name === countryProvider)) countryProvider = countryList.find(country => country.name === countryProvider).id;

  switch (countryProvider) {
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      countryProvider = countryList.find(country => country.id === US).id;
      break;

    case countries.CAN:
    case countries.CANADA:
      countryProvider = countryList.find(country => country.id === CA).id;
      break;

    case countries.AUS:
    case countries.AUSTRALIA:
      countryProvider = countryList.find(country => country.id === AU).id;
      break;
  };

  let statesList = (typesState && typesState.codes && typesState.codes.states) || [];
  statesList = statesList.filter(state => {
    let country = (provider && countryProvider) || US;
    return state.countryId === country;
  });
  const usaCode = countryList && countryList.find(country => country.id === US);
  const showIncompletes = state.showIncompletes;

  const handleNoSecCounsel = (value) => {
    const obj = {
      ...initialContact,
      noOutsideCounsel: value
    };
    setProvider(prevState => {
      return {
        ...prevState,
        ...obj
      };
    });
  };

  const onValueChanged = (value, field) => {
    const updatedProvider = provider;
    updatedProvider[field] = value;

    if (field === 'name' && value) updatedProvider.name = value.trim();

    if (field === 'country') {
      const country = countryList.find(c => c.id === value);
      updatedProvider.countryName = country && country.name || value;

      if (updatedProvider.state) {
        if (!statesList.find(state => state.countryId === value)) {
          updatedProvider.state = null;
        }
      }
    }

    if (!updatedProvider.country) updatedProvider.country = countryProvider;
    if (!updatedProvider.countryName) updatedProvider.countryName = countryList.find(c => c.id === countryProvider).name || countryProvider;

    setProvider(prevState => {
      return {
        ...prevState,
        updatedProvider
      };
    });
  };

  const handleSelection = value => {
    setProvider(prevState => {
      return {
        ...prevState,
        primaryContactTitle: value
      };
    });
  };

  const handleUpdateProvider = () => {
    if (!provider.country) provider.country = countryProvider;
    handleUpdate(provider);
  };

  const handleSubmit = () => {
    if (!provider.country) provider.country = countryProvider;
    handleAdd(provider);
  };

  // Method to get selected provider's detail
  // const handleDetailSelect = detail => {
  //   setProvider({
  //     ...provider,
  //     ...detail
  //   })
  // }

  return (
    <div>
      <Title type='h2' title={title} />
      {(isTransferAgent && !isDNS) && <p className={styles.description}>
        Companies incorporated in the U.S. or Canada must retain a transfer agent that participates in the <a href={urls.TAVSP} target='_blank' rel='noopener noreferrer'>Transfer Agent Verified Shares Program</a>.
      </p>}
      <p className={styles.description}>
        Provide contact information below.
      </p>
      {isSecurityCounsel && <div className={cn(styles.col6, 'mtLg mbLg')}>
        <CheckBoxField
          readOnly={isReadOnly}
          value={provider.noOutsideCounsel}
          text='The Company does not currently have outside securities counsel. Contact information for internal securities counsel is below.'
          onValueChanged={e => handleNoSecCounsel(e.value)} />
      </div>}
      <div className={styles.innerGrid}>
        <div className={styles.col6}>
          <ServiceProviderSearch
            typeId={typeId}
            disableSearch={showFirmContactFields}
            notAvailable={isNoOutsideCounsel}
            isRequired={showIncompletes && !isNoOutsideCounsel}
            readOnly={isReadOnly || provider.isPreExisting}
            value={provider.name}
            disabled={isNoOutsideCounsel}
            // onDetailSelect={typeId === '4' ? handleDetailSelect : undefined}
            onValueChanged={value => onValueChanged(value, 'name')}
          />
        </div>
        {showFirmContactFields && <>
          <div className={styles.col3}>
            <DynamicPhoneInput
              label='Firm Phone Number (optional)'
              readOnly={isReadOnly}
              phoneValue={provider.phone}
              countryCodeValue={provider.phoneCountryCode}
              disabled={isNoOutsideCounsel}
              countryCodeField='phoneCountryCode'
              phoneNumberField='phone'
              onValueChanged={onValueChanged} />
          </div>
          <div className={styles.col3}>
            <EmailField
              label='Firm Email (optional)'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'email@mail.com'}
              name='firmEmail'
              readOnly={isReadOnly}
              value={provider.email}
              disabled={isNoOutsideCounsel}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'email')} />
          </div>
          <div className={styles.col6}>
            <WebsiteField
              label='Firm Website (optional)'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'http://www.google.com'}
              name='firmName'
              readOnly={isReadOnly}
              value={provider.website}
              disabled={isNoOutsideCounsel}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'website')} />
          </div>
          <div className={cn(styles.col6, styles.fieldGroup)}>
            <TextBox
              label='Firm Address'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Address 1'}
              name='address1'
              showClearButton
              isRequired={showIncompletes && !isNoOutsideCounsel}
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel}
              value={provider.address1}
              onValueChanged={e => onValueChanged(e.value, 'address1')} />
            <TextBox
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Address 2'}
              name='address2'
              showClearButton
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel}
              value={provider.address2}
              onValueChanged={e => onValueChanged(e.value, 'address2')} />
            <TextBox
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Address 3'}
              name='address3'
              showClearButton
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel}
              value={provider.address3}
              onValueChanged={e => onValueChanged(e.value, 'address3')} />
          </div>
          <div className={styles.col3}>
            <SelectBox
              label='Country'
              name='country'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Select Country'}
              valueExpr='id'
              displayExpr='name'
              items={countryList}
              isRequired={showIncompletes && !isNoOutsideCounsel}
              disabled={isNoOutsideCounsel}
              readOnly={isReadOnly}
              defaultValue={(usaCode && usaCode.id) || null}
              value={countryProvider}
              onValueChanged={e => onValueChanged(e.value, 'country')} />
          </div>
          <div className={styles.col3} />
          <div className={styles.col2}>
            <TextBox
              label='City'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'City'}
              name='city'
              showClearButton
              isRequired={showIncompletes && !isNoOutsideCounsel}
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel}
              value={provider.city}
              onValueChanged={e => onValueChanged(e.value, 'city')} />
          </div>
          <div className={styles.col2}>
            <SelectBox
              label='State/Province'
              name='state'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Select State'}
              valueExpr='id'
              displayExpr='name'
              items={statesList}
              searchExpr={['id', 'name']}
              isRequired={(showIncompletes && isRequiredCountry(countryProvider)) && !isNoOutsideCounsel}
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel || (!statesList || (statesList && statesList.length === 0))}
              value={provider.state}
              onValueChanged={e => onValueChanged(e.value, 'state')} />
          </div>
          <div className={styles.col2}>
            <TextBox
              label='Postal Code'
              placeholder={isNoOutsideCounsel ? PLACEHOLDER_NA : 'Postal Code'}
              name='zip'
              showClearButton
              isRequired={showIncompletes && !isNoOutsideCounsel}
              readOnly={isReadOnly}
              disabled={isNoOutsideCounsel}
              value={provider.zip}
              onValueChanged={e => onValueChanged(e.value, 'zip')} />
          </div>
        </>}
      </div>
      {!(isDNS && !isUSAorCAN) && <>
        <Title className='mtXL' type='h2' title='Primary Contact' />
        <p className='mbLg'>
          Provide contact information for the Primary Contact at the firm.
        </p>
        <div className={styles.innerGrid}>
          <div className={styles.col2}>
            <TextBox
              label='First Name'
              placeholder='First Name'
              name='primaryFirstName'
              {...(!isDNS && { isRequired: showIncompletes })}
              readOnly={isReadOnly}
              value={provider.primaryFirstName}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'primaryFirstName')} />
          </div>
          <div className={styles.col2}>
            <TextBox
              label='Middle Name'
              placeholder='Middle Name'
              name='primaryMiddleName'
              readOnly={isReadOnly}
              value={provider.primaryMiddleName}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'primaryMiddleName')} />
          </div>
          <div className={styles.col2}>
            <TextBox
              label='Last Name'
              placeholder='Last Name'
              name='primaryLastName'
              {...(!isDNS && { isRequired: showIncompletes })}
              readOnly={isReadOnly}
              value={provider.primaryLastName}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'primaryLastName')} />
          </div>
          <div className={styles.col3}>
            <DynamicPhoneInput
              label='Primary Phone Number'
              {...(!isDNS && { isRequired: showIncompletes })}
              readOnly={isReadOnly}
              phoneValue={provider.primaryContactPhone}
              countryCodeValue={provider.primaryContactCountryCode}
              countryCodeField='primaryContactCountryCode'
              phoneNumberField='primaryContactPhone'
              onValueChanged={onValueChanged} />
          </div>
          <div className={styles.col3}>
            <EmailField
              label='Primary Contact Email'
              placeholder='email@mail.com'
              name='primaryContactEmail'
              isRequired={showIncompletes}
              readOnly={isReadOnly}
              value={provider.primaryContactEmail}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'primaryContactEmail')} />
          </div>
          <div className={styles.col3}>
            <TitleSelect label='Primary Contact Title (optional)' appendTitles={['Relationship Manager']} value={provider.primaryContactTitle} onSelection={handleSelection} />
          </div>
          {isTransferAgent && <div className={cn(styles.col6, 'mtXL')}>
            <CheckBoxField
              readOnly={isReadOnly}
              value={provider.verifiedSharesAuth}
              isRequired={showIncompletes}
              text='The Company has authorized this transfer agent to provide to OTC Markets Group, upon its request, information related to the Company’s securities, including, but not limited to, shares authorized, shares issued and outstanding, and share issuance history.'
              onValueChanged={e => onValueChanged(e.value, 'verifiedSharesAuth')} />
          </div>}
        </div>
      </>}
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={isAdd ? 'Add' : 'Update'}
          onCancelClick={() => setSlidePane(false)}
          onSubmitClick={isAdd ? handleSubmit : handleUpdateProvider} />
      </div>}
    </div>
  );
};

export default AddServiceProvider;
