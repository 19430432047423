import React, { useState, useEffect, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import { validateCompleteDesignatedContact } from '../../../constants/cardComplete';
import { entities } from '../../../constants';
import SlidePanel from '../../SlidePanel';
import styles from '../Application.module.scss';
import EntityList from '../../EntityList';
import AddContact from './AddContact';

const FLAG = 'isPrimaryContact';

const contactObj = {
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  title: null
};

const PrimaryContactsTab = ({ appTypeName, peopleList, onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [state] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [contactView, setContactView] = useState(null);
  const contactTotal = peopleList.filter(person => person[FLAG]).length;
  const contactTotalTitle = `${contactTotal} out of 3 Primary Contacts`;
  const primaryContactList = peopleList.filter(person => person[FLAG]);
  const prevAddedList = peopleList.filter(person => !person[FLAG] && (person.isOfficer || person.isDirector || person.isAuthorizedUser || person.isPrimaryBillingContact));
  const isReadOnly = state.readOnly;
  let primaryTitle = `${contactView && contactView.firstName} ${contactView && contactView.lastName}`;
  const showIncompletes = state.showIncompletes;
  let existingContactAffiliation = null;

  if (!primaryTitle || primaryTitle.trim().length === 0) primaryTitle = entities.NO_NAME;

  if (contactView && contactView.isOfficer) existingContactAffiliation = 'Officer';
  if (contactView && contactView.isDirector) existingContactAffiliation = 'Director';
  if (contactView && contactView.isOfficer && contactView.isDirector) existingContactAffiliation = 'Officer, Director';

  useEffect(() => {
    if (!slidePane) setContactView(null);
  }, [slidePane]);

  const handleUpdate = contact => {
    onPersonUpdate(contact);
    setSlidePane(false);
  };

  const handleAdd = contact => {
    onPersonAdd(contact);
    setSlidePane(false);
  };

  const handleEdit = contact => {
    setContactView(contact);
    setSlidePane(true);
  };

  const handleRemove = (e, contact) => {
    e.stopPropagation();
    onPersonRemove(contact, FLAG);
  };

  const validateComplete = contact => {
    return validateCompleteDesignatedContact(contact, FLAG);
  };

  const entityTitle = contact => {
    const title = `${contact.firstName} ${contact.lastName}`;
    if (!title || title.trim().length === 0) return entities.NO_NAME;

    return title;
  };

  return (
    <div>
      <p className={styles.description}>
        Designate up to three primary contacts to respond to inquiries regarding this application and to receive important {appTypeName} communications, including compliance notices.
      </p>
      <EntityList
        title={contactTotalTitle}
        addTitle={contactTotal < 3 && 'Add Primary Contact'}
        showIncompleteList={showIncompletes && contactTotal < 1}
        showIncompletes={showIncompletes}
        incompleteMessage='One Primary Contacts is required.'
        icon={'user'}
        readOnly={isReadOnly}
        iconComplete={'userComplete'}
        list={primaryContactList}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        entityTitle={entityTitle}
        validateComplete={validateComplete}
        handleAddClick={() => setSlidePane(true)}
        handleEntityClick={handleEdit}
        handleEntityRemove={!isReadOnly && handleRemove} />
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title={contactView ? primaryTitle : 'Add Primary Contact'}
        subTitle={existingContactAffiliation && existingContactAffiliation}>
        <AddContact
          contactType='Primary Contact'
          prevAddDesc='Select an individual previously entered, or Add a New Primary Contact.'
          addNewDesc='Enter title and name of Primary Contact.'
          flag={FLAG}
          contactObj={contactObj}
          contactView={contactView}
          setContactView={setContactView}
          prevAddedList={prevAddedList}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          setSlidePane={setSlidePane} />
      </SlidePanel>
    </div>
  );
};

export default PrimaryContactsTab;
