import React from 'react';
import { changeInCtrl } from '../../constants';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';
import { format } from '../../utils/locale';

const ChangeInControl = ({ data, people }) => {
  const descArray = data && data.changeInControlDetail && data.changeInControlDetail.split(',');
  const description = descArray && descArray.map(desc => changeInCtrl[desc]).join(', ');
  const formerExecs = people && people.filter(person => person.isFormerExecutive);

  return (
    <>
      <View fixed>
        <SectionHeader text='CHANGE IN CONTROL' />
      </View>
      <Row>
        <Column size='75'>
          <Label text='QUESTION' />
          <Value text='Has the company undergone a Change in Control event?' />
        </Column>
        <Column size='25'>
          <Label text='Answer' />
          <Value text={data && format(data.changeInControl, null, 'bool')} />
        </Column>
      </Row>
      {data.changeInControl && <>
        <Row>
          <Column>
            <Label text='DESCRIPTION' />
            <Value text={description} />
          </Column>
        </Row>
        <SectionTitle text='COURT APPOINTED CUSTODIAN' />
        <Row>
          <Column size='75'>
            <Label text='QUESTION' />
            <Value text='Is there a Court Appointed Custodian?' />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={data && format(data.isCourtAppointedCustodian, null, 'bool')} />
          </Column>
        </Row>
        {data.changeInControl && <>
          <View fixed>
            <SectionTitle text='FORMER EXECUTIVES' />
          </View>
          {formerExecs && formerExecs.map((person, i) => {
            const personTitles = [];
            if (person.title1) personTitles.push(person.title1);
            if (person.title2) personTitles.push(person.title2);
            if (person.title3) personTitles.push(person.title3);
            if (person.title4) personTitles.push(person.title4);

            const titles = personTitles.join(', ');
            let pageBreak = i !== 0 && isInt(i / 4);

            return (
              <View break={pageBreak} key={`former-execs-${i}`}>
                <Row>
                  <Column size='50'>
                    <Label text='Name' />
                    <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
                  </Column>
                  <Column size='50'>
                    <Label text='Titles' />
                    <Value text={titles} />
                  </Column>
                </Row>
                <Row>
                  <Column size='100'>
                    <Label text='Phone Number' />
                    <Value text={person.phone} />
                  </Column>
                </Row>
                <Row>
                  <Column size='100'>
                    <Label text='Email' />
                    <Value text={person.email} />
                  </Column>
                </Row>
                <Divider />
              </View>
            );
          })}
          {(!formerExecs || formerExecs.length === 0) && <Value text={null} />}
        </>}
      </>}
    </>
  );
};

export default ChangeInControl;
