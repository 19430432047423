import React from 'react';
import { Svg, Path, Polygon } from '@react-pdf/renderer';

const LOGO_OTCQB = () => (
  <Svg style={{ width: 400, height: 400 }}>
    <Path class='st0' d='M155.4,6.2h8.9c4,0,6.1,1.7,6.1,4.5v0.1c0,3.2-2.6,4.8-6.6,4.8h-6.2v5.2h7.5c4.9,0,7.2,1.8,7.2,4.7v0.1
            c0,3.1-2.5,4.8-6.8,4.8h-21.2l-1.5-1.3c2.3-3.1,3.6-6.9,3.5-10.8v-0.1c0-10.1-8.2-18.3-18.4-18.2s-18.3,8.2-18.2,18.4v0.1
            c-0.1,9.8,7.7,17.9,17.5,18c0.2,0,0.5,0,0.7,0c2.6,0,5.3-0.5,7.7-1.6l-5-4.3c-0.9,0.2-1.7,0.3-2.6,0.3c-7,0-11.9-5.6-11.9-12.5v-0.1
            c0-6.8,4.9-12.4,11.8-12.4s11.9,5.6,11.9,12.5v0.1c0,2.3-0.6,4.6-1.8,6.5l-6-5.5l-4,4.5l13.6,11.9h23.8c7.8,0,13-3.4,13-9.6V26
            c0-4.8-2.9-7.1-7-8.5c2.6-1.4,5-3.6,5-7.8V9.7c0.1-2.2-0.8-4.3-2.4-5.8c-2-2-5.1-3.1-9.1-3.1h-15.7v26.8h6.1V6.2z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Polygon class='st0' points='69.1,19.5 62.6,19.5 63.6,16.6 70.1,16.6 ' />
    <Polygon class='st0' points='75.5,19.5 69.1,19.5 70.1,16.6 76.5,16.6 ' />
    <Polygon class='st0' points='82,19.5 75.5,19.5 76.5,16.6 83,16.6 ' />
    <Path class='st0' d='M50.3,3.3v13.4h6.1V3.3h1.8c7.6,0,9.5,1.3,9.9,5.5H70V0.7H36.8v8.1h1.9c0.4-4.2,2.7-5.5,9.8-5.5L50.3,3.3z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Path class='st0' d='M19.5,33.7c-6.1,0-12.1-5-12.9-14.2H0c0.6,10.2,8.9,16.9,18.9,16.9c10.1,0,18.2-6.6,18.9-16.9h-6.6
            C30.9,27.3,26.7,33.7,19.5,33.7z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Path class='st0' d='M18.2,2.7c8.1,0,12.2,7.1,12.8,14.1h6.6C37,6.3,28.4,0,19,0C10.1,0,0.8,5.5,0,16.7h6.6
            C6.8,10.4,10,2.7,18.2,2.7z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Path class='st0' d='M56.4,29v-9.5h-6.1V29c0,3.2-0.2,4.7-4.5,4.7h-1.6v2h18.2v-2h-1.6C57,33.7,56.4,32.7,56.4,29z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Path class='st0' d='M89.3,2.7c8.4,0,11.3,4.9,11.9,8.5h1.9v-9C98.6,0.9,94,0.2,89.3,0c-12.5,0-18.6,7.4-20,14.7h6.5
            C76.8,7.5,81.2,2.7,89.3,2.7z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    <Path class='st0' d='M89.4,36.3c4.6-0.1,9.2-0.8,13.7-2.2V25h-1.9c-1,5.4-4.6,8.6-11.9,8.6c-8.6,0-12.5-5.4-13.5-12.1h-6.5
            C70.6,28.7,76.4,36.3,89.4,36.3z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
  </Svg>
);

export default LOGO_OTCQB;
