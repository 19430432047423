import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { getUserDocs, uploadUserDoc } from '../../api/document';
import { AuthContext } from '../../context/Auth.context';
import { TypesContext } from '../../context/Types.context';
import { errors, storage, types } from '../../constants';
import { NotificationsContext } from '../../context/Notifications.context';
import Title from '../../components/Title';
import Table from '../../components/Table';
import AddNewDocument from '../../components/AddNewDocument';
import DropDownFilter from '../../components/DropDownFilter';
import SelectedFilters from '../../components/SelectedFilters';
import DocumentTooltip from '../../components/DocumentTooltip';
import SlidePanel from '../../components/SlidePanel';
import Entity from '../../components/Entity';
import styles from './MyDocuments.module.scss';

const columns = [
  {
    header: 'Document Type',
    field: 'typeName'
  },
  {
    header: 'Date Added',
    field: 'created',
    type: 'date'
  }
];

const MyDocuments = () => {
  const [authState] = useContext(AuthContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [typesState] = useContext(TypesContext);
  const [slidePane, setSlidePane] = useState(false);
  const [companyList, setCompanyList] = useState(null);
  const [companyFilters, setCompanyFilters] = useState([]);
  const [companySelected, setCompanySelected] = useState(null);
  const [symbolSelected, setSymbolSelected] = useState(null);
  const [docTypeFilters, setDocTypeFilters] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const userEmail = authState && authState.email;
  const hasDocuments = documentList && documentList.length > 0;

  useEffect(() => {
    getDocsList();
  }, []);

  useEffect(() => {
    !slidePane && setCompanySelected(null);
  }, [slidePane]);

  const onPanelClose = () => {
    setError(null);
    setIsUploading(false);
    setSlidePane(false);
  };

  const getTypeName = doc => {
    const docType = typesState.docTypes.find(type => type.id === doc.documentTypeId);
    let name = docType.description;

    if (docType.typeId === types.BG_CHECK) name = `${name} (${doc.attributes.individualNames.join(', ')})`;
    if (docType.typeId === types.ID) name = `${name} (${doc.attributes.fullName})`;
    if (docType.typeId === types.PERSONAL) name = `PIF (${doc.attributes.fullName})`;

    return name;
  };

  const getDocsList = () => {
    getUserDocs(userEmail)
      .then(data => {
        const docs = data.map(d => d && { ...d, typeName: getTypeName(d) });
        let docOptions = typesState.docTypes.map(type => {
          if (docs.find(doc => doc.documentTypeId === type.id)) {
            return { ...type, isSelected: true };
          } else {
            return type;
          }
        });
        const companyList = [...new Set(data.map(doc => doc.attributes.company))];
        const companyOptions = companyList.map(option => option && { Name: option, description: option, isSelected: true });
        sessionStorage.setItem(storage.DOCS_COMPANIES, JSON.stringify(companyOptions));
        setDocumentList(docs);
        setDocTypeFilters(docOptions);
        setCompanyList(companyList);
        setCompanyFilters(companyOptions);
      })
      .catch(e => setError(errors.TRY_AGAIN));
  };

  const handleDocumentSubmit = (file, docType, attributes) => {
    const userFullName = [authState.firstName, authState.middleName, authState.lastName];
    const uploaderName = userFullName.join(' ').replace(/\s+/g, ' ').trim();
    setIsUploading(true);
    uploadUserDoc(docType, userEmail, file, { ...attributes, uploaderName })
      .then(() => {
        const id = new Date().getTime();
        getDocsList();

        dispatchNotification({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: id,
            description: `Your document has been uploaded. ${moment().format('MMMM D, YYYY')}`,
            type: 'save'
          }
        });

        setTimeout(() => {
          dispatchNotification({
            type: 'REMOVE_NOTIFICATION',
            id: id
          });
        }, 3000);

        onPanelClose();
      })
      .catch(error => {
        setIsUploading(false);
        const id = new Date().getTime();
        const errorMsg = error && error.response && error.response.data && error.response.data.message;

        dispatchNotification({
          type: 'FAILED_SAVE_APPLICATION',
          payload: {
            id: id,
            description: `Error Uploading File${errorMsg ? ': ' + errorMsg : ''} ${moment().format('MMMM D, YYYY')}`,
            type: 'error'
          }
        });
        setError(errors.FILE_UPLOAD);
      });
  };

  const handleAddNewCompanyClick = (company, symbol) => {
    setCompanySelected(company);
    setSymbolSelected(symbol);
    setSlidePane(true);
  };

  // Sort company list ASC
  companyList && companyList.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  const onFilterItemClick = (value, selection, type) => {
    const isCompany = type === 'company';
    const filters = isCompany ? companyFilters : docTypeFilters;
    const setFunc = isCompany ? setCompanyFilters : setDocTypeFilters;

    const updatedOptions = filters.map(option => {
      if (option.description === selection.description) {
        return { ...option, isSelected: value };
      } else {
        return option;
      }
    });

    setFunc(updatedOptions);
  };

  const companyFilterItems = companyFilters.filter(item => item.isSelected).map(item => item && { ...item, title: 'Company', filterType: 'company' });
  const docTypeFilterItems = docTypeFilters.filter(item => item.isSelected).map(item => item && { ...item, title: 'Doc Type' });
  const showAllCompanies = !companyFilters.find(type => type.isSelected);
  const showAllDocTypes = !docTypeFilters.find(type => type.isSelected);

  const renderTooltip = ({ item }) => {
    const docType = typesState.docTypes.find(type => type.id === item.documentTypeId);
    const title = docType.description;

    return <DocumentTooltip title={title} attributes={item.attributes} />;
  };

  const handleHideTooltip = item => {
    const docType = typesState.docTypes.find(type => type.id === item.documentTypeId);
    if (docType.typeId === types.LOGO) return true;
    return false;
  };

  return (
    <div className={styles.container}>
      <Title className='mbLg' title='My Documents' />
      {hasDocuments && <div className={styles.filterContainer}>
        <div className={styles.filters}>
          <DropDownFilter
            title='Company'
            items={companyFilters}
            onItemClick={(val, selection) => onFilterItemClick(val, selection, 'company')}
          />
          <DropDownFilter
            title='Document Type'
            items={docTypeFilters}
            onItemClick={(val, selection) => onFilterItemClick(val, selection, 'document')}
          />
        </div>
        <SelectedFilters items={[...companyFilterItems, ...docTypeFilterItems]} onRemove={onFilterItemClick} max={2} />
      </div>}
      <section>
        {!hasDocuments && <Entity
          className='mbMed'
          title='No Documents Added'
          isEmpty />}
        <Entity
          title='Add New Document for New Company'
          isAdd
          onClick={() => setSlidePane(true)} />
      </section>
      {(hasDocuments && companyList) && companyList.map(company => {
        if (!showAllCompanies && !companyFilters.find(filter => filter.description === company && filter.isSelected)) return;
        const companyData = documentList.filter(doc => (docTypeFilters.find(type => type.id === doc.documentTypeId && type.isSelected) || showAllDocTypes) && !doc.isDeleted && doc.attributes.company === company).sort((a, b) => {
          if (a.dateAdded > b.dateAdded) {
            return -1;
          }
          if (a.dateAdded < b.dateAdded) {
            return 1;
          }
          return 0;
        });

        const hasData = companyData && companyData.length > 0;
        const docObj = documentList.find(doc => doc.attributes.company === company && doc.attributes.symbol);
        const symbol = docObj && docObj.attributes && docObj.attributes.symbol ? docObj.attributes.symbol : undefined;

        return (
          <section key={company}>
            <Title title={company} type='h2' />
            {hasData && <Table className='mtMed' columns={columns} data={companyData} tooltip={renderTooltip} hideTooltip={handleHideTooltip} />}
            {!hasData && <Entity className='mbLg' title={`No Documents to Show for ${company}`} isInactive />}
            <Entity
              className='mtSm'
              title={`Add New Document - ${company}`}
              isAdd
              onClick={() => handleAddNewCompanyClick(company, symbol)} />
          </section>
        );
      })}
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        title='Add New Document'>
        <AddNewDocument
          defaultCompany={companySelected}
          defaultSymbol={symbolSelected}
          setSlidePane={setSlidePane}
          errorMsg={error}
          isUploading={isUploading}
          onSubmit={handleDocumentSubmit} />
      </SlidePanel>
    </div>
  );
};

export default MyDocuments;
