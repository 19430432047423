import React, { useState, useContext } from 'react';
import { ApplicationContext } from '../../../context/Application.context';
import cn from 'classnames';
import TextBox from '../../TextBox';
import EmailField from '../../EmailField';
import DynamicPhoneInput from '../../DynamicPhoneInput';
import PreviousAdded from '../../PreviousAdded';
import Button from '../../Button';
import Controls from '../../Controls';
import CheckBoxField from '../../CheckBoxField';
import CompanyAffiliation from '../../CompanyAffiliation';
import styles from '../Application.module.scss';

const PREV_ADDED_VIEW = 'previousAdded';
const ADD_NEW_VIEW = 'addNew';

const AddContact = ({ contactView, contactObj, contactType, flag, addNewDesc, prevAddDesc, setContactView, prevAddedList, setSlidePane, handleAdd, handleUpdate }) => {
  const [state] = useContext(ApplicationContext);
  const previousAddView = !contactView && prevAddedList && prevAddedList.length > 0;
  const [view, setView] = useState(previousAddView ? PREV_ADDED_VIEW : ADD_NEW_VIEW);
  const [contact, setContact] = useState(contactView || contactObj);
  const [history, setHistory] = useState(null);
  const isContactViewExisting = contactView && (contactView.isOfficer || contactView.isDirector);
  const [isExistingContact, setExistingContact] = useState(isContactViewExisting);
  const isAdd = !contactView || (contactView && !contactView[flag]);
  const isAdditionalBillingContact = contactType === 'Additional Billing Contact';
  const isAccountPayable = contact.isAccountPayable || (contact.firstName === 'Accounts' && contact.lastName === 'Payable');
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;

  const onValueChanged = (value, field) => {
    let val = value;

    setContact(prevState => {
      return {
        ...prevState,
        [field]: val
      };
    });

    if (field === 'isAccountPayable') {
      if (value === true) {
        setContact(prevState => {
          return {
            ...prevState,
            firstName: 'Accounts',
            middleName: null,
            lastName: 'Payable',
            titles: null
          };
        });
      } else {
        setContact(prevState => {
          return {
            ...prevState,
            firstName: null,
            lastName: null
          };
        });
      }
    }
  };

  const handleUpdateContact = () => {
    const newContact = contact;
    if (!newContact[flag]) newContact[flag] = true;
    handleUpdate(newContact);
  };

  const handleSelect = person => {
    const newContact = person;
    setHistory(PREV_ADDED_VIEW);
    setContact(newContact);
    setContactView(newContact);
    setExistingContact(true);
    setView(ADD_NEW_VIEW);
  };

  const handleSubmit = () => {
    handleAdd({ ...contact, [flag]: true });
  };

  const handleAddNew = () => {
    setHistory(PREV_ADDED_VIEW);
    setView(ADD_NEW_VIEW);
  };

  const handleCancel = () => {
    history === PREV_ADDED_VIEW && prevAddedList && prevAddedList.length > 0 ? setView(PREV_ADDED_VIEW) : setSlidePane(false);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        {prevAddDesc}
      </p>
      <PreviousAdded
        listTitle='List of Individuals Previously Entered'
        items={prevAddedList}
        handleSelect={handleSelect} />
      <div className='mtMed'>
        <Button
          title={`Add New ${contactType}`}
          buttonType='secondary'
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={cn('mtXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePane(false)} />
      </div>
    </div>
  );

  const renderAddNew = () => (
    <div>
      {!isExistingContact && <p className={styles.description}>
        {addNewDesc}
      </p>}
      <div className={styles.innerGrid}>
        {(!isExistingContact && isAdditionalBillingContact) && <div className={styles.col6}>
          <CheckBoxField
            readOnly={isReadOnly}
            value={isAccountPayable}
            text='This is an Accounts Payable Contact.'
            onValueChanged={e => onValueChanged(e.value, 'isAccountPayable')} />
        </div>}
        {!isExistingContact && <div className={styles.col2}>
          <TextBox
            label='First Name'
            placeholder='First Name'
            name='firstName'
            readOnly={isReadOnly || contact.isPreExisting}
            value={isAccountPayable ? 'N/A' : contact.firstName}
            disabled={isAccountPayable}
            isRequired={showIncompletes}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'firstName')} />
        </div>}
        {!isExistingContact && <div className={styles.col2}>
          <TextBox
            label='Middle Name'
            placeholder='Middle Name'
            name='middleName'
            readOnly={isReadOnly || contact.isPreExisting}
            value={isAccountPayable ? 'N/A' : contact.middleName}
            disabled={isAccountPayable}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'middleName')} />
        </div>}
        {!isExistingContact && <div className={styles.col2}>
          <TextBox
            label='Last Name'
            placeholder='Last Name'
            name='lastName'
            readOnly={isReadOnly || contact.isPreExisting}
            value={isAccountPayable ? 'N/A' : contact.lastName}
            disabled={isAccountPayable}
            isRequired={showIncompletes}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'lastName')} />
        </div>}
        <p className={cn('mbNone', styles.col6, {
          'mtNone': isExistingContact
        })}>
          {isExistingContact ? `Enter phone number and email for ${contactType}.`
            : `Enter phone number, email and company affiliation for ${contactType}.`}
        </p>
        <div className={styles.col3}>
          <DynamicPhoneInput
            label='Phone Number'
            readOnly={isReadOnly}
            phoneValue={contact.phone}
            isRequired={showIncompletes}
            countryCodeValue={contact.phoneCountryCode}
            countryCodeField='phoneCountryCode'
            phoneNumberField='phone'
            onValueChanged={onValueChanged} />
        </div>
        <div className={styles.col3}>
          <EmailField
            label='Email'
            placeholder='email@mail.com'
            name='email'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={contact.email}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'email')} />
        </div>
        {!isExistingContact && <div className={styles.col3}>
          <CompanyAffiliation
            label='Company Affiliation'
            isRequired={showIncompletes}
            disabled={isAccountPayable}
            readOnly={isReadOnly}
            value={contact.title1 && contact.title1}
            setCompanyAffilation={value => onValueChanged(value, 'title1')} />
        </div>}
      </div>
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={isAdd ? 'Add' : 'Update'}
          onCancelClick={handleCancel}
          onSubmitClick={contactView ? handleUpdateContact : handleSubmit} />
      </div>}
    </div>
  );

  return (
    <>
      {view === PREV_ADDED_VIEW && renderPreviouslyAdded()}
      {view === ADD_NEW_VIEW && renderAddNew()}
    </>
  );
};

export default AddContact;
