import React from 'react';
import Row from './Row';
import cn from 'classnames';
import styles from './Table.module.scss';

const Table = ({ className, columns, data, tooltip, hideTooltip }) => (
  <table className={cn(className, styles.container)}>
    <thead>
      <tr>
        {columns && columns.map((col, i) => <th key={i} className={styles.cell}>{col.header}</th>)}
      </tr>
    </thead>
    <tbody>
      {data && data.map((item, i) => (
        <Row key={i} item={item} columns={columns} tooltip={tooltip} hideTooltip={hideTooltip} />
      ))}
    </tbody>
  </table>
);

export default Table;
