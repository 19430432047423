import React, { useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import CreditCardTextBox from '../../CreditCardTextBox';
import { controls, countries, errors, paymentTypes, SECURE_PAY_DESC, CC_PAY_DESC } from '../../../constants';
import isEmail from 'validator/lib/isEmail';
import { isRequiredCountry, creditCardInfoComplete, checkZipCode, validCvvNumber } from '../../../constants/cardComplete';
import { getCreditCardExpiryMonthYear } from '../../../utils/helper';
import { monthsList } from '../../../constants/months';
import { futureYearsList } from '../../../constants/years';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import Footnote from '../../Footnote';
import Controls from '../../Controls';
import { ApplicationContext } from '../../../context/Application.context';
import { TypesContext } from '../../../context/Types.context';
import Title from '../../Title';
import Label from '../../Label';
import appStyles from '../Application.module.scss';

const US = countries.US;
const UNITED_STATES = countries.UNITED_STATES;

const cvvNumberError = 'Incomplete Data. CVV Number should be of 3 or 4 digits';

const CreditCardPayment = ({ payment, closeSlidePane, paymentError, handleAddPayment }) => {
  const DEFAULT_CREDIT_CARD_PAYMENT = { ...payment, ccNumber: payment.ccNumber || '', ccError: payment.ccError || false, address: payment.address || { country: US, countryName: UNITED_STATES } };
  const [state, dispatch] = useContext(ApplicationContext);
  const [typesState] = useContext(TypesContext);
  const countryList = (typesState && typesState.codes && typesState.codes.countries) || [];
  const statesList = (typesState && typesState.codes && typesState.codes.states) || [];
  const [readyToPay, setReadyToPay] = useState(false);
  const [ccFocus, setCCFocus] = useState(false);
  const [creditCardPaymentInfo, setCreditCardPaymentInfo] = useState({ ...DEFAULT_CREDIT_CARD_PAYMENT });
  const [showRequired, setShowRequired] = useState(false);
  const showIncompletes = state.showIncompletes;
  const address = creditCardPaymentInfo.address;
  const countryAddress = address && address.country;
  const expiresInfo = getCreditCardExpiryMonthYear(creditCardPaymentInfo.month, creditCardPaymentInfo.year);

  const onValueChanged = (value, field) => {
    setCreditCardPaymentInfo(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const onAddressChanged = (value, field) => {
    const updateAddress = { ...address };

    updateAddress[field] = value;

    const isAddressCountry = field === 'country';

    if (isAddressCountry) {
      const countryName = countryList.find(country => country.id === value).name;
      updateAddress.countryName = countryName;
      if (updateAddress.state && !billingStatesList.find(state => state.countryId === value)) {
        updateAddress.state = null;
      }
    }

    setCreditCardPaymentInfo(prevState => {
      return {
        ...prevState,
        address: updateAddress
      };
    });
  };

  const handleCancel = () => {
    if (readyToPay) {
      setReadyToPay(false);
    } else {
      closeSlidePane();
    }
  };

  const handleAdd = () => {
    setShowRequired(true);
    if (creditCardInfoComplete(creditCardPaymentInfo) && !creditCardPaymentInfo.ccError) {
      let paymentInfo = { ...creditCardPaymentInfo };
      paymentInfo.ccNumber = paymentInfo.ccNumber ? paymentInfo.ccNumber.replace(/\s+/g, '') : '';
      paymentInfo.expiresInfo = expiresInfo;
      paymentInfo.paymentType = paymentTypes.CREDIT;
      if (paymentInfo.address && paymentInfo.address.state) {
        const stateInfo = billingStatesList.find(state => state.id === paymentInfo.address.state);
        paymentInfo.address.stateName = stateInfo ? stateInfo.name : '';
      }

      handleAddPayment(paymentInfo);
    }
  };

  const handleCCFocus = () => {
    setCCFocus(!ccFocus);
  };

  const billingStatesList = statesList.filter(state => {
    let country = (countryAddress && countryList.find(country => country.id === countryAddress));
    return state.countryId === country.id;
  });

  return (
    <div>
      <div>
        <p>{SECURE_PAY_DESC}</p>
        <p>{CC_PAY_DESC}</p>
        <div className={cn(appStyles.formSection, appStyles.innerGrid)}>
          <div className={cn(appStyles.col3, appStyles.fieldGroup)}>
            <CreditCardTextBox
              label='Credit Card Number'
              placeholder='XXXX XXXX XXXX XXXX'
              name='ccNumber'
              isValid={showIncompletes || !!(!ccFocus && creditCardPaymentInfo.ccNumber && creditCardPaymentInfo.ccError)}
              value={creditCardPaymentInfo.ccNumber}
              error={!!creditCardPaymentInfo.ccError}
              errorText={(creditCardPaymentInfo.ccNumber && !!creditCardPaymentInfo.ccError) ? errors.INVALID_CC_NUMBER : null}
              onError={err => onValueChanged(err, 'ccError')}
              onFocusIn={handleCCFocus}
              onFocusOut={handleCCFocus}
              onMetaChanged={cardType => onValueChanged(cardType ? cardType.displayName : 'Credit Card', 'ccType')}
              onValueChanged={e => onValueChanged(e.value, 'ccNumber')}
            />
          </div>
          <div className={appStyles.col3} />
          <div className={cn(appStyles.col3, appStyles.fieldGroup)}>
            <TextBox
              label={'Cardholder\'s Full Name'}
              placeholder='Name'
              name='fullName'
              showClearButton
              value={creditCardPaymentInfo.fullName}
              isRequired={showIncompletes || showRequired}
              onValueChanged={e => onValueChanged(e.value, 'fullName')}
            />
            <TextBox
              label={'Cardholder\'s Email'}
              placeholder='Email'
              name='email'
              showClearButton
              value={creditCardPaymentInfo.email}
              isRequired={showIncompletes || showRequired}
              isValid={creditCardPaymentInfo.email ? isEmail(creditCardPaymentInfo.email) : true}
              onValueChanged={e => onValueChanged(e.value, 'email')}
            />
          </div>
          <div className={appStyles.col3} />
          <div className={cn(appStyles.col2, appStyles.fieldGroup)}>

            <SelectBox
              label='Month Expire'
              name='month'
              placeholder='MM'
              valueExpr='id'
              displayExpr='twoDigitId'
              items={monthsList}
              isRequired={showIncompletes || showRequired}
              value={creditCardPaymentInfo.month}
              onValueChanged={e => onValueChanged(e.value, 'month')} />
          </div>
          <div className={cn(appStyles.col2, appStyles.fieldGroup)}>

            <SelectBox
              label='Year Expire'
              name='year'
              placeholder='YYYY'
              items={futureYearsList}
              isRequired={showIncompletes || showRequired}
              value={creditCardPaymentInfo.year}
              onValueChanged={e => onValueChanged(e.value, 'year')} />
          </div>
          <div className={appStyles.col2} />

          <div className={appStyles.col3}>
            <TextBox
              label='CVV'
              placeholder='XXX'
              name='cvv'
              value={creditCardPaymentInfo.cvv}
              isRequired={showIncompletes || showRequired}
              isValid={creditCardPaymentInfo.cvv ? validCvvNumber(creditCardPaymentInfo.cvv) : true}
              errorText={cvvNumberError}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'cvv')} />
            <Footnote text={() => <>This is usually found on the back of your card</>} />
          </div>
        </div>
        <Title title={'Cardholder\'s Address'} type='h2' />

        <div className={cn(appStyles.formSection, appStyles.innerGrid)}>
          <div className={cn(appStyles.col6, appStyles.fieldGroup)}>
            <TextBox
              label='Address'
              placeholder='Address 1'
              name='address1'
              showClearButton
              isRequired={showIncompletes || showRequired}
              value={address.address1}
              onValueChanged={e => onAddressChanged(e.value, 'address1')} />
            <TextBox
              placeholder='Address 2'
              name='address2'
              showClearButton
              value={address.address2}
              onValueChanged={e => onAddressChanged(e.value, 'address2')} />
          </div>
          <div className={appStyles.col3}>
            <SelectBox
              label='Country'
              name='country'
              placeholder='Select Country'
              valueExpr='id'
              displayExpr='name'
              items={countryList}
              isRequired={showIncompletes || showRequired}
              value={countryAddress}
              onValueChanged={e => onAddressChanged(e.value, 'country')} />
          </div>
          <div className={appStyles.col3} />
          <div className={appStyles.col2}>
            <TextBox
              label='City'
              placeholder='City'
              name='city'
              showClearButton
              isRequired={showIncompletes || showRequired}
              value={address.city}
              onValueChanged={e => onAddressChanged(e.value, 'city')} />
          </div>
          <div className={appStyles.col2}>
            <SelectBox
              label='State/Province'
              name='state'
              placeholder='Select State'
              valueExpr='id'
              displayExpr='name'
              searchExpr={['id', 'name']}
              disabled={!billingStatesList || (billingStatesList && billingStatesList.length === 0)}
              items={billingStatesList}
              isRequired={showIncompletes && (countryAddress && isRequiredCountry(countryAddress)) || showRequired}
              value={address.state}
              onValueChanged={e => onAddressChanged(e.value, 'state')} />
          </div>
          <div className={appStyles.col2}>
            <TextBox
              label='Postal Code'
              placeholder='Postal Code'
              name='zip'
              showClearButton
              isRequired={showIncompletes || showRequired}
              isValid={address.zip ? checkZipCode(countryAddress, address.zip) : true}
              value={address.zip}
              onValueChanged={e => onAddressChanged(e.value, 'zip')} />
          </div>
        </div>
      </div>
      <div className={cn('mtXXL', appStyles.controls)}>
        <Controls
          cancelText={controls.CANCEL}
          submitText={controls.ADD_PAYMENT}
          onCancelClick={handleCancel}
          onSubmitClick={handleAdd} />
        {paymentError && <Label className='mtLg' isError title={paymentError} />}
      </div>
    </div>
  );
};

CreditCardPayment.propTypes = {
  payment: PropTypes.object,
  handleAddPayment: PropTypes.func,
  paymentError: PropTypes.any,
  closeSlidePane: PropTypes.func
};

export default CreditCardPayment;
