import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextBox from '../TextBox';
import { isEmail } from '../../utils/validations';

const EmailField = ({ label, name, placeholder, readOnly, isRequired, value, disabled, onValueChanged, showClearButton }) => {
  const isEmailValid = isEmail(value);

  return (
    <TextBox
      label={label}
      placeholder={placeholder}
      name={name}
      readOnly={readOnly}
      disabled={disabled}
      showClearButton={showClearButton}
      isRequired={isRequired}
      isValid={isEmailValid}
      errorText={!isEmailValid && 'Invalid Email Format.'}
      value={value}
      onValueChanged={onValueChanged} />
  );
}
;

EmailField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  showClearButton: PropTypes.bool,
  value: PropTypes.string,
  onValueChanged: PropTypes.func
};

EmailField.defaultProps = {
  label: 'Email',
  name: 'email',
  placeholder: 'email@mail.com'
};

export default EmailField;
