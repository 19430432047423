import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './FieldLabel.module.scss';

const FieldLabel = ({ text, size }) => {
  return <label className={cn(styles.container, styles[size])}>
    {text}
  </label>;
};

FieldLabel.defaultProps = {
  size: 'small'
};

FieldLabel.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big'])
};

export default FieldLabel;
