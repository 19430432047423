import React from 'react';
import PropTypes from 'prop-types';
import { errors } from '../../constants';
import { filesize } from 'filesize';
import Entity from '../Entity';

const ChooseFile = ({ title, file, accept, accepList, isInactive, setFile, setError }) => {
  const hiddenFileInput = React.useRef(null);

  const fileChangedHandler = e => {
    const file = e && e.target && e.target.files[0] && e.target.files[0];
    const fileType = file && file.type;

    if (!accepList.includes(fileType)) {
      setError(errors.FILE_NOT_SUPPORTED);
      return;
    }

    if (file && file > 100000000) {
      setError(errors.FILE_SIZE);
      return;
    }

    setFile(e.target.files[0]);
  };

  const handleAddClick = () => {
    setError(null);
    hiddenFileInput.current.click();
  };

  const handleRemoveClick = () => setFile(false);

  return <>
    {!file && <>
      <input
        type='file'
        onChange={fileChangedHandler}
        ref={hiddenFileInput}
        accept={accept && accept}
        style={{ display: 'none' }} />
      <Entity
        title={title || 'Upload Document'}
        isAdd
        isInactive={isInactive}
        onClick={handleAddClick} />
    </>}
    {file &&
      <Entity
        title={file && file.name}
        subTitle={file.size && filesize(file.size, { base: 10 })}
        handleRemove={handleRemoveClick} />
    }
  </>;
};

ChooseFile.propTypes = {
  title: PropTypes.string,
  isInactive: PropTypes.bool,
  file: PropTypes.object,
  accept: PropTypes.string,
  accepList: PropTypes.array,
  setError: PropTypes.func
};

export default ChooseFile;
