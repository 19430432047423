import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { getUserApplications, cancelApplication } from '../../api/application';
import { AuthContext } from '../../context/Auth.context';
import { TypesContext } from '../../context/Types.context';
import Title from '../../components/Title';
import Entity from '../../components/Entity';
import DropDownFilter from '../../components/DropDownFilter';
import SelectedFilters from '../../components/SelectedFilters';
import Label from '../../components/Label';
import ApplicationAttribute from '../../components/ApplicationAttribute';
import { appStates, storage, routes, phone_numbers, emails } from '../../constants';
import { getFilteredCompanyList } from '../../utils/helper';
import styles from './MyApplications.module.scss';

const intlCountryCodeUserMsg = <p>Gateway now requires users to enter a Country Code in front of every phone number. If you have an application in progress and did not enter a Country Code with a phone number, you will now need to enter a <b>Country Code</b> and hit <b>Save</b>. If you run into any problems with this feature, please call Issuer Services at {phone_numbers.ISSUER_SERVICES} or email <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</p>;
const intlCountryCodeUserMsgExpires = moment('2022-12-01', 'YYYY-MM-DD');

const MyApplications = () => {
  const [authState] = useContext(AuthContext);
  const [typesState] = useContext(TypesContext);
  const [filters, setFilters] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const userEmail = authState.email;

  useEffect(() => {
    if (typesState.isLoaded) {
      const applicationStates = typesState.appStates.map(item => {
        if (item.stateId === appStates.IN_PROGRESS || item.stateId === appStates.SUBMITTED) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      });
      setFilters(applicationStates);
      getUserApplications(userEmail)
        .then(data => {
          const companyList = getFilteredCompanyList(data);

          sessionStorage.setItem(storage.APP_COMPANIES, JSON.stringify(companyList));
          setApplicationList(data);
        })
        .catch(e => console.log('e', e));
      if (moment().isBefore(intlCountryCodeUserMsgExpires)) {
        setShowMsg(true);
      }
    }
  }, [typesState.isLoaded]);

  const inProgressApps = applicationList && applicationList.filter(app => app.status === appStates.IN_PROGRESS);
  inProgressApps.sort((a, b) => {
    if (a.lastUpdated > b.lastUpdated) {
      return -1;
    }
    if (a.lastUpdated < b.lastUpdated) {
      return 1;
    }
    return 0;
  });

  const otherApps = applicationList && applicationList.filter(app => app.status !== appStates.IN_PROGRESS);
  otherApps.sort((a, b) => {
    if (a.lastUpdated > b.lastUpdated) {
      return -1;
    }
    if (a.lastUpdated < b.lastUpdated) {
      return 1;
    }
    return 0;
  });

  const sortedApplications = [...inProgressApps, ...otherApps];
  const showAllApplications = !filters.find(filter => filter.isSelected);
  const filteredList = sortedApplications.filter(app => showAllApplications || filters.find(filter => filter.stateId === app.status).isSelected);
  const hasApplications = applicationList && applicationList.length > 0 && filteredList.length > 0;

  const onItemClick = (value, selection) => {
    const updatedOptions = filters.map(option => {
      if (option.description === selection.description) {
        return { ...option, isSelected: value };
      } else {
        return option;
      }
    });

    setFilters(updatedOptions);
  };

  const handleCancel = item => {
    cancelApplication(item.id, userEmail)
      .then(data => {
        const updatedList = applicationList.map(app => {
          if (app.id === item.id) {
            app.status = 'CANCELED';
            return app;
          } else {
            return app;
          }
        });
        setApplicationList(updatedList);
      })
      .catch(error => console.log('ERROR CANCELLING'));
  };

  const statusFilterItems = filters.filter(item => item.isSelected).map(item => item && { ...item, title: 'Status' });

  return (
    <section className={styles.container}>
      <Title className='mbLg' title='My Applications' />
      {showMsg && <Label className='mbLg' title={intlCountryCodeUserMsg} />}
      <div>
        {typesState && typesState.appStates && <div className={styles.filter}>
          <DropDownFilter
            title='Status'
            items={filters}
            onItemClick={onItemClick}
          />
          <SelectedFilters items={statusFilterItems} onRemove={onItemClick} max={2} />
        </div>}
        {!hasApplications && <Entity className='mbLg' title='No Applications to Show' isInactive />}
        {filteredList.map(item => <ApplicationAttribute key={item.id} item={item} onCancelClick={handleCancel} />)}
        <Link to={routes.START_APPLICATION}>
          <Entity
            title='Start New Application'
            isAdd />
        </Link>
        {/* APP LIST */}
      </div>
    </section>
  );
};

export default MyApplications;
