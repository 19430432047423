import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './EntityTitle.module.scss';

const EntityTitle = ({ className, title, size }) => {
  return <div className={cn(className, styles.container, styles[size], styles.centerAlign)}>
    <span className={styles.title}>
      {title}
    </span>
  </div>;
};

EntityTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

EntityTitle.defaultProps = {
  size: 'large'
};

export default EntityTitle;
