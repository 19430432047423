import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isString } from '../../../utils/validations';
import { ApplicationContext } from '../../../context/Application.context';
import TextArea from '../../TextArea';
import ApplicationDescription from '../../ApplicationDescription';
import CheckBoxField from '../../CheckBoxField';
import Title from '../../Title';
import Label from '../../Label';

const questions = [
  {
    title: 'The Company, its Predecessors, and Subsidiaries',
    subTitle: 'Provide disclosure for any proceedings within the past 10 years, or indicate there were no proceedings.',
    checkboxText: 'There were no proceedings in the past 10 years.',
    checkFieldValue: 'companyLegalHistoryPastTenYears',
    textFieldValue: 'companyLegalHistoryPastTenYearsDesc',
    placeholder: 'Describe legal proceedings involving the Company.'
  },
  {
    title: 'Past Officers and Directors of the Company, its Predecessors, and Subsidiaries.',
    subTitle: 'Provide disclosure for proceedings within the past 5 years related to individuals who served as Officers and/or Directors of the Company, its predecessors and subsidiaries, during the time the individual was employed by the Company. Indicate none if there were no proceedings.',
    checkboxText: 'There were no proceedings in the past 5 years.',
    checkFieldValue: 'companyOfficerLegalHistoryPastFiveYears',
    textFieldValue: 'companyOfficerLegalHistoryPastFiveYearsDesc',
    placeholder: 'Describe legal proceedings involving past officers/directors.'
  }
];

const LegalDisciplinaryHistory = ({ name }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const application = state.application;
  const legalHistory = application.legalHistory = { ...application.legalHistory };
  const appTypeName = application.typeDesc;
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'legalHistory',
      card: 'legal-disciplinary-history'
    });
  };

  const onValueChanged = (e, field) => {
    let updateLegalHistory = legalHistory;
    updateLegalHistory[field] = e.value;
    onUpdate(updateLegalHistory);
  };

  const renderQuestions = (q, key) => {
    const isChecked = legalHistory && legalHistory[q.checkFieldValue] === true || legalHistory[q.checkFieldValue] === 'true';
    const placeholder = isChecked ? 'N/A' : q.placeholder;
    const showIncompleteField = showIncompletes && (!legalHistory[q.checkFieldValue] || legalHistory && legalHistory[q.checkFieldValue] === 'false');
    return (
      <div key={key} className='mtXL'>
        <Title title={q.title} type='h2' />
        <p>
          {q.subTitle}
        </p>
        <CheckBoxField
          readOnly={isReadOnly}
          value={legalHistory && legalHistory[q.checkFieldValue] === true || legalHistory[q.checkFieldValue] === 'true'}
          text={q.checkboxText}
          onValueChanged={e => onValueChanged(e, q.checkFieldValue)} />
        <div className='mtMed'>
          <TextArea
            label='Description of Proceedings'
            showClearButton
            placeholder={placeholder}
            name='description'
            height={80}
            maxLength={3000}
            disabled={isChecked}
            isValid={showIncompleteField ? isString(legalHistory && legalHistory[q.textFieldValue]) : true}
            readOnly={isReadOnly}
            value={!isChecked && legalHistory ? legalHistory[q.textFieldValue] : undefined}
            onValueChanged={e => onValueChanged(e, q.textFieldValue)} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc='Provide a detailed description of any current, past, pending or threatened legal proceeding (e.g. investigations, lawsuits, litigation, arbitration, hearings, or any other legal or administrative proceedings) for each of the following:' />
      {questions.map((q, i) => renderQuestions(q, i))}
      <Title className='mtXL' title='Current Officers, Directors and Control Persons' type='h2' />
      <p>
        The legal and disciplinary history of current Officers, Directors and Control Persons must be provided in Section 12: Background Check Authorization.
      </p>
      <Label className='mtXL' title={`Failure to disclose any of the foregoing may result in the denial of this ${appTypeName} Application. In connection with the response above, OTC Markets Group may request additional information, such as official documents, letters, and orders.`} />
    </div>
  );
};

LegalDisciplinaryHistory.propTypes = {
  name: PropTypes.string
};

export default LegalDisciplinaryHistory;
