import React, { useContext, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { signUp, getExistingIQUser, resendAwsVerify } from '../../api/auth';
import { routes, errors, awsCodes } from '../../constants';
import { isEmail, isPhone } from '../../utils/validations';
import { AuthContext } from '../../context/Auth.context';
import { TypesContext } from '../../context/Types.context';
import AuthWrapper from '../../components/AuthWrapper';
import Button from '../../components/Button';
import EmailField from '../../components/EmailField';
import TextBox from '../../components/TextBox';
import WithRouter from '../../components/WithRouter';
import FieldError from '../../components/FieldError';
import PasswordRequirements from '../../components/PasswordRequirements';
import PhoneInput from '../../components/PhoneInput';
import Footnote from '../../components/Footnote';
import Label from '../../components/Label';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import styles from './CreateAccountPage.module.scss';

window.recaptchaOptions = {
  useRecaptchaNet: true
};

const IQ_EMAIL_IN_USE = <>{errors.SIGN_UP_EMAIL_IN_USE} There is an account already associated with this email for OTCIQ. Please <Link to={routes.SIGNIN}>Sign In</Link> with those credentials.</>;
const AWS_EMAIL_IN_USE = <>{errors.SIGN_UP_EMAIL_IN_USE} Please <Link to={routes.SIGNIN}>Sign In</Link> with your user credentials associated with this email or go to the link sent to you to create a new account.</>;

const userObj = {
  firstName: null,
  lastName: null,
  fullName: null,
  email: null,
  initials: null,
  password: null,
  phoneCode: 'US',
  phone: null
};

const CREATE = 'create';
const SUCCESS = 'success';

const CreateAccountPage = props => {
  const [user, setUser] = useState(userObj);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [view, setView] = useState(CREATE);
  const [error, setError] = useState(null);
  const [resendConfirm, setResendConfirm] = useState(null);
  const [resendError, setResendError] = useState(null);
  const [isCaptcha, setCaptcha] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorEmailMismatch, setEmailMismatchError] = useState(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [showRequired, setShowRequired] = useState(false);
  const [isCaptchaHover, setCaptchaHover] = useState(false);
  const [authState, dispatch] = useContext(AuthContext);
  const [typesState] = useContext(TypesContext);
  const isCreateView = view === CREATE;
  const isSuccessView = view === SUCCESS;
  let authWrapperTitle;
  if (isCreateView) authWrapperTitle = 'Create New Account';
  if (isSuccessView) authWrapperTitle = '';

  const passwordButton = {
    icon: passwordMode === 'text' ? '/images/icons/eye-regular.svg' : '/images/icons/eye-slash-regular.svg',
    type: 'default',
    onClick: () => {
      passwordMode === 'text' ? setPasswordMode('password') : setPasswordMode('text');
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) props.navigate(routes.MY_APPLICATIONS);
  }, [authState]);

  const onValueChanged = (value, field) => {
    setUser(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleCaptcha = value => {
    value && setCaptcha(true);
    !value && setCaptcha(false);
  };

  const handleCreateAccount = () => {
    setDisableSubmitBtn(true);
    if (error) setError(null);
    if (errorEmailMismatch) setEmailMismatchError(null);
    if (passwordError) setPasswordError(false);
    if (showRequired) setShowRequired(false);
    const incompleteFields = !isCaptcha || !user.email || !user.confirmEmail || !user.firstName || !user.lastName || !user.password || !user.phone;
    const mismatchEmails = user.email && user.confirmEmail && (user.email !== user.confirmEmail);
    const isValidEmails = isEmail(user.email);
    const isValidPhone = isPhone(user.phone);
    if (incompleteFields || mismatchEmails || !isValidEmails || !isValidPhone) {
      if (incompleteFields || !isValidEmails || !isValidPhone) setError(errors.SIGN_UP_INCOMPLETE);
      if (mismatchEmails) setEmailMismatchError(errors.SIGN_UP_EMAIL_MISMATCH);
      setShowRequired(true);
      setDisableSubmitBtn(false);
      return;
    }

    getExistingIQUser(user.email)
      .then(() => {
        setError(IQ_EMAIL_IN_USE);
        setDisableSubmitBtn(false);
      })
      .catch(() => createCognitoUser());
  };

  const createCognitoUser = () => {
    let phoneCode = typesState && typesState.codes && typesState.codes.countries && typesState.codes.countries.find(c => c.id === user.phoneCode);
    phoneCode = phoneCode && phoneCode.phoneCode && phoneCode.phoneCode.replace(/[^0-9]+/g, '');
    const userObj = {
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
        family_name: user.lastName,
        given_name: user.firstName,
        phone_number: `+${phoneCode || '1'}${user.phone}`
      }
    };

    if (user.middleName) {
      userObj.attributes.middle_name = user.middleName;
    }

    signUp(userObj)
      .then(() => {
        setView(SUCCESS);
        setDisableSubmitBtn(false);
      })
      .catch(e => {
        if (e && e.code && e.code === awsCodes.SIGN_UP_INVALID_PWD) setPasswordError(true);
        if (e && e.code && e.code === awsCodes.SIGN_UP_USER_EXIST) {
          setError(AWS_EMAIL_IN_USE);
        } else {
          const errorMsg = <>
            {e && e.message} {errors.SIGN_UP_USER_AWS_FAIL}
          </>;
          setError(errorMsg);
        }
        setDisableSubmitBtn(false);
      });
  };

  const handleResendVerify = () => {
    resendAwsVerify(user.email)
      .then(d => {
        setResendConfirm(`Verification email has been resent to ${d.CodeDeliveryDetails.Destination}`);
      }).catch(() => setResendError(errors.TRY_AGAIN));
  };

  const handleCaptchaEnter = () => {
    setCaptchaHover(true);
  };

  const handleCaptchaLeave = () => {
    setCaptchaHover(false);
  };

  const isEmailMatch = user.email === user.confirmEmail;

  return (
    <AuthWrapper title={authWrapperTitle}>
      {isCreateView && <div className={styles.form}>
        <div className={cn('mtMed', {
          [styles.incomplete]: showRequired && !isEmailMatch
        })}>
          <EmailField
            label='Email'
            name='email'
            isRequired={showRequired}
            value={user.email}
            onValueChanged={e => onValueChanged(e.value, 'email')} />
        </div>
        <div className={cn('mtMed', {
          [styles.incomplete]: showRequired && !isEmailMatch
        })}>
          <EmailField
            label='Confirm Email'
            name='confirm-email'
            value={user.confirmEmail}
            isRequired={showRequired}
            onValueChanged={e => onValueChanged(e.value, 'confirmEmail')} />
        </div>
        <div className='mtMed'>
          <TextBox
            label='Password'
            placeholder='At least 14 characters'
            name='password'
            value={user.password}
            mode={passwordMode}
            isRequired={showRequired}
            valueChangeEvent='keyup'
            onValueChanged={e => onValueChanged(e.value, 'password')}>
            <TextBoxButton
              name='password'
              location='after'
              options={passwordButton}
            />
          </TextBox>
          <PasswordRequirements className='mtMed' value={user.password} isError={passwordError} />
        </div>
        <div className='mtMed'>
          <TextBox
            label='First Name'
            name='firstName'
            value={user.firstName}
            isRequired={showRequired}
            onValueChanged={e => onValueChanged(e.value, 'firstName')} />
        </div>
        <div className='mtMed'>
          <TextBox
            label='Middle Name'
            name='middleName'
            value={user.middleName}
            onValueChanged={e => onValueChanged(e.value, 'middleName')} />
        </div>
        <div className='mtMed'>
          <TextBox
            label='Last Name'
            name='lastName'
            value={user.lastName}
            isRequired={showRequired}
            onValueChanged={e => onValueChanged(e.value, 'lastName')} />
        </div>
        <div className='mtMed'>
          <PhoneInput
            label='Mobile Phone Number to Receive PIN by Text'
            mask={user.phone ? '(000)000-0000' : ''}
            placeholder='(XXX) XXX-XXXX'
            name='phone'
            showCountryCode
            phoneValue={user.phone}
            countryCodeValue={user.phoneCode}
            countryCodeField='phoneCode'
            phoneNumberField='phone'
            isRequired={showRequired}
            onValueChanged={onValueChanged} />
          <Footnote text='PIN Code Entry is Required to Login.' />
        </div>
        <div className='mtMed'>
          <div onMouseEnter={handleCaptchaEnter} onMouseLeave={handleCaptchaLeave}>
            <div>
              {(!isCaptcha && showRequired) && <FieldError isFocus={isCaptchaHover} error='Check the checkbox to validate.' />}
            </div>
            <ReCAPTCHA
              sitekey='6Ld6KRITAAAAAFRuh0xWphfJcWYUN0VeAcGwNsNg'
              onChange={handleCaptcha}
            />
          </div>
        </div>
        {error && <Label className='mtMed' isError>
          <b>{error}</b>
        </Label>}
        {errorEmailMismatch && <Label className='mtMed' title={errorEmailMismatch} isError />}
        <div className='mtMed'>
          <Button className='mtMed' title='Create Account' type='submit' inactive={disableSubmitBtn} showLoader={disableSubmitBtn} fullWidth onClick={handleCreateAccount} />
          <div className={styles.footerSignup}>
            Already have an account? <Link to={routes.SIGNIN}>Sign In</Link>
          </div>
        </div>
      </div>}
      {isSuccessView && <div>
        Your account has been created. We have sent you a confirmation email, please follow the instructions to verify your account and login to Gateway.<br /><br />
        <a onClick={handleResendVerify}>Click here</a> to resend email.
        {resendConfirm && <Label clasName='mtMed' title={resendConfirm} />}
        {resendError && <Label>{resendError}</Label>}
      </div>}
    </AuthWrapper>
  );
};

CreateAccountPage.propTypes = {

};

export default WithRouter(CreateAccountPage);
