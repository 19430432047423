import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createID } from '../../utils/helper';
import Controls from '../../components/Controls';
import TextBox from '../../components/TextBox';
import SearchResults from '../../components/SearchResults';
import Label from '../../components/Label';
import { findCompany } from '../../api/company';
import styles from './CompanySearch.module.scss';

const ADD = 'ADD';

const CompanySearch = ({ onCancel, onSubmit }) => {
  const [searchValue, setSearchValue] = useState(null);
  const [selected, setSelected] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState(null);

  const onOptionChanged = e => {
    if (e.name === 'text') {
      const value = e.value;
      setSearchValue(value);
      if (searchValue && (!value || value && value.length === 0)) onSubmit(null);
      if (value && value.length > 0) search(value);
    }
  };

  const handleSelect = value => {
    if (value.id === ADD) value.Name = searchValue;

    setSelected(value);
    setSearchValue(value.Name);
    setSearchResults([]);
    onSubmit(value);
  };

  const search = value => {
    findCompany(value)
      .then(data => {
        const addItem = { id: ADD, Name: value };
        const isExistingName = data && data.find(d => d.Name && d.Name === value.trim());
        if (value && value.trim().length > 0 && !isExistingName) {
          setSearchResults([...data, addItem]);
        } else {
          setSearchResults([...data]);
        }
      })
      .catch(() => setSearchError('Unable to search companies...'));
  };

  const hasSearchResults = searchResults.length > 0 && searchValue && searchValue.length > 0;

  return (
    <section className={styles.container}>
      <TextBox
        placeholder='Search for Company or Ticker Symbol'
        name='company'
        value={searchValue}
        showClearButton
        maxLength={75}
        onOptionChanged={onOptionChanged} />
      {hasSearchResults &&
        <SearchResults
          data={searchResults}
          setSelectedCompany={handleSelect} />
      }
      {searchError && <Label className='mtMed' isError title={searchError} />}
    </section>
  );
};

CompanySearch.propTypes = {
  setSelectedCompany: PropTypes.func
};

export default CompanySearch;
