import React from 'react';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';
import { format } from '../../utils/locale';

const Directors = ({ data }) => {
  const directors = data && data.filter(item => item.isDirector);
  const hasDirectors = directors && directors.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='OFFICERS, DIRECTORS, & CONTROL PERSONS' />
        <SectionTitle text='Directors' />
      </View>
      {directors && directors.map((person, i) => {
        const pageBreak = i !== 0 && isInt(i / 4);
        let committeeMembership = [];
        if (person.isAuditCommittee) committeeMembership.push('Audit Committee');
        if (person.isNominatingCommittee) committeeMembership.push('Nominating Committee');
        if (person.isCompensationCommittee) committeeMembership.push('Compensation Committee');

        return (
          <View break={pageBreak} key={`directors-${i}`}>
            <Row>
              <Column size='50'>
                <Label text='Name' />
                <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
              </Column>
              <Column size='50'>
                <Label text='Phone Number' />
                <Value text={person.phone} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Email' />
                <Value text={person.email} />
              </Column>
            </Row>
            <Row>
              <Column size='50'>
                <Label text='INDEPENDENT DIR?' />
                <Value text={format(person.isIndependent, null, 'bool')} />
              </Column>
              <Column size='50'>
                <Label text='COMMITTEE MEMBERSHIPS' />
                <Value text={committeeMembership && committeeMembership.join(', ')} />
              </Column>
            </Row>
            <Divider />
          </View>
        );
      })}
      {!hasDirectors && <Value text={null} />}
    </>
  );
};

export default Directors;
