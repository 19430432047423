import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FlowListItem.module.scss';

const FlowListItem = ({ index, isSuccess, isFail, isBlank }) => {
  return <div className={cn(styles.container, {
    [styles.blank]: isBlank,
    [styles.icon]: isFail,
    [styles.icon]: isSuccess
  })}>
    {index && index}
    {isFail && <FontAwesomeIcon icon={['fal', 'times']} />}
    {isSuccess && <FontAwesomeIcon icon={['fal', 'check']} />}
  </div>;
};

FlowListItem.propTypes = {
  index: PropTypes.number,
  isSuccess: PropTypes.bool,
  isFail: PropTypes.bool,
  isBlank: PropTypes.bool
};

export default FlowListItem;
