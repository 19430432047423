import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './TabButton.module.scss';

const TabButton = props => {
  return (
    <div id={props.id} className={cn(styles.container, {
      [styles.active]: props.active
    })} onClick={props.onClick}>
      <div>
        {props.text}
      </div>
    </div>
  );
};

TabButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  active: PropTypes.bool
};

export default TabButton;
