import { appNames, types, reportingStandards, countries, usaZipCodeRegex, pifReportingStandards, charOrNumRegex, paymentTypes, offlinePaymentTypes } from './index';
import { isSameOrBefore, getBackgroundList, isCorporateEntity } from '../utils/helper';
import { isPhoneWithCountry, isEmail, isNumber, isBoolean, isUSA, isPositiveNumber, isDate, isCusip, isIsin } from '../utils/validations';

const countryUsCa = [countries.US, countries.CA];

let appId;
let isAppOTCQXUS = false;
let isAppOTCQXBank = false;
let isAppOTCQXIntl = false;
let isAppOTCQB = false;
let isAppDNS = false;

export const resetAppId = () => appId = null;

export const validNumberOfShares = numberOfShares => numberOfShares || isNumber(numberOfShares);
export const validPercentOfClass = percentOfClass => percentOfClass || isNumber(percentOfClass);

export const validateControlPersonOwnership = security => {
  if (security && validNumberOfShares(security.numberOfShares) && validPercentOfClass(security.percentOfClass)) {
    return true;
  } else return false;
};

export const validateCorporateEntityOwnership = security => {
  if (security && validNumberOfShares(security.numberOfShares) && validPercentOfClass(security.percentOfClass)) {
    return true;
  } else return false;
};

const validateCompleteOwnership = (person, securities) => {
  const totalSecurities = securities && securities.length;
  if (!securities) return false;
  const totalCompleteOwnerships = person && person.securityOwnerships && person.securityOwnerships.filter(sec => {
    const security = securities.find(s => s.secId === sec.secId);

    if (person && person.isControl && !validateControlPersonOwnership(sec)) return false;
    if (person && person.entityName && !validateCorporateEntityOwnership(sec)) return false;

    return security && isNumber(sec.numberOfShares) && isNumber(sec.percentOfClass);
  }).length;
  return (person && person.isExempt) || totalSecurities === totalCompleteOwnerships;
};

export const validateCompleteSecurity = (item, reportingStandard, financialStandards) => {
  return !!(item.type &&
    item.securityClass &&
    (reportingStandard === reportingStandards.INTERNATIONAL ? true : isCusip(item.cusip)) &&
    (isNumber(item.authorizedShares) || item.isAuthorizedUnlimited) &&
    isNumber(item.restrictedShares) &&
    isNumber(item.unrestrictedShares) &&
    isNumber(item.unrestrictedSharesAffiliates) &&
    isNumber(item.sharesOutstanding) &&
    (item.publicFloat || isNumber(item.publicFloat)) &&
    (item.publicFloatPercentage || isNumber(item.publicFloatPercentage)) &&
    isNumber(item.sharesAsOfDate) && isSameOrBefore(item.sharesAsOfDate) &&
    isNumber(item.beneficialShareholders) &&
    (isNumber(item.parValue) || item.noParValue));
};

export const validateCompleteDNSSecurity = (item, reportingStandard) => {
  return !!(item.type &&
    item.securityClass &&
    (reportingStandard === reportingStandards.INTERNATIONAL ? true : isCusip(item.cusip)) &&
    (isNumber(item.parValue) || item.noParValue) &&
    (isNumber(item.authorizedShares) || item.isAuthorizedUnlimited) &&
    isNumber(item.sharesAsOfDate) && isSameOrBefore(item.sharesAsOfDate) &&
    (!item.sharesOutstanding || isNumber(item.sharesOutstanding)));
};

export const validateCompleteServiceProvider = (provider, type, companyInfo) => {
  const countryOfIncorporation = companyInfo?.countryOfIncorporation;
  const isUSAorCANCountry = isUSAorCAN(countryOfIncorporation);
  const isProviderComplete = provider.name && provider.address1 && provider.city && provider.country &&
    ((isRequiredCountry(provider.country) && provider.state) || (provider.country && !isRequiredCountry(provider.country)));
  const isPrimaryComplete = (isAppDNS && !isUSAorCANCountry) || (isAppDNS && provider.primaryContactEmail) ||
      (provider.primaryFirstName && provider.primaryLastName && provider.primaryContactPhone && provider.primaryContactCountryCode &&
      isPhoneWithCountry(provider.primaryContactPhone, provider.primaryContactCountryCode) && provider.primaryContactEmail);
  if (type === 'transferAgents') {
    if (isAppDNS && !isUSAorCANCountry) {
      return provider.name && isPrimaryComplete;
    } else {
      return provider.name && isPrimaryComplete && provider.verifiedSharesAuth === true;
    }
  } else if (type === 'qxSponsor' || type === 'qbSponsor') {
    return provider.name && isPrimaryComplete;
  } else if (type === 'securitiesCounsels') {
    return (isProviderComplete && isPrimaryComplete) || (provider.noOutsideCounsel && isPrimaryComplete);
  } else {
    return isProviderComplete && isPrimaryComplete;
  }
};

export const validateCompletePersonBackground = (appType, person, corporateEntityList, companyInfo, reportingStandard) => {
  const hasPersonalInfo = !!(person.birthDay && person.birthMonth && person.birthYear && person.country && person.city &&
    ((isRequiredCountry(person.country) && person.state) || (person.country && !isRequiredCountry(person.country))));
  const hasDelistHistory = (person.isConvicted ||
    person.isLimitedActivitiesFiveYears ||
    person.isViolationOfSecLaw ||
    person.isLimitedActivities);
  const isDelistHistoryComplete = !!((person.isNoneApply) || hasDelistHistory && (person.pifUploadedId || person.noPIFAvailable));
  const hasFinraSecReg = (isBoolean(person.isFinraOrSecRegistered) && person.isFinraOrSecRegistered && (person.crdNumber || person.secNumber));
  const hasNoFinraSecReg = (isBoolean(person.isFinraOrSecRegistered) && !person.isFinraOrSecRegistered);
  const finraSecRegComplete = hasFinraSecReg || hasNoFinraSecReg;
  const isNotOfficerDirector = !person.isOfficer && !person.isDirector;
  const parentCorporateEntity = isCorporateEntity(person, corporateEntityList);
  const hasOwnershipExemption = person.securityOwnerships && person.securityOwnerships.find(ownership => ownership.percentOfClass < 0.10);
  const hasCorpEntityOwnershipExpemption = parentCorporateEntity && parentCorporateEntity.securityOwnerships && parentCorporateEntity.securityOwnerships.find(ownership => ownership.percentOfClass < 0.10);
  const hasBgExemptOption = isNotOfficerDirector && (hasOwnershipExemption || hasCorpEntityOwnershipExpemption);
  let completeBgPerson = false;
  if (person.isBGExempt && person.bgExemptDetails && hasBgExemptOption) completeBgPerson = true;
  else if (isPifNeeded(person, appType, hasDelistHistory, reportingStandard, companyInfo)) completeBgPerson = !!(person.pifUploadedId || person.noPIFAvailable);

  else { completeBgPerson = !!(isDelistHistoryComplete && finraSecRegComplete && hasPersonalInfo); };

  return completeBgPerson;
};

export const isPifNeeded = (person, appType, isLegalDisciplinaryHistory, reportingStandard, companyInfo) => {
  const isOTCQXUS = appType === 'OTCQX_US' || appType === 1;
  const isOTCQXBanks = appType === 'OTCQX_BANKS' || appType === 2;
  const isOTCQXIntl = appType === 'OTCQX_INTL' || appType === 3;
  const isOTCQB = appType === 'OTCQB' || appType === 4;
  const isDNS = appType === 'DNS' || appType === 5;
  if (person.isBGExempt) return false;
  if (isLegalDisciplinaryHistory) return true;

  if (isOTCQXUS || isOTCQXBanks) {
    if (pifReportingStandards.includes(reportingStandard) && !countryUsCa.includes(person.country)) return true;
  } else if (isOTCQB || isDNS || isOTCQXIntl) {
    if (pifReportingStandards.includes(reportingStandard) && !countryUsCa.includes(person.country) && !companyInfo.hasOtherExchange) return true;
  }

  return false;
};

export const validateCompleteDesignatedContact = (contact, flag) => {
  const isContactInfo = (contact.phone && contact.phoneCountryCode && isPhoneWithCountry(contact.phone, contact.phoneCountryCode)) && (contact.email && isEmail(contact.email));
  const isAccountPayable = contact.firstName === 'Accounts' && contact.lastName === 'Payable' && isContactInfo;
  const isValidOfficerDirector = (contact.isOfficer || contact.isDirector) && isContactInfo;
  const isValidContact = contact[flag] && contact.firstName && contact.lastName && isContactInfo && contact.title1;

  if (flag === 'isOtherBillingContact' && contact.isAccountPayable && isContactInfo) return true;

  return isValidOfficerDirector || isValidContact || isAccountPayable;
};

export const isUSAorCAN = country => {
  switch (country) {
    case countries.US:
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      return true;

    case countries.CA:
    case countries.CAN:
    case countries.CANADA:
      return true;
  };

  return false;
};

export const isRequiredCountry = country => {
  switch (country) {
    case countries.US:
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      return true;

    case countries.CA:
    case countries.CAN:
    case countries.CANADA:
      return true;

    case countries.AU:
    case countries.AUS:
    case countries.AUSTRALIA:
      return true;
  };

  return false;
};

const companyInformationComplete = data => {
  const hasAddresses = !!(data.companyAddress && data.companyAddress.address1 && data.companyAddress.city && ((isRequiredCountry(data.companyAddress.country) && data.companyAddress.state && data.companyAddress.zip) || (data.companyAddress.country && !isRequiredCountry(data.companyAddress.country))) && data.billingAddress && data.billingAddress.address1 && data.billingAddress.city && (((isRequiredCountry(data.billingAddress.country) || isRequiredCountry(data.billingAddress.countryName)) && data.billingAddress.state && data.billingAddress.zip) || ((data.billingAddress.country && !isRequiredCountry(data.billingAddress.country)) || (data.billingAddress.countryName && !isRequiredCountry(data.billingAddress.countryName)))));
  const hasContactInfo = !!(data.phoneCountryCode && data.phoneNumber && isPhoneWithCountry(data.phoneNumber, data.phoneCountryCode) && (!isAppDNS ? data.companyWebsiteUrl : true) && data.companyEmail);
  let hasProfileData = !!(data.businessDescription && isNumber(data.numberOfEmployees) && data.numberOfEmployeesAsOf && isSameOrBefore(data.numberOfEmployeesAsOf));
  const hasFiscalInfo = !!(data.fiscalYearEndMonth && data.fiscalYearEndDay);
  const hasIncorporationInfo = !!(data.yearOfIncorporation && ((isRequiredCountry(data.countryOfIncorporation) && data.stateOfIncorporation) || (!isRequiredCountry(data.countryOfIncorporation))));
  let hasQuestions = false;

  if (isAppDNS) {
    hasQuestions = !!((isBoolean(data.hasOtherExchange) &&
    ((data.hasOtherExchange && data.hasOtherExchangeName && data.hasOtherExchangeSymbol && data.hasOtherExchangeDate && data.hasOtherExchangeCycle && data.hasOtherExchangeAccount) || !data.hasOtherExchange)) && (isBoolean(data.hasUsExchange) &&
    ((data.hasUsExchange && data.hasUsExchangeName && data.hasUsExchangeSymbol && data.hasUsExchangeDate) || !data.hasUsExchange)) && ((!data.hasUsExchange || isBoolean(data.isDelistFromUsExchange)) &&
    (!data.isDelistFromUsExchange || (data.isDelistFromUsExchange && (data.isDelistFromUsExchangeDate || data.isDelistFromUsExchangeDateUnknown === true)))));
    hasProfileData = true;
  } else if (isAppOTCQXUS || isAppOTCQXBank) {
    hasQuestions = !!((isBoolean(data.hasOtherMarketSecurities) && ((data.hasOtherMarketSecurities && data.hasOtherMarketSecuritiesDetail) || !data.hasOtherMarketSecurities)) &&
      (isBoolean(data.changeInControl) && ((data.changeInControl && data.changeInControlDetail) || !data.changeInControl)) &&
      (isBoolean(data.isDelistFromMajorExchange) && ((data.isDelistFromMajorExchange && data.isDelistFromMajorExchangeDetail && data.isDelistFromMajorExchangeConcerns) || !data.isDelistFromMajorExchange)));
  } else if (isAppOTCQB || isAppOTCQXIntl) {
    hasQuestions = !!((isBoolean(data.hasOtherExchange) && ((data.hasOtherExchange && data.hasOtherExchangeName && data.hasOtherExchangeSymbol && data.hasOtherExchangeDate && data.hasOtherExchangeCycle && data.hasOtherExchangeAccount) || !data.hasOtherExchange)) &&
      (isBoolean(data.changeInControl) && ((data.changeInControl && data.changeInControlDetail) || !data.changeInControl)) &&
      (isBoolean(data.isDelistFromMajorExchange) && ((data.isDelistFromMajorExchange && data.isDelistFromMajorExchangeDetail && data.isDelistFromMajorExchangeConcerns) || !data.isDelistFromMajorExchange)));
  }
  return !!(data && hasAddresses && hasContactInfo && hasProfileData && hasFiscalInfo && hasIncorporationInfo && hasQuestions);
};

const eligibilityStandardsComplete = (data, reportingStandard) => {
  if (!data) return false;
  const isAuditedFinancials = isBoolean(data.auditedFinancials) && data.auditedFinancials ? true : data.auditedFinancialsDetail;
  const isBankrupt = isBoolean(data.isBankrupt) && data.isBankrupt ? data.isBankruptDetail : true;
  const isInGoodStanding = isBoolean(data.inGoodStanding) && data.inGoodStanding ? true : data.inGoodStandingDetail;
  let isShareholderMeetings = isBoolean(data.hasShareholderMeetings) && data.hasShareholderMeetings ? true : data.hasShareholderMeetingsDetail;
  let isPricedQuotation = isBoolean(data.hasPricedQuotation) && data.hasPricedQuotation;
  const isForm211BidPrice = isBoolean(data.hasForm211BidPrice) && data.hasForm211BidPrice;
  const isInitialReview = isBoolean(data.hasInitialReview);
  let isShellBlankCheckCompany = isBoolean(data.isShellBlankCheckCompany) && data.isShellBlankCheckCompany ? data.isShellBlankCheckCompanyDetail : true;
  let isTwoIndependentDirectors = isBoolean(data.hasTwoIndependentDirectors) && data.hasTwoIndependentDirectors ? true : data.hasTwoIndependentDirectorsDetail;
  let isAuditComittee = isBoolean(data.hasAuditComittee) && data.hasAuditComittee ? true : data.hasAuditComitteeDetail;

  if (isAppOTCQB) {
    if (!reportingStandard) return false;

    const isAlternative = reportingStandard === reportingStandards.ALTERNATIVE;
    isShareholderMeetings = true;

    isShellBlankCheckCompany = isBoolean(data.isShellBlankCheckCompany);
    isTwoIndependentDirectors = isBoolean(data.hasTwoIndependentDirectors) && data.hasTwoIndependentDirectors ? true : isAlternative ? data.hasTwoIndependentDirectorsDetail : true;
    isAuditComittee = isBoolean(data.hasAuditComittee) && data.hasAuditComittee ? true : isAlternative ? data.hasAuditComitteeDetail : true;
  }

  if (!isPricedQuotation) {
    if (isForm211BidPrice) {
      isPricedQuotation = true;
    } else {
      isPricedQuotation = isInitialReview;
    }
  }

  if (isAppOTCQXIntl) {
    isTwoIndependentDirectors = true;
    isAuditComittee = true;
    isShareholderMeetings = true;
  }

  return isAuditedFinancials &&
    isShellBlankCheckCompany &&
    isBankrupt &&
    isInGoodStanding &&
    isTwoIndependentDirectors &&
    isAuditComittee &&
    isPricedQuotation &&
    isShareholderMeetings;
};

const reportingStandardsComplete = (data, detail, countryIncorporation) => {
  if (!data) return false;
  // for qx us bank and otcqb , country of inc must be USA to be BANK as reporting standard
  if ((isAppOTCQB || isAppOTCQXBank) && data === reportingStandards.BANK && !isUSA(countryIncorporation)) {
    return false;
  }
  const complete = data === reportingStandards.SEC || data === reportingStandards.REGULATION || data === reportingStandards.ALTERNATIVE || data === reportingStandards.INTERNATIONAL || (data === reportingStandards.BANK && detail);
  return complete;
};

const changeInControlComplete = (data, people, reportingStandard) => {
  if (validDnsSkipCompany(data, reportingStandard)) return true;
  const noChangeInControl = !!(data && isBoolean(data.changeInControl) && !data.changeInControl);
  const hasChangeInControl = !!(data && isBoolean(data.changeInControl) && data.changeInControl && data.changeInControlDetail);
  const isCourtApptCustodianAnswered = !!(data && isBoolean(data.isCourtAppointedCustodian));
  const formerExecList = people && people.filter(person => person.isFormerExecutive);
  const formerExecListEmpty = formerExecList ? formerExecList.length === 0 : true;
  const completeFormerExec = formerExecList && formerExecList.filter(person => isFormerExexComplete(person));
  const isFormerExecDetailsComplete = formerExecList && formerExecList.length > 0 && completeFormerExec && completeFormerExec.length > 0 && formerExecList.length === completeFormerExec.length;
  // check if former exch is empty or complete
  const isFormerExecComplete = formerExecListEmpty || isFormerExecDetailsComplete;
  if (noChangeInControl || (hasChangeInControl && isCourtApptCustodianAnswered)) return true;
};

const financialStandardsComplete = data => {
  if (!data) return false;
  return data.doesNotQualify || data.qualifiesForQXUS || data.qualifiesForQXIntl;
};

export const isOfficerComplete = person => person.isOfficer &&
  person.firstName &&
  person.lastName &&
  (person.phone && person.phoneCountryCode && isPhoneWithCountry(person.phone, person.phoneCountryCode)) &&
  (person.email && isEmail(person.email)) &&
  person.title1;

export const isFormerExexComplete = person => person.isFormerExecutive &&
  person.firstName &&
  person.lastName &&
  (person.phone && person.phoneCountryCode && isPhoneWithCountry(person.phone, person.phoneCountryCode)) &&
  (person.email && isEmail(person.email)) &&
  person.title1;

export const isDirectorComplete = person => person.isDirector && person.firstName && person.lastName && isBoolean(person.isIndependent);

export const isIndividualComplete = person => person.isControl && person.firstName && person.lastName;

export const isBeneficialOwnerComplete = person => person.firstName && person.lastName;

export const checkBeneficialOwners = (beneficialOwnersIds, peopleList) => {
  if (!Array.isArray(beneficialOwnersIds) || beneficialOwnersIds.length < 1) { return false; }
  let beneficialOwnerDetails = [];
  for (const personId of beneficialOwnersIds) {
    const beneficialOwner = peopleList.find(people => people.id === personId);
    if (beneficialOwner) {
      beneficialOwnerDetails.push(beneficialOwner);
    } else {
      return false;
    }
  }

  for (const beneficialOwnerDetail of beneficialOwnerDetails) {
    const beneficialOwnerDetailComplete = isBeneficialOwnerComplete(beneficialOwnerDetail);
    if (!beneficialOwnerDetailComplete) {
      return false;
    }
  }

  return true;
};

export const isCorporateEntityListComplete = (corporateEntityList, peopleList) => {
  if (!Array.isArray(corporateEntityList) || corporateEntityList.length < 1) {
    return null;
  }
  let corporateEntityComplete = [];
  for (let i = 0; i < corporateEntityList.length; i++) {
    let item = corporateEntityList[i];
    let itemComplete = isCorporateEntityComplete(item, peopleList);
    if (itemComplete) {
      corporateEntityComplete.push(item);
    }
  }
  return corporateEntityComplete;
};

export const isCorporateEntityComplete = (item, peopleList) => {
  return item.entityName && item.city &&
    ((isRequiredCountry(item.country) && item.state) || (item.country && !isRequiredCountry(item.country))) &&
    typeof item.haveBeneficialOwners === 'boolean' &&
    ((item.haveBeneficialOwners && checkBeneficialOwners(item.owners, peopleList)) ||
      (!item.haveBeneficialOwners && item.reasonForNone));
};

const officerDirControlComplete = (peopleList, corporateEntityList, noControlPerson) => {
  if (!peopleList) return false;
  const minNumOfOfficers = 1;
  let minNumOfDirectors = 2;
  const minNumOfControlPerson = 1;
  const minNumOfCorporateEnities = 1;
  const completeOfficers = peopleList && peopleList.filter(person => isOfficerComplete(person));

  if (isAppOTCQB) minNumOfDirectors = 1;
  if (isAppDNS) minNumOfDirectors = 0;

  const completeDirectors = peopleList && peopleList.filter(person => isDirectorComplete(person));

  const completeControlPerson = peopleList && peopleList.filter(person => isIndividualComplete(person));
  const completeCorporateEnities = peopleList && corporateEntityList && isCorporateEntityListComplete(corporateEntityList, peopleList);
  return completeOfficers && completeOfficers.length >= minNumOfOfficers && completeDirectors && completeDirectors.length >= minNumOfDirectors && (noControlPerson || ((completeControlPerson && completeControlPerson.length >= minNumOfControlPerson) || (completeCorporateEnities && completeCorporateEnities.length >= minNumOfCorporateEnities)));
};

const serviceProvidersComplete = (isDelistFromMajorExchange, transferAgents, auditors, securitiesCounsels, corporateBroker, qbSponsor, reportingStandard, companyInfo) => {
  const completeAuditors = auditors && auditors.filter(item => validateCompleteServiceProvider(item, 'auditors'));
  const completeSecuritiesCounsels = securitiesCounsels && securitiesCounsels.filter(item => validateCompleteServiceProvider(item, 'securitiesCounsels'));

  const AuditorsComplete = completeAuditors && completeAuditors.length >= 1;
  const SecuritiesCounselsComplete = completeSecuritiesCounsels && completeSecuritiesCounsels.length >= 1;

  const countryOfIncorporation = companyInfo.countryOfIncorporation;
  const isUSAorCANCountry = isUSAorCAN(countryOfIncorporation);
  if (isUSAorCANCountry) {
    const completeTransferAgents = transferAgents && transferAgents.filter(item => validateCompleteServiceProvider(item, 'transferAgents'));
    const transferAgentComplete = completeTransferAgents && completeTransferAgents.length >= 1;

    if (isAppOTCQXUS) return transferAgentComplete && AuditorsComplete && SecuritiesCounselsComplete;

    if (isAppOTCQXIntl) return transferAgentComplete && AuditorsComplete;

    if (isAppOTCQXBank) {
      const completeCorporateBroker = corporateBroker && corporateBroker.filter(item => validateCompleteServiceProvider(item, 'corporateBroker'));
      const CorporateBrokerComplete = completeCorporateBroker && completeCorporateBroker.length >= 1;
      return transferAgentComplete && AuditorsComplete && SecuritiesCounselsComplete && (isDelistFromMajorExchange ? true : CorporateBrokerComplete);
    }

    if (isAppOTCQB) {
      const completeOTCQBSponsor = qbSponsor && qbSponsor.filter(item => validateCompleteServiceProvider(item, 'qbSponsor'));
      const QBSponsorComplete = completeOTCQBSponsor && completeOTCQBSponsor.length >= 1;
      return transferAgentComplete && AuditorsComplete && SecuritiesCounselsComplete && (reportingStandard === reportingStandards.INTERNATIONAL ? QBSponsorComplete : true);
    }

    if (isAppDNS) return transferAgentComplete;
  } else {
    if (isAppOTCQXUS) return AuditorsComplete && SecuritiesCounselsComplete;

    if (isAppOTCQXIntl) return AuditorsComplete;

    if (isAppOTCQXBank) {
      const completeCorporateBroker = corporateBroker && corporateBroker.filter(item => validateCompleteServiceProvider(item, 'corporateBroker'));
      const CorporateBrokerComplete = completeCorporateBroker && completeCorporateBroker.length >= 1;
      return AuditorsComplete && SecuritiesCounselsComplete && (isDelistFromMajorExchange ? true : CorporateBrokerComplete);
    }

    if (isAppOTCQB) {
      const completeOTCQBSponsor = qbSponsor && qbSponsor.filter(item => validateCompleteServiceProvider(item, 'qbSponsor'));
      const QBSponsorComplete = completeOTCQBSponsor && completeOTCQBSponsor.length >= 1;
      return AuditorsComplete && SecuritiesCounselsComplete && (reportingStandard === reportingStandards.INTERNATIONAL ? QBSponsorComplete : true);
    }

    if (isAppDNS) return true;
  }
};

const securityDetailsComplete = (secDetails, reportingStandard, financialStandards) => {
  let tradedField = 'isQXtraded';
  if (isAppOTCQB) tradedField = 'isQBtraded';
  if (!secDetails) return false;

  const appliedSecurities = secDetails && secDetails.securityList && secDetails.securityList.filter(sec => sec[tradedField]);
  const completedSecurities = appliedSecurities && appliedSecurities.filter(sec => validateCompleteSecurity(sec, reportingStandard, financialStandards));

  let completeVotingRights = false;
  if (isBoolean(secDetails.hasVotingRightsDividends)) {
    completeVotingRights = true;
  }

  if (isBoolean(secDetails.hasVotingRightsDividends) && secDetails.hasVotingRightsDividends === false) completeVotingRights = true;

  if (isAppDNS) {
    const securityList = secDetails && secDetails.securityList;
    const validSecurities = securityList && securityList.filter(sec => validateCompleteDNSSecurity(sec, reportingStandard));
    return securityList.length >= 1 && securityList.length === validSecurities.length;
  } else if (isAppOTCQXIntl) {
    return appliedSecurities && appliedSecurities.length >= 1 && appliedSecurities.length === completedSecurities.length;
  }

  return appliedSecurities && appliedSecurities.length >= 1 && appliedSecurities.length === completedSecurities.length && completeVotingRights;
};
const securityOwnershipComplete = (application, reportingStandard) => {
  if (isAppDNS && validDnsSkipCompany(application.companyInfo, reportingStandard)) return true;

  const securities = application && application.securityDetails && application.securityDetails.securityList;
  const corporateEntityList = application.corporateEntityList && application.corporateEntityList.map(item => { return { ...item, type: 'entity' }; });
  const owners = application.peopleList && application.peopleList.filter(person => person.isOfficer || person.isDirector || person.isControl).map(item => { return { ...item, type: 'person' }; }) || [];
  const secOwnersList = corporateEntityList ? owners.concat(corporateEntityList) : owners;
  const completedOwners = secOwnersList && secOwnersList.filter(owner => validateCompleteOwnership(owner, securities));
  return !!(securities && secOwnersList && secOwnersList.length > 0 && completedOwners && completedOwners.length > 0 && secOwnersList.length === completedOwners.length);
};

const legalHistoryComplete = data => {
  if (!data) return false;
  const isCompanyLegalHistoryPastTenYears = data.companyLegalHistoryPastTenYears === true || data.companyLegalHistoryPastTenYears === 'true';
  const isCompanyOfficerLegalHistoryPastFiveYears = data.companyOfficerLegalHistoryPastFiveYears === true || data.companyOfficerLegalHistoryPastFiveYears === 'true';
  return (isCompanyLegalHistoryPastTenYears || data.companyLegalHistoryPastTenYearsDesc) && (isCompanyOfficerLegalHistoryPastFiveYears || data.companyOfficerLegalHistoryPastFiveYearsDesc);
};

const additionalservicesComplete = (data) => {
  if (isAppOTCQXUS || isAppOTCQXBank || isAppOTCQXIntl) return data && isBoolean(data.isInterestedInVIC);
  if (isAppOTCQB) return data && isBoolean(data.isInterestedInVIC) && isBoolean(data.isInterestedInBSM);
  if (isAppDNS) return data && isBoolean(data.isRealTimeLevel2) && isBoolean(data.isInterestedInVIC) && isBoolean(data.isInterestedInBSM);
};

const designateContactsComplete = data => {
  if (!data) return false;
  const primaryContacts = data.filter(person => person.isPrimaryContact);
  const completePrimaryContacts = primaryContacts.filter(person => validateCompleteDesignatedContact(person, 'isPrimaryContact'));
  const authorizedUsers = data.filter(person => person.isAuthorizedUser);
  const completeAuthorizedUsers = authorizedUsers.filter(person => validateCompleteDesignatedContact(person, 'isAuthorizedUser'));
  const primaryBillingContact = data.find(person => person.isPrimaryBillingContact);
  const completePrimaryBillingContact = primaryBillingContact && validateCompleteDesignatedContact(primaryBillingContact, 'isPrimaryBillingContact');
  const otherBillingContact = data.filter(person => person.isOtherBillingContact);
  const completeOtherBillingContact = otherBillingContact.filter(person => validateCompleteDesignatedContact(person, 'isOtherBillingContact'));
  const isPrimaryContactsComplete = primaryContacts.length >= 1 && primaryContacts.length === completePrimaryContacts.length;
  const isAuthorizedUsersComplete = authorizedUsers.length >= 1 && authorizedUsers.length === completeAuthorizedUsers.length;
  const isPrimaryBillingContactComplete = primaryBillingContact && completePrimaryBillingContact;
  const isOtherBillingContactComplete = otherBillingContact.length === completeOtherBillingContact.length;

  return isPrimaryContactsComplete && isAuthorizedUsersComplete && isPrimaryBillingContactComplete && isOtherBillingContactComplete;
};

const backgroundCheckComplete = (people, corporateEntityList, companyInfo, reportingStandard) => {
  if (isAppDNS && validDnsSkipCompany(companyInfo, reportingStandard)) return true;
  if (!people || people.length === 0) return false;

  const peopleList = getBackgroundList(people, corporateEntityList, appId);

  const peopleComplete = peopleList.filter(person => {
    return validateCompletePersonBackground(appId, person, corporateEntityList, companyInfo, reportingStandard);
  });

  return (peopleList.length > 0 && peopleComplete.length > 0) && peopleList.length === peopleComplete.length;
};

export const validDnsSkipCompany = (compInfo, reportingStandard) => {
  const isIntlReportStnd = reportingStandard === reportingStandards.INTERNATIONAL;
  const isSecReportStnd = reportingStandard === reportingStandards.SEC;
  return (compInfo.hasOtherExchange || (!compInfo.isDelistFromUsExchange && compInfo.hasUsExchange)) && (isIntlReportStnd || isSecReportStnd);
};

export const supplementalDocumentsCheckComplete = (docs, data, people, reportingStandard) => {
  if (isAppDNS && validDnsSkipCompany(data, reportingStandard)) return true;

  if (!docs) return;
  const hasShareHolderList = docs.find(doc => doc.typeId === types.SHAREH_LIST);
  const hasDraftQbCert = docs.find(doc => doc.typeId === types.DRAFT_QB_CERT);
  const hasLogo = docs.find(doc => doc.typeId === types.LOGO);
  const hasLetterResign = docs.find(doc => doc.typeId === types.LETTER_RESIGN);
  const hasBoard = docs.find(doc => doc.typeId === types.BOARD);
  const hasSuppChangeControl = docs.find(doc => doc.typeId === types.DSC_CHG_CTRL_EVT);
  const hasAppApptCustRequired = docs.find(doc => doc.typeId === types.APP_APPT_CUST);
  const hasCrtOrdGrntCustRequired = docs.find(doc => doc.typeId === types.CRT_ORD_GRNT_CUST);

  const noChangeInControl = !!(data && isBoolean(data.changeInControl) && !data.changeInControl);
  const hasChangeInControl = !!(data && isBoolean(data.changeInControl) && data.changeInControl && data.changeInControlDetail);
  const isCourtApptCustodianAnswered = data && isBoolean(data.isCourtAppointedCustodian) ? data.isCourtAppointedCustodian : false;

  if (isAppOTCQXUS || isAppOTCQXBank || isAppOTCQXIntl) return !!(hasShareHolderList && hasLogo);

  if (isAppOTCQB) return !!(hasShareHolderList && hasDraftQbCert);

  if (isAppDNS) {
    if (noChangeInControl) return !!hasShareHolderList;
    if (hasChangeInControl && isCourtApptCustodianAnswered) return !!(hasShareHolderList && hasSuppChangeControl && hasAppApptCustRequired && hasCrtOrdGrntCustRequired);
    if (hasChangeInControl && !isCourtApptCustodianAnswered) return !!(hasShareHolderList && hasSuppChangeControl && hasBoard);
  }
};

export const signSubmitCheckComplete = (signSubmit, eligibilityStandards) => {
  const hasInitialReviewAddendum = isBoolean(eligibilityStandards?.hasPricedQuotation) && !eligibilityStandards?.hasPricedQuotation && isBoolean(eligibilityStandards?.hasForm211BidPrice) && !eligibilityStandards?.hasForm211BidPrice && !!eligibilityStandards?.hasInitialReview;
  const signSubmitComplete = signSubmit?.hasReviewed && signSubmit?.hasReviewedPrivacyPolicy &&
    signSubmit?.hasReviewedIssuerAgreement &&
    (hasInitialReviewAddendum ? signSubmit?.hasReviewedAddendum : true) &&
    signSubmit?.acceptedSignatured && signSubmit?.signature?.length > 0 && signSubmit?.signeeTitle?.length > 0;
  return signSubmitComplete;
};

export const applicationSectionsComplete = (application, documents, cards) => {
  const cardsState = cards;
  const isDelistFromMajorExchange = application.companyInfo.isDelistFromMajorExchange;
  const countryIncorporation = application.companyInfo.countryOfIncorporation;
  const reportingStandard = application.reportingStandard;
  const financialStandards = application.financialStandards;
  appId = application.typeId;
  appId === 1 ? isAppOTCQXUS = true : isAppOTCQXUS = false;
  appId === 2 ? isAppOTCQXBank = true : isAppOTCQXBank = false;
  appId === 3 ? isAppOTCQXIntl = true : isAppOTCQXIntl = false;
  appId === 4 ? isAppOTCQB = true : isAppOTCQB = false;
  appId === 5 ? isAppDNS = true : isAppDNS = false;

  if (!cardsState) return cardsState;
  cardsState['company-information'] = companyInformationComplete(application.companyInfo);
  cardsState['eligibility-standards'] = eligibilityStandardsComplete(application.eligibilityStandards, reportingStandard);
  cardsState['reporting-standards'] = reportingStandardsComplete(reportingStandard, application.reportingStandardDetail, countryIncorporation);
  cardsState['officers-directors-control-persons'] = officerDirControlComplete(application.peopleList, application.corporateEntityList, application.noControlPerson);
  cardsState['service-providers'] = serviceProvidersComplete(isDelistFromMajorExchange, application.transferAgents, application.auditors, application.securitiesCounsels, application.corporateBroker, application.qbSponsor, reportingStandard, application.companyInfo);
  cardsState['security-details'] = securityDetailsComplete(application.securityDetails, reportingStandard, financialStandards);
  cardsState['security-ownership'] = securityOwnershipComplete(application, reportingStandard);
  cardsState['legal-disciplinary-history'] = legalHistoryComplete(application.legalHistory);
  cardsState['additional-services'] = additionalservicesComplete(application.additionalServices);
  cardsState['designate-contacts'] = designateContactsComplete(application.peopleList);
  cardsState['background-check-authorization'] = backgroundCheckComplete(application.peopleList, application.corporateEntityList, application.companyInfo, reportingStandard);
  cardsState['supplemental-documents'] = supplementalDocumentsCheckComplete(documents, application.companyInfo, application.peopleList, reportingStandard);
  cardsState['sign-submit'] = signSubmitCheckComplete(application.signSubmit, application.eligibilityStandards);
  cardsState['payment'] = application.payment && (offlinePaymentTypes.includes(application.payment.paymentType) && application.submitDate || !!application.payment.transactionId);

  if (isAppOTCQXUS || isAppOTCQXBank || isAppOTCQXIntl) cardsState['financial-standards'] = financialStandardsComplete(application.financialStandards);
  if (isAppDNS) cardsState['change-in-control'] = changeInControlComplete(application.companyInfo, application.peopleList, reportingStandard);
  return cardsState;
};

export const paymentCheckComplete = payment => {
  const paymentType = payment && payment.paymentType;
  switch (paymentType) {
    case paymentTypes.ACH_CHECKINGS:
    case paymentTypes.ACH_SAVINGS:
      return achPaymentInfoComplete(payment);
    case paymentTypes.CREDIT:
      return creditCardInfoComplete(payment);
    case paymentTypes.OFFLINE_CHECK:
      return checkPaymentInfoComplete(payment);
    case paymentTypes.OFFLINE_WIRE:
      return wirePaymentInfoComplete(payment);
    default:
      return false;
  }
};

export const isValidSubmit = submitDate => {
  return !!(submitDate && isDate(submitDate));
};

export const isSubmitReady = completeCards => {
  return isSignReady(completeCards) && completeCards['sign-submit'];
};

export const isSignReady = completeCards => {
  return completeCards['company-information'] &&
    ((isAppOTCQXUS || isAppOTCQXBank || isAppOTCQB || isAppOTCQXIntl) ? completeCards['eligibility-standards'] : true) &&
    completeCards['reporting-standards'] &&
    (isAppDNS ? completeCards['change-in-control'] : true) &&
    ((isAppOTCQXUS || isAppOTCQXBank || isAppOTCQXIntl) ? completeCards['financial-standards'] : true) &&
    completeCards['officers-directors-control-persons'] &&
    completeCards['service-providers'] &&
    completeCards['security-details'] &&
    completeCards['security-ownership'] &&
    ((isAppOTCQXUS || isAppOTCQXBank || isAppOTCQB || isAppOTCQXIntl) ? !!completeCards['legal-disciplinary-history'] : true) &&
    completeCards['additional-services'] &&
    completeCards['designate-contacts'] &&
    completeCards['background-check-authorization'] &&
    completeCards['supplemental-documents'];
};

export const addressComplete = address => {
  const hasAddresses = !!(
    address && address.address1 && address.city &&
    ((isRequiredCountry(address.country) && address.state && address.zip) ||
      (address.country && !isRequiredCountry(address.country))));
  return hasAddresses;
};

export const checkWireTransferConfNumber = (data) => {
  if (!data || !charOrNumRegex.test(data)) {
    return false;
  }
  const wireTransferConfNumLength = data.length;
  return wireTransferConfNumLength > 15 && wireTransferConfNumLength < 21;
};

export const checkBankAccountNumber = (data) => {
  if (!data || !isPositiveNumber(data)) {
    return false;
  }
  return data.length < 51;
};

export const checkBankRoutingNumber = (data) => {
  if (!data || !isPositiveNumber(data)) {
    return false;
  }
  return data.length === 9;
};

export const validateBankCheckNumber = (data) => {
  if (!data || !isPositiveNumber(data)) {
    return false;
  }
  return true;
};

export const achPaymentInfoComplete = data => {
  return data && data.fullName && data.accountType && checkBankAccountNumber(data.bankAccountNumber) && checkBankRoutingNumber(data.bankRoutingNumber) && isEmail(data.email);
};

export const checkZipCode = (country, zip) => {
  if (zip && country) {
    if (isUSA(country)) {
      return zip.match(usaZipCodeRegex);
    }
    // all other country except USA don't validate
    return true;
  }
  return false;
};

export const validCvvNumber = (data) => {
  if (!data || !isPositiveNumber(data)) {
    return false;
  }
  return data.length === 3 || data.length === 4;
};

export const creditCardInfoComplete = data => {
  return data && addressComplete(data.address) && checkZipCode(data.address.country, data.address.zip) && data.ccNumber && data.fullName && isEmail(data.email) && data.year && data.month && validCvvNumber(data.cvv);
};

export const wirePaymentInfoComplete = data => {
  return data && data.nameOnBankAcct && checkWireTransferConfNumber(data.wireTransferConfNum) && isEmail(data.email) && data.wireDate;
};

export const checkPaymentInfoComplete = data => {
  return data && data.nameOnBankAcct && validateBankCheckNumber(data.checkNumber) && data.checkDate && isDate(data.checkDate) && isEmail(data.email);
};
