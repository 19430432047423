import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { format } from '../../utils/locale';

const Value = ({ text, emptyText, type }) => {
  let value = text;

  if (type) value = format(value, value, type);

  return (
    <Text style={styles.title}>
      {value && value}
      {!value && !emptyText && <Text style={styles.empty}>Empty</Text>}
      {emptyText && <Text style={styles.empty}>{emptyText}</Text>}
    </Text>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    fontWeight: 200
  },
  empty: {
    color: '#B1B1B1',
    fontStyle: 'italic'
  }
});

export default Value;
