import React, { useState } from 'react';
import cn from 'classnames';
import FilterLabel from '../FilterLabel';
import styles from './SelectedFilters.module.scss';

const SelectedFilters = ({ items, onRemove, max }) => {
  const [showAll, setShowAll] = useState(false);
  const hasMore = items.length > max;

  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <>
      <div className={styles.container}>
        {items.map((item, i) => (showAll || i < max) && <FilterLabel key={i} name={item.title} filter={item.description} onRemove={() => onRemove(false, item, item.filterType)} />)}
        {(hasMore && !showAll) && <span className={cn(styles.label, styles.more)} onClick={toggleShowAll}>
          {`+${items.length - max}`}
        </span>}
      </div>
      {showAll && <div className={cn(styles.label, styles.less)} onClick={toggleShowAll}>
        Show Less Filters
      </div>}
    </>
  );
};

SelectedFilters.defaultProps = {
  max: 2
};

export default SelectedFilters;
