import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { routes, urls } from '../../constants';
import Title from '../Title';
import styles from './AuthWrapper.module.scss';

const AuthWrapper = ({ title, children, formOnly }) => (
  <div className={cn(styles.container, {
    [styles.formOnly]: formOnly
  })}>
    {!formOnly && <Link to={routes.HOME}>
      <img className={styles.logo} src='/logos/otc-gateway-logo-color.png' alt='OTC Gateway' />
    </Link>}
    <div className={cn(styles.wrapper, {
      [styles.hideBorder]: formOnly
    })}>
      {title && <Title title={title} type='h2' />}
      {children && <div className={styles.info}>
        {children}
      </div>}
    </div>
    {!formOnly && <footer>
      <ul className={styles.footerGroup}>
        <li className={styles.item}>
          <a className={styles.link} href={urls.OTC_WEBSITE} target='_blank' rel='noopener noreferrer'>
            OTCMarkets.com
          </a>
        </li>
        <li className={styles.item}>
          <a className={styles.link} href={urls.TERMS} target='_blank' rel='noopener noreferrer'>
            Terms of Use
          </a>
        </li>
        <li className={styles.item}>
          <a className={styles.link} href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </a>
        </li>
      </ul>
    </footer>}
  </div>
);

export default AuthWrapper;
