import React from 'react';
import { emails, urls } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../Title';
import ApplicationGuideLink from '../ApplicationGuideLink';
import styles from './HelpSection.module.scss';

const HelpSection = () => <section>
  <Title title='Saving Progress' type='h2' />
  <p>
    You can save your work at any time and resume later by clicking on the Save button on the top right corner of your screen. Your progress is also automatically saved as you move between different sections of the application form.
  </p>
  <Title className='mtXL' title='Helpful Links' type='h2' />
  <ul className={styles.links}>
    <li>
      <a href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>
        Privacy Policy
      </a>
    </li>
    <li>
      <a href={urls.STOCK_PROMO_POLICY} target='_blank' rel='noopener noreferrer'>
        Stock Promotion Policy
      </a>
    </li>
    <li>
      <a href={urls.BEST_PRACTICE_ISSUERS} target='_blank' rel='noopener noreferrer'>
        Best Practices for Issuers
      </a>
    </li>
    <li>
      <a href={urls.GLOSSARY} target='_blank' rel='noopener noreferrer'>
        Glossary of Terms
      </a>
    </li>
  </ul>
  <Title className='mtXL' title='Application Guides' type='h2' />
  <ul className={styles.links}>
    <li>
      <ApplicationGuideLink appType='OTCQX_US' />
    </li>
    <li>
      <ApplicationGuideLink appType='OTCQX_BANKS' />
    </li>
    <li>
      <ApplicationGuideLink appType='OTCQX_INTL' />
    </li>
    <li>
      <ApplicationGuideLink appType='OTCQB_US' />
    </li>
    <li>
      <ApplicationGuideLink appType='OTCQB_INTL' />
    </li>
  </ul>
  <Title className='mtXL' title='Contact Issuer Services' type='h2' />
  <Title className='mtLg' title='Phone:' type='h3' />
  <div className={styles.phone}>
    <FontAwesomeIcon icon={['far', 'phone']} /> (212)-896-4420
  </div>
  <Title className='mtLg' title='Email:' type='h3' />
  <a href={emails.ISSUER_SERVICES}>
    <FontAwesomeIcon icon={['far', 'envelope']} /> issuers@otcmarkets.com
  </a>
  <Title className='mtLg' title='Email for OTCQX Application Inquiries:' type='h3' />
  <a href={emails.OTCQX_APP_INQUIRIES}>
    <FontAwesomeIcon icon={['far', 'envelope']} /> otcqxapplications@otcmarkets.com
  </a>

</section>;

export default HelpSection;
