import api from './api';

export const getTypes = async () => {
  const response = await api.request({
    method: 'get',
    url: '/app-portal/common/types'
  });
  return response.data;
};

export const getCountryCodes = async () => {
  const response = await api.request({
    method: 'get',
    url: '/common/country/codes'
  });
  return response.data;
};

export const getConfig = async () => {
  const response = await api.request({
    method: 'get',
    url: '/common/config'
  });
  return response.data;
};
