import React from 'react';
import { reportingStandards } from '../../constants';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Value } from '../components';

const ReportingStandards = ({ data, detail }) => (
  <>
    <View fixed>
      <SectionHeader text='Reporting Standards' />
    </View>
    <View>
      <Row>
        <Column size='100'>
          <Label text='STANDARD USED' />
          <Value text={data && data} />
        </Column>
      </Row>
      {(data && data === reportingStandards.BANK) && <Row>
        <Column size='100'>
          <Label text='Names(s)' />
          <Value text={detail && detail} />
        </Column>
      </Row>}
    </View>
  </>
);

export default ReportingStandards;
