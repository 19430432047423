import React from 'react';
import cn from 'classnames';
import styles from './PhoneInput.module.scss';

export default function Item (data) {
  return (
    <div className='f16'>
      <div className={cn(styles.countryField, 'flag', `${data && data.id && data.id.toLowerCase()}`)} />
      <div className={styles.countryField}>
        {data && `${data.name} +${data.phoneCode}`}
      </div>
    </div>
  );
}
