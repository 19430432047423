import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from '../../components/WithRouter';
import { getApplicationOwners, inviteNewOwner, deleteOwner } from '../../api/application';
import { entities, errors, routes, titles } from '../../constants';
import { AuthContext } from '../../context/Auth.context';
import { ApplicationContext } from '../../context/Application.context';
import { isEmail } from '../../utils/validations';
import EntityList from '../../components/EntityList';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import TextArea from '../../components/TextArea';
import TextBox from '../../components/TextBox';
import EmailField from '../../components/EmailField';
import Controls from '../../components/Controls';
import Label from '../../components/Label';

const OWNERS_LIST = 'owners list';
const ADD_OWNER = 'add owner';
const newOwnerObj = {
  affiliation: null,
  email: null,
  fullName: null,
  initials: null
};

const ApplicationOwners = ({ navigate }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [authState] = useContext(AuthContext);
  const [view, setView] = useState(OWNERS_LIST);
  const [newOwner, setNewOwner] = useState(newOwnerObj);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const owners = state.owners;
  const hasOwners = owners && owners.length > 0;
  const multipleOwners = owners && owners.length > 1;
  const [ownersLoaded, setLoaded] = useState(hasOwners);
  const [loadingError, setLoadingError] = useState(null);
  const [error, setError] = useState(null);
  const [showRequired, setShowRequired] = useState(false);
  const isListView = view === OWNERS_LIST;
  const isAddView = view === ADD_OWNER;
  const appId = state && state.application && state.application.id;
  const userEmail = authState && authState.email;

  useEffect(() => {
    if (!hasOwners) {
      getApplicationOwners(appId, userEmail)
        .then(d => {
          setLoaded(true);
          dispatch({
            type: 'SET_OWNERS',
            payload: d
          });
        })
        .catch(e => setLoadingError('Error fetching owners.'));
    }
  }, []);

  const onValueChanged = (value, field) => {
    setNewOwner(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const entityTitle = person => {
    return person.fullName;
  };

  const handleAddOwner = () => {
    setView(ADD_OWNER);
  };

  const handleRemoveOwner = (e, data) => {
    e.stopPropagation();
    const user = {
      affiliation: data.affiliation,
      email: data.id.email,
      fullName: data.fullName,
      initials: data.initials
    };
    deleteOwner(appId, userEmail, user)
      .then(() => {
        const updateOwnersList = owners.filter(owner => owner.id !== data.id);

        if (user.email === userEmail) {
          navigate(routes.SIGNIN);
        } else {
          dispatch({
            type: 'SET_OWNERS',
            payload: updateOwnersList
          });
        }
      })
      .catch(e => setError(errors.TRY_AGAIN));
  };

  const handleAddCancel = () => {
    setView(OWNERS_LIST);
    setNewOwner(newOwnerObj);
  };

  const handleAddSubmit = () => {
    if (error) setError(null);
    const isValidEmails = isEmail(newOwner.email);
    if (!isValidEmails || !newOwner.email || !newOwner.firstName || !newOwner.lastName || !newOwner.affiliation) {
      setShowRequired(true);
      setError(errors.INCOMPLETE_DATA);
    } else {
      setShowRequired(false);
      setIsSubmitting(true);
      const userFullName = [newOwner.firstName, newOwner.middleName, newOwner.lastName];

      const data = {
        email: newOwner.email,
        fullName: userFullName.join(' ').replace(/\s+/g, ' ').trim(),
        initials: newOwner.firstName.charAt(0) + newOwner.lastName.charAt(0),
        affiliation: newOwner.affiliation
      };

      inviteNewOwner(appId, userEmail, data)
        .then(d => {
          const updateOwnersList = [...owners];
          updateOwnersList.push(d);
          setIsSubmitting(false);
          dispatch({
            type: 'SET_OWNERS',
            payload: updateOwnersList
          });
          setNewOwner(newOwnerObj);
          setView(OWNERS_LIST);
        })
        .catch(e => setError(errors.TRY_AGAIN));
    }
  };

  return (
    <div>
      <Loading type='table'
        loaded={ownersLoaded}
        error={loadingError}
        data={owners}>
        <div>
          {isListView &&
            <>
              <p>
                Below is a list of all Application Participants for this application. Any Participant may change any data within the application, add new participants, delete participants, submit payment, and sign and submit the completed application.
              </p>
              <EntityList
                className='mtMed'
                icon={'user'}
                list={owners}
                hideSubTitle
                entityTitle={entityTitle}
                addTitle={titles.ADD_NEW_PARTICIPANTS}
                confirmRemoveText={entities.CONFIRM_REMOVE_OWNER}
                handleAddClick={handleAddOwner}
                handleEntityRemove={multipleOwners && handleRemoveOwner} />
            </>}
          {isAddView && <div>
            <Title title={titles.ADD_NEW_PARTICIPANTS} type='h2' />
            <p>
              OTC Markets will send an email to the person below inviting them to participate in this application. The email will provide instructions to create a Gateway account and login.
            </p>
            <div className='innerGrid mbXL'>
              <div className='col6'>
                <EmailField
                  label='User Email'
                  placeholder='email@mail.com'
                  name='email'
                  isRequired={showRequired}
                  value={newOwner.email}
                  showClearButton
                  onValueChanged={e => onValueChanged(e.value, 'email')} />
              </div>
              <div className='col2'>
                <TextBox
                  label='First Name'
                  placeholder='First Name'
                  name='firstName'
                  isRequired={showRequired}
                  value={newOwner.firstName}
                  showClearButton
                  onValueChanged={e => onValueChanged(e.value, 'firstName')} />
              </div>
              <div className='col2'>
                <TextBox
                  label='Middle Name (optional)'
                  placeholder='Middle Name'
                  name='middleName'
                  value={newOwner.middleName}
                  showClearButton
                  onValueChanged={e => onValueChanged(e.value, 'middleName')} />
              </div>
              <div className='col2'>
                <TextBox
                  label='Last Name'
                  placeholder='Last Name'
                  name='lastName'
                  isRequired={showRequired}
                  value={newOwner.lastName}
                  showClearButton
                  onValueChanged={e => onValueChanged(e.value, 'lastName')} />
              </div>
              <div className='col6'>
                <TextArea
                  label='Affiliation'
                  showClearButton
                  placeholder='What is their relationship to the company?'
                  name='affiliation'
                  height={80}
                  maxLength={30}
                  errorText='Incomplete Data. Please enter Affiliation.'
                  isRequired={showRequired}
                  value={newOwner.affiliation}
                  onValueChanged={e => onValueChanged(e.value, 'affiliation')}
                />
              </div>
            </div>
            {error && <Label className='mbMed' isError>
              {error}
            </Label>}
            <Controls
              cancelText='Back'
              submitText='Send Invite'
              isSubmitDisabled={isSubmitting}
              onCancelClick={handleAddCancel}
              onSubmitClick={handleAddSubmit} />
          </div>}
        </div>
      </Loading>
    </div>
  );
};

export default withRouter(ApplicationOwners);
