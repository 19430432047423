import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from '../../WithRouter';
import { ApplicationContext } from '../../../context/Application.context';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import ApplicationDescription from '../../ApplicationDescription';
import OfficersTab from './OfficersTab';
import DirectorsTab from './DirectorsTab';
import ControlPersonTab from './ControlPersonTab';
import TabMenu from '../../TabMenu';
import { createID } from '../../../utils/helper';

const tabItems = [
  {
    title: 'Officers',
    path: 'officers',
    component: OfficersTab
  },
  {
    title: 'Directors',
    path: 'directors',
    component: DirectorsTab
  },
  {
    title: 'Control Persons',
    path: 'control-person',
    component: ControlPersonTab
  }
];

const OfficersDirectorsControlPersons = ({ navigate, match: { params: { appId, appSection, appTab } }, name, company, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const currentTab = tabItems.find(item => item.path === appTab);
  const [activeTab, setActiveTab] = useState(currentTab || tabItems[0]);
  const application = state.application;
  const backupApplication = state.backupApplication;
  const peopleList = application.peopleList || [];
  const corporateEntityList = application.corporateEntityList || [];
  const isReadOnly = state.readOnly;

  useEffect(() => {
    const isPeopleChange = !equal({ ...backupApplication.peopleList }, { ...application.peopleList });
    const isCorpEntityChange = !equal({ ...backupApplication.corporateEntityList }, { ...application.corporateEntityList });
    const isNoControlChange = backupApplication.noControlPerson !== application.noControlPerson;

    if (isPeopleChange || isCorpEntityChange || isNoControlChange) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    const newTab = tabItems.find(item => item.path === appTab);
    setActiveTab(newTab);
  }, [appTab]);

  useEffect(() => {
    activeTab && navigate(`/application/${appId}/${appSection}/${activeTab.path}`);
  }, [activeTab]);

  const onNoControlPersonChanged = e => {
    let updateApplication = application;
    updateApplication.noControlPerson = e.value;
    onUpdate(updateApplication);
  };

  const onUpdate = (data, field) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: field
    });
  };

  const onPersonAdd = person => {
    const newPerson = person;
    if (!person.id) {
      const id = createID(peopleList);
      newPerson.id = id;
    }

    onUpdate([...peopleList, newPerson], 'peopleList');
  };

  const onPersonUpdate = person => {
    let updatePeopleList = [...peopleList];

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id === person.id) return person;

      return item;
    });

    onUpdate(updatePeopleList, 'peopleList');
  };

  const onPersonRemove = (person, type) => {
    const updatePeopleList = peopleList;
    const personUpdate = updatePeopleList.find(item => item.id === person.id);

    if (type === 'isChairPerson') personUpdate.isDirector = false;
    personUpdate[type] = false;
    onUpdate(updatePeopleList, 'peopleList');
  };

  const onCorpEntityAdd = entity => {
    const newEntity = entity;
    const id = createID(peopleList);
    newEntity.id = id;
    onUpdate([...corporateEntityList, newEntity], 'corporateEntityList');
  };

  const onCorpEntityUpdate = entity => {
    let updateCorpEntityList = corporateEntityList;

    updateCorpEntityList = updateCorpEntityList.map(item => {
      if (item.id === entity.id) return entity;

      return item;
    });

    onUpdate(updateCorpEntityList, 'corporateEntityList');
  };

  const onCorpEntityRemove = entity => {
    const updateCorpEntityList = corporateEntityList;
    const corpEntityUpdate = updateCorpEntityList.filter(item => item.id !== entity.id);

    onUpdate(corpEntityUpdate, 'corporateEntityList');
  };

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const renderTab = () => {
    const Component = activeTab.component;
    return <Component
      company={company}
      noControlPerson={application && application.noControlPerson}
      peopleList={peopleList}
      corporateEntityList={corporateEntityList}
      readOnly={isReadOnly}
      onValueChange={onNoControlPersonChanged}
      onPersonAdd={onPersonAdd}
      onPersonUpdate={onPersonUpdate}
      onPersonRemove={onPersonRemove}
      onCorpEntityAdd={onCorpEntityAdd}
      onCorpEntityUpdate={onCorpEntityUpdate}
      onCorpEntityRemove={onCorpEntityRemove} />;
  };

  return (
    <div>
      <ApplicationDescription
        title={name} />
      {activeTab && <>
        <TabMenu items={tabItems} activeTab={activeTab.title} handleTabClick={handleTabClick} />
        {renderTab()}
      </>}
    </div>
  );
};

OfficersDirectorsControlPersons.propTypes = {
  name: PropTypes.string,
  saveApplication: PropTypes.func
};

export default withRouter(OfficersDirectorsControlPersons);
