import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const Divider = () => (
  <View style={styles.divider} />
);

const styles = StyleSheet.create({
  divider: {
    borderBottom: '1px solid #D4D4D4',
    marginBottom: '4px',
    marginTop: '-6px'
  }
});

export default Divider;
