import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../../components/WithRouter';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryObj } from '../../utils/helper';
import { confirmSignUp } from '../../api/auth';
import { errors, routes } from '../../constants';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Label from '../../components/Label';
import styles from './ConfirmAccountPage.module.scss';

const ConfirmAccountPage = ({ navigation, location }) => {
  const [verified, setVerify] = useState(false);
  const [error, setError] = useState(null);
  const query = getQueryObj(location.search);
  const hasQuery = !!(query.user_name && query.code);

  useEffect(() => {
    !hasQuery && navigation(routes.SIGNIN);

    hasQuery && confirmSignUp(query.user_name, query.code).then(data => {
      setVerify(true);
    }).catch(e => setError(e && e.message));
  }, []);
  return <div className={styles.container}>
    <div className='mtXXL'>
      {verified && <>
        <Title title='Account Verified' />
        <div className={cn('mtMed mbXL', styles.lockup)}>
          <FontAwesomeIcon className='icon' icon={['far', 'check-circle']} />
          <div>
            Your account has been verified. Please Sign In to start using the application.
          </div>
        </div>
        <Link to={routes.SIGNIN}>
          <Button title='Go to Sign In' color='gray' fullWidth buttonType='secondary' />
        </Link>
      </>}
      {error && <Label isError>
        {error} {errors.CONTACT}
      </Label>}
    </div>
  </div>;
};

export default withRouter(ConfirmAccountPage);
