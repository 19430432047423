import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { errors } from '../../constants';

const containerStyle = {
  'box-shadow': 'none',
  'font-size': '14px',
  'border': '1px solid #ddd'
};

const containerFocusStyle = {
  'box-shadow': 'none',
  'border-color': '#00a88f'

};

const errorStyle = {
  'box-shadow': 'none',
  'border-color': '#ddd',
  'font-size': '14px',
  'border-bottom-color': '#E02020',
  'border-bottom-width': '3px'
};
const errorFocusStyle = {
  'font-size': '14px',
  'box-shadow': 'none',
  'border-color': '#00a88f',
  'border-bottom-color': '#E02020',
  'border-bottom-width': '3px'
};

const CreditCardTextBox = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label;
  const isValid = props.isValid;
  const showError = props.error || (!props.value || props.value.trim().length === 0);
  const displayError = isValid && showError;
  const { meta, getCardNumberProps, getCardImageProps, wrapperProps } = usePaymentInputs();

  const handleFocusIn = e => {
    props.onFocusIn();
    setFocus(true);
  };

  const handleError = e => {
    props.onError(e);
  };

  const handleFocusOut = e => {
    props.onFocusOut();
    props.onMetaChanged(meta.cardType);
    setFocus(false);
  };

  const handleValueChange = e => {
    e.target && props.onValueChanged && props.onValueChanged(e.target);
  };

  return <div id='creditCardTextBox'>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} size={props.labelSize} />}
      {displayError && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <div>
      <PaymentInputsWrapper {...wrapperProps}
        styles={
          {
            fieldWrapper: {
              base: { 'width': '100%' }
            },
            inputWrapper: {
              base: displayError ? errorStyle : containerStyle,
              errored: displayError ? errorStyle : containerStyle,
              focused: displayError && isFocus ? errorFocusStyle : containerFocusStyle
            },
            input: {
              base: { width: '100% !important' }
            },
            errorText: {
              base: { display: 'none' }
            }
          }
        }
      >
        <svg {...getCardImageProps({ images })} />
        <input {...getCardNumberProps({ onChange: handleValueChange, onError: handleError, onFocus: handleFocusIn, onBlur: handleFocusOut })}
          placeholder={props.placeholder} value={props.value} />
      </PaymentInputsWrapper>
    </div>
  </div>;
};

CreditCardTextBox.propTypes = {
  label: PropTypes.string,
  labelSize: PropTypes.any,
  value: PropTypes.any,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onError: PropTypes.func,
  onMetaChanged: PropTypes.func,
  name: PropTypes.string,
  isValid: PropTypes.bool,
  onValueChanged: PropTypes.func,
  onOptionChanged: PropTypes.func
};

export default CreditCardTextBox;
