import React from 'react';
import moment from 'moment-timezone';
import { types } from '../../constants';
import { isInt } from '../../utils/validations';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Divider, SectionTitle, Value } from '../components';

const SuppDocsAdditional = ({ docs, docTypes, shareHoldRequired, logoRequired, letterResignRequired, boardRequired, changeCtrlRequired, appApptCustDocRequired, crtOrdGrntCustRequired, draftQbCertRequired }) => {
  const reqShareHolderDoc = shareHoldRequired && docs ? docs.find(doc => doc.typeId === types.SHAREH_LIST) : { id: 'NOID' };
  const reqDraftQbCert = draftQbCertRequired && docs ? docs.find(doc => doc.typeId === types.DRAFT_QB_CERT) : { id: 'NOID' };
  const reqLogoDoc = logoRequired && docs ? docs.find(doc => doc.typeId === types.LOGO) : { id: 'NOID' };
  const reqLetterResignDoc = letterResignRequired && docs ? docs.find(doc => doc.typeId === types.LETTER_RESIGN) : { id: 'NOID' };
  const reqBoardResolutionDoc = boardRequired && docs ? docs.find(doc => doc.typeId === types.BOARD) : { id: 'NOID' };
  const reqChangeCtrlDoc = changeCtrlRequired && docs ? docs.find(doc => doc.typeId === types.DSC_CHG_CTRL_EVT) : { id: 'NOID' };
  const reqAppApptCustDoc = appApptCustDocRequired && docs ? docs.find(doc => doc.typeId === types.APP_APPT_CUST) : {};
  const reqCrtOrdGrntCustDoc = crtOrdGrntCustRequired && docs ? docs.find(doc => doc.typeId === types.CRT_ORD_GRNT_CUST) : {};
  const additionalDocs = docs && docs.filter(doc => (!reqShareHolderDoc || doc.id !== reqShareHolderDoc.id) && (!reqLogoDoc || doc.id !== reqLogoDoc.id) &&
                          (!reqLetterResignDoc || doc.id !== reqLetterResignDoc.id) && (!reqBoardResolutionDoc || doc.id !== reqBoardResolutionDoc.id) &&
                          (!reqChangeCtrlDoc || doc.id !== reqChangeCtrlDoc.id) && (!reqAppApptCustDoc || doc.id !== reqAppApptCustDoc.id) &&
                          (!reqCrtOrdGrntCustDoc || doc.id !== reqCrtOrdGrntCustDoc.id) && (!reqDraftQbCert || doc.id !== reqDraftQbCert.id));
  const docTypesMap = docTypes && new Map(docTypes.map(doc => [doc.typeId, doc.description]));

  return (
    <>
      <View fixed>
        <SectionHeader text='SUPPORTING DOCUMENTS | ADDITIONAL' />
      </View>
      {additionalDocs && additionalDocs.map((doc, i) => {
        const pageBreak = i !== 0 && isInt(i / 4);
        const isShareholder = doc.typeId === types.SHAREH_LIST;
        const isDraftQbCert = doc.typeId === types.DRAFT_QB_CERT;
        const isPIF = doc.typeId === types.PERSONAL;
        const isBoard = doc.typeId === types.BOARD;
        const isBGCheck = doc.typeId === types.BG_CHECK;
        const isLetter = doc.typeId === types.LETTER_RESIGN;
        const isContracts = doc.typeId === types.CONTRACTS;
        const isFinReport = doc.typeId === types.FINANCIAL_REPORT;
        const isID = doc.typeId === types.ID;
        const isNobo = doc.typeId === types.NOBO_LIST;
        const isSuppDiscChngCtrl = doc.typeId === types.DSC_CHG_CTRL_EVT;
        const isTransJournal = doc.typeId === types.TRANS_JOURNAL;
        const isAppApptCust = doc.typeId === types.APP_APPT_CUST;
        const isCrtOrdGrntCust = doc.typeId === types.CRT_ORD_GRNT_CUST;
        const isOther = doc.typeId === types.OTHER;
        const showDocDate = isShareholder || isDraftQbCert || isBGCheck || isNobo || isPIF || isContracts || isSuppDiscChngCtrl || isTransJournal || isLetter || isBoard || isAppApptCust || isCrtOrdGrntCust;
        const docTitle = docTypesMap && docTypesMap.get(doc.typeId);

        return (
          <View break={pageBreak} key={`doc-${i}`}>
            <SectionTitle text={docTitle} />
            <Row>
              <Column size='50'>
                <Label text='UPLOAD DATE' />
                <Value text={doc.created && moment(doc.created).format('LL')} />
              </Column>
              {showDocDate && <Column size='50'>
                <Label text='DOCUMENT DATE' />
                <Value text={doc.attributes.asOfDate && moment(doc.attributes.asOfDate).format('LL')} />
              </Column>}
              {isFinReport && <Column size='50'>
                <Label text='Period End DATE' />
                <Value text={doc.attributes.periodEndDate && moment(doc.attributes.periodEndDate).format('LL')} />
              </Column>}
            </Row>
            {(isPIF || isLetter || isID) && <Row>
              <Column>
                <Label text='FULL NAME OF INDIVIDUAL LISTED ON DOCUMENT' />
                <Value text={doc.attributes.fullName} />
              </Column>
            </Row>}
            {isBGCheck && <Row>
              <Column>
                <Label text='FULL NAME OF INDIVIDUALS LISTED ON DOCUMENT' />
                <Value text={doc.attributes.individualNames.join(', ')} />
              </Column>
            </Row>}
            {isOther && <Row>
              <Column>
                <Label text='FILE DESCRIPTION' />
                <Value text={doc.attributes.description} />
              </Column>
            </Row>}
            <Divider />
          </View>
        );
      })
      }
      {!additionalDocs || additionalDocs.length === 0 && <Value emptyText='No additional documents.' />}
    </>
  );
};

export default SuppDocsAdditional;
