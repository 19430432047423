import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WebFont from 'webfontloader';
import './styles/dx.common.scss';
import './styles/dx.light.scss';
import './styles/global.scss';
import * as serviceWorker from './serviceWorker';
import 'normalize.css/normalize.css';
import './icons';

// load OTC fonts
WebFont.load({
  google: {
    families: [
      'Nunito Sans:200,200i,400,400i,600,600i,700,700i,800,800i900,900i',
      'Source Serif Pro:400,700'
    ]
  }
});

// set version number in html element
const version = process.env.REACT_APP_VERSION;
const el = document.querySelector('html');
el.setAttribute('data-ui-version', version);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
