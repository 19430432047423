import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import cn from 'classnames';
import { entities } from '../../../constants';
import { validateCompleteSecurity, validateCompleteDNSSecurity } from '../../../constants/cardComplete';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import FieldError from '../../FieldError';
import EntityList from '../../EntityList';
import Title from '../../Title';
import SlidePanel from '../../SlidePanel';
import QuestionCard from '../../QuestionCard';
import AddSecurityDetail from './AddSecurityDetail';
import DropContainer from '../../DropContainer';
import TargetDrag from '../../TargetDrag';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createID } from '../../../utils/helper';
import styles from './SecurityDetails.module.scss';

const SECURITY_BOX = 'securityBox';
const AVAILABLE_BOX = 'availableBox';
const APP_TYPE_NAME = 'REPLACE TEXT';
const ADD_SECURITY = 'Add New Security';
let TRADED_TEXT = `Include Security with ${APP_TYPE_NAME} Application`;

const question = {
  title: 'Voting Rights, Dividend Payments, Other Preferences',
  subTitle: 'Do the securities added above entitle holder(s) to differential voting rights, dividend payments, or other preferences?',
  fieldName: 'hasVotingRightsDividends',
  trueDetails: [
    {
      desc: 'Provide description of such rights and the names of the shareholders who own the preferential shares.',
      fieldName: 'hasVotingRightsDividendsDetail'
    }
  ]
};

const SecurityDetails = ({ name, company, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [securityListFocus, setSecurityListFocus] = useState(false);
  const application = state.application;
  const appId = application.typeId;
  const appTypeName = application.typeDesc;
  const backupApplication = state.backupApplication;
  const securityDetails = application.securityDetails;
  const securityList = securityDetails && securityDetails.securityList;
  const [tradedField, setTradedField] = useState(null);
  const [isTradedText, setTradedText] = useState(TRADED_TEXT);
  const qxTradedSecurities = securityList && securityList.filter(sec => sec[tradedField]);
  const availableSecurities = securityList && securityList.filter(sec => (sec.isExistingSecurity && !sec[tradedField]) || !sec[tradedField]);
  const [slidePane, setSlidePane] = useState(false);
  const [secDetailView, setSecDetailView] = useState(false);
  const [dragItem, setDragItem] = useState(null);
  const hasQXTradedSec = qxTradedSecurities && qxTradedSecurities.length > 0;
  const isDNS = application.typeId === 5;
  const isOTCQXIntl = application.typeId === 3;
  const reportingStandard = application.reportingStandard;
  const financialStandards = application.financialStandards;
  const showDragDrop = !isDNS;
  const minimumSecText = `Minimum one security must be used to apply for ${appTypeName}.`;
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;
  const showTradingIncomplete = (showIncompletes && !hasQXTradedSec);
  const showDNSIncomplete = showIncompletes && (!securityList || securityList.length < 1);

  let pageDesc = [
    {
      text: `Enter each security the Company is applying to trade on ${appTypeName} and provide detailed security information below.`
    },
    {
      text: 'You may either drag and drop a security from the Available Securities list below or add a new security.'
    }
  ];

  if (isDNS) {
    pageDesc = [
      {
        text: 'Provide security level information for the securities listed below.'
      }
    ];
  }

  useEffect(() => {
    setTradedText(TRADED_TEXT.replace(APP_TYPE_NAME, appTypeName));
    switch (appId) {
      case 1:
      case 2:
      case 3:
        setTradedField('isQXtraded');
        break;
      case 4:
        setTradedField('isQBtraded');
        break;
      case 4:
        setTradedField('isDNStraded');
        break;
    }
  }, []);

  useEffect(() => {
    if (!equal({ ...backupApplication.securityDetails, version: null }, { ...application.securityDetails, version: null })) {
      saveApplication();
    }
  }, [backupApplication, application]);

  useEffect(() => {
    if (!slidePane) setSecDetailView(null);
  }, [slidePane]);

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'securityDetails',
      card: 'security-details'
    });
  };

  const onValueChanged = (value, field) => {
    const updateSecDetails = securityDetails;
    updateSecDetails[field] = value;
    onUpdate(updateSecDetails);
  };

  const handleAdd = data => {
    const updateSecDetails = securityDetails || {};
    const updatedSecurities = securityList || [];
    const newSecurity = data;
    const id = createID(securityList);

    newSecurity.secId = id;
    updatedSecurities.push(newSecurity);

    updateSecDetails.securityList = updatedSecurities;
    onUpdate(updateSecDetails);

    setSlidePane(false);
  };

  const handleUpdate = (data) => {
    const updateSecDetails = securityDetails;
    const updatedSecurities = securityList.map(sec => {
      if (sec.secId === data.secId) {
        return data;
      } else {
        return sec;
      }
    });

    updateSecDetails.securityList = updatedSecurities;

    onUpdate(updateSecDetails);

    setSlidePane(false);
  };

  const handleDropUpdate = (data) => {
    const updateSecDetails = securityDetails;
    const updatedSecurities = securityList.map(sec => {
      if (sec.secId === dragItem.secId) {
        return data;
      } else {
        return sec;
      }
    });

    updateSecDetails.securityList = updatedSecurities;

    onUpdate(updateSecDetails);
  };

  const handleEdit = (item) => {
    setSecDetailView(item);
    setSlidePane(true);
  };

  const handleRemove = (e, item) => {
    e.stopPropagation();
    const updateSecDetails = securityDetails;
    updateSecDetails.securityList = securityList.filter(sec => sec.secId !== item.secId);

    onUpdate(updateSecDetails);
  };

  const entityTitle = item => {
    const symbol = item.symbol && `${item.symbol} - `;
    let title = entities.NO_NAME;
    if (item.type && item.securityClass) {
      title = `${symbol || ''}${item.type}${item.securityClass ? ' - Class ' + item.securityClass : ''}`;
    }
    return title;
  };

  const entityTitles = item => {
    if (item.metadata && item.metadata.length > 0) {
      let titles = [entities.NO_NAME];
      const securityTitles = item.metadata.map(metadata => {
        return `${metadata.symbol}/${metadata.classname}`;
      });

      if (securityTitles && securityTitles.length > 0) titles = securityTitles.join(', ');
      return titles;
    } else {
      return entityTitle(item);
    }
  };

  const handleHideRemove = item => {
    const hide = item.isExistingSecurity;

    return hide;
  };

  const handleDrag = item => {
    setDragItem(item);
  };

  const handleDrop = (item, boxType) => {
    let updateItem = { ...item, [tradedField]: true };
    if (boxType === SECURITY_BOX) updateItem[tradedField] = false;
    handleDropUpdate(updateItem);
    setDragItem(null);
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={pageDesc} />
      {showDragDrop && <DndProvider backend={HTML5Backend}>
        <div
          onMouseEnter={() => setSecurityListFocus(true)}
          onMouseLeave={() => setSecurityListFocus(false)}>
          <div className={cn({
            [styles.incomplete]: showTradingIncomplete
          })}>
            <Title className={styles.title} title={`Apply these Securities for ${appTypeName}`} type='h3' />
            {showTradingIncomplete && <FieldError error={minimumSecText} isFocus={securityListFocus} />}
          </div>
          <DropContainer boxType={AVAILABLE_BOX}>
            <EntityList
              addTitle={ADD_SECURITY}
              list={qxTradedSecurities}
              entityTitle={entityTitles}
              icon='fileCert'
              iconComplete='fileCert'
              showIncompletes={showIncompletes}
              readOnly={isReadOnly}
              confirmRemoveText={entities.CONFIRM_REMOVE_SECURITY}
              handleHideRemove={handleHideRemove}
              validateComplete={item => validateCompleteSecurity(item, reportingStandard, financialStandards)}
              handleAddClick={() => setSlidePane(true)}
              handleEntityClick={handleEdit}
              handleEntityRemove={!isReadOnly && handleRemove}>
              {(Component, item, key) => <div key={key} stlye={{ padding: '10px', background: '#ff0000' }}>
                {<TargetDrag boxType={SECURITY_BOX} dragItem={item} onDrag={handleDrag} onDrop={handleDrop}>
                  <Component />
                </TargetDrag>}
              </div>}
            </EntityList>
          </DropContainer>
        </div>
        {(hasQXTradedSec && !isOTCQXIntl) && <div className='mtXL'>
          <QuestionCard
            item={question}
            section={securityDetails}
            readOnly={isReadOnly}
            handleQuestionClick={onValueChanged}
            onValueChanged={onValueChanged} />
        </div>}
        <div className='mtXL'>
          <Title title='Available Securities' type='h3' />
          <DropContainer boxType={SECURITY_BOX}>
            <EntityList
              list={availableSecurities}
              entityTitle={entityTitles}
              icon='fileCert'
              confirmRemoveText={entities.CONFIRM_REMOVE_SECURITY}
              showIncompletes={showIncompletes}
              validateComplete={item => validateCompleteSecurity(item, reportingStandard, financialStandards)}
              hideSubTitle
              handleHideRemove={handleHideRemove}
              emptyTitle='No Available Securities'
              readOnly={isReadOnly}
              handleEntityClick={handleEdit}
              handleEntityRemove={!isReadOnly && handleRemove}>
              {(Component, item, key) => <div key={key} stlye={{ padding: '10px', background: '#ff0000' }}>
                {<TargetDrag boxType={AVAILABLE_BOX} dragItem={item} onDrag={handleDrag} onDrop={handleDrop}>
                  <Component />
                </TargetDrag>}
              </div>}
            </EntityList>
          </DropContainer>
        </div>
      </DndProvider>}
      {isDNS && <div
        onMouseEnter={() => setSecurityListFocus(true)}
        onMouseLeave={() => setSecurityListFocus(false)}>
        <div className={cn({
          [styles.incomplete]: showDNSIncomplete
        })}>
          <Title className={styles.title} title={`Securities linked to ${company}`} type='h3' />
          {showDNSIncomplete && <FieldError error={minimumSecText} isFocus={securityListFocus} />}
        </div>
        <EntityList
          addTitle={ADD_SECURITY}
          list={securityList}
          entityTitle={entityTitles}
          icon='fileCert'
          iconComplete='fileCert'
          readOnly={isReadOnly}
          showIncompletes={showIncompletes}
          confirmRemoveText={entities.CONFIRM_REMOVE_SECURITY}
          handleHideRemove={handleHideRemove}
          validateComplete={item => validateCompleteDNSSecurity(item, reportingStandard)}
          handleAddClick={() => setSlidePane(true)}
          handleEntityClick={handleEdit}
          handleEntityRemove={!isReadOnly && handleRemove} />
      </div>}
      <SlidePanel
        isOpen={slidePane}
        onClose={() => setSlidePane(false)}
        // use entityTitles
        title={secDetailView ? entityTitles(secDetailView) : ADD_SECURITY}>
        <AddSecurityDetail
          securityView={secDetailView}
          readOnly={isReadOnly}
          tradedField={tradedField}
          isTradedText={isTradedText}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          setSlidePane={setSlidePane}
          isDNS={isDNS}
          reportingStandard={reportingStandard}
          financialStandards={financialStandards}
          showIncompletes={showIncompletes} />
      </SlidePanel>
    </div>
  );
};

SecurityDetails.propTypes = {
  name: PropTypes.string
};

export default SecurityDetails;
