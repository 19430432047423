import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Footnote.module.scss';

const Footnote = ({ text, className }) => {
  const Component = text;
  const isComponent = typeof Component === 'function';

  return (
    <p className={cn(className, styles.container)}>
      {isComponent ? <Component /> : text}
    </p>
  );
};

Footnote.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default Footnote;
