import axios from 'axios';
import queryString from 'query-string';

axios.interceptors.request.use(function (config) {
  // Serialize the parameteters
  config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: 'brackets' });
  return config;
});

export default {
  request: async (options) => {
    const response = await axios(options);
    return response;
  }
};
