import React from 'react';
import moment from 'moment-timezone';
import { View } from '@react-pdf/renderer';
import { getBackgroundList, renderFullName } from '../../utils/helper';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { format } from '../../utils/locale';

const BackgroundCheck = ({ type, people, entities }) => {
  const bgList = getBackgroundList(people, entities, type);
  const hasBgList = bgList && bgList.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='BACKGROUND CHECK AUTHORIZATION' />
      </View>
      {bgList && bgList.map((person, i) => {
        const hasDateOfBirth = person.birthMonth && person.birthDay && person.birthYear;
        const isBGExempt = person.isBGExempt;

        return (
          <View key={`bgcheck-${i}`} break={i > 0}>
            {isBGExempt && <View>
              <View fixed>
                <SectionTitle text={renderFullName(person.firstName, person.middleName, person.lastName)} />
                <Row>
                  <Column size='75'>
                    <Label text='Question' />
                    <Value text='Does this individual not have any direct or indirect involvement in, or control over, the operations, management or activities of the Company?' />
                  </Column>
                  <Column size='25'>
                    <Label text='Answer' />
                    <Value text={format(person.isBGExempt, null, 'bool')} />
                  </Column>
                </Row>
                <Row>
                  <Column size='100'>
                    <Label text='DESCRIPTION' />
                    <Value text={person.bgExemptDetails} />
                  </Column>
                </Row>
              </View>
            </View>}
            {!isBGExempt && <View>
              <View fixed>
                <SectionTitle text={renderFullName(person.firstName, person.middleName, person.lastName)} />
                <Row>
                  <Column>
                    <Label text='Pif Attached' />
                    <Value text={person.pifUploadedId ? 'Yes' : 'No'} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='Other Names' />
                    <Value text={person.otherNames} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='DATE OF BIRTH' />
                    <Value text={hasDateOfBirth ? moment(`${person.birthMonth}/${person.birthDay}/${person.birthYear}`).format('LL') : null} />
                  </Column>
                </Row>
                <Row>
                  <Column size='25'>
                    <Label text='HOME CITY' />
                    <Value text={person.city} />
                  </Column>
                  <Column size='25'>
                    <Label text='HOME State' />
                    <Value text={person.state} />
                  </Column>
                  <Column size='50'>
                    <Label text='HOME Country' />
                    <Value text={person.country} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='LIMITATION ON BUSINESS OR SECURITY ACTIVITY' />
                    <Value text={person.isLimitedActivities ? 'Yes' : 'No'} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='VIOLATION OF SECURITIES OR COMMODITIES LAW' />
                    <Value text={person.isViolationOfSecLaw ? 'Yes' : 'No'} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='LIMITATIONS ON BUSINESS, SECURITIES, COMMODITIES, OR BANKING ACTIVITIES' />
                    <Value text={person.isLimitedActivitiesFiveYears ? 'Yes' : 'No'} />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label text='CRIMINAL PROCEEDINGS' />
                    <Value text={person.isConvicted ? 'Yes' : 'No'} />
                  </Column>
                </Row>
                <SectionTitle text='FINRA or SEC Registration' />
                <Row>
                  <Column size='75'>
                    <Label text='Question' />
                    <Value text={`Has ${person.firstName} ${person.lastName} ever been a licensed registered\nrepresentative with FINRA or registered as an investment\nadvisor with the SEC or a U.S. state?`} />
                  </Column>
                  <Column size='25'>
                    <Label text='Answer' />
                    <Value text={format(person.isFinraOrSecRegistered, null, 'bool')} />
                  </Column>
                </Row>
                {person.isFinraOrSecRegistered && <>
                  <Row>
                    <Column size='25'>
                      <Label text='CRD Number' />
                      <Value text={person.crdNumber} />
                    </Column>
                    <Column size='75'>
                      <Label text='SEC Number' />
                      <Value text={person.secNumber} />
                    </Column>
                  </Row>
                </>}
              </View>
            </View>}
          </View>
        );
      })}
      {!hasBgList && <Value text={null} />}
    </>
  );
};

export default BackgroundCheck;
