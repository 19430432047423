import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'devextreme-react/tooltip';
import { accountTypes, accountTypeChecking, controls, SECURE_PAY_DESC } from '../../../constants';
import isEmail from 'validator/lib/isEmail';
import { checkBankAccountNumber, checkBankRoutingNumber, achPaymentInfoComplete } from '../../../constants/cardComplete';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import Controls from '../../Controls';
import { ApplicationContext } from '../../../context/Application.context';
import { format } from '../../../utils/locale';
import Label from '../../Label';
import appStyles from '../Application.module.scss';
import styles from './PaymentSubmit.module.scss';

const achDisclosureId = 'achDisclosureId';
const achDisclosureTooltipText = 'Please note that by entering the company’s routing and account number, as an authorized company representative, you authorize the payment in the amount indicated below to be processed as an electronic funds transfer (EFT) or draft drawn from the company’s checking or savings account as indicated and, if necessary, electronic credits to the company’s account to correct erroneous debits. You understand that the processing of the payment will be completed within 1 - 2 banking days. If the payment returns unpaid, you authorize OTC Markets Group Inc. or our service provider to collect the payment by EFT(s) or draft(s) drawn from the company account. You understand that this authorization will remain in full force and effect until you notify us that you wish to revoke it by contacting us and allowing us reasonable opportunity to act on your notice. You accept these terms, acknowledge these disclosures and authorize this payment on behalf of the company and further agree, on the company’s behalf, that the company shall be bound by the NACHA Rules in effect, both now and as amended from time to time.';
const routingNumberError = 'Incomplete Data. Routing Number should be of 9 digits';

const AchPayment = ({ closeSlidePane, paymentError, submitPayment, handleAddPayment, isReducedFeeTypeUpgrade, payment }) => {
  const DEFAULT_ACH_PAYMENT = { ...payment, accountType: payment.accountType || accountTypeChecking };
  const [state, dispatch] = useContext(ApplicationContext);
  const [readyToPay, setReadyToPay] = useState(false);
  const [achPaymentInfo, setAchPaymentInfo] = useState({ ...DEFAULT_ACH_PAYMENT });
  const [showAchDisclosureToolTip, setShowAchDisclosureToolTip] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const showIncompletes = state.showIncompletes;

  const onValueChanged = (value, field) => {
    setAchPaymentInfo(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleCancel = () => {
    if (readyToPay) {
      setReadyToPay(false);
    } else {
      closeSlidePane();
    }
  };

  const handleAdd = () => {
    setShowRequired(true);
    if (achPaymentInfoComplete(achPaymentInfo)) {
      handleAddPayment({ ...achPaymentInfo, paymentType: achPaymentInfo.accountType });
    }
  };

  return (
    <div>
      <div>
        <p>
          By entering the company's routing and account number, as an authorized company representative, you hearby authorize payment of the Application Fee in full. <a id={achDisclosureId} onMouseEnter={() => setShowAchDisclosureToolTip(true)} onMouseLeave={() => setShowAchDisclosureToolTip(false)}>See full disclosure</a>
        </p>
        <p>{SECURE_PAY_DESC}</p>
        <Tooltip
          maxWidth={600}
          target={`#${achDisclosureId}`}
          visible={showAchDisclosureToolTip}
          position='bottom'
          hideOnOutsideClick={false}
        >
          <div>
            {achDisclosureTooltipText}
          </div>
        </Tooltip>

        <img className={styles.sampleCheck} src='/images/sample-check.png' alt='ACH Sample Check' />
        <div className={cn('mtMed', appStyles.innerGrid)}>
          <div className={appStyles.col3}>
            <TextBox
              label={'Name of Account Holder'}
              placeholder='Name'
              name='fullName'
              showClearButton
              value={achPaymentInfo.fullName}
              isRequired={showIncompletes || showRequired}
              onValueChanged={e => onValueChanged(e.value, 'fullName')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label={'Email of Account Holder'}
              placeholder='Email'
              name='email'
              showClearButton
              value={achPaymentInfo.email}
              isRequired={showIncompletes || showRequired}
              isValid={achPaymentInfo.email ? isEmail(achPaymentInfo.email) : true}
              onValueChanged={e => onValueChanged(e.value, 'email')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label='Routing Number'
              placeholder='XXXXXXXXXX'
              name='bankRoutingNumber'
              showClearButton
              value={achPaymentInfo.bankRoutingNumber}
              isRequired={showIncompletes || showRequired}
              isValid={achPaymentInfo.bankRoutingNumber ? checkBankRoutingNumber(achPaymentInfo.bankRoutingNumber) : true}
              errorText={routingNumberError}
              onValueChanged={e => onValueChanged(e.value, 'bankRoutingNumber')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label='Account Number'
              placeholder='XXXXXXXXXX'
              name='bankAccountNumber'
              showClearButton
              value={achPaymentInfo.bankAccountNumber}
              isRequired={showIncompletes || showRequired}
              isValid={achPaymentInfo.bankAccountNumber ? checkBankAccountNumber(achPaymentInfo.bankAccountNumber) : true}
              onValueChanged={e => onValueChanged(e.value, 'bankAccountNumber')}
            />
          </div>
          <div className={appStyles.col3}>
            <SelectBox
              label='Account Type'
              name='accountType'
              placeholder='Select Account Type'
              valueExpr='id'
              displayExpr='text'
              items={accountTypes}
              value={achPaymentInfo.accountType}
              onValueChanged={e => onValueChanged(e.value, 'accountType')}
            />
          </div>
        </div>
      </div>
      <div className={cn('mtXXL', appStyles.controls)}>
        <Controls
          cancelText={controls.CANCEL}
          submitText={controls.ADD_PAYMENT}
          onCancelClick={handleCancel}
          onSubmitClick={handleAdd} />
        {paymentError && <Label className='mtLg' isError title={paymentError} />}
      </div>
    </div>
  );
};

AchPayment.propTypes = {
  payment: PropTypes.object,
  submitPayment: PropTypes.func,
  paymentError: PropTypes.any,
  isReducedFeeTypeUpgrade: PropTypes.bool,
  closeSlidePane: PropTypes.func
};

export default AchPayment;
