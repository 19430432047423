import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { withRouter } from '../../WithRouter';
import { errors, reportingStandards, urls, types } from '../../../constants';
import { AuthContext } from '../../../context/Auth.context';
import { NotificationsContext } from '../../../context/Notifications.context';
import { ApplicationContext } from '../../../context/Application.context';
import { TypesContext } from '../../../context/Types.context';
import { uploadApplicationDoc, deleteApplicationDoc } from '../../../api/document';

import { validateCompletePersonBackground, validDnsSkipCompany } from '../../../constants/cardComplete';
import ApplicationDescription from '../../ApplicationDescription';
import EntityList from '../../EntityList';
import Entity from '../../Entity';
import SlidePanel from '../../SlidePanel';
import PrivacyPolicyDisclaimer from '../../PrivacyPolicyDisclaimer';
import Label from '../../Label';
import BackgroundCheckForm from './BackgroundCheckForm';
import PIFForm from './PIFForm';
import { getBackgroundList } from '../../../utils/helper';

const skipText = 'This section is currently not applicable to your company. OTC Markets may reach out separately if it deems background checks are necessary during the application review process. Select the Next button below to continue with your application.';

const BackgroundCheckAuthorization = ({ name, company, saveApplication, match: { params: { appId } } }) => {
  const [authState] = useContext(AuthContext);
  const [state, dispatch] = useContext(ApplicationContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const hasIncompleteNotification = notificationState.notifications.find(n => n.type === 'incompletes');
  const [typesState] = useContext(TypesContext);
  const [slidePane, setSlidePane] = useState(false);
  const [childChange, setChildChange] = useState(null);
  const [personView, setPersonView] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [pifVIew, setPIFView] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const userFullName = authState && authState.fullName;
  const userEmail = authState && authState.email;
  const documents = state.documents;
  const pifTitle = typesState.docTypes.find(type => type.typeId === types.PERSONAL).description;
  const reportingStandard = state.application.reportingStandard;
  const storageKey = `PPD${appId}`;
  const application = state.application;
  const backupApplication = state.backupApplication;
  const appType = typesState.appTypes.find(type => type.id === application.typeId).typeId;
  const appTypeId = application.typeId;
  const peopleList = application && application.peopleList;
  const corporateEntityList = application && application.corporateEntityList;
  const isOTCQXUS = appTypeId === 1;
  const isOTCQXBanks = appTypeId === 2;
  const isOTCQXIntl = appTypeId === 3;
  const isOTCQB = appTypeId === 4;
  const isDNS = appTypeId === 5;
  const bgCheckPeopleList = getBackgroundList(peopleList, corporateEntityList, appTypeId);
  const completeCards = state.completeCards;
  const showIncompletes = state.showIncompletes;
  const canSkip = isDNS && application && validDnsSkipCompany(application.companyInfo, application.reportingStandard);
  const pageDesc = [
    {
      text: `Provide the personal information requested below for all individuals associated with ${company}.`
    },
    {
      text: 'Background checks may not be required for certain independent, non-affiliate shareholders of less than 10% of any class of securities.'
    }
  ];

  let maxSection = 10;
  let isReady = completeCards['company-information'] &&
    completeCards['reporting-standards'] &&
    completeCards['officers-directors-control-persons'] &&
    completeCards['service-providers'] &&
    completeCards['security-details'] &&
    completeCards['security-ownership'] &&
    completeCards['additional-services'];

  if (isOTCQXUS || isOTCQXBanks || isOTCQXIntl) {
    isReady = isReady && completeCards['financial-standards'];
  }
  if (isOTCQXUS || isOTCQXBanks || isOTCQXIntl || isOTCQB) isReady = isReady && completeCards['eligibility-standards'] && completeCards['legal-disciplinary-history'];
  if (isOTCQB) {
    isReady = isReady && completeCards['designate-contacts'];
  }
  if (isDNS) {
    isReady = isReady && completeCards['change-in-control'];
    maxSection = 9;
  }

  useEffect(() => {
    if (!equal({ ...backupApplication.peopleList }, { ...application.peopleList })) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    if (isReady && !localStorage.getItem(storageKey)) {
      setShowDisclaimer(true);
    }
  }, []);

  useEffect(() => {
    if (!slidePane) {
      setPIFView(false);
    }
  }, [slidePane]);

  let personViewTitle = '';

  if (personView) {
    const isOfficer = personView.isOfficer;

    if (isOfficer) personViewTitle = personView.title1;
    if (!isOfficer && personView.isDirector) personViewTitle = 'Director';
    if (!isOfficer && personView.isControl) personViewTitle = 'Control Person';
    if (!isOfficer && personView.isCorporateEntity) personViewTitle = 'Beneficial Owner';
  }

  const handleFormRender = () => {
    if (!pifVIew) return true;
  };

  const handlePifRender = () => {
    return pifVIew;
  };

  const handleDisclaimerClose = () => {
    if (!localStorage.getItem(storageKey)) {
      localStorage.setItem(storageKey, new Date().getTime());
    }

    setShowDisclaimer(false);
  };

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'peopleList',
      card: 'background-check-authorization'
    });
  };

  const onPanelClose = () => {
    setError(null);
    setIsSuccess(false);
    setSlidePane(false);
    setTimeout(() => setIsUploading(false), 1000);
  };

  const handleFileSubmit = (file, attributes) => {
    setIsUploading(true);
    const userFullName = [authState.firstName, authState.middleName, authState.lastName];
    const uploaderName = userFullName.join(' ').replace(/\s+/g, ' ').trim();

    uploadApplicationDoc(types.PERSONAL, application.id, userEmail, file, { ...attributes, uploaderName })
      .then(d => {
        const id = new Date().getTime();

        onPersonUpdate({ ...personView, pifUploadedId: d.id });

        const updatedDocsList = documents ? [...documents] : [];
        updatedDocsList.push(d);

        dispatch({
          type: 'SET_DOCUMENTS',
          payload: updatedDocsList
        });

        dispatchNotification({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: id,
            description: `Your document has been uploaded. ${moment().format('MMMM D, YYYY')}`,
            type: 'save'
          }
        });

        setTimeout(() => {
          dispatchNotification({
            type: 'REMOVE_NOTIFICATION',
            id: id
          });
        }, 3000);

        onPanelClose();
      })
      .catch(error => {
        const id = new Date().getTime();
        setIsUploading(false);
        const errorMsg = error && error.response && error.response.data && error.response.data.message;

        dispatchNotification({
          type: 'FAILED_SAVE_APPLICATION',
          payload: {
            id: id,
            description: `Error Uploading File${errorMsg ? ': ' + errorMsg : ''} ${moment().format('MMMM D, YYYY')}`,
            type: 'error'
          }
        });
        setError(errors.FILE_UPLOAD);
      });
  };

  const handleEdit = person => {
    setPersonView(person);
    setSlidePane(true);
  };

  const onPersonUpdate = (data, closeSLide) => {
    const updatePeopleList = peopleList.map(person => {
      if (person.id === data.id) {
        return data;
      } else {
        return person;
      }
    });

    onUpdate(updatePeopleList);
    setPersonView(data);
    if (closeSLide) onPanelClose();
  };

  const handleRemovePif = doc => {
    deleteApplicationDoc(doc.id, application.id, userEmail)
      .then(() => {
        delete personView.pifUploadedId;
        onPersonUpdate(personView);
      })
      .catch(e => setError(errors.FILE_UPLOAD));
  };

  const handleMailTo = person => {
    const email = person.email;
    const body = `Dear ${person.firstName && person.firstName} ${person.lastName && person.lastName},
%0D%0A
%0D%0A
${company.replace('&', '%26')} is currently applying for ${application.typeDesc.replace('&', '%26')} and as part of the application process, OTC Markets requires a Personal Information Form from you.
%0D%0A
%0D%0A
Please fill out the Personal Information Form (${urls.PIF_URL}) for yourself and send it back to me so that I can submit it along with ${company.replace('&', '%26')}’s application.  
%0D%0A
%0D%0A
Let me know if you have any questions.
%0D%0A
%0D%0A
Thank you,
%0D%0A
${userFullName}`;
    window.location.href = `mailto:${email}?subject=Personal Information Form Request – OTC Markets Application&body=${body}`;
  };

  const entityTitle = person => {
    return `${person.firstName} ${person.lastName}`;
  };

  const handleIncompleteClick = () => {
    !hasIncompleteNotification && dispatchNotification({
      type: 'ADD_NOTIFICATION',
      payload: {
        id: new Date().getTime(),
        description: 'You are now able to view all Incomplete Data Fields',
        type: 'incompletes'
      }
    });

    dispatch({
      type: 'SET_INCOMPLETES',
      payload: true
    });
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={canSkip ? null : pageDesc} />
      {canSkip &&
        <Label className='mtLg' title={skipText} />
      }
      {!canSkip && !isReady && <Label isError>
        <b>
          Please complete Sections 1 through {maxSection} in order to access the Background Check Authorization section. If you want to view all the incomplete fields on the application, <a onClick={handleIncompleteClick}>Click Here</a>
        </b>
      </Label>}
      {!canSkip && isReady && <>
        <div>
          <Entity
            title={'Disclaimer for Data Collection and Privacy Policy'}
            subTitle='View'
            size='small'
            onClick={() => setShowDisclaimer(true)} />
          {showDisclaimer && <PrivacyPolicyDisclaimer onClose={handleDisclaimerClose} />}
        </div>
        <EntityList
          className='mtXL'
          title={`All Individuals Associated with ${company}`}
          icon={'user'}
          iconComplete={'userComplete'}
          showIncompletes={showIncompletes}
          list={bgCheckPeopleList}
          entityTitle={entityTitle}
          validateComplete={person => validateCompletePersonBackground(appType, person, corporateEntityList, application.companyInfo, reportingStandard)}
          handleEntityClick={handleEdit} />
        <SlidePanel
          isOpen={slidePane}
          childChange={childChange}
          onClose={() => setSlidePane(false)}
          title={personView && `${personView.firstName} ${personView.lastName}`}
          subTitle={personViewTitle}>
          {handlePifRender() && <PIFForm
            company={company}
            onMailTo={handleMailTo}
            personView={personView}
            pifFile={personView && personView.pifUploadedId && (documents && documents.find(doc => doc.id === personView.pifUploadedId))}
            pifTitle={pifTitle}
            setChildChange={setChildChange}
            setPIFView={setPIFView}
            showIncompletes={showIncompletes}
            handleUpdate={onPersonUpdate}
            onFileSubmit={handleFileSubmit}
            onRemoveClick={handleRemovePif}
            errorMsg={error}
            isUploading={isUploading}
            isSuccess={isSuccess}
            setSlidePane={setSlidePane} />}
          {handleFormRender() && <BackgroundCheckForm
            personView={personView}
            setPIFView={setPIFView}
            setChildChange={setChildChange}
            handleUpdate={onPersonUpdate}
            setSlidePane={setSlidePane} /> }
        </SlidePanel>
      </>}
    </div>
  );
};

BackgroundCheckAuthorization.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string,
      applicationCard: PropTypes.string
    })
  })
};

export default withRouter(BackgroundCheckAuthorization);
