import React from 'react';
import PropTypes from 'prop-types';
import { accountTypes } from '../../../constants';
import { format } from '../../../utils/locale';
import EntityTitle from '../../Entity/EntityTitle';
import Title from '../../Title';
import styles from './PaymentSubmit.module.scss';

const ReviewAchPayment = ({ payment, paymentInfo, companyAddress, applicationBasicFee, isReducedFeeTypeUpgrade }) => {
  const companyAddressState = (companyAddress && companyAddress.state) || '';
  const applicationReducedFee = isReducedFeeTypeUpgrade && format(paymentInfo.reducedFee, paymentInfo.reducedFee, 'currency');
  const taxAmount = payment.taxAmount && format(payment.taxAmount, payment.taxAmount, 'currency');
  const applicationTotalFee = format(payment.appFeeTotal, payment.appFeeTotal, 'currency');
  const accountTypeSelected = accountTypes.find(accountType => accountType.id === paymentInfo.accountType).text;

  return (
    <div id='reviewPaymentContainer' className={styles.reviewPayment}>
      <Title className='mtXL' title='Ready For Payment' type='h2' />
      <p>Please carefully review the payment information and verify it is correct before submitting the payment.</p>
      <Title className='mtLg' title='' type='h2' />
      <p><span className={styles.fieldValue}>{paymentInfo.fullName}</span></p>
      <p><span className={styles.fieldValue}>{paymentInfo.email}</span></p>
      <p>
        <span className={styles.label}>Routing Number: </span>
        <span className={styles.fieldValue}>{paymentInfo.bankRoutingNumber}</span>
      </p>
      <p>
        <span className={styles.label}>Account Number ending in: </span>
        <span className={styles.fieldValue}>{paymentInfo.bankAccountNumber.slice(-4)}</span>
      </p>
      <p>
        <span className={styles.label}>Account Type: </span>
        <span className={styles.fieldValue}>{accountTypeSelected}</span>
      </p>
      <Title className='mtXL' title='Payment Due' type='h2' />

      <p>
        <span className={styles.label}>Application Fee: </span>
        <span className={styles.fieldValue}>{applicationBasicFee}</span>
      </p>
      {applicationReducedFee && <p>
        <span className={styles.label}>Discount: </span>
        <span className={styles.fieldValue}>-{applicationReducedFee}</span>
      </p> }
      {payment.taxAmount && <p>
        <span className={styles.label}>{`${companyAddressState} Sales Tax (${payment.taxRate}%): `}</span>
        <span className={styles.fieldValue}>{taxAmount}</span>
      </p> }
      <Title className='mtLg' title='' type='h2' />
      <EntityTitle title={applicationTotalFee} size='medium' />
    </div>
  );
};

ReviewAchPayment.propTypes = {
  payment: PropTypes.object,
  paymentInfo: PropTypes.object,
  companyAddress: PropTypes.object,
  applicationBasicFee: PropTypes.string,
  isReducedFeeTypeUpgrade: PropTypes.bool
};

export default ReviewAchPayment;
