import React, { useState } from 'react';
import cn from 'classnames';
import { CheckBox } from 'devextreme-react';
import PropTypes from 'prop-types';
import { errors } from '../../constants';
import FieldError from '../FieldError';
import styles from './CheckBoxField.module.scss';

const CheckBoxField = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);

  const handleMouseEnter = () => {
    props.isRequired && setFocus(true);
  };

  const handleMouseLeave = () => {
    props.isRequired && setFocus(false);
  };

  return <div className={cn(props.className, {
    [styles.flex]: !props.text,
    'small': props.size === 'small'
  })} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    {(!props.value && props.isRequired) && <FieldError className={styles.error} isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    <CheckBox
      value={props.value || false}
      onValueChanged={props.onValueChanged}
      text={props.text}
      readOnly={props.readOnly}
      disabled={props.disabled} />
    {props.children && <div className={cn(styles.children, {
      [styles.disabled]: props.disabled
    })}>
      {props.children}
    </div>}
    {props.customChild && <div className={styles.customChildren}>
      {props.customChild}
    </div>}
  </div>;
};

CheckBoxField.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  errorText: PropTypes.string,
  value: PropTypes.bool,
  isRequired: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
  children: PropTypes.node,
  customChild: PropTypes.node,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onValueChanged: PropTypes.func
};

export default CheckBoxField;
