import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxField from '../CheckBoxField';
import styles from './DropDownFilter.module.scss';

const DropDownFilter = ({ title, items, onItemClick, ignoreSelected }) => {
  const btnNode = useRef();
  const dropDownNode = useRef();
  const options = items;
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = e => {
    if (btnNode.current.contains(e.target)) {

    } else if (!dropDownNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className={styles.container}>
      <div ref={btnNode} className={styles.button} onClick={() => setOpen(!isOpen)}>
        {title}
        <i className={styles.icon}>
          <FontAwesomeIcon icon={['fas', 'caret-down']} />
        </i>
      </div>
      <div ref={dropDownNode}>
        {isOpen && <div className={styles.dropdown}>
          {options.map(option => <CheckBoxField
            key={option.description}
            className={styles.checkboxItem}
            value={option.isSelected}
            text={option.description}
            onValueChanged={e => onItemClick(e.value, option)} />)}
        </div>}
      </div>
    </div>
  );
};

export default DropDownFilter;
