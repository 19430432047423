import React, { useState, useEffect, useContext } from 'react';
import { getFilteredCompanyList, isSameOrBefore } from '../../utils/helper';
import { TypesContext } from '../../context/Types.context';
import { acceptFiles, errors, storage, types, urls } from '../../constants';
import CompanySearch from '../../containers/CompanySearch';
import DialogBox from '../DialogBox';
import TextBox from '../TextBox';
import Footnote from '../Footnote';
import DateBox from '../DateBox';
import TextArea from '../TextArea';
import FieldError from '../FieldError';
import Label from '../Label';
import Entity from '../Entity';
import Title from '../Title';
import ChooseFile from '../ChooseFile';
import Controls from '../Controls';
import styles from './AddNewDocument.module.scss';

const maxDate = new Date();

const AddNewDocument = ({ defaultCompany, defaultSymbol, defaultDocType, onSubmit, errorMsg, setSlidePane, isUploading }) => {
  const [typesState] = useContext(TypesContext);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [submitText, setSubmitText] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [individualName, setIndividualName] = useState(null);
  const [view, setView] = useState('selectCompany');
  const [addName, toggleAddName] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState(defaultCompany || null);
  const [symbol, setSymbol] = useState(defaultSymbol || null);
  const [docType, setDocType] = useState(defaultDocType || null);
  const [individualNameFocus, setIndividualNameFocus] = useState(false);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const docTypes = typesState.docTypes;
  const individualNames = attributes && attributes.individualNames;
  const showAddIndividualName = !individualNames || (individualNames && individualNames.length < 10);
  const typeId = docType && docType.typeId;
  const isShareList = typeId === types.SHAREH_LIST;
  const isNoboList = typeId === types.NOBO_LIST;
  const isAsOfDate = isShareList || typeId === types.DRAFT_QB_CERT || isNoboList || typeId === types.PERSONAL || typeId === types.BG_CHECK || typeId === types.BOARD || typeId === types.LETTER_RESIGN || typeId === types.CONTRACTS || typeId === types.DSC_CHG_CTRL_EVT || typeId === types.TRANS_JOURNAL || typeId === types.APP_APPT_CUST || typeId === types.CRT_ORD_GRNT_CUST;
  const isFullName = typeId === types.ID || typeId === types.PERSONAL || typeId === types.LETTER_RESIGN;
  const isPeriodEndDate = typeId === types.FINANCIAL_REPORT;
  const isDescription = typeId === types.OTHER;
  const isIndividualsName = typeId === types.BG_CHECK;
  const isLogo = typeId === types.LOGO;
  const isSuppChangeControl = typeId === types.DSC_CHG_CTRL_EVT;
  const isDraftQbCert = typeId === types.DRAFT_QB_CERT;
  const isCompanySelectView = view === 'selectCompany';

  const hasAttributes = () => {
    let valid = true;
    if (isAsOfDate && !attributes.asOfDate) return false;
    if (isFullName && !attributes.fullName) return false;
    if (isPeriodEndDate && !attributes.periodEndDate) return false;
    if (isDescription && !attributes.description) return false;
    if (isIndividualsName && (!individualNames || individualNames.length === 0)) return false;
    return valid;
  };

  useEffect(() => {
    setSubmitText('Add');
    if (company) {
      setView('selectType');
      setAttributes({
        company,
        symbol: symbol || null
      });
    }
    if (!defaultCompany) {
      const appCompanyStorage = sessionStorage.getItem(storage.APP_COMPANIES);
      const docCompanyStorage = sessionStorage.getItem(storage.DOCS_COMPANIES);
      let appCompanies = appCompanyStorage ? JSON.parse(appCompanyStorage) : [];
      let docCompanies = docCompanyStorage ? JSON.parse(docCompanyStorage) : [];
      if (appCompanyStorage || docCompanyStorage) {
        let list = getFilteredCompanyList([...appCompanies, ...docCompanies]);

        setCompanyList(list);
      }
    }
  }, []);

  useEffect(() => {
    if (docType) setView('upload');
  }, [docType]);

  useEffect(() => {
    setError(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    if (isUploading) setSubmitText('Adding Document...');
    if (!isUploading) setSubmitText('Add');
  }, [isUploading]);

  const handleCancel = () => {
    if (view === 'upload') {
      setFile(null);
      setDocType(null);
      setView('selectType');
      setIndividualName(null);
      setShowIncomplete(false);
      setAttributes({
        company,
        symbol: symbol || null
      });
    } else if (view === 'selectType') {
      defaultCompany ? setSlidePane(false) : setView('selectCompany');
    } else {
      setSlidePane(false);
    }
  };

  const onValueChanged = (value, field) => {
    const updateAttribute = { ...attributes };
    updateAttribute[field] = value;

    setAttributes(updateAttribute);
  };

  const onCompanySelectChange = value => {
    const companyName = companyList.find(comp => comp.id === value).Name;
    setCompany(companyName);
  };

  const onIndividualNamesChanged = (value) => {
    setIndividualName(value);
  };

  const handleSubmit = () => {
    if (!file) {
      setError(errors.FILE_MISSING);
      return;
    }

    const data = { ...attributes };

    if (!hasAttributes()) {
      setShowIncomplete(true);
      return;
    };

    if (data.asOfDate && !isSameOrBefore(data.asOfDate)) {
      setError(errors.FUTURE_DATE);
      return;
    }

    if (data.periodEndDate && !isSameOrBefore(data.periodEndDate)) {
      setError(errors.FUTURE_DATE);
      return;
    }

    error && setError(null);

    onSubmit(file, docType.typeId, data);
  };

  const handleSelectCompany = () => {
    setView('selectType');
    setAttributes({
      company,
      symbol
    });
  };

  const handleAddCompany = company => {
    if (!company) {
      setCompany(null);
      return;
    }
    const updatedList = [...companyList];
    updatedList.push(company);
    setCompany(company.Name);
    setSymbol(company.symbol);
    setCompanyList(updatedList);
    setView('selectCompany');
  };

  const handleSelectDocType = type => {
    setDocType(type);
  };

  const handleIndividualNamesave = () => {
    const names = individualNames || [];
    onValueChanged([...names, individualName], 'individualNames');
    setIndividualName(null);
    toggleAddName(false);
  };

  const handleIndividualNamesRemove = (e, name) => {
    e.stopPropagation();
    const updatedIndividualNames = individualNames.filter(n => n !== name);
    onValueChanged(updatedIndividualNames, 'individualNames');
  };

  const renderSelectCompany = () => {
    return (
      <div>
        <Title className='mtLg' title='Company' type='h2' />
        <p>
          Please enter company name below. If the company name matches with any of the companies in our system the matches will be shown in an auto complete dropdown. If you are unable to find the company you are looking for, please select Add New Company.
        </p>
        <CompanySearch
          onCancel={() => setView('selectCompany')}
          onSubmit={handleAddCompany} />
      </div>
    );
  };

  const renderSelectDocType = () => (
    <div className={styles.list}>
      {company && <Title className='mbLg' title={company} type='h2' />}
      {docTypes.map(type => <Entity key={type.typeId} title={type.description} subTitle='Select' size='small' onClick={() => handleSelectDocType(type)} />)}
    </div>
  );

  const renderUploadForm = () => {
    let formTitle = `${company} | ${docType.description}`;
    const accept = isLogo ? acceptFiles.IMAGES : acceptFiles.DOCUMENTS;
    const acceptList = isLogo ? acceptFiles.IMG_LIST : acceptFiles.DOCS_LIST;

    return (
      <div>
        <Title className='mbLg' title={formTitle} type='h2' />
        { isSuppChangeControl && <p>
          For Disclosure of Change in Control form, click <a href={urls.ADDENDUM_CHANGE} target='_blank' rel='noopener noreferrer'>here</a>.
        </p>}
        { isDraftQbCert && <p>
          For Draft OTCQB Certification, click <a href={urls.OTCQB_CERT} target='_blank' rel='noopener noreferrer'>here</a>.
        </p>}
        {<p>
          {isLogo ? 'Please upload the company’s current corporate logo in high resolution (minimum of 72 DPI). Logo submissions may be in .jpeg, .svg, .png, .eps or .ai format. If you have multiple versions of the logo you’d like to submit, please use the Add New Document button on the previous screen. The file size limit per upload is 20 MB.' : 'Add one document at a time. The file size limit is 20MB. Please upload .docx, .doc, .xls, .xlsx, .pdf or .csv file types.'}
        </p>}
        <div className='mtLg mbXL'>
          <ChooseFile file={file} accept={accept} accepList={acceptList} setFile={setFile} setError={setError} />
        </div>
        {isFullName && <div className='mbXL'>
          <p>Enter full name of individual listed in this document.</p>
          <TextBox
            label='Name'
            placeholder='Enter Full Name'
            name='fullName'
            maxLength={100}
            showClearButton
            isRequired={showIncomplete}
            errorText='Incomplete Data. Please enter Full Name.'
            value={attributes.fullName}
            onValueChanged={e => onValueChanged(e.value, 'fullName')}
          />
        </div>}
        {isDescription && <div>
          <p>Enter description of file.</p>
          <TextArea
            label='File Description'
            showClearButton
            placeholder='Enter description into the text box'
            name='description'
            height={80}
            maxLength={1500}
            isRequired={showIncomplete}
            errorText='Incomplete Data. Please enter Description.'
            value={attributes.description}
            onValueChanged={e => onValueChanged(e.value, 'description')}
          />
        </div>}
        {isIndividualsName && <div className='mbXL' onMouseEnter={() => setIndividualNameFocus(true)}
          onMouseLeave={() => setIndividualNameFocus(false)}>
          <div>
            <Title className={styles.inline} type='h3' title='All Individuals on Background Check Authorization Form' />
            {(showIncomplete && (!individualNames || individualNames.length === 0)) && <FieldError error={'Incomplete Data. At least one Entity Name required.'} isFocus={individualNameFocus} />}
          </div>
          <p>
            Enter full name for each individual listed in the Background Check Authorization Form.
          </p>
          <div>
            {individualNames && individualNames.map((name, i) => <Entity key={`${name}-${i}`} className='mbSm' size='small' title={name} handleRemove={e => handleIndividualNamesRemove(e, name)} />)}
            {showAddIndividualName && <Entity isAdd size='small' title='Add Individuals Full Name' onClick={() => toggleAddName(true)} />}
            {addName &&
              <DialogBox
                title='Add Individuals Full Name'
                onCancelClick={() => toggleAddName(false)}
                onSubmitClick={handleIndividualNamesave}>
                <TextBox
                  placeholder='Enter Full Name'
                  name='individualName'
                  maxLength={200}
                  showClearButton
                  value={individualName}
                  onValueChanged={e => onIndividualNamesChanged(e.value)}
                />
              </DialogBox>
            }
          </div>
        </div>}
        {isAsOfDate && <div>
          <p>What is the date of this document?</p>
          <DateBox
            label='Document Date'
            className={styles.date}
            showClearButton
            placeholder='MM/DD/YYYY'
            type='date'
            max={maxDate}
            isRequired={showIncomplete}
            errorText='Incomplete Data. Please enter date of document.'
            value={attributes && attributes.asOfDate}
            onValueChanged={e => onValueChanged(e.value, 'asOfDate')}
          />
          {isShareList && <Footnote text='Must be a recent shareholder list.' />}
          {isNoboList && <Footnote text='Must be a recent NOBO list.' />}
        </div>}
        {isPeriodEndDate && <div>
          <p>What is the period end date of this financial report?</p>
          <DateBox
            className={styles.date}
            label='Period End Date'
            showClearButton
            placeholder='MM/DD/YYYY'
            type='date'
            max={maxDate}
            isRequired={showIncomplete}
            errorText='Incomplete Data. Please enter Period End Date.'
            value={attributes.periodEndDate}
            onValueChanged={e => onValueChanged(e.value, 'periodEndDate')}
          />
        </div>}
      </div>
    );
  };

  const isUploadView = view === 'upload';
  let submitFunc;

  if (isUploadView) submitFunc = handleSubmit;
  if (isCompanySelectView) submitFunc = handleSelectCompany;

  return (
    <>
      {isCompanySelectView && renderSelectCompany()}
      {view === 'selectType' && renderSelectDocType()}
      {isUploadView && renderUploadForm()}

      {error && <Label className='mtLg' isError>
        {error}
      </Label>}
      <div className={'mtXXL'}>
        <Controls
          cancelText={(isCompanySelectView || defaultCompany) ? 'Cancel' : 'Back'}
          submitText={submitText}
          showSubmit={isUploadView || isCompanySelectView}
          isLoading={isUploading}
          isSubmitDisabled={!company}
          onCancelClick={handleCancel}
          onSubmitClick={submitFunc} />
      </div>
    </>
  );
};

export default AddNewDocument;
