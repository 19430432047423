import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const SectionTitle = ({ text, emptyText }) => (
  <Text style={styles.title}>
    {text && text}
    {emptyText && <span style={styles.empty}>{emptyText}</span>}
  </Text>
);

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginTop: 8,
    marginBottom: 8
  },
  empty: {
    color: '#B1B1B1',
    fontStyle: 'italic',
    textTransform: 'normal'
  }
});

export default SectionTitle;
