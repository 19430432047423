export const monthsList = [
  {
    id: 1,
    month: 'January',
    twoDigitId: '01',
    days: 31
  },
  {
    id: 2,
    month: 'Feburary',
    twoDigitId: '02',
    days: 29
  },
  {
    id: 3,
    month: 'March',
    twoDigitId: '03',
    days: 31
  },
  {
    id: 4,
    month: 'April',
    twoDigitId: '04',
    days: 30
  },
  {
    id: 5,
    month: 'May',
    twoDigitId: '05',
    days: 31
  },
  {
    id: 6,
    month: 'June',
    twoDigitId: '06',
    days: 30
  },
  {
    id: 7,
    month: 'July',
    twoDigitId: '07',
    days: 31
  },
  {
    id: 8,
    month: 'August',
    twoDigitId: '08',
    days: 31
  },
  {
    id: 9,
    month: 'September',
    twoDigitId: '09',
    days: 30
  },
  {
    id: 10,
    month: 'October',
    twoDigitId: '10',
    days: 31
  },
  {
    id: 11,
    month: 'November',
    twoDigitId: '11',
    days: 30
  },
  {
    id: 12,
    month: 'December',
    twoDigitId: '12',
    days: 31
  }
];
