import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Value } from '../components';
import { paymentTypes, paymentTypesDesc, onlinePayments } from '../../constants';

const PaymentDetails = ({ data, submitDate }) => {
  const paymentType = data.paymentType && paymentTypes[data.paymentType];
  const achPayment = paymentType && (paymentType === paymentTypes.ACH_CHECKINGS || paymentType === paymentTypes.ACH_SAVINGS);
  const creditPayment = paymentType && paymentType === paymentTypes.CREDIT;
  const wireTransfer = paymentType && paymentType === paymentTypes.OFFLINE_WIRE;
  const check = paymentType && paymentType === paymentTypes.OFFLINE_CHECK;
  const isOnlinePayment = paymentType && onlinePayments.includes(paymentType) && data.transactionId;
  const reducedFeeType = data.reducedFeeType;
  const isReducedFeeTypeReapply = data.reducedFeeType ? data.reducedFee === 0 : false;
  const paymentDate = isReducedFeeTypeReapply ? null : data.paymentDate && moment(data.paymentDate);
  const paymentDateStr = paymentDate && paymentDate.format('LL') + ' at ' + paymentDate.format('LTS');
  const taxAmount = isReducedFeeTypeReapply ? null : data.taxAmount;
  const discount = reducedFeeType ? data.appFeeSubtotal - data.reducedFee : null;

  return (
    <>
      <View fixed>
        <SectionHeader text='Pay and Submit' />
      </View>
      <View>
        <Row>
          <Column size='50'>
            <Label text='Payment type' />
            <Value text={paymentTypesDesc.get(paymentType)} />
          </Column>
          <Column size='50'>
            <Label text='Payment Date' />
            <Value text={paymentDateStr} />
          </Column>
        </Row>
        {wireTransfer && <Row>
          <Column size='50'>
            <Label text='Name of Account Holder' />
            <Value text={data.nameOnBankAcct} />
          </Column>
          <Column size='50'>
            <Label text='Wire Confirmation Number' />
            <Value text={data.wireTransferConfNum} />
          </Column>
        </Row>}
        {check && <><Row>
          <Column size='50'>
            <Label text='Name of Account Holder' />
            <Value text={data.nameOnBankAcct} />
          </Column>
          <Column size='50'>
            <Label text='Check Number' />
            <Value text={data.checkNumber} />
          </Column>
        </Row>
          <Row>
          <Column size='50'>
              <Label text='Date of Check' />
              <Value text={data.checkDate && moment(data.checkDate).format('LL')} />
            </Column>
        </Row>
        </>}
        {isOnlinePayment && <>
          <Row>
            {achPayment && <Column size='50'>
              <Label text='Name of Account Holder' />
              <Value text={data.fullName} />
            </Column>}
            {creditPayment && <Column size='50'>
              <Label text={`Cardholder's Name`} />
              <Value text={data.fullName} />
            </Column>}
            <Column size='50'>
              <Label text='Order Number' />
              <Value text={`#${data.transactionId}`} />
            </Column>
          </Row>
        </>}
        <Row>
          <Column size='50'>
            <Label text='Application Fee' />
            <Value text={data.appFeeSubtotal} type='currency' />
          </Column>
          <Column size='50'>
            <Label text='Application Discount' />
            <Value text={discount} type='currency' />
          </Column>
        </Row>
        <Row>
          <Column size='50'>
            <Label text='Sales Tax' />
            <Value text={taxAmount} type='currency' />
          </Column>
          <Column size='50'>
            <Label text='Total' />
            <Value text={data.appFeeTotal} type='currency' />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='DATE SUBMITTED' />
            <Value text={submitDate && moment(submitDate).format('LL')} />
          </Column>
        </Row>
      </View>
    </>
  );
};

PaymentDetails.propTypes = {
  data: PropTypes.object,
  submitDate: PropTypes.number
};

export default PaymentDetails;
