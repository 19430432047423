import api from './api';

export const getServiceProviders = async (typeId, search) => {
  const url = typeId === '4' ? '/otcapi/service-provider/sponsor' : '/otcapi/service-provider';
  const response = await api.request({
    method: 'get',
    url,
    params: {
      search,
      typeId
    }
  });
  return response.data;
};
