import React, { useState } from 'react';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import CharacterCount from '../CharacterCount';
import { TextArea as TextAreaDevEx } from 'devextreme-react';
import { errors } from '../../constants';

const TextArea = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);
  const [chatacterCount, setChatacterCount] = useState(0);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || props.value.trim().length === 0);

  const handleFocusIn = e => {
    setFocus(true);
  };

  const handleFocusOut = e => {
    setFocus(false);
  };

  const handleValueChange = e => {
    const event = e;
    e.value = e.value && e.value.trim();
    props.onValueChanged && props.onValueChanged(event);
  };

  const handleOptionChanged = e => {
    const event = e;

    if (e.name === 'text' && typeof e.value === 'string') {
      if (props.onOptionChanged) {
        e.value = e.value && e.value.trim();
        props.onOptionChanged && props.onOptionChanged(event);
      }

      setChatacterCount(e.value.length);
    }

    if (e.name === 'text' && !e.value) {
      setChatacterCount(0);
    }
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} />}
      {!props.hideErrorIcon && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <TextAreaDevEx
      {...props}
      labelMode='hidden'
      onFocusIn={props.onFocusIn || handleFocusIn}
      onFocusOut={props.onFocusOut || handleFocusOut}
      inputAttr={{
        autocomplete: 'no-thanks'
      }}
      isValid={showRequired ? props.value && props.value.trim().length > 0 : props.isValid}
      showClearButton={props.showClearButton && isFocus}
      onValueChanged={handleValueChange}
      onOptionChanged={handleOptionChanged} />
    <CharacterCount inputLength={chatacterCount} total={props.maxLength} />
  </>;
};

TextArea.defaultProps = {
  isValid: true
};

export default TextArea;
