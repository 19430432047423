import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const NotificationsContext = createContext();

export const NotificationsConsumer = NotificationsContext.Consumer;

const initialState = {
  notifications: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FAILED_SAVE_APPLICATION':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.payload
        ]
      };
    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.payload
        ]
      };
    case 'REMOVE_NOTIFICATION': {
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.id)
      };
    }
    default:
      throw new Error();
  }
};

export const NotificationsContextProvider = props => {
  const [notificationState, dispatchNotification] = useReducer(reducer, initialState);

  return (
    <NotificationsContext.Provider value={[notificationState, dispatchNotification]}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

NotificationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
