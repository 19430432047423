import React from 'react';
import { View } from '@react-pdf/renderer';
import { isInt } from '../../utils/validations';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';
import { renderFullName } from '../../utils/helper';

const AuthorizedUsers = ({ data }) => {
  const contacts = data && data.filter(item => item.isAuthorizedUser);
  const hasContacts = contacts && contacts.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='DESIGNATE CONTACTS' />
        <SectionTitle text='AUTHORIZED USERS' />
      </View>
      {contacts && contacts.map((person, i) => {
        const pageBreak = i !== 0 && isInt(i / 3);

        return (
          <View break={pageBreak} key={`authorized-${i}`}>
            <Row>
              <Column size='50'>
                <Label text='Name' />
                <Value text={renderFullName(person.firstName, person.middleName, person.lastName)} />
              </Column>
              <Column size='50'>
                <Label text='COMPANY AFFILIATION' />
                <Value text={person.title1} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Phone Number' />
                <Value text={person.phone} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='Email' />
                <Value text={person.email} />
              </Column>
            </Row>
            <Divider />
          </View>
        );
      })}
      {!hasContacts && <Value text={null} />}
    </>
  );
};

export default AuthorizedUsers;
