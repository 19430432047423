import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { appStates, timezones } from '../../constants';
import { TypesContext } from '../../context/Types.context';
import Button from '../Button';
import Entity from '../Entity';
import DialogBox from '../DialogBox';
import styles from './ApplicationAttribute.module.scss';

const ApplicationAttribute = ({ className, item, onCancelClick }) => {
  const [typesState] = useContext(TypesContext);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [isSeeDocsOpen, setSeeDocsOpen] = useState(false);
  const appType = typesState.appTypes.find(type => type.id === item.typeId);
  const description = item.typeId && typesState.appStates.find(state => state.stateId === item.status).description;
  const isOTCQX = item.typeId === 1 || item.typeId === 2 || item.typeId === 3;
  const isOTCQB = item.typeId === 4;
  const isDNS = item.typeId === 5;
  const showName = isOTCQX || isDNS;
  const isInProgress = item.status === appStates.IN_PROGRESS;
  const isSubmitted = item.status === appStates.SUBMITTED;
  const isCancelled = item.status === appStates.CANCELED;
  const isApproved = item.status === appStates.APPROVED;
  const isExpired = item.status === appStates.EXPIRED;
  const isClosed = item.status === appStates.CLOSED;
  const hasApplicationDoc = item.hasApplicationDoc;
  const hasSignatureDoc = item.hasSignatureDoc;
  const showDocuments = (isSubmitted || isApproved || isClosed) && (hasApplicationDoc || hasSignatureDoc);
  const applicationLink = `/application/${item.id}`;
  let dateText;
  let ctaText = 'View';

  if (isInProgress) {
    dateText = 'Last Updated on';
    ctaText = 'View/Edit';
  };
  if (isCancelled) dateText = 'Cancelled on';
  if (isApproved) dateText = 'Approved on';
  if (isExpired) dateText = 'Expired on';
  if (isClosed) dateText = 'Closed on';
  if (isSubmitted) {
    dateText = 'Submitted on';
  };

  const handleSeeDocsClick = () => {
    setSeeDocsOpen(!isSeeDocsOpen);
  };

  const handleSubmit = () => {
    onCancelClick(item);
    setCancelDialog(false);
  };

  return <div className={cn(styles.container, className)}>
    <div className={styles.titleBar}>
      <div className={styles.typeContainer}>
        <div className={cn(styles.typeIcon, {
          [styles.green]: isOTCQX,
          [styles.orange]: isOTCQB,
          [styles.pink]: isDNS
        })}>
          <img className={styles.logo} src={`/logos/${appType.logo}`} alt={appType.name} />
        </div>
        {(appType && showName) && <span className={styles.type}>
          {appType.name}
        </span>}
      </div>
      <div className={styles.status}>
        {item.status && description}
      </div>
    </div>
    <div className={styles.info}>
      <div className={styles.name}>
        {item.companyName && item.companyName}
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.metaInfo}>
          {isInProgress && <div className={styles.complete}>
            {(item.sectionComplete >= 0) && item.totalSections && `${item.sectionComplete} out of ${item.totalSections} Sections Completed`}
            {(!item.sectionComplete && !item.totalSections) && 'No Sections Completed'}
          </div>}
          {isSubmitted && <div className={styles.complete}>
            <div>
              Application Completed
            </div>
          </div>}
          <div className={styles.bottomRow}>
            <div>
              {isInProgress && <div className={styles.cancel} onClick={() => setCancelDialog(true)}>Cancel Application</div>}
            </div>
            {item.lastUpdated && <div className={cn(styles.date, {
              [styles.inProgress]: isInProgress
            })}>
              {dateText} {moment(item.lastUpdated).tz(timezones.NEW_YORK).format('MM/DD/YYYY')}
            </div>}
            {showDocuments && <div className={styles.seeMoreLink} onClick={handleSeeDocsClick}>
              See Documents
              <FontAwesomeIcon className={cn(styles.chevron, { [styles.open]: isSeeDocsOpen })} icon={['far', 'chevron-down']} />
            </div>}
          </div>
        </div>
        {isInProgress && <Link to={applicationLink}>
          <Button title={ctaText} buttonType='secondary' color='gray' size='large' fullWidth />
        </Link>}
      </div>
      {showDocuments && <div className={cn(styles.divider, { [styles.open]: isSeeDocsOpen })} />}
      {showDocuments && <div className={cn(styles.seeMoreContent, { [styles.open]: isSeeDocsOpen })}>
        {hasApplicationDoc && <a href={`/files/application/${item.id}`} target='_blank' rel='noopener noreferrer'>
          <Entity title='Completed Application' icon='file' subTitle='View' size='small' />
        </a>}
        {hasSignatureDoc && <a href={`/files/application/${item.id}/agreement`} target='_blank' rel='noopener noreferrer'>
          <Entity className={hasApplicationDoc && 'mtSm'} title='Issuer Services Agreement' icon='file' subTitle='View' size='small' />
        </a>}
      </div>}
    </div>
    {cancelDialog && <div className={styles.dialogContainer}>
      <DialogBox
        className={styles.dialog}
        subTitle='Are you sure you want to cancel this application? This will permanently cancel for all owners.'
        cancelText='No, go back'
        submitText='Yes, I understand'
        onCancelClick={() => setCancelDialog(false)}
        onSubmitClick={() => handleSubmit()} />
    </div>}
  </div>;
};

ApplicationAttribute.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onCancelClick: PropTypes.func
};

export default ApplicationAttribute;
