import React from 'react';
import { Svg, G, Path } from '@react-pdf/renderer';

const LOGO_OTCQXINTL = () => (
  <Svg style={{ width: 450, height: 400 }}>
    <G id='Symbols' stroke='none' stroke-width='1' fill='white' fill-rule='evenodd'>
      <Path d='M65.6,19c0.8,5.9,4.2,10.6,11.6,10.6c6.3,0,9.3-2.9,10.2-7.6l0,0H89v8
		c-3.8,1.2-7.8,1.8-11.7,1.9C66.1,32,61.2,25.3,60,19l0,0H65.6z M111.2,0c5.4-0.1,10.5,2.5,13.5,6.9l0,0l5.6,8.3l-5.7,8.8l1.7,1.5
		h3.5l7-10.3L127,0.6h9.6l5.3,8.4l5.3-8.4h9.3l-9.9,14.6L157,30.4h-9.6l-5.7-9l-5.8,9h-13.4l-12.4-10.8l4.2-4.7l4.1,3.7
		c0.3-0.9,0.5-1.9,0.5-2.9l0,0v-0.1c0-4.3-3.2-8.1-7.8-8.1c-4.5,0-7.6,3.7-7.6,8l0,0v0.1c0,4.2,3,8,7.5,8.1l0,0l6.9,6
		c-2.1,0.9-4.4,1.4-6.7,1.4C101.9,31,95,24.1,95,15.6l0,0v-0.1C95,6.9,101.9,0,111.2,0z M5.8,17c0.7,7.6,5.9,11.8,11.3,11.8
		c6.3,0,10-5.3,10.2-11.8l0,0H33c-0.6,8.5-7.6,14-16.5,14C7.8,31,0.6,25.5,0,17l0,0H5.8z M48.7,17l0,8.4c0,3.1,0.6,3.9,3.9,3.9l0,0
		H54V31H38v-1.8h1.4c3.8,0,3.9-1.2,3.9-4V17H48.7z M72,14l-1,3H53l1-3H72z M60,1v7.1h-1.6C58,4.4,56.4,3.3,50,3.3l0,0h-1.5V15h-5.1
		V3.3l-1.5,0c-6,0-8,1.1-8.3,4.8l0,0H32V1H60z M16.6,0C24.8,0,32.4,5.2,33,14l0,0h-5.8C26.6,8.2,23,2.2,16,2.2
		C8.7,2.2,5.9,8.7,5.8,14l0,0H0C0.7,4.6,8.9,0,16.6,0z M77.2,0c4,0.1,8,0.7,11.8,1.8l0,0v7.4h-1.7c-0.5-3-2.9-7-10.2-7
		c-7,0-10.7,4-11.6,9.8l0,0H60C61.2,6,66.4,0,77.2,0z M170.4,31.3v-30h5.3v30H170.4z M181.9,31.3V10.6h5v3.2
		c0.7-1.2,1.6-2.1,2.8-2.7c1.2-0.6,2.5-0.9,3.9-0.9c4.7,0,7.1,2.8,7.1,8.5v12.7h-5.1V18.9c0-1.6-0.3-2.8-0.9-3.5
		c-0.6-0.7-1.5-1.1-2.7-1.1c-1.5,0-2.7,0.5-3.6,1.5c-0.9,1-1.3,2.3-1.3,3.9v11.7H181.9z M215.5,31.8c-5.3,0-8-2.7-8-8.2v-9.1h-3.9
		v-4h3.9V4.3h5.1v6.2h6.1v4h-6.1v8.8c0,1.4,0.3,2.4,0.9,3.1c0.6,0.7,1.5,1,2.8,1c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.2,1.3-0.3
		l0.8,3.9c-0.5,0.3-1.1,0.5-1.9,0.7C216.9,31.7,216.2,31.8,215.5,31.8z M232.1,31.8c-2.3,0-4.2-0.4-5.9-1.3
		c-1.6-0.9-2.9-2.1-3.8-3.7c-0.9-1.6-1.3-3.5-1.3-5.7c0-2.2,0.4-4,1.3-5.7c0.9-1.6,2.1-2.9,3.6-3.8c1.5-0.9,3.2-1.4,5.2-1.4
		c2.8,0,5.1,0.9,6.7,2.8c1.7,1.9,2.5,4.4,2.5,7.6v1.6h-14.4c0.4,3.7,2.4,5.6,6.2,5.6c1.1,0,2.2-0.2,3.4-0.5c1.1-0.4,2.1-0.9,3.1-1.7
		l1.4,3.6c-1,0.8-2.2,1.5-3.6,2C235,31.5,233.6,31.8,232.1,31.8z M231.4,13.7c-1.5,0-2.7,0.5-3.7,1.4c-0.9,1-1.5,2.3-1.7,3.9h10.1
		c-0.1-1.7-0.6-3.1-1.4-4C234,14.2,232.9,13.7,231.4,13.7z M244.9,31.3V10.6h5v3.7c1-2.5,3.1-3.8,6.3-4.1l1.6-0.1l0.3,4.5l-3,0.3
		c-3.4,0.3-5,2.1-5,5.4v11.2H244.9z M261.4,31.3V10.6h5v3.2c0.7-1.2,1.6-2.1,2.8-2.7c1.2-0.6,2.5-0.9,3.9-0.9c4.7,0,7.1,2.8,7.1,8.5
		v12.7h-5.1V18.9c0-1.6-0.3-2.8-0.9-3.5c-0.6-0.7-1.5-1.1-2.7-1.1c-1.5,0-2.7,0.5-3.6,1.5c-0.9,1-1.3,2.3-1.3,3.9v11.7H261.4z
		 M292.4,31.8c-1.5,0-2.7-0.3-3.9-0.9c-1.1-0.6-2-1.4-2.7-2.4c-0.6-1-1-2.1-1-3.4c0-1.5,0.4-2.7,1.1-3.6c0.8-0.9,2-1.5,3.7-1.9
		c1.7-0.4,4-0.6,6.9-0.6h1.4v-0.9c0-1.4-0.3-2.4-0.9-3.1c-0.6-0.6-1.6-0.9-3-0.9c-1.1,0-2.3,0.2-3.4,0.5c-1.2,0.4-2.4,0.9-3.6,1.6
		l-1.5-3.6c0.7-0.5,1.6-1,2.5-1.3c1-0.4,2-0.7,3.1-0.9s2.1-0.3,3.1-0.3c2.9,0,5.1,0.7,6.5,2.1c1.4,1.4,2.1,3.6,2.1,6.5v12.6h-4.8V28
		c-0.5,1.2-1.2,2.1-2.2,2.7S293.8,31.8,292.4,31.8z M293.5,28.1c1.3,0,2.5-0.5,3.3-1.4c0.9-1,1.3-2.2,1.3-3.7v-0.9h-1.4
		c-2.6,0-4.4,0.2-5.4,0.6c-1,0.4-1.5,1.2-1.5,2.2c0,0.9,0.3,1.7,0.9,2.3C291.5,27.8,292.3,28.1,293.5,28.1z M317.8,31.8
		c-5.3,0-8-2.7-8-8.2v-9.1H306v-4h3.9V4.3h5.1v6.2h6.1v4H315v8.8c0,1.4,0.3,2.4,0.9,3.1c0.6,0.7,1.5,1,2.8,1c0.4,0,0.8,0,1.2-0.1
		c0.4-0.1,0.8-0.2,1.3-0.3l0.8,3.9c-0.5,0.3-1.1,0.5-1.9,0.7C319.3,31.7,318.5,31.8,317.8,31.8z M324.5,6.1V0.9h5.7v5.2H324.5z
		 M324.8,31.3V10.6h5.1v20.8H324.8z M344.8,31.8c-2.1,0-3.9-0.4-5.4-1.3c-1.5-0.9-2.7-2.1-3.6-3.8c-0.8-1.6-1.3-3.5-1.3-5.8
		s0.4-4.1,1.3-5.7c0.8-1.6,2-2.9,3.6-3.7c1.5-0.9,3.3-1.3,5.4-1.3c2.1,0,3.9,0.4,5.4,1.3c1.5,0.9,2.7,2.1,3.6,3.7
		c0.8,1.6,1.3,3.5,1.3,5.7s-0.4,4.1-1.3,5.8c-0.8,1.6-2,2.9-3.6,3.8C348.7,31.3,346.9,31.8,344.8,31.8z M344.8,27.7
		c1.5,0,2.8-0.6,3.7-1.7s1.4-2.8,1.4-5.1c0-2.3-0.5-4-1.4-5.1c-0.9-1.1-2.2-1.7-3.7-1.7c-1.5,0-2.8,0.6-3.7,1.7
		c-0.9,1.1-1.4,2.8-1.4,5.1c0,2.2,0.5,3.9,1.4,5.1C342.1,27.1,343.3,27.7,344.8,27.7z M359.7,31.3V10.6h5v3.2
		c0.7-1.2,1.6-2.1,2.8-2.7c1.2-0.6,2.5-0.9,3.9-0.9c4.7,0,7.1,2.8,7.1,8.5v12.7h-5.1V18.9c0-1.6-0.3-2.8-0.9-3.5s-1.5-1.1-2.7-1.1
		c-1.5,0-2.7,0.5-3.6,1.5c-0.9,1-1.3,2.3-1.3,3.9v11.7H359.7z M390.7,31.8c-1.5,0-2.7-0.3-3.9-0.9s-2-1.4-2.7-2.4
		c-0.6-1-1-2.1-1-3.4c0-1.5,0.4-2.7,1.1-3.6c0.8-0.9,2-1.5,3.7-1.9c1.7-0.4,4-0.6,6.9-0.6h1.4v-0.9c0-1.4-0.3-2.4-0.9-3.1
		c-0.6-0.6-1.6-0.9-3-0.9c-1.1,0-2.3,0.2-3.4,0.5c-1.2,0.4-2.4,0.9-3.6,1.6l-1.5-3.6c0.7-0.5,1.6-1,2.5-1.3c1-0.4,2-0.7,3.1-0.9
		c1.1-0.2,2.1-0.3,3.1-0.3c2.9,0,5.1,0.7,6.5,2.1c1.4,1.4,2.1,3.6,2.1,6.5v12.6h-4.8V28c-0.5,1.2-1.2,2.1-2.2,2.7
		C393.3,31.4,392.1,31.8,390.7,31.8z M391.8,28.1c1.3,0,2.5-0.5,3.3-1.4c0.9-1,1.3-2.2,1.3-3.7v-0.9h-1.4c-2.6,0-4.4,0.2-5.4,0.6
		c-1,0.4-1.5,1.2-1.5,2.2c0,0.9,0.3,1.7,0.9,2.3C389.8,27.8,390.7,28.1,391.8,28.1z M414,31.8c-2.4,0-4.1-0.7-5.3-2
		c-1.1-1.3-1.7-3.3-1.7-5.9V1.4h5.1v22.2c0,2.5,1,3.8,3.1,3.8c0.3,0,0.6,0,0.9,0c0.3,0,0.6-0.1,0.9-0.2l-0.1,4.2
		C415.9,31.6,414.9,31.8,414,31.8z' />
    </G>
  </Svg>
);

export default LOGO_OTCQXINTL;
