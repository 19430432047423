import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import styles from '../styles';
import { appNames } from '../../constants';
import { HeaderOTCQX } from '../headers';
import { Footer } from '../components';
import Cover from '../pages/Cover';
import CompanyInfo from '../pages/CompanyInfo';
import ReportingStandards from '../pages/ReportingStandards';
import FinancialStandards from '../pages/FinancialStandards';
import AdditionalServices from '../pages/AdditionalServices';
import EligibilityStandards from '../pages/EligibilityStandards';
import Officers from '../pages/Officers';
import Directors from '../pages/Directors';
import ControlPersonIndividual from '../pages/ControlPersonIndividual';
import ControlPersonCorpEntity from '../pages/ControlPersonCorpEntity';
import SPTransferAgent from '../pages/SPTransferAgent';
import SPAuditor from '../pages/SPAuditor';
import SPSecCounsel from '../pages/SPSecCounsel';
import SPInvestorRelationsServices from '../pages/SPInvestorRelationsServices';
import SPInvestmentBank from '../pages/SPInvestmentBank';
import SecurityDetails from '../pages/SecurityDetails';
import SecurityOwnership from '../pages/SecurityOwnership';
import LegalDisciplinaryHistory from '../pages/LegalDisciplinaryHistory';
import PrimaryContacts from '../pages/PrimaryContacts';
import AuthorizedUsers from '../pages/AuthorizedUsers';
import BillingContacts from '../pages/BillingContacts';
import BackgroundCheck from '../pages/BackgroundCheck';
import SuppDocsRequired from '../pages/SuppDocsRequired';
import SuppDocsAdditional from '../pages/SuppDocsAdditional';
import PaymentDetails from '../pages/PaymentDetails';

const OTCQX_US = appNames.OTCQX_US;

const TemplateOTCQXUS = ({ application, documents, owners, docTypes, appStates }) => {
  const status = application && application.status;
  const appStateObj = appStates && appStates.find(state => state.stateId === status);
  const statusDesc = appStateObj && appStateObj.description || application.status;
  const symbol = application && application.primarySymbol;
  const companyName = application && application.companyInfo && application.companyInfo.name;

  return (
    <Document style={styles.document} >
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <Cover type={OTCQX_US} status={statusDesc} symbol={symbol} symbolType={'OTCQX'} companyName={companyName} owners={owners} people={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <CompanyInfo data={application.companyInfo} type={OTCQX_US} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <EligibilityStandards data={application.eligibilityStandards} type={OTCQX_US} />
        <View>
          <ReportingStandards data={application.reportingStandard} />
          <FinancialStandards data={application.financialStandards} />
        </View>
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <Officers data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <Directors data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <ControlPersonIndividual data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <ControlPersonCorpEntity entities={application.corporateEntityList} people={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SecurityDetails data={application.securityDetails} type={OTCQX_US} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SecurityOwnership people={application.peopleList} entities={application.corporateEntityList} securities={application.securityDetails && application.securityDetails.securityList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <LegalDisciplinaryHistory data={application.legalHistory} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <PrimaryContacts data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <AuthorizedUsers data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <BillingContacts data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <BackgroundCheck type={OTCQX_US} people={application.peopleList} entities={application.corporateEntityList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SPTransferAgent data={application.transferAgents} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SPAuditor data={application.auditors} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SPSecCounsel data={application.securitiesCounsels} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SPInvestorRelationsServices data={application.investorRelations} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SPInvestmentBank data={application.investmentBanks} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SuppDocsRequired docs={documents} docTypes={docTypes} shareHoldRequired logoRequired />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <SuppDocsAdditional docs={documents} docTypes={docTypes} shareHoldRequired logoRequired />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <AdditionalServices data={application.additionalServices} hasVIC />
        <Footer companyName={companyName} />
      </Page>
      { application.payment && <Page style={styles.page} size='A4'>
        <HeaderOTCQX type={OTCQX_US} />
        <PaymentDetails data={application.payment} submitDate={application.submitDate} />
        <Footer companyName={companyName} />
      </Page> }
    </Document>
  );
};

export default TemplateOTCQXUS;
