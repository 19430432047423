import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({ family: 'Nunito Sans',
  fonts: [
    { src: '/fonts/Nunito_Sans/NunitoSans-ExtraLight.woff', fontWeight: 200 },
    { src: '/fonts/Nunito_Sans/NunitoSans-ExtraLightItalic.woff', fontWeight: 200, fontStyle: 'italic' },
    { src: '/fonts/Nunito_Sans/NunitoSans-Regular.woff', fontWeight: 400 },
    { src: '/fonts/Nunito_Sans/NunitoSans-SemiBold.woff', fontWeight: 600 },
    { src: '/fonts/Nunito_Sans/NunitoSans-Bold.woff', fontWeight: 700 }
  ] });

export const styles = StyleSheet.create({
  document: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
    fontFamily: 'Nunito Sans'
  },
  page: {
    position: 'relative',
    padding: '80px 72px 70px'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    padding: '0 72px'
  },
  footerText: {
    display: 'flex',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    fontWeight: 200,
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '0 72px',
    fontSize: 14,
    color: '#000000'
  }
});

export default styles;
