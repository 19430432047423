import React, { useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { RadioGroup } from 'devextreme-react';
import { StyleSheet } from '@react-pdf/renderer';
import { checkWireTransferConfNumber, wirePaymentInfoComplete, validateBankCheckNumber, checkPaymentInfoComplete } from '../../../constants/cardComplete';
import { controls, paymentTypes, offlinePaymentTypes, paymentTypesDesc, appStates, errors, SECURE_PAY_DESC } from '../../../constants';
import TextBox from '../../TextBox';
import EntityTitle from '../../Entity/EntityTitle';
import Controls from '../../Controls';
import FieldError from '../../FieldError';
import Footnote from '../../Footnote';
import { ApplicationContext } from '../../../context/Application.context';
import { format } from '../../../utils/locale';
import Title from '../../Title';
import Label from '../../Label';
import DateBox from '../../DateBox';
import appStyles from '../Application.module.scss';

const styles = StyleSheet.create({
  sampleCheck: {
    width: 300
  },
  title: {
    display: 'inline-block'
  },
  empty: {
    color: '#B1B1B1',
    fontStyle: 'italic'
  }
});

const OfflinePayment = ({ payment, closeSlidePane, paymentError, handleAddPayment, isReducedFeeTypeUpgrade, applicationBasicFee }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [offlinePaymentInfo, setOfflinePaymentInfo] = useState({ ...payment });
  const [showRequired, setShowRequired] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const offlinePaymentType = offlinePaymentInfo.paymentType;
  const application = state && state.application;
  const isPaymentReadOnly = application.status !== appStates.IN_PROGRESS;
  const companyAddress = application.companyInfo && application.companyInfo.companyAddress;
  const companyAddressState = (companyAddress && companyAddress.state) || '';
  const showIncompletes = state.showIncompletes;
  const applicationTotalFee = format(payment.appFeeTotal, payment.appFeeTotal, 'currency');
  const applicationReducedFee = isReducedFeeTypeUpgrade && format(payment.reducedFee, payment.reducedFee, 'currency');
  const taxAmount = payment.taxAmount && format(payment.taxAmount, payment.taxAmount, 'currency');

  const onValueChanged = (value, field) => {
    setOfflinePaymentInfo(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleCancel = () => {
    closeSlidePane();
  };

  const handleAdd = () => {
    if (!offlinePaymentType) { return; }
    setShowRequired(true);
    let paymentInfo = {};
    if (offlinePaymentType === paymentTypes.OFFLINE_WIRE) {
      if (wirePaymentInfoComplete(offlinePaymentInfo)) {
        // set only wire specific info
        paymentInfo.nameOnBankAcct = offlinePaymentInfo.nameOnBankAcct;
        paymentInfo.email = offlinePaymentInfo.email;
        paymentInfo.wireDate = offlinePaymentInfo.wireDate;
        paymentInfo.wireTransferConfNum = offlinePaymentInfo.wireTransferConfNum;
        paymentInfo.paymentType = paymentTypes.OFFLINE_WIRE;
        // reset check specific fields
        paymentInfo.checkNumber = null;
        paymentInfo.checkDate = null;
        handleAddPayment(paymentInfo);
      }
    } else if (offlinePaymentType === paymentTypes.OFFLINE_CHECK) {
      if (checkPaymentInfoComplete(offlinePaymentInfo)) {
        // set only check info
        paymentInfo.nameOnBankAcct = offlinePaymentInfo.nameOnBankAcct;
        paymentInfo.email = offlinePaymentInfo.email;
        paymentInfo.checkNumber = offlinePaymentInfo.checkNumber;
        paymentInfo.checkDate = offlinePaymentInfo.checkDate;
        paymentInfo.paymentType = paymentTypes.OFFLINE_CHECK;
        // reset wire specific fields
        paymentInfo.wireTransferConfNum = null;
        handleAddPayment(paymentInfo);
      }
    }
  };

  const renderCustomItem = data => {
    const checkPayment = data === offlinePaymentType && offlinePaymentType === paymentTypes.OFFLINE_CHECK;
    const wirePayment = data === offlinePaymentType && offlinePaymentType === paymentTypes.OFFLINE_WIRE;

    return <div className={styles.radioItem}>
      <Title title={paymentTypesDesc.get(data)} type='h3' />
      {checkPayment && <div>
        <p>Please note that checks may take up to 10 days to process once they are received.</p>
        <p>Make checks payable to OTC Markets Group Inc. U.S. Dollars only. Checks must be mailed separately to our lockbox at the address below:</p>
        <p>
          OTC Markets Group Inc.<br />
          PO Box 29959<br />
          New York, NY 10087-9959<br />
        </p>
        <div className={cn(appStyles.formSection, appStyles.innerGrid)}>
          <div className={appStyles.col3}>
            <TextBox
              label={'Name of Account Holder'}
              placeholder='Name'
              name='nameOnBankAcct'
              showClearButton
              value={offlinePaymentInfo.nameOnBankAcct}
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              onValueChanged={e => onValueChanged(e.value, 'nameOnBankAcct')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label={'Email'}
              placeholder='Name'
              name='email'
              showClearButton
              value={offlinePaymentInfo.email}
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              onValueChanged={e => onValueChanged(e.value, 'email')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label='Check Number'
              placeholder='XXXX'
              name='checkNumber'
              showClearButton
              value={offlinePaymentInfo.checkNumber}
              readOnly={isPaymentReadOnly}
              onValueChanged={e => onValueChanged(e.value, 'checkNumber')}
              isRequired={showIncompletes || showRequired}
              errorText={offlinePaymentInfo.checkNumber ? errors.INVALID_CHECK_NUMBER : null}
              isValid={offlinePaymentInfo.checkNumber ? validateBankCheckNumber(offlinePaymentInfo.checkNumber) : true}
            />
          </div>
          <div className={appStyles.col3}>
            <DateBox
              label='Date of Check'
              showClearButton
              placeholder='MM/DD/YYYY'
              type='date'
              allowFuture
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              value={offlinePaymentInfo.checkDate}
              onValueChanged={e => onValueChanged(e.value, 'checkDate')}
            />
          </div>
        </div>
      </div>}
      {wirePayment && <div>
        <p>Please wire funds to the address below. Ensure that the Company name is clearly stated on the wire transfer.:</p>
        <p>
          JP Morgan Chase<br />
          Money Transfer & Wire Department<br />
          4 New York Plaza<br />
          New York, NY 10004<br />
          ABA Routing Number: 021000021<br />
          Credit the Account of: OTC Markets Group Inc.<br />
          Account #: 764232302<br />
          SWIFT: CHASUS33
        </p>
        <p>Please enter wire details below. The wire confirmation number must be 16-20 digits.</p>
        <div className={cn(appStyles.formSection, appStyles.innerGrid)}>
          <div className={appStyles.col3}>
            <TextBox
              label={'Name of Account Holder'}
              placeholder='Name'
              name='nameOnBankAcct'
              showClearButton
              value={offlinePaymentInfo.nameOnBankAcct}
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              onValueChanged={e => onValueChanged(e.value, 'nameOnBankAcct')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label={'Email'}
              placeholder='Name'
              name='email'
              showClearButton
              value={offlinePaymentInfo.email}
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              onValueChanged={e => onValueChanged(e.value, 'email')}
            />
          </div>
          <div className={appStyles.col3}>
            <TextBox
              label='Wire Confirmation Number'
              placeholder='1234567890123456'
              maxLength={20}
              name='wireTransferConfNum'
              showClearButton
              value={offlinePaymentInfo.wireTransferConfNum}
              isRequired={showIncompletes || showRequired}
              isValid={offlinePaymentInfo.wireTransferConfNum ? checkWireTransferConfNumber(offlinePaymentInfo.wireTransferConfNum) : true}
              readOnly={isPaymentReadOnly}
              errorText='Incomplete Data. This should be a 16 to 20 characters'
              onValueChanged={e => onValueChanged(e.value, 'wireTransferConfNum')}
            />
          </div>
          <div className={appStyles.col3}>
            <DateBox
              label='Value Date'
              showClearButton
              placeholder='MM/DD/YYYY'
              type='date'
              allowFuture
              isRequired={showIncompletes || showRequired}
              readOnly={isPaymentReadOnly}
              value={offlinePaymentInfo.wireDate}
              onValueChanged={e => onValueChanged(e.value, 'wireDate')}
            />
            <Footnote text='A value date is required upon initiating wire transfer and is provided to the payer within wire confirmation.' />
          </div>
        </div>
      </div>}
    </div>;
  };

  return (
    <div id='offLinePaymentContainer'>
      <div>
        <p>
          Payment must be submitted separately. Applications will be processed once funds are received.
        </p>
        <p>{SECURE_PAY_DESC}</p>
        <Title className='mtXL' title='Payment Due' type='h2' />
        <Title styles={styles.title} title={`Application Fee: ${applicationBasicFee}`} type='h3' />
        {applicationReducedFee && <Title styles={styles.title} title={`Discount: -${applicationReducedFee}`} type='h3' />}
        {payment.taxAmount && <Title styles={styles.title} title={`${companyAddressState} Sales Tax (${payment.taxRate}%): ${taxAmount}`} type='h3' /> }
        <Title className='mtLg' title='' type='h2' />
        <EntityTitle title={applicationTotalFee} size='medium' />
        <div className={cn(styles.innerGrid, 'mtMed')}
          onMouseEnter={(showIncompletes && !offlinePaymentType) ? () => setFocus(true) : undefined}
          onMouseLeave={(showIncompletes && !offlinePaymentType) ? () => setFocus(false) : undefined}>
          <div className={cn({
            [appStyles.incomplete]: (showIncompletes && !offlinePaymentType)
          })} style={{ paddingBottom: '8px' }}>
            {(showIncompletes && !offlinePaymentType) && <FieldError error='Select One Offline Payment method.' isFocus={isFocus} />}
          </div>
          {!isPaymentReadOnly &&
          <RadioGroup
            id='radio-group-offline-payments'
            items={offlinePaymentTypes}
            value={offlinePaymentType}
            itemRender={renderCustomItem}
            isRequired={showIncompletes || showRequired}
            onValueChanged={e => onValueChanged(e.value, 'paymentType')}
          /> }
          { isPaymentReadOnly && renderCustomItem(offlinePaymentType)}
        </div>

      </div>
      {!isPaymentReadOnly &&
      <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText={controls.CANCEL}
          submitText={controls.ADD_PAYMENT}
          onCancelClick={handleCancel}
          onSubmitClick={handleAdd} />
        {paymentError && <Label className='mtLg' isError title={paymentError} />}

      </div> }
    </div>
  );
};

OfflinePayment.propTypes = {
  payment: PropTypes.object,
  handleAddPayment: PropTypes.func,
  applicationBasicFee: PropTypes.string,
  paymentError: PropTypes.any,
  isReducedFeeTypeUpgrade: PropTypes.bool,
  closeSlidePane: PropTypes.func
};

export default OfflinePayment;
