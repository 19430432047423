import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import styles from '../styles';
import { isBoolean } from '../../utils/validations';
import { HeaderDNS } from '../headers';
import { Footer } from '../components';
import Cover from '../pages/Cover';
import CompanyInfo from '../pages/CompanyInfo';
import ReportingStandards from '../pages/ReportingStandards';
import AdditionalServices from '../pages/AdditionalServices';
import ChangeInControl from '../pages/ChangeInControl';
import Officers from '../pages/Officers';
import Directors from '../pages/Directors';
import ControlPersonIndividual from '../pages/ControlPersonIndividual';
import ControlPersonCorpEntity from '../pages/ControlPersonCorpEntity';
import SPTransferAgent from '../pages/SPTransferAgent';
import SecurityDetails from '../pages/SecurityDetails';
import SecurityOwnership from '../pages/SecurityOwnership';
import PrimaryContacts from '../pages/PrimaryContacts';
import AuthorizedUsers from '../pages/AuthorizedUsers';
import BillingContacts from '../pages/BillingContacts';
import BackgroundCheck from '../pages/BackgroundCheck';
import SuppDocsRequired from '../pages/SuppDocsRequired';
import SuppDocsAdditional from '../pages/SuppDocsAdditional';
import PaymentDetails from '../pages/PaymentDetails';
import { appNames } from '../../constants';
import { validDnsSkipCompany } from '../../constants/cardComplete';
import { renderPercentOwnership } from '../../utils/helper';

const DNS = appNames.DNS;

const TemplateDNS = ({ application, documents, owners, docTypes, appStates }) => {
  const status = application && application.status;
  const appStateObj = appStates && appStates.find(state => state.stateId === status);
  const statusDesc = appStateObj && appStateObj.description || application.status;
  const symbol = application && application.primarySymbol;
  const companyName = application && application.companyInfo && application.companyInfo.name;
  const isDNS = application.typeId === 5;
  const hasCourtApptCustodian = application && application.companyInfo && application.companyInfo.changeInControl && application.companyInfo.changeInControl && application.companyInfo.isCourtAppointedCustodian;
  const hasNoCourtApptCustodian = application && application.companyInfo && application.companyInfo.changeInControl && application.companyInfo.changeInControl && (isBoolean(application.companyInfo.isCourtAppointedCustodian) && !application.companyInfo.isCourtAppointedCustodian);
  const isShareHolderRequired = !(isDNS && validDnsSkipCompany(application.companyInfo, application.reportingStandard));
  const percentOwnership = renderPercentOwnership(application);

  return (
    <Document style={styles.document} >
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <Cover type={DNS} status={statusDesc} symbol={symbol} symbolType={'DNS'} companyName={companyName} owners={owners} people={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <CompanyInfo data={application.companyInfo} type={DNS} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <ReportingStandards data={application.reportingStandard} detail={application.reportingStandardDetail} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <ChangeInControl data={application.companyInfo} people={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <Officers data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <Directors data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <ControlPersonIndividual data={application.peopleList} percentOwnership={percentOwnership} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <ControlPersonCorpEntity entities={application.corporateEntityList} people={application.peopleList} percentOwnership={percentOwnership} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <SecurityDetails data={application.securityDetails} type={DNS} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <SecurityOwnership people={application.peopleList} entities={application.corporateEntityList} securities={application.securityDetails && application.securityDetails.securityList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <PrimaryContacts data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <AuthorizedUsers data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <BillingContacts data={application.peopleList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <BackgroundCheck type={DNS} people={application.peopleList} entities={application.corporateEntityList} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <SPTransferAgent data={application.transferAgents} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <SuppDocsRequired
          type={DNS}
          docs={documents}
          docTypes={docTypes}
          shareHoldRequired={isShareHolderRequired}
          changeCtrlRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          boardRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          letterResignRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          appApptCustDocRequired={hasCourtApptCustodian}
          crtOrdGrntCustRequired={hasCourtApptCustodian} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <SuppDocsAdditional
          docs={documents}
          docTypes={docTypes}
          shareHoldRequired={isShareHolderRequired}
          changeCtrlRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          boardRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          letterResignRequired={hasCourtApptCustodian || hasNoCourtApptCustodian}
          appApptCustDocRequired={hasCourtApptCustodian}
          crtOrdGrntCustRequired={hasCourtApptCustodian} />
        <Footer companyName={companyName} />
      </Page>
      <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <AdditionalServices data={application.additionalServices} hasBluesky hasVIC hasLevel2 />
        <Footer companyName={companyName} />
      </Page>
      { application.payment && <Page style={styles.page} size='A4'>
        <HeaderDNS />
        <PaymentDetails data={application.payment} submitDate={application.submitDate} />
        <Footer companyName={companyName} />
      </Page> }
    </Document>
  );
};

export default TemplateDNS;
