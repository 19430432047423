import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Title';
import styles from './ApplicationDescription.module.scss';

const ApplicationDescription = ({ title, desc, descObj }) => {
  return <div className={styles.container}>
    <Title title={title} />
    {descObj &&
      descObj.map((d, i) => <p key={i}>{d.text}</p>)
    }
    {desc && <p>{desc}</p>}
  </div>;
};

ApplicationDescription.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  descObj: PropTypes.array
};

export default ApplicationDescription;
