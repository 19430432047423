import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import { TypesContext } from '../../../context/Types.context';
import { ApplicationContext } from '../../../context/Application.context';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isBoolean } from '../../../utils/validations';
import Title from '../../Title';
import TextBox from '../../TextBox';
import TextArea from '../../TextArea';
import SelectBox from '../../SelectBox';
import QuestionCard from '../../QuestionCard';
import FieldError from '../../FieldError';
import Controls from '../../Controls';
import CheckBoxField from '../../CheckBoxField';
import DateBox from '../../DateBox';
import { countries } from '../../../constants';
import { isCorporateEntity } from '../../../utils/helper';
import { isRequiredCountry, isPifNeeded } from '../../../constants/cardComplete';
import styles from '../../Application/Application.module.scss';
import Label from '../../Label';

const US = countries.US;
const CA = countries.CA;
const AU = countries.AU;
const maxDate = new Date();

const BackgroundCheckForm = ({ personView, handleUpdate, setPIFView, setChildChange, setSlidePane }) => {
  const [typesState] = useContext(TypesContext);
  const [state] = useContext(ApplicationContext);
  const [person, setPerson] = useState(personView);
  const [historyFocus, setHistoryFocus] = useState(false);
  const application = state.application;
  const appTypeId = application.typeId;
  const companyInfo = application && application.companyInfo;
  const corporateEntityList = application && application.corporateEntityList;
  const parentCorporateEntity = isCorporateEntity(person, corporateEntityList);
  const isLegalDisciplinaryHistory = !!(person.isConvicted || person.isLimitedActivities || person.isViolationOfSecLaw || person.isLimitedActivitiesFiveYears);
  const hasHistorySelected = person.isConvicted || person.isLimitedActivitiesFiveYears || person.isViolationOfSecLaw || person.isLimitedActivities || person.isNoneApply;
  const countryList = (typesState && typesState.codes && typesState.codes.countries) || [];
  const hasBirthDate = person.birthMonth && person.birthDay && person.birthYear;
  const isNotOfficerDirector = !person.isOfficer && !person.isDirector;
  const hasOwnershipExemption = person.securityOwnerships && person.securityOwnerships.find(ownership => ownership.percentOfClass < 0.10);
  const hasCorpEntityOwnershipExpemption = parentCorporateEntity && parentCorporateEntity.securityOwnerships && parentCorporateEntity.securityOwnerships.find(ownership => ownership.percentOfClass < 0.10);
  const hasBgExemptOption = isNotOfficerDirector && (hasOwnershipExemption || hasCorpEntityOwnershipExpemption);
  const isBGExempt = !!(person.isBGExempt && hasBgExemptOption);
  let countryPerson = person && person.country ? person.country : US;
  if (countryPerson && countryList.find(country => country.name === countryPerson)) countryPerson = countryList.find(country => country.name === countryPerson).id;
  const isPersonPifNeeded = isPifNeeded(person, appTypeId, isLegalDisciplinaryHistory, application.reportingStandard, companyInfo);

  switch (countryPerson) {
    case countries.USA:
    case countries.UNITED_STATES:
    case countries.US_OF_A:
      countryPerson = countryList.find(country => country.id === US).id;
      break;

    case countries.CAN:
    case countries.CANADA:
      countryPerson = countryList.find(country => country.id === CA).id;
      break;

    case countries.AUS:
    case countries.AUSTRALIA:
      countryPerson = countryList.find(country => country.id === AU).id;
      break;
  };

  let statesList = (typesState && typesState.codes && typesState.codes.states) || [];
  statesList = statesList.filter(state => {
    let country = (person && countryPerson) || 'US';
    return state.countryId === country;
  });
  const usaCode = countryList && countryList.find(country => country.id === 'US');
  const showIncompletes = state.showIncompletes && !isBGExempt;
  const isReadOnly = state.readOnly;

  const custodianQuestion = {
    title: 'FINRA or SEC Registration',
    subTitle: `Please indicate whether ${person.firstName} ${person.lastName} has ever been a licensed registered representative with FINRA or registered as an investment advisor with the SEC or a U.S. state.`,
    fieldName: 'isFinraOrSecRegistered',
    trueDetails: [
      {
        desc: () => <div className='mtMed'>
          <TextBox
            label='CRD Number'
            placeholder='CRD Number'
            name='crdNumber'
            showClearButton
            readOnly={isReadOnly}
            maxLength={100}
            isRequired={showIncompletes && !person.secNumber}
            value={person.crdNumber}
            disabled={isBGExempt}
            onValueChanged={e => onValueChanged(e.value, 'crdNumber')} />
          <div className='mtMed'>
            <TextBox
              label='SEC Number'
              placeholder='SEC Number'
              name='secNumber'
              showClearButton
              readOnly={isReadOnly}
              maxLength={100}
              isRequired={showIncompletes && !person.crdNumber}
              value={person.secNumber}
              disabled={isBGExempt}
              onValueChanged={e => onValueChanged(e.value, 'secNumber')} />
          </div>
        </div>,
        hideDetails: true
      }
    ]
  };

  const handleCancel = () => {
    setSlidePane(false);
  };

  const handleNext = () => {
    handleUpdate(person, false);
    setChildChange(new Date().getTime());
    setPIFView(true);
  };

  const handleSubmit = () => {
    handleUpdate(person, true);
  };

  const onValueChanged = (value, field) => {
    const updatedPerson = person;
    updatedPerson[field] = value;

    if (field === 'country') {
      const country = countryList.find(c => c.id === value);
      updatedPerson.countryName = country && country.name || value;

      if (updatedPerson.state) {
        if (!statesList.find(state => state.countryId === value)) {
          updatedPerson.state = null;
        }
      }
    } else if (field === 'isBGExempt') {
      updatedPerson.bgExemptDetails = null;
      updatedPerson.isNoneApply = false;
    }

    const hasApplies = field === 'isConvicted' ||
      field === 'isLimitedActivitiesFiveYears' ||
      field === 'isViolationOfSecLaw' ||
      field === 'isLimitedActivities';

    const hasNoApplies = !updatedPerson.isNoneApply &&
      !updatedPerson.isConvicted &&
      !updatedPerson.isLimitedActivitiesFiveYears &&
      !updatedPerson.isViolationOfSecLaw &&
      !updatedPerson.isLimitedActivities;

    if (hasApplies) updatedPerson.isNoneApply = false;
    if (updatedPerson.isNoneApply || hasNoApplies) updatedPerson.noPIFAvailable = false;

    if (!updatedPerson.country) updatedPerson.country = countryPerson;
    if (!updatedPerson.countryName) updatedPerson.countryName = countryList.find(c => c.id === countryPerson).name || countryPerson;

    setPerson(prevState => {
      return {
        ...prevState,
        updatedPerson
      };
    });
  };

  const handleQuestionClick = (value, field) => {
    const updatedPerson = person;
    updatedPerson[field] = value;
    setPerson(prevState => {
      return {
        ...prevState,
        updatedPerson
      };
    });
  };

  const onDateChange = value => {
    const updatedPerson = person;

    if (value) {
      updatedPerson.birthMonth = moment(value).month() + 1;
      updatedPerson.birthDay = moment(value).date();
      updatedPerson.birthYear = moment(value).year();
    } else {
      updatedPerson.birthMonth = null;
      updatedPerson.birthDay = null;
      updatedPerson.birthYear = null;
    }

    setPerson(prevState => {
      return {
        ...prevState,
        updatedPerson
      };
    });
  };

  return (
    <div>
      {!hasBgExemptOption && <Label className='mbLg' title='Failure to provide the information on this form may result in the denial of access to OTC Markets Group products and services.' />}
      {hasBgExemptOption && <CheckBoxField
        className='mbLg'
        readOnly={isReadOnly}
        value={person.isBGExempt}
        text='This individual does not have any direct or indirect involvement in, or control over, the operations, management or activities of the Company'
        onValueChanged={e => onValueChanged(e.value, 'isBGExempt')} />}
      { isBGExempt && <>
        <p>
          Please explain why the company is not able to fill out this background check form for this control person.
        </p>
        <TextArea
          label='Details'
          name='bgExemptDetails'
          placeholder={'Enter text here.'}
          showClearButton
          readOnly={isReadOnly}
          isRequired={state.showIncompletes}
          value={person.bgExemptDetails}
          height={80}
          maxLength={1500}
          onValueChanged={e => onValueChanged(e.value, 'bgExemptDetails')}
        />
      </>
      }
      <Title title='Other Names' type='h2' />
      <p>
        List any other names this individual is currently or has previously been known by, including legal names, assumed names or nicknames.
      </p>
      <TextBox
        label='Other Names'
        placeholder='Bobby, Rob, etc'
        name='otherNames'
        showClearButton
        readOnly={isReadOnly}
        value={person.otherNames}
        disabled={isBGExempt}
        onValueChanged={e => onValueChanged(e.value, 'otherNames')} />
      <Title className='mtXL' title='Date of Birth' type='h2' />
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <DateBox
            showClearButton
            placeholder='MM/DD/YYYY'
            type='date'
            max={maxDate}
            isRequired={showIncompletes}
            disabled={isBGExempt}
            errorText='Incomplete Data. Please enter date of document.'
            value={hasBirthDate && `${person.birthMonth}/${person.birthDay}/${person.birthYear}`}
            onValueChanged={e => onDateChange(e.value)}
          />
        </div>
      </div>
      <Title className='mtXL' title='Home Address' type='h2' />
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <SelectBox
            label='Country'
            name='country'
            placeholder='Select Country'
            valueExpr='id'
            displayExpr='name'
            items={countryList}
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            defaultValue={(usaCode && usaCode.id) || null}
            disabled={isBGExempt}
            value={countryPerson}
            onValueChanged={e => onValueChanged(e.value, 'country')} />
        </div>
        <div className={styles.col2}>
          <SelectBox
            label='State/Province'
            name='State/Province'
            placeholder='Select State'
            valueExpr='id'
            displayExpr='name'
            searchExpr={['id', 'name']}
            items={statesList}
            disabled={!statesList || (statesList && statesList.length === 0)}
            isRequired={showIncompletes && isRequiredCountry(countryPerson)}
            readOnly={isReadOnly}
            disabled={isBGExempt}
            value={person.state}
            onValueChanged={e => onValueChanged(e.value, 'state')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='City'
            placeholder='City'
            name='city'
            showClearButton
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            disabled={isBGExempt}
            value={person.city}
            onValueChanged={e => onValueChanged(e.value, 'city')} />
        </div>
      </div>
      <div
        className='mtXL'
        onMouseEnter={() => setHistoryFocus(true)}
        onMouseLeave={() => setHistoryFocus(false)}>
        <div>
          <Title className={styles.inline} title='Legal/Disciplinary History' type='h2' />
          {(showIncompletes && !hasHistorySelected) && <FieldError error='Select at least one checkbox below.' isFocus={historyFocus} />}
        </div>
        <p>
          Please indicate whether {person.firstName} {person.lastName} has:
        </p>
        <CheckBoxField
          readOnly={isReadOnly}
          value={person.isConvicted}
          disabled={isBGExempt}
          text='Criminal Proceeding'
          onValueChanged={e => onValueChanged(e.value, 'isConvicted')}>
          <p>
            In the last five years, been the subject of a conviction in a criminal proceeding or named as a defendant in a pending criminal proceeding (excluding traffic offenses and other minor offenses).
          </p>
        </CheckBoxField>
        <CheckBoxField
          className='mtMed'
          readOnly={isReadOnly}
          disabled={isBGExempt}
          value={person.isLimitedActivitiesFiveYears}
          text='Limitations on Business, Securities, Commodities or Banking Activities'
          onValueChanged={e => onValueChanged(e.value, 'isLimitedActivitiesFiveYears')}>
          <p>
            In the last five years, been subject of the entry of an order, judgment or decree, not subsequently reversed, suspended or vacated, by a court competent jurisdiction that permanently or temporarily enjoined, barred, suspended or otherwise limited such person’s involvement in any type of business, securities, commodities, or banking activities.
          </p>
        </CheckBoxField>
        <CheckBoxField
          className='mtMed'
          readOnly={isReadOnly}
          disabled={isBGExempt}
          value={person.isViolationOfSecLaw}
          text='Violation of Securities or Commodities Law'
          onValueChanged={e => onValueChanged(e.value, 'isViolationOfSecLaw')}>
          <p>
            Ever been the subject of a finding or judgment by a court of competent jurisdiction (in a civil action), the Securities and Exchange Commission, the Commodity Futures Trading Commission, or a state securities regulator of a violation of a federal or state securities or commodities law, which finding or judgement has not been reversed, suspended or vacated.
          </p>
        </CheckBoxField>
        <CheckBoxField
          className='mtMed'
          readOnly={isReadOnly}
          disabled={isBGExempt}
          value={person.isLimitedActivities}
          text='Limitations on Business or Security Activity'
          onValueChanged={e => onValueChanged(e.value, 'isLimitedActivities')}>
          <p>
            Ever been the subject of the entry of an order by a self-regulatory organization that permanently or temporarily barred, suspended or otherwise limited such person’s involvement in any type of business or securities activities
          </p>
        </CheckBoxField>
        <CheckBoxField
          className='mtMed'
          disabled={isLegalDisciplinaryHistory || isBGExempt}
          readOnly={isReadOnly}
          value={person.isNoneApply}
          text='None of the Above'
          onValueChanged={e => onValueChanged(e.value, 'isNoneApply')}>
          <p>
            There is no legal/disciplinary history for this individual.
          </p>
        </CheckBoxField>
      </div>
      <div
        className='mtXL'>
        <div>
          <Title className={styles.inline} title='FINRA or SEC Registration' type='h2' />
          <QuestionCard
            item={custodianQuestion}
            readOnly={isReadOnly}
            section={person}
            isValid={showIncompletes ? isBoolean(person.isFinraOrSecRegistered) : true}
            showIncompletes={showIncompletes}
            disabled={isBGExempt}
            handleQuestionClick={handleQuestionClick}
            onValueChanged={onValueChanged} />
        </div>
      </div>
      {isPersonPifNeeded && <Label className='mtMed mbMed' title='Depending on the information provided above, a Personal Information Form will be required in the next section.' />}
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={isPersonPifNeeded ? 'Next' : 'Save & Close'}
          onCancelClick={handleCancel}
          onSubmitClick={isPersonPifNeeded ? handleNext : handleSubmit} />
      </div>}
    </div>
  );
};

BackgroundCheckForm.propTypes = {
  person: PropTypes.object,
  handleUpdate: PropTypes.func,
  setSlidePane: PropTypes.func,
  setPIFView: PropTypes.func
};

export default BackgroundCheckForm;
