import React from 'react';
import { Svg, G, Path, Polygon } from '@react-pdf/renderer';

const LOGO_OTCQXUS = () => (
  <Svg style={{ width: 400, height: 400 }}>
    <G id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <G id='OTCQX-US' transform='translate(0.000000, -0.000000)' fill='#FFFFFF' fill-rule='nonzero'>
        <Path d='M182.414,31.378 C186.446,31.378 189.512,30.321 191.612,28.207 C193.712,26.093 194.762,23.034 194.762,19.03 L194.762,19.03 L194.762,1.39 L189.47,1.39 L189.47,19.324 C189.47,21.844 188.868,23.762 187.664,25.078 C186.46,26.394 184.71,27.052 182.414,27.052 C180.09,27.052 178.326,26.401 177.122,25.099 C175.918,23.797 175.316,21.872 175.316,19.324 L175.316,19.324 L175.316,1.39 L170.024,1.39 L170.024,19.03 C170.024,23.062 171.074,26.128 173.174,28.228 C175.274,30.328 178.354,31.378 182.414,31.378 Z M205.094,31 L205.094,25.204 L199.214,25.204 L199.214,31 L205.094,31 Z M221.012,31.378 C223.42,31.378 225.513,31.021 227.291,30.307 C229.069,29.593 230.427,28.571 231.365,27.241 C232.303,25.911 232.772,24.364 232.772,22.6 C232.772,20.976 232.359,19.632 231.533,18.568 C230.707,17.504 229.517,16.629 227.963,15.943 C226.409,15.257 224.386,14.634 221.894,14.074 C219.542,13.542 217.876,12.968 216.896,12.352 C215.916,11.736 215.426,10.854 215.426,9.706 C215.426,8.334 215.979,7.263 217.085,6.493 C218.191,5.723 219.696,5.338 221.6,5.338 C224.792,5.338 227.704,6.388 230.336,8.488 L230.336,8.488 L232.142,4.54 C230.938,3.448 229.398,2.587 227.522,1.957 C225.646,1.327 223.672,1.012 221.6,1.012 C219.332,1.012 217.33,1.397 215.594,2.167 C213.858,2.937 212.514,4.008 211.562,5.38 C210.61,6.752 210.134,8.306 210.134,10.042 C210.134,11.722 210.526,13.108 211.31,14.2 C212.094,15.292 213.256,16.188 214.796,16.888 C216.336,17.588 218.338,18.204 220.802,18.736 C223.182,19.268 224.883,19.828 225.905,20.416 C226.927,21.004 227.438,21.858 227.438,22.978 C227.438,24.266 226.885,25.267 225.779,25.981 C224.673,26.695 223.098,27.052 221.054,27.052 C219.206,27.052 217.491,26.8 215.909,26.296 C214.327,25.792 212.822,24.994 211.394,23.902 L211.394,23.902 L209.588,27.85 C210.96,28.97 212.64,29.838 214.628,30.454 C216.616,31.07 218.744,31.378 221.012,31.378 Z' id='U.S' />
        <Path d='M126.368843,25.5414571 L124.653293,24.0767853 L130.374654,15.2632078 L124.756226,6.91798465 C121.705028,2.49298748 116.61244,-0.106877424 111.211954,0.00337120742 C101.94798,0.00337120742 95,6.94353126 95,15.5016427 L95,15.5867981 C95,24.1449095 101.862203,31 111.126176,31 C113.435128,31.0051127 115.720755,30.5414502 117.842557,29.6374288 L110.980354,23.6765552 C106.511345,23.5573377 103.509131,19.8275339 103.509131,15.5953136 L103.509131,15.5101583 C103.509131,11.2098137 106.562811,7.46297882 111.10902,7.46297882 C115.65523,7.46297882 118.871887,11.2523914 118.871887,15.5953136 L118.871887,15.6804689 C118.879134,16.668605 118.704723,17.649763 118.357222,18.5757504 L114.282789,14.9140709 L110.07969,19.5976145 L122.517432,30.4123424 L135.898727,30.4123424 L141.688711,21.4284543 L147.435805,30.4123424 L157,30.4123424 L146.706696,15.1695369 L156.571112,0.607974107 L147.221361,0.607974107 L141.903154,9.00429044 L136.584947,0.616489641 L127.012175,0.616489641 L136.876591,15.2632078 L129.8943,25.5414571 L126.368843,25.5414571 Z' id='Path' />
        <Polygon id='Path' points='53 17 53.9639037 14 60 14 59.0360963 17' />
        <Polygon id='Path' points='59 17 59.9639037 14 66 14 65.0360963 17' />
        <Polygon id='Path' points='65 17 65.9639037 14 72 14 71.0360963 17' />
        <Path d='M43.4008439,3.29265255 L43.4008439,15 L48.5485232,15 L48.5485232,3.29265255 L50.0337553,3.29265255 C56.4388186,3.29265255 58.0253165,4.43462017 58.3628692,8.06102117 L60,8.06102117 L60,1 L32,1 L32,8.10460772 L33.628692,8.10460772 C33.9240506,4.47820672 35.8902954,3.3362391 41.907173,3.3362391 L43.4008439,3.29265255 Z' id='Path' />
        <Path d='M17.0991254,28.7732938 C11.7201166,28.7732938 6.52478134,24.6189911 5.81632653,17 L0,17 C0.56851312,25.4913947 7.79300292,31 16.5043732,31 C25.3556851,31 32.4489796,25.4913947 33,17 L27.2536443,17 C27.0612245,23.4973294 23.4139942,28.7732938 17.0991254,28.7732938 Z' id='Path' />
        <Path d='M15.9666137,2.24133811 C23.0230525,2.24133811 26.6168521,8.20430108 27.1939587,14 L33,14 C32.3616852,5.2437276 24.8155803,5.68434189e-13 16.6136725,5.68434189e-13 C8.86645469,5.68434189e-13 0.6645469,4.57467145 0,14 L5.75357711,14 C5.92845787,8.739546 8.71780604,2.24133811 15.9666137,2.24133811 Z' id='Path' />
        <Path d='M48.6871908,25.1961752 L48.6871908,17 L43.3216053,17 L43.3216053,25.1961752 C43.3216053,28.0030845 43.1720726,29.2381246 39.4073667,29.2381246 L38,29.2381246 L38,31 L54,31 L54,29.2726712 L52.5926333,29.2726712 C49.1885651,29.2726712 48.6871908,28.45219 48.6871908,25.1961752 Z' id='Path' />
        <Path d='M77.1387078,2.19372442 C84.3930053,2.19372442 86.84262,6.16371078 87.332543,9.19236016 L89,9.19236016 L89,1.82537517 C85.1608001,0.741749354 81.1922773,0.128352744 77.1902786,5.68434189e-13 C66.4033788,5.68434189e-13 61.2119146,6.04092769 60,12 L65.5868405,12 C66.4377593,6.17189632 70.1594547,2.19372442 77.1387078,2.19372442 Z' id='Path' />
        <Path d='M77.2576161,32 C81.2387278,31.9206516 85.1901258,31.2779386 89,30.0900474 L89,22.0629655 L87.3359953,22.0629655 C86.4782609,26.7806364 83.4247264,29.6411645 77.1632653,29.6411645 C69.8039042,29.6411645 66.3986986,24.9146919 65.5838509,19 L60,19 C61.1665188,25.2755586 66.1070689,32 77.2576161,32 Z' id='Path' />
      </G>
    </G>
  </Svg>
);

export default LOGO_OTCQXUS;
