import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Title from '../Title';
import Entity from '../Entity';
import FieldError from '../FieldError';
import Footnote from '../Footnote';
import styles from './EntityList.module.scss';

const ENTER_INFORMATION = 'Enter Information';

const EntityList = ({ className, children, title, icon, iconComplete, addTitle, readOnly, entityTitle, subTitle, footNote, confirmRemoveText, hideSubTitle, handleHideRemove, emptyTitle, showIncompleteList, showIncompletes, incompleteMessage, validateComplete, handleAddClick, handleEntityClick, handleEntityRemove, list }) => {
  const [listFocus, setListFocus] = useState(false);
  const showEmpty = emptyTitle && (!list || (list && list.length < 1));
  const onEntityClick = item => handleEntityClick && handleEntityClick(item);

  const handleMouseEnter = () => {
    showIncompleteList && setListFocus(true);
  };

  const handleMouseLeave = () => {
    showIncompleteList && setListFocus(false);
  };

  return (
    <div
      className={cn(styles.container, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {title && <div className={cn({
        [styles.incomplete]: showIncompleteList
      })}>
        <Title className={styles.title} title={title} type='h3' />
        {showIncompleteList && <FieldError error={incompleteMessage} isFocus={listFocus} />}
        {footNote && <Footnote text={footNote} />}
      </div>}
      {list && <div className={styles.list}>
        {list.map((item, i) => {
          const isComplete = validateComplete && validateComplete(item);
          const subTitleText = isComplete ? 'Complete' : subTitle || ENTER_INFORMATION;
          const hideClose = handleHideRemove && handleHideRemove(item);
          let entityIcon = icon;

          if (isComplete) entityIcon = iconComplete;
          if (showIncompletes && !isComplete) entityIcon = 'incomplete';

          const EntityItem = () => (
            <Entity
              key={i}
              title={entityTitle(item)}
              icon={entityIcon}
              isComplete={isComplete}
              hideSubTitle
              showIncompletes={showIncompletes && !isComplete}
              confirmRemoveText={confirmRemoveText}
              subTitle={hideSubTitle ? '' : subTitleText}
              subTitleHighlight={subTitleText === ENTER_INFORMATION}
              onClick={() => onEntityClick(item)}
              hideClose={hideClose}
              handleRemove={handleEntityRemove && (e => handleEntityRemove(e, item))} />
          );

          if (children) return children(EntityItem, item, i);

          return <EntityItem key={i} />;
        })}
      </div>
      }
      {((readOnly && !showEmpty) && (!list || list.length === 0)) && <Entity
        title={`No ${title}`}
        isEmpty
        size='small' />}

      {(addTitle && !readOnly) && <Entity
        title={addTitle}
        isAdd
        onClick={handleAddClick} />}
      {showEmpty && <Entity
        title={emptyTitle}
        isEmpty />}
    </div>
  );
};

EntityList.propTypes = {
  addTitle: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  hideSubTitle: PropTypes.bool,
  handleAddClick: PropTypes.func,
  list: PropTypes.array,
  footNote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  entityTitle: PropTypes.func,
  confirmRemoveText: PropTypes.string,
  validateComplete: PropTypes.func
};

export default EntityList;
