import React from 'react';
import { isInt } from '../../utils/validations';
import { numToStr, renderFullName } from '../../utils/helper';
import { View } from '@react-pdf/renderer';
import { Column, Divider, Label, Row, SectionHeader, SectionTitle, Value } from '../components';

const SecurityOwnership = ({ people, entities, securities }) => {
  const peopleOwners = people ? people.filter(person => !person.isExempt && (person.isOfficer || person.isDirector || person.isControl) && person.securityOwnerships && person.securityOwnerships.length > 0) : [];
  peopleOwners.map(owner => {
    owner.securityOwnerships.sort((a, b) => {
      if (a.numberOfShares > b.numberOfShares) {
        return -1;
      }
      if (a.numberOfShares < b.numberOfShares) {
        return 1;
      }
      return 0;
    });
  });

  const entityOwners = entities ? entities.filter(item => !item.isExempt && item.securityOwnerships && item.securityOwnerships.length > 0) : [];
  entityOwners.map(owner => {
    owner.securityOwnerships.sort((a, b) => {
      if (a.numberOfShares > b.numberOfShares) {
        return -1;
      }
      if (a.numberOfShares < b.numberOfShares) {
        return 1;
      }
      return 0;
    });
  });

  const owners = [...peopleOwners, ...entityOwners];
  const hasOwners = owners && owners.length > 0;
  owners.sort((a, b) => {
    if (a.securityOwnerships[0].numberOfShares > b.securityOwnerships[0].numberOfShares) {
      return -1;
    }
    if (a.securityOwnerships[0].numberOfShares < b.securityOwnerships[0].numberOfShares) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <View fixed>
        <SectionHeader text='SECURITY OWNERSHIP' />
      </View>
      {hasOwners && owners.map((person, i) => {
        const fullName = renderFullName(person.firstName, person.middleName, person.lastName);
        let personTitle;
        if (person.isControl) personTitle = 'Individual Control Person';
        if (person.isDirector) personTitle = 'Director';
        if (person.isChairPerson) personTitle = 'Chairperson';
        if (person.isOfficer) personTitle = 'Officer';

        return (
          <View break={i > 0} key={`sec-owner-${i}`}>
            <View fixed>
              <SectionTitle text={person.entityName ? `${person.entityName}, Corporate Entity` : `${fullName || ''}${fullName && personTitle ? ', ' : ''}${personTitle}`} />
            </View>
            {person.securityOwnerships && person.securityOwnerships.map((ownership, j) => {
              const pageBreak = j !== 0 && isInt(j / 6);
              const security = securities ? securities.find(sec => sec.secId === ownership.secId) : {};
              if (!security) return null;
              return (
                <View break={pageBreak} key={`ownership-${j}`}>
                  <Row>
                    <Column size='50'>
                      <Label text='SECURITY TYPE/Class' />
                      <Value text={`${security.type || 'Empty'} - ${security.securityClass || 'Empty'}`} />
                    </Column>
                    <Column size='25'>
                      <Label text='NUMBER' />
                      <Value text={numToStr(ownership.numberOfShares)} type='int' />
                    </Column>
                    <Column size='25'>
                      <Label text='PERCENT' />
                      <Value text={numToStr(ownership.percentOfClass)} type='devExpressPercent' />
                    </Column>
                  </Row>
                  <Row>
                    <Column size='100'>
                      <Label text='Notes' />
                      <Value text={ownership.note} />
                    </Column>
                  </Row>
                  <Divider />
                </View>
              );
            })}
            {!person.securityOwnerships && <Value text={null} />}
          </View>
        );
      })}
      {!hasOwners && <Value text={null} />}
    </>
  );
};

export default SecurityOwnership;
