import React from 'react';
import { useDrag } from 'react-dnd';

const style = {
  width: '100%',
  cursor: 'move'
};

const TargetDrag = ({ children, boxType, onDrag, onDrop, dragItem }) => {
  const [{ isDragging }, drag] = useDrag({
    type: boxType,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(dragItem, boxType);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;
  isDragging && onDrag(dragItem);

  return (
    <div ref={drag} style={{ ...style, opacity }}>
      {children}
    </div>
  );
};

export default TargetDrag;
