import React, { useState } from 'react';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { Autocomplete } from 'devextreme-react';
import { errors } from '../../constants';

const AutoCompleteBox = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || props.value.trim().length === 0);

  const handleFocusIn = e => {
    setFocus(true);
  };

  const handleFocusOut = e => {
    setFocus(false);
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} />}
      {!props.hideErrorIcon && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <Autocomplete
      {...props}
      labelMode='hidden'
      onFocusIn={props.onFocusIn || handleFocusIn}
      onFocusOut={props.onFocusOut || handleFocusOut}
      inputAttr={{
        autocomplete: 'no-thanks'
      }}
      isValid={showRequired ? !!(props.value && props.value.trim().length > 0) : props.isValid}
      showClearButton={props.showClearButton && isFocus}
    />
  </>;
};

AutoCompleteBox.defaultProps = {
  isValid: true
};

export default AutoCompleteBox;
