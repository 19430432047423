import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errors } from '../../constants';
import Title from '../Title';
import styles from './ProcessingOverlay.module.scss';

const defaultText = 'Please wait while your application is being submitted. Do not close this window until you see a confirmation.';
const defaultTitle = 'Processing';
const defaultErrorTitle = 'Application Error';

const ProcessingOverlay = ({ title, text, submitError }) => {
  const handleUnload = (e) => {
    let confirmationMessage = 'Are you sure you want to navigate away?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!submitError && <>
          <Title className={styles.title} title={title} type='h1' />
          <div className={styles.loader} />
          <p className={styles.desc}>
            {text}
          </p>
        </>}
        {submitError && <>
          <Title className={styles.errorTitle} title={defaultErrorTitle} type='h1' isError />
          <FontAwesomeIcon className={styles.errorTitle} icon={['fal', 'exclamation-triangle']} size='4x' />
          <p className={styles.desc}>
            {errors.SUBMIT_APP_API}
          </p>
        </>}
      </div>
    </div>
  );
};

ProcessingOverlay.defaultProps = {
  text: defaultText,
  title: defaultTitle
};

ProcessingOverlay.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string
};

export default ProcessingOverlay;
