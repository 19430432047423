import React from 'react';
import { Svg, G, Path } from '@react-pdf/renderer';

const LOGO_DNS = () => (
  <Svg style={{ color: '#ff0000', width: 400, height: 400 }}>
    <G id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <Path class='cls-1' d='M29.2.75h4.4V5H29.2Zm.25,9.9H33.3V36.5H29.45Z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
      <Path class='cls-1' d='M4,5.15H12.8c5.65,0,9.4,2.6,9.4,7.6v.1c0,4.6-3.8,7.7-9.6,7.7H7.8v3.6h4.65c7.45,0,13.75-3.9,13.75-11.45v-.1C26.2,5.7,21,1.5,13.1,1.5H0v35H4Z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
      <Path class='cls-1' d='M37.8,10.65h3.85v4.5A9.79,9.79,0,0,1,50.5,10.1c6.25,0,9.9,4.2,9.9,10.35V36.5H56.55V21.4c0-4.8-2.6-7.8-7.15-7.8s-7.75,3.25-7.75,8.1V36.5H37.8Z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
      <Path class='cls-1' d='M65.5,0h3.85V25.2l14-14.55h4.8L77.25,21.75,88.45,36.5h-4.6l-9.25-12-5.25,5.3V36.5H65.5Z' id='Combined-Shape' fill='#FFFFFF' fill-rule='nonzero' />
    </G>
  </Svg>
);

export default LOGO_DNS;
