import React, { useState, useContext } from 'react';
import cn from 'classnames';
import TextBox from '../../TextBox';
import { ApplicationContext } from '../../../context/Application.context';
import Controls from '../../Controls';
import styles from '../Application.module.scss';

const personObj = {
  isControl: true,
  firstName: '',
  middleName: '',
  lastName: ''
};

const AddIndividualControlPerson = ({ individualView, setSlidePane, handleAdd, handleUpdate }) => {
  const [person, setPerson] = useState(individualView || personObj);
  const [state] = useContext(ApplicationContext);
  const isReadOnly = state.readOnly;
  const showIncompletes = state.showIncompletes;

  const onValueChanged = (value, field) => {
    setPerson(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleUpdatePerson = () => {
    handleUpdate(person);
  };

  const handleSubmit = () => {
    handleAdd(person);
  };

  return (
    <div>
      <p className={styles.description}>
        Enter Name of Control Person.
      </p>
      <div className={styles.innerGrid}>
        <div className={styles.col2}>
          <TextBox
            label='First Name'
            placeholder='First Name'
            name='firstName'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={person.firstName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'firstName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Middle Name'
            placeholder='Middle Name'
            name='middleName'
            readOnly={isReadOnly}
            value={person.middleName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'middleName')} />
        </div>
        <div className={styles.col2}>
          <TextBox
            label='Last Name'
            placeholder='Last Name'
            name='lastName'
            isRequired={showIncompletes}
            readOnly={isReadOnly}
            value={person.lastName}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'lastName')} />
        </div>
      </div>
      {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={individualView ? 'Update' : 'Add'}
          onCancelClick={() => setSlidePane(false)}
          onSubmitClick={individualView ? handleUpdatePerson : handleSubmit} />
      </div>}
    </div>
  );
};

export default AddIndividualControlPerson;
