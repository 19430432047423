import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { phone_numbers, financialStandardsQualification, emails } from '../../../constants';
import { ApplicationContext } from '../../../context/Application.context';
import { TypesContext } from '../../../context/Types.context';
import ApplicationDescription from '../../ApplicationDescription';
import Entity from '../../Entity';
import DetermineFinancialEligibility from './DetermineFinancialEligibility';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';
import Label from '../../Label';
import NumberList from '../../NumberList';
import DialogBox from '../../DialogBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FinancialStandards.module.scss';

const pageDesc = [
  {
    text: `To review the financial eligibility standards and verify that your Company qualifies for OTCQX, click on the Determine Company's Financial Eligibility button below.`
  },
  {
    text: 'As you respond to the questions, please note that all financial standards must be met based on audited financial statements dated within the last 15 months.'
  }
];

const numList = [
  'If you want to change your response to any of the questions, select Restart to redo the “Determine Company’s Financial Eligibility” wizard again.',
  'Proceed with this application if you expect to meet the financial standards soon. We’ll contact you to discuss the financial standards in more detail.',
  `<span>If you would like to learn about our other markets that you may qualify for, contact our Corporate Services team at <br>+1 ${phone_numbers.ISSUER_SERVICES} or <a href=${emails.SALES_TEAM}>sales@otcmarkets.com</a>.</span>`
];

const questionFieldNames = [
  'operatingfor3Years',
  'hasTwoMillionNTA',
  'hasRevenueOfSixMillionInPastThreeYears',
  'hasFiveMillionNTA',
  'hasBidPriceOfFiveDollars',
  'hasBidPahasFiledFormTwoElevenAtBidPriceOfFiveDollarsriceOfFiveDollars',
  'hasRequiredNtaRevenueNetIncomeTotalAssets',
  'hasMinBidPriceOfTwentyFiveCents',
  'hasMarketCapOfTenMillion',
  'hasTenPercentFloat',
  'hasFiftyBeneficialShareholders',
  'willApplyForFloatExemption',
  'hasBeenInOperationForThreeYears',
  'GREATER_THAN_FIFTEEN_MILLION',
  'BETWEEN_ONE_AND_FIFTEEN_MILLION',
  'LESS_THAN_ONE_MILLION',
  'hasGlobalMarketCapOfTenMillion'
];

const FinancialStandards = ({ name, company, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [typesState] = useContext(TypesContext);
  const [slidePane, setSlidePane] = useState(false);
  const [showRestartDialog, setResetDialog] = useState(false);
  const application = state.application;
  const appType = typesState.appTypes.find(type => type.id === application.typeId).typeId;
  const financialStandards = application.financialStandards = { ...application.financialStandards };
  const doesNotQualify = financialStandards.doesNotQualify === true;
  const qualifiesForQXUS = financialStandards.qualifiesForQXUS === true;
  const qualifiesForQXIntl = financialStandards.qualifiesForQXIntl === true;
  const hasCompleted = !slidePane && doesNotQualify || qualifiesForQXUS || qualifiesForQXIntl;
  const hasAnswers = questionFieldNames.filter(fieldName => {
    const field = financialStandards[fieldName];
    return (field && field.length > 0) || typeof field === 'boolean';
  });
  const isReadOnly = state.readOnly;
  const isIncomplete = hasAnswers.length > 0 && !hasCompleted;
  let determineCta = 'Start';

  if (isIncomplete) determineCta = 'Continue';
  if (hasCompleted) determineCta = 'Restart';

  let successResult;

  if (qualifiesForQXUS) successResult = 'OTCQX U.S';
  if (qualifiesForQXIntl) successResult = 'OTCQX International';

  useEffect(() => {
    if (showRestartDialog) setResetDialog(false);
  }, [slidePane]);

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'financialStandards',
      card: 'financial-standards'
    });
  };

  const handleFormClose = () => {
    saveApplication();

    setSlidePane(false);
  };

  const handleUpdate = (value, field) => {
    const updateFinancialStandards = financialStandards;
    updateFinancialStandards[field] = value;
    onUpdate(updateFinancialStandards);
  };

  const handleResult = (value, field) => {
    const updateFinancialStandards = financialStandards;
    updateFinancialStandards[field] = value;
    if ((field === financialStandardsQualification.QUALIFIES_US ||
      field === financialStandardsQualification.QUALIFIES_INTL) &&
      updateFinancialStandards[field]) {
      updateFinancialStandards[financialStandardsQualification.DOES_NOT_QUALIFY] = false;
    }
    onUpdate(updateFinancialStandards);

    handleFormClose();
  };

  const onDetermineClick = () => {
    if (hasCompleted) {
      setResetDialog(true);
    } else {
      setSlidePane(true);
    }
  };

  const handleQuestionReset = () => {
    const updateFinancialStandards = financialStandards;

    Object.keys(updateFinancialStandards).map(function (key) {
      updateFinancialStandards[key] = '';
    });

    onUpdate({});

    setResetDialog(false);
    setSlidePane(true);
  };

  const renderConfirmDialog = () => (
    <DialogBox
      title='Restart Financial Eligibility'
      subTitle='Are you sure you want to restart the Financial Eligibility questions? This will delete your previous response.'
      cancelText='No, cancel'
      submitText='Yes, restart questions'
      onCancelClick={() => setResetDialog(false)}
      onSubmitClick={() => handleQuestionReset()} />
  );

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={pageDesc} />
      {!isReadOnly && <div>
        <Entity
          title={`Determine Company's Financial Eligibility`}
          subTitle={determineCta}
          section={'none'}
          onClick={onDetermineClick} />
        {(showRestartDialog && hasCompleted) && renderConfirmDialog()}
      </div>}
      {(isIncomplete && !slidePane) && <>
        <Label className='mtMed' title={`There are currently questions that have been answered to determine the company's financial eligibility`} />
        <div>
          <Label className='mtMed'>
            <div className={styles.incomplete}>
              If you want to delete all your answers and start over, <span className={styles.click} onClick={() => setResetDialog(true)}>Click Here.</span>
            </div>
          </Label>
          {(showRestartDialog && isIncomplete) && renderConfirmDialog()}
        </div>
      </>}
      {hasCompleted && <div className={cn('mtMed', styles.complete)}>
        {doesNotQualify && <>
          <Title title={`${company} Does Not Qualify for OTCQX`} type='h2' />
          <p>
            We’re sorry. Based on the information you entered, it looks like your company does not meet the financial standards for OTCQX.
          </p>
          <Label title='But you don’t have to stop here. Below are several options to consider:' className='mtLg'>
            <NumberList list={numList} />
          </Label>
        </>}
        {(qualifiesForQXUS || qualifiesForQXIntl) && <div className={styles.success}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={['fal', 'check']} />
          </span>
          <span>
            {`${company} Meets the Financial Standards for ${successResult}`}
          </span>
        </div>
        }
      </div>}
      <SlidePanel
        isOpen={slidePane}
        onClose={handleFormClose}
        title={`Determine Company's Financial Eligibility`}>
        <DetermineFinancialEligibility
          appType={appType}
          companyName={company}
          isDelistFromMajorExchange={application && application.companyInfo && application.companyInfo.isDelistFromMajorExchange}
          section={financialStandards}
          isIncomplete={isIncomplete}
          handleResult={handleResult}
          handleUpdate={handleUpdate} />
      </SlidePanel>
    </div>
  );
};

FinancialStandards.propTypes = {
  name: PropTypes.string,
  saveApplication: PropTypes.func
};

export default FinancialStandards;
