const getConfig = () => {
  if (window.location.host.match(/localhost/)) {
    return {
      REACT_ENV: 'local',
      IDLE_TIMEOUT: process.env.REACT_APP_IDLE_TIMEOUT || 120,
      IDLE_COUNTDOWN: 55,
      IQ_BASE_URL: 'http://nj3webi01tst.test.otc:8080'
    };
  } else if (window.location.host.match(/dev/)) {
    return {
      REACT_ENV: 'dev',
      IDLE_TIMEOUT: 10,
      IDLE_COUNTDOWN: 2,
      IQ_BASE_URL: 'http://nj3webi01tst.test.otc:8080'
    };
  } else if (window.location.host.match(/test/) || window.location.host.match(/tst/)) {
    return {
      REACT_ENV: 'test',
      IDLE_TIMEOUT: 10,
      IDLE_COUNTDOWN: 2,
      IQ_BASE_URL: 'http://nj3webi01tst.test.otc:8080'
    };
  } else if (window.location.host.match(/stage/) || window.location.host.match(/stg/) || window.location.host.match(/.otc\.co/)) {
    return {
      REACT_ENV: 'stage',
      IDLE_TIMEOUT: 55,
      IDLE_COUNTDOWN: 5,
      IQ_BASE_URL: 'http://nj3webi01stg.stage.otc:8080'
    };
  } else if (window.location.host.match(/.otcmarkets\.com/) ||
  window.location.host.match(/www\.otcmarkets\.com/) || window.location.host.match(/prod/)) {
    return {
      REACT_ENV: 'prod',
      IDLE_TIMEOUT: 55,
      IDLE_COUNTDOWN: 5,
      IQ_BASE_URL: 'https://otciq.com'
    };
  }
};

export default getConfig;
