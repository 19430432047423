import { Auth } from 'aws-amplify';
import api from './api';

export const validateSession = async token => {
  const response = await api.request({
    method: 'get',
    url: '/session-token/',
    params: {
      token
    }
  });
  return response.data;
};

export const logoutSession = async _ => {
  const response = await api.request({
    method: 'post',
    url: '/session-token/log-out'
  });
  return response.data;
};

export const signUp = async user => {
  const response = await Auth.signUp(user);
  return response;
};

export const confirmSignUp = async (user, code) => {
  const response = await Auth.confirmSignUp(user, code);
  return response;
};

export async function signInAWS (user, password) {
  const response = await Auth.signIn(user, password);
  return response;
};

export const signInIQ = async (user) => {
  user.email = user.email && user.email.toLowerCase();
  const response = await api.request({
    method: 'post',
    url: '/user/',
    data: user
  });
  return response.data;
};

export const getCurrentAwsUser = async () => {
  const response = await Auth.currentAuthenticatedUser();
  return response;
};

export const getExistingIQUser = async (email, getIqUrl) => {
  const response = await api.request({
    method: 'get',
    url: '/user/',
    params: {
      email,
      iqUrl: getIqUrl
    }
  });
  return response.data;
};

export const resendAwsVerify = async user => {
  const response = await Auth.resendSignUp(user);
  return response;
};

export const resendAwsMFA = async user => {
  const response = await Auth.resendSignUp(user);
  return response;
};

export const resendIQMFA = async userId => {
  const response = await api.request({
    method: 'post',
    url: '/user/challenge',
    params: {
      userId,
      pinCodeLength: 4
    }
  });
  return response.data;
};

export const awsForgotPassword = async user => {
  const response = await Auth.forgotPassword(user);
  return response;
};

export const awsForgotPasswordSubmit = async (user, code, pwd) => {
  const response = Auth.forgotPasswordSubmit(user, code, pwd);
  return response;
};

export const awsChangePassword = async (user, oldPwd, newPwd) => {
  const response = Auth.changePassword(user, oldPwd, newPwd);
  return response;
};

export const updateAwsUser = async (user, attributes) => {
  const response = await Auth.updateUserAttributes(user, attributes);
  return response;
};

export const validateAwsChallenge = async (user, code, type) => {
  const response = await Auth.confirmSignIn(user, code, type);
  return response;
};

export const validateIQChallenge = async (userId, code, challengeId) => {
  const response = await api.request({
    method: 'post',
    url: '/user/validate',
    params: {
      userId,
      pin: code,
      challengeId
    }
  });
  return response.data;
};

export const awsSignout = async () => {
  const response = Auth.signOut();
  return response;
};

export const logUser = async email => {
  const ipData = await api.request({
    method: 'get',
    url: 'https://api.ipify.org/?format=json'
  });

  const ipAddress = ipData && ipData.data && ipData.data.ip;

  if (!ipAddress) return;

  api.request({
    method: 'get',
    url: '/user/login-log',
    params: {
      email,
      appId: 'GW',
      ipAddress: ipAddress.toString()
    }
  });
};
